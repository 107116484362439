/* eslint-disable no-shadow */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/*
 * @Author: wangyafei
 * @Date: 2019-08-19 09:32:31
 * @Last Modified by: gy
 * @Last Modified time: yyyy-09-We 03:50:38
 */
import React from 'react'
import {
  Button,
  Modal,
  message,
  Select,
  Row,
  Col,
  Form,
  Input,
  Tabs,
  Icon,
  Tooltip,
  Spin
} from 'antd'
// import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import * as actions from '@/redux/actions/phonebar'
import {
  getUserSkill,
  Outbound,
  HangUp,
  addSign,
  newCallWorkOrder,
  loginLogSignIn,
  loginLogSignOut,
  getUserInteract // 坐席助手
} from './api'
import DailPhone from './components/DailPhone'
import SwitchOver from './components/SwitchOver'
import SignInControl from './components/SignInControl'
import RestControl from './components/RestControl'
import CircleComponent from './components/CircleComponent'
import Switching from './components/Switching'
import SanFang from './components/SanFang'
import './components/style/OnlineSwitching.less'
import seatInfoData from './SeatInfo'
import WS from './WS'
// import { clearRequest } from '../../pages/System/RequestTypeManager/api'

const Free = require('../../images/tool/free.png')

const { Option } = Select
const { TabPane } = Tabs

const arrNum = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#']

@connect(state => state.dailoutReducer, {
  ...actions
})
class PhoneBar extends React.PureComponent {
  state = {
    isWaihu: false, //  是否点击外呼
    orderId: '', // 是否点击外呼工单Id
    isShowSwitching: false, // 是否正在转接
    isStillWork: false, // 是否正在工作
    idleTime: 240, // 工作时长读秒
    callLastTime: 0, // 通话持续时间
    skillsArr: [],
    workSkillType: '', // 技能
    isCalling: false, // 是否正在通话
    isCallOutValue: 0, // 是否外呼操作，外呼操作为1， 来电操作为0， 默认来电
    isKeepHangPhone: true, // 是否保持
    isMute: true, // 是否静音
    isShowRest: false, // 是否显示休息
    isWorking: true, // 显示为 工作态还是休息态
    isRest: true, // 显示为 休息还是取消休息
    isShowCircle: false, // 是否显示三方操作-------------------------------------------------------------------------------
    isShowBusy: false, // 是否显示示忙操作
    isShowSignIn: false, // 是否显示签入modal
    isShowDail: false, // 是否拨打电话(包含内呼和外呼)
    isShowSwitchOver: false, // 是否转接电话
    isSignIn: true, // 是否签入操作
    isDisabledAll: false, // 是否禁用全部按钮
    isOnlineSwitching: false, // 是否在线转接
    isOnlineSwitchingSearch: false, // 是否在线转接 查询
    isphoneNumber: '', // 是否选择了转接坐席
    seleCss: '', // 在线转接 列表点击选中
    lastarrB: [], // 在线转接 企业
    lastarrM: [], // 在线转接 班长
    englishArr: [], // 在线转接 英文席
    leaderArr: [], // 在线转接 领导
    lastArrCdpf: [], // 在线转接 残联
    offArr: [], // 在线转接 交通局
    isShowListenBox: false, // 是否显示监听通话模态框
    isShowKeepCall: false, // 是否显示保持通话模态框
    isShowListenBoxSearch: false, // 是否显示监听通话模态框 查询
    seleCssListen: '', // 监听通话 列表点击选中
    allList: [], // 总签入席 所有列表
    keepCallList: [], // 保持通话 所有列表
    giiValue: '', // 监听通话 inputValue
    searchArr: [],
    hangUp: '', // 挂断
    inputValue: '', // 二次拨号 提交框value值
    isNumWindeow: false, // 是否展示小键盘
    isCallingBefore: false,
    queueNum: '', // 队列号
    isShowQueue: false, // 是否显示转队列窗口
    findInfoInputValue: '',
    tabFalse: false,
    tabTrue: true,
    tabDefault: '1',
    isWorState: false,
    coidID: '', // 录音成功扩展事 callID
    isSayFree: true,
    workCallId: '',
    isCallSuccess: false, // 7-3新加 处理话后 外呼不成功 工作态倒计时重置bug
    isKeepRelease: false, // 保持挂机
    isListen: false, // 是否显示监听按钮
    isWebsocket: false, // 建立ws连接 21.4.23新+
    isTime: '', // 时间戳
    isWaihuCallPhone: '', // 回访外呼默认回填号码
    dataFalgeL: 0, //  判断是外呼还是呼叫
    isSanfang: false, // 三方
    isKeep: false // 保持按钮
  }

  componentDidMount() {
    this.getSkills()
    try {
      if (typeof Phone.TransToAgent === 'undefined') {
        this.setState({
          isDisabledAll: true
        })
      }
    } catch (err) {
      this.setState({
        isDisabledAll: true
      })
    }

    this.PhoneEventListener = {
      OnRecordSuccessEx: this.doOnRecordSuccessEx,
      OnRequestReleaseEx: this.doOnRequestReleaseEx,
      OnLongNoAnswer: this.doOnLongNoAnswer,
      OnTransOutSuccess: this.doOnTransOutSuccess,
      OnCallInnerSuccess: this.doOnCallInnerSuccess
    }
    window.PhoneEventListener = this.PhoneEventListener
  }

  componentWillReceiveProps(nextProps) {
    // eslint-disable-next-line react/prop-types
    const { isDail, orderId, callerPhone, isRevisit } = nextProps
    const { dispatchDailPhone } = this.props
    // console.log('[ isRevisit ]', isRevisit)
    if (isDail) {
      // console.log('[ will---isRevisit ]', isRevisit)
      this.setState(
        {
          isWaihu: true,
          orderId,
          dataFalge: 0,
          isWaihuCallPhone: callerPhone,
          isRevisit
        },
        () => {
          const { isRevisit } = this.state
          dispatchDailPhone({
            isDail: false,
            orderId: '',
            isRevisit
          })
          this.showPhoneBarModal(
            {
              preventDefault: () => {}
            },
            2
          )
        }
      )
    }
  }

  componentWillUnmount() {
    if (this.getIntervalTime) {
      clearInterval(this.getIntervalTime)
    }
    if (this.getIdleTime) {
      clearTimeout(this.getIdleTime)
    }
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  // 在线转接 查询38 99（企业热线、班长）空闲席位
  QueryBySkillQueue = () => {
    const workNoORskillId = [99, 10812, 11754, 38, 117]
    const monitorArray = [] // 班长
    const businessArray = [] // 企业
    const EnglishArray = [] // 英文席
    const cdpfArr = [] // 残联
    const leaderArr = [] // 领导
    const offArr = [] // 交管局
    for (let i = 0; i < workNoORskillId.length; i += 1) {
      const num = workNoORskillId[i]
      // 如果是38走技能id查询其他走班组查询
      if (num === 38 || num === 99 || num === 117) {
        Phone.RequestAgentBySkillQueueEx(num, 0)
        Phone.QueryAcdID()
        Phone.QueryAgentsByAcdIDEx(Phone.PTotalAcdID)
      } else {
        Phone.QueryAgentsByWorkGroup(num)
      }
      for (let k = 0; k < Phone.AgentNum; k += 1) {
        const gii = Phone.GetAgentIDByIdx(k)
        Phone.QueryAgentStatusEx(gii)
        // 如果是空闲
        if (Phone.AgentInfoEx_CurState === 1) {
          const obj = {
            gii, // 工号
            curState: Phone.AgentInfoEx_CurState, // 状态
            name: Phone.AgentInfoEx_Name, // 姓名
            workGroupId: Phone.AgentInfoEx_WorkGroupID // 组别
          }
          if (num === 11754) {
            offArr.push(obj)
          } else if (num === 99) {
            businessArray.push(obj)
          } else if (num === 10812) {
            cdpfArr.push(obj)
          } else if (num === 38 && obj.workGroupId === 31) {
            leaderArr.push(obj)
          } else if (num === 38) {
            monitorArray.push(obj)
          } else if (num === 117) {
            EnglishArray.push(obj)
          }
        }
      }
    }

    this.setState({
      lastarrB: businessArray,
      lastarrM: monitorArray,
      englishArr: EnglishArray,
      lastArrCdpf: cdpfArr,
      leaderArr,
      offArr
    })
  }

  // 在线转接 选择空闲列表中的坐席
  handClickGroup = gii => {
    this.setState({
      seleCss: gii,
      isphoneNumber: gii
    })
  }

  // 在线转接 请求38、99 打开窗口
  showPhoneBarModalTransfer = () => {
    this.QueryBySkillQueue()
    this.setState({
      isOnlineSwitching: true
    })
  }

  // 在线转接  关闭窗口
  handleCloseModalTransfer = () => {
    this.setState({
      isOnlineSwitching: false,
      findInfoInputValue: ''
    })
  }

  // 在线转接 查询 输入工号
  changeFindInfoValue = e => {
    const changVal = e.target.value
    this.setState({
      findInfoInputValue: changVal
    })
  }

  // 在线转接 点击查询
  searchNum = () => {
    const { findInfoInputValue } = this.state
    Phone.QueryAgentStatusEx(findInfoInputValue)
    const arr = []
    // 如果是空闲
    if (Phone.AgentInfoEx_CurState === 1) {
      const obj = {
        gii: findInfoInputValue, // 工号
        curState: Phone.AgentInfoEx_CurState, // 状态
        name: Phone.AgentInfoEx_Name, // 姓名
        workGroupId: Phone.AgentInfoEx_WorkGroupID // 组别
      }
      arr.push(obj)
    }
    this.setState({
      searchArr: arr,
      isOnlineSwitchingSearch: true
    })
  }

  // 监听通话 点击查询
  ListhenGii = () => {
    console.log('点击查询时~')
    const { giiValue } = this.state
    Phone.QueryAgentStatusEx(giiValue)
    if (Phone.AgentInfoEx_CurState === 5) {
      const rtn = Phone.Listen(Phone.DesktopNo, giiValue)
      if (rtn === 0 || rtn === '0') {
        message.success(`监听${giiValue}通话成功！`)
        // 监听成功后显示挂机按钮 挂机按钮用于退出监听通话
        this.setState({
          isCallingBefore: true,
          isShowListenBox: false,
          isListen: false
        })
      } else {
        message.error('坐席状态异常，监听失败')
        // 转接失败后重新打开窗口获取最新坐席状态
        this.setState({
          isShowListenBox: false
        })
        this.handleListen()
      }
    } else {
      message.error('坐席状态异常，监听失败')
      // 转接失败后重新打开窗口获取最新坐席状态
      this.setState({
        isShowListenBox: false
      })
      this.handleListen()
    }
  }

  // 当监听通话 输入框的值发生改变时
  giiValueChange = e => {
    console.log(e.target.value)
    const giiValueNew = e.target.value

    this.setState({
      giiValue: giiValueNew
    })
  }

  // 在线转接  关闭窗口
  handleCloseModalTransferSearch = () => {
    this.setState({
      isOnlineSwitchingSearch: false,
      giiValue: ''
    })
  }

  QueryBySkillQueueNew = () => {
    const allListNew = []
    Phone.RequestAgentBySkillQueueEx(18, 0)
    Phone.QueryAcdID()
    Phone.QueryAgentsByAcdIDEx(Phone.PTotalAcdID)
    for (let k = 0; k < Phone.AgentNum; k += 1) {
      const gii = Phone.GetAgentIDByIdx(k)
      Phone.QueryAgentStatusEx(gii) // 如果是通话
      if (Phone.AgentInfoEx_CurState === 5) {
        const obj = {
          gii, // 工号
          curState: Phone.AgentInfoEx_CurState, // 状态
          name: Phone.AgentInfoEx_Name, // 姓名
          workGroupId: Phone.AgentInfoEx_WorkGroupID // 组别
        }
        allListNew.push(obj)
      }
    }
    this.setState({
      allList: allListNew
    })
  }

  // 监听通话 请求38、99   打开 监听通话模态框
  handleListen = () => {
    this.QueryBySkillQueueNew()
    this.setState({
      isShowListenBox: true
    })
  }

  // 监听通话  关闭窗口
  handleCloseListenBox = () => {
    this.setState({
      isShowListenBox: false,
      giiValue: ''
    })
  }

  // 二次拨号
  AgentSend = val => {
    const rtn = Phone.AgentSendDTMF(val)
    // 生成日志
    Phone.selfWriteContent = `二次拨号返回码：${rtn}--${Phone.GetPromptByErrorCode(
      rtn
    )}二次拨号的号码：${val}`
    Phone.onSelfWriteToText()
  }

  // 二次拨号 点击键盘
  handleBtnNum = num => {
    const { inputValue } = this.state
    const inputValuels = inputValue
    const addNmu = inputValuels + num
    this.setState({
      inputValue: addNmu
    })
  }

  // 二次拨号 提交键盘
  handleSubmitNum = () => {
    const { inputValue } = this.state
    this.AgentSend(inputValue)
    this.setState({
      inputValue: ''
    })
  }

  // 二次拨号 关闭窗口
  handleCloseModalNum = () => {
    this.setState({
      inputValue: '',
      isNumWindeow: false
    })
  }

  // 二次拨号 打开窗口
  showPhoneBarModalNmu = () => {
    this.setState({
      isNumWindeow: true
    })
  }

  // 二次拨号 手动输入
  changeValue = e => {
    const changVal = e.target.value
    this.setState({
      inputValue: changVal
    })
  }

  // 转队列 选择坐席
  handClickQueue = gii => {
    this.setState({
      seleCss: gii,
      queueNum: gii
    })
  }

  // 转队列 关闭窗口
  handleCloseModalQueue = () => {
    this.setState({
      isShowQueue: false
    })
  }

  // 转队列 打开窗口
  showPhoneBarModalQueue = () => {
    this.setState({
      isShowQueue: true
    })
  }

  // 坐席添加通话标记
  addSigns = () => {
    const { coidID, callLastTime } = this.state
    const parameter = {
      coid: coidID,
      signTime: callLastTime
    }
    addSign(parameter).then(suc => {
      const { data: dataResult } = suc
      const { code } = dataResult
      console.log(code)
    })
  }

  getSkills = () => {
    getUserSkill().then(suc => {
      const { data } = suc
      const { code, data: dataArr, msg } = data
      if (code === 200) {
        this.setState({
          skillsArr: dataArr,
          defaultValueText:
            dataArr && dataArr.length > 0 ? dataArr[0].dictKey : '',
          workSkillType: dataArr && dataArr.length > 0 ? dataArr[0].dictKey : ''
        })
      } else {
        console.log(msg)
        // message.error(msg)
      }
    })
  }

  // 用户挂机提示
  doOnRequestReleaseEx = () => {
    const {
      hangUp,
      isStillWork,
      isMute,
      isKeepRelease,
      isKeepHangPhone
    } = this.state
    // 判断是否是在保持通话期间挂机
    if (!isKeepRelease || isKeepHangPhone) {
      // const { workCallId } = this.state
      // Phone.SetCallKey(workCallId) // 设置唯一标识
      // 用户挂机后，进入6分钟工作态，避免系统分配新的呼叫，坐席处理工单
      // 新增挂断接口
      HangUp({ coid: hangUp })
      // 判断保持功能是否异常
      if (!isKeepHangPhone) {
        this.setState({
          isKeepHangPhone: !isKeepHangPhone
        })
      }
      // 判断挂机时是否是静音
      if (!isMute) {
        this.setState({
          isMute: !isMute
        })
      }
      // 7-3 console.log('统一处理挂机方法:7-3重置接话成功状态为false')
      this.setState(
        {
          isCallSuccess: false,
          isCalling: false,
          isCallOutValue: 0,
          isCallingBefore: false,
          isWaihuCallPhone: '' // 挂机时清空外呼得值
        },
        () => {
          if (isStillWork) {
            clearInterval(this.getIdleTime)
            clearTimeout(this.timeoutId)
            this.setState(
              {
                idleTime: 240
              },
              () => {
                this.exitAgentEnterIdle()
              }
            )
          } else {
            this.setState(
              {
                idleTime: 240,
                isStillWork: true,
                isWorState: true,
                isWorking: false
              },
              () => {
                this.exitAgentEnterIdle()
              }
            )
          }
        }
      )
    }
    // 判断是否是在 保持通话期间挂机
    if (isKeepRelease && !isKeepHangPhone) {
      this.getKeepCall()
    }
  }

  // 接入电话回调函数
  doOnRecordSuccessEx = (pCallID, caller, called, selfRecFileName) => {
    Phone.QueryCallIDOnAgentEx(Phone.WorkNo)
    Phone.QueryCallInfoEx(Phone.GetCallIDByIdx(0))
    const {
      isCallOutValue,
      isWaihu,
      orderId,
      isShowBusy,
      txtPhoneNumVal,
      isRevisit
    } = this.state
    console.log('[ 接入电话orderId ]', orderId)
    console.log('[ 接入电话isRevisit ]', isRevisit)
    this.setState({
      hangUp: pCallID,
      coidID: pCallID, // 录音成功扩展事 callID
      isCallSuccess: true
    })
    // 0519  判断通话前是否示忙
    if (isShowBusy && Phone.IsFree) {
      this.setState({
        isShowBusy: !isShowBusy,
        isSayFree: true
      })
    }
    let orderIdVal = orderId

    if (isWaihu || isCallOutValue === 1) {
      if (isCallOutValue === 1 && isWaihu === false) {
        orderIdVal = '0'
      }
      /* if (
        orderIdVal === '0' ||
        orderIdVal === '' ||
        orderIdVal === undefined ||
        orderIdVal === null
      ) {
        message.error('工单不存在，请联系技术人员。')
      } else { */
      // 调取接口
      const parame = {
        calledPhone: caller,
        callerPhone: called,
        coid: pCallID,
        recordPath: selfRecFileName,
        orderId: orderIdVal,
        // callTypeDetail: isWaihu && isCallOutValue !== 1 ? '1' : '0' // 回拨1   外呼0
        callTypeDetail: isRevisit === '1' ? '1' : '0' // 回拨1   外呼0
        // isWaihu && isCallOutValue !== 1 && isRevisit === '1' ? '1' : '0' // 回拨1   外呼0
      }
      Outbound(parame).then(suc => {
        const { data: dataResult } = suc
        const { code, msg, data } = dataResult
        if (code !== 200) {
          message.info(msg)
        }
        this.setState(
          {
            isWaihu: false,
            workCallId: data.substr(0, 16)
          },
          () => {
            this.getOutbound(data)
          }
        )
        // console.log(`外呼或者isCallOutValue===1设置唯一标识${workCallId}`)
        // Phone.SetCallKey(workCallId) // 设置唯一标识
      })
      // }
    }

    this.setState(
      {
        isCalling: true,
        callLastTime: 0,
        seatCurrentState: '通话中'
      },
      () => {
        /* eslint-disable react/prop-types */
        message.info('您有新的电话接入')
        this.startCountTime()
        if (isCallOutValue === 0) {
          const { history } = this.props
          newCallWorkOrder({
            calledPhone: called,
            callerPhone: caller,
            coid: pCallID,
            recordPath: selfRecFileName
          }).then(suc => {
            const { data: dataResult } = suc
            const { code, data: callOrderInfo } = dataResult
            if (code === 200) {
              const { workOrder: workCallIdState, workCallId } =
                callOrderInfo || {}
              const { id, callTime } = workCallIdState
              this.getOutbound(workCallId)
              // send--坐席助手
              const uuid = this.guid()
              const params = {
                msgId: uuid, // 科大定义
                cmd: 'orderInfo', // 科大定义
                sessionId: /* '55552002' ||  */ txtPhoneNumVal, // 坐席电话号
                agentId: /* '55552002' || */ txtPhoneNumVal, // 坐席电话号
                orderId: id, // 工单id
                userPhone: caller, // 市民号码
                callTime // 来电时间
              }
              console.log('接通坐席电话:', txtPhoneNumVal)
              this.setState(
                {
                  webSendData: params,
                  isKeep: false
                },
                () => {
                  // 来电工单的路由
                  history.push(`/acc_popup_order?orderId=${id}`)
                }
              )

              // const curuserdnNum = Phone.WorkNo
              // history.push(`/acc_popup_order?orderId=${id}&curuserdnNum=${curuserdnNum}`)
            }
          })
        }
      }
    )
  }

  guid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      // eslint-disable-next-line no-bitwise
      const r = (Math.random() * 16) | 0
      // eslint-disable-next-line no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  doOnTransOutSuccess = () => {
    message.success('转接成功') // 转接之后 关闭窗口
    this.setState({
      isOnlineSwitching: false,
      isOnlineSwitchingSearch: false
    })
    this.doOnRequestReleaseEx() // 生成日志
    Phone.selfWriteContent = `转接成功将会打印此记录`
    Phone.onSelfWriteToText()
  }

  // 内呼成功事件
  doOnCallInnerSuccess = () => {
    const { isKeepHangPhone } = this.state
    if (!isKeepHangPhone) {
      this.setState({
        isKeepRelease: true
      })
    }
  }

  // 根据id传递外呼参数 0506
  getOutbound = callOrderInfo => {
    const workCallId = callOrderInfo.substr(0, 16)
    Phone.SetCallKey(workCallId) // 设置唯一标识
  }

  // 自动流程IVR 0402
  transIVR = () => {
    // eslint-disable-next-line no-unused-vars
    const { workCallId } = this.state
    const myDate = new Date()
    const yearVal = myDate.getFullYear()
    const monthVal = myDate.getMonth() + 1
    const dateVal = myDate.getDate()
    const hourVal = myDate.getHours()
    const minutesVal = myDate.getMinutes()
    const secondVal = myDate.getSeconds()

    const monthValStr = `0${monthVal}`
    const newMonthValSubstr = monthValStr.substr(-2)
    const dateValStr = `0${dateVal}`
    const dateValSubstr = dateValStr.substr(-2)
    const hourValStr = `0${hourVal}`
    const hourValSubstr = hourValStr.substr(-2)
    const minutesValStr = `0${minutesVal}`
    const minutesValSubstr = minutesValStr.substr(-2)
    const secondValStr = `0${secondVal}`
    const secondValSubstr = secondValStr.substr(-2)

    // 时间戳标记
    const timeString = `${yearVal}-${newMonthValSubstr}-${dateValSubstr} ${hourValSubstr}:${minutesValSubstr}:${secondValSubstr}`
    // const callDatakey = workCallId // 唯一标识
    let callDatakey = Phone.CallInfoEx_Key // 唯一标识
    let callDataNo = Phone.WorkNo // 工号
    let callDataPhone = Phone.CallInfoEx_Ani // 来电号码
    const callDataTime = timeString // 来电时间
    if (callDataNo.toString().length === 4) {
      callDataNo += ' '
    }
    // 判断如果主叫是12345取被叫
    if (callDataPhone === 12345 || callDataPhone === '12345') {
      callDataPhone = Phone.CallInfoEx_DialedNumber
    }
    // 判断来电号码是否符合存储标准
    if (callDataPhone.length !== 11) {
      if (callDataPhone.length < 11) {
        const a = 11 - callDataPhone.length
        let str = ''
        for (let i = 0; i < a; i += 1) {
          // str += ` `
          str += ' '
        }
        callDataPhone += `${str}`
      } else {
        callDataPhone = callDataPhone.substr(-11)
      }
    }
    if (callDatakey === '' || callDatakey === null) {
      // 如果未正常设置唯一标识使用该数据填充字段
      callDatakey = '0               '
    }
    const callData = `${callDatakey}  ${callDataNo}04 ${callDataPhone}   ${callDataTime}`
    // callDatakey + '  ' + callDataNo + '04' + callDataPhone + callDataTime

    // Phone.SetCallKey(callDatakey) // 设置唯一标识
    Phone.SetCallDataEx(5, callData)

    const selfCallData = Phone.QueryCallDataEx(5)
    console.log(`获取设置成功的华为唯一标识:${selfCallData}`)

    const rtn = Phone.TransToIVR(5, 0, '112')
    if (rtn !== 0) {
      this.hangOutPhone()
    }
    // 生成日志
    Phone.selfWriteContent = `挂机评价返回码：${rtn}--${Phone.GetPromptByErrorCode(
      rtn
    )}`
    Phone.onSelfWriteToText()
  }

  //  示忙后显示按钮，提示示闲操作
  doOnLongNoAnswer = () => {
    this.setState({
      isShowBusy: true,
      isSayFree: false
    })
  }

  // 开始计时操作
  startCountTime = () => {
    // 开始计时
    if (this.getIntervalTime) {
      clearInterval(this.getIntervalTime)
    }
    this.getIntervalTime = setInterval(() => {
      const { callLastTime } = this.state
      this.setState({
        callLastTime: callLastTime + 1
      })
    }, 1000)
  }

  // 话后开始计时
  startCountIdleTime = () => {
    // 开始计时
    if (this.getIdleTime) {
      clearInterval(this.getIdleTime)
    }
    this.getIdleTime = setInterval(() => {
      const { idleTime } = this.state
      this.setState({
        idleTime: idleTime - 1
      })
    }, 1000)
  }

  // 退出话后定时任务
  exitAgentEnterIdle = () => {
    Phone.SetAgentAutoEnterIdle(false)
    this.startCountIdleTime()
    clearInterval(this.getIntervalTime)
    // clearInterval(this.getIdleTime1)
    // clearInterval(this.getIdleTime2)
    this.timeoutId = setTimeout(() => {
      clearInterval(this.getIdleTime)
      this.setState(
        {
          isStillWork: false,
          isWorState: false,
          isWorking: true
        },
        () => {
          Phone.SetAgentAutoEnterIdle(true)
          const rtn = Phone.AgentEnterIdle()
          // 生成日志
          Phone.selfWriteContent = `自动退出话后返回码：${rtn}--${Phone.GetPromptByErrorCode(
            rtn
          )}`
          Phone.onSelfWriteToText()
        }
      )
    }, 240000)
  }

  // 挂机
  hangOutPhone = () => {
    const { isCallSuccess, isListen } = this.state
    const rtn = Phone.ReleaseCallEx(5) // 触发挂机后读秒操作19/11/04ps：(调用)

    if (!isListen && (Phone.workNo === 16888 || Phone.workNo === '16888')) {
      this.setState({
        isListen: !isListen
      })
    }

    if (Phone.FreeStatus === 1 && isCallSuccess) {
      this.doOnRequestReleaseEx()
    } else {
      this.setState({
        isCalling: false,
        isCallOutValue: 0,
        isCallingBefore: false
      })
    }
    this.setState({
      isKeep: false
    })

    // 生成日志
    Phone.selfWriteContent = `挂机返回码：${rtn}--${Phone.GetPromptByErrorCode(
      rtn
    )}是否通话成功：${
      Phone.FreeStatus
    }--状态码1代表成功通话其他表示本次通话未接通`
    Phone.onSelfWriteToText()
  }

  // 根据呼叫标识挂机
  hangOutIdPhone = () => {
    Phone.QueryCallIDOnAgentEx(Phone.WorkNo)
    const callId = Phone.GetCallIDByIdx(1)
    if (callId !== 0) {
      const rtn = Phone.ReleaseCallByCallID(callId)
      if (rtn === '0' || rtn === 0) {
        this.setState({
          isKeepRelease: false
        })
      }
      message.success(Phone.GetPromptByErrorCode(rtn))
    }
  }

  // 保持通话
  hangOnPhone = () => {
    const { isKeepHangPhone } = this.state
    if (isKeepHangPhone) {
      // 保持通话
      this.keepCall()
    } else {
      this.getKeepCall()
    }
  }

  keepCall = () => {
    // 该方法把座席正在通话的普通语音呼叫保持（只对语音呼叫有效）。
    const rtn = Phone.HoldEx()
    if (rtn === '0' || rtn === 0) {
      this.QueryBySkillQueuekeep()
      this.setState({
        isKeepHangPhone: false,
        isCallingBefore: false,
        isShowKeepCall: true
      })
    } else {
      message.error(Phone.GetPromptByErrorCode(rtn))
    }
  }

  QueryBySkillQueuekeep = () => {
    const keepCallListNew = []
    Phone.RequestAgentBySkillQueueEx(18, 0)
    Phone.QueryAcdID()
    Phone.QueryAgentsByAcdIDEx(Phone.PTotalAcdID)
    for (let k = 0; k < Phone.AgentNum; k += 1) {
      const gii = Phone.GetAgentIDByIdx(k)
      Phone.QueryAgentStatusEx(gii) // 如果是通话
      if (Phone.AgentInfoEx_CurState === 1 && gii === 16918) {
        const obj = {
          gii, // 工号
          curState: Phone.AgentInfoEx_CurState, // 状态
          name: Phone.AgentInfoEx_Name, // 姓名
          workGroupId: Phone.AgentInfoEx_WorkGroupID // 组别
        }
        keepCallListNew.push(obj)
      }
    }
    this.setState({
      keepCallList: keepCallListNew
    })
  }

  getKeepCall = () => {
    this.hangOutIdPhone()
    // 查询座席当前保持的所有语音呼叫
    const rtn = Phone.QueryHoldListEx()
    if (rtn === '0' || rtn === 0) {
      // 获取callid
      const holdcallid = Phone.GetCallIDByIdx(0)
      const rtn1 = Phone.GetHoldEx(holdcallid)
      this.setState({
        isKeepHangPhone: true,
        isCallingBefore: true
      })
      if (rtn1 === 0 || rtn1 === '0') {
        message.success(`${Phone.GetPromptByErrorCode(rtn1)}`)
      } else {
        message.error(`保持通话出现异常！${rtn1}`)
      }
      console.log(`${Phone.GetPromptByErrorCode(rtn1)}`)
    } else {
      message.error(`${Phone.GetPromptByErrorCode(rtn)}rtn`)
    }
  }

  // 关闭弹出层
  handleCloseModal = () => {
    this.setState({
      isShowDail: false,
      isShowSwitchOver: false,
      isShowSignIn: false,
      isShowCircle: false,
      isShowRest: false,
      isShowSwitching: false,
      isSanfang: false
    })
    document.getElementById('root').style.height = 'auto'
  }

  // 确认操作
  handleOKAndCloseModal = () => {
    // 基础信息表单部分
    const modalFormResult = this.modalFormControl.getItemsValues()
    const {
      isShowSwitching,
      isShowDail,
      isShowSwitchOver,
      isShowSignIn,
      isShowCircle,
      isShowRest,
      isSanfang
    } = this.state

    if (modalFormResult !== -1) {
      // 拨打操作确认事件
      if (isShowDail) {
        // 表示弹出层为拨打电话操作
        this.setState(
          {
            isShowDail: false
          },
          () => {
            this.doDailProcess(modalFormResult)
          }
        )
      }
      // 转接和外呼操作确认事件
      if (isShowSwitchOver) {
        // 表示弹出层为拨打电话操作
        this.setState(
          {
            isShowSwitchOver: false
          },
          () => {
            this.doSwitchOverProcess(modalFormResult)
          }
        )
      }
      // 转接和外呼操作确认事件
      if (isShowSwitching) {
        // 表示弹出层为拨打电话操作
        this.setState(
          {
            isShowSwitching: false
          },
          () => {
            this.doSwitchingProcess(modalFormResult)
          }
        )
      }

      // 三方操作确认事件
      if (isShowCircle) {
        this.setState(
          {
            isShowCircle: false
          },
          () => {
            this.doShowCircleProcess(modalFormResult)
          }
        )
      }

      // 休息操作确认事件
      if (isShowRest) {
        this.setState(
          {
            isShowRest: false
          },
          () => {
            this.doShowRestProcess(modalFormResult)
          }
        )
      }

      // 签入操作确认事件
      if (isShowSignIn) {
        this.setState(
          {
            // isLoadingIndex: true
          },
          () => {
            const result = this.modalFormControl.getItemsValues()
            if (result !== -1) {
              const { workSkillType } = this.state
              let workSkillTypeNew = workSkillType
              const {
                txtMostlyAddress,
                txtSubordinationAddress,
                // 上线隐藏
                // txtMostlyAddress36,
                // txtSubordinationAddress36,
                serverType,
                txtPhoneID
              } = PhoneBarConstants
              const {
                seatType,
                txtPhoneNum,
                txtLabourNo,
                password,
                CTI
              } = result
              console.log(
                '%c [ result ]-1119',
                'font-size:13px; background:pink; color:#bf2c9f;',
                result
              )
              // const { txtLabourNo, password } = result
              // 先初始化openeye控件

              Phone.CcsID = serverType
              Phone.WorkNo = txtLabourNo
              Phone.Password = password
              Phone.MyID = txtPhoneID

              // // 测试 16976工号签入新地址
              // if (txtLabourNo === 16976 || txtLabourNo === '16976') {
              //   Phone.MainCcsIp = '132.77.149.133'
              //   // Phone.BackCcsIP = '132.77.156.201'
              //   console.log('16976--3.6MCP地址')
              //   this.autoGraphJoin(seatType, txtPhoneNum)
              // } else if (txtLabourNo === 16918 || txtLabourNo === '16918') {
              //   Phone.MainCcsIp = '132.77.149.133'
              //   // Phone.BackCcsIP = '132.77.156.201'
              //   console.log('16918--3.6MCP地址')
              // } else {
              //   Phone.MainCcsIp = txtMostlyAddress
              //   Phone.BackCcsIP = txtSubordinationAddress
              //   console.log('旧地址')
              // }

              // 7-2修改 --3.5地址--上线打开
              Phone.MainCcsIp = txtMostlyAddress
              Phone.BackCcsIP = txtSubordinationAddress

              // 2021.6.4修改--上线隐藏
              /* if (CTI === 1) {
                console.log('[ 3.5地址 ]', '3.5地址')
                Phone.MainCcsIp = txtMostlyAddress
                Phone.BackCcsIP = txtSubordinationAddress
              } else {
                console.log('[ 3.6地址 ]', `3.6地址${txtMostlyAddress36}`)
                Phone.MainCcsIp = txtMostlyAddress36
                Phone.BackCcsIP = txtSubordinationAddress36
              } */

              // Phone.MainCcsIp = '132.77.149.133'

              const initialResult = Phone.Initial()
              if (initialResult === '0' || initialResult === 0) {
                if (workSkillTypeNew === '1' || workSkillTypeNew === 1) {
                  workSkillTypeNew = '1,5,34'
                }
                if (workSkillTypeNew.indexOf('_') > 0) {
                  workSkillTypeNew = workSkillTypeNew.replace(/_/g, ',')
                }
                const exitDouHaoState = workSkillTypeNew.indexOf(',')
                if (exitDouHaoState === -1) {
                  // 不存在逗号 调用之前的初始化方法
                  if (workSkillTypeNew) {
                    // 获取当前坐席工号
                    const workNo = Phone.WorkNo
                    // 开始重设技能
                    Phone.ResetSkillEx_Begin()
                    // 强制重设技能id为18
                    Phone.ResetSkillEx_AddSkillID(18)
                    // 添加坐席从下拉框选择的技能id
                    Phone.ResetSkillEx_AddSkillID(workSkillTypeNew)

                    Phone.ResetSkillEx(workNo)
                  }
                  this.autoGraphJoin(seatType, txtPhoneNum, txtLabourNo)
                } else {
                  // 转变坐席技能id字符串为 坐席技能数组
                  const workSkillIdArray = workSkillTypeNew.split(',')
                  // 获取当前坐席工号
                  const workNo = Phone.WorkNo
                  // 开始重设技能
                  Phone.ResetSkillEx_Begin()
                  // 强制重设技能id为18
                  Phone.ResetSkillEx_AddSkillID(18)
                  // 遍历添加坐席技能id组
                  workSkillIdArray.map(item => {
                    // 添加坐席技能id
                    Phone.ResetSkillEx_AddSkillID(item)
                    return item
                  })
                  Phone.ResetSkillEx(workNo)
                  this.autoGraphJoin(seatType, txtPhoneNum, txtLabourNo)
                }
              } else {
                message.error(Phone.GetPromptByErrorCode(initialResult))
              }
            }
          }
        )
      }

      // 热转
      if (isSanfang) {
        this.setState(
          {
            isSanfang: false
          },
          () => {
            this.doShowSanFangProcess(modalFormResult)
          }
        )
        document.getElementById('root').style.height = 'auto'
      }
    }
  }

  //  签入操作留痕
  // autoGraphJoin = (seatType, txtPhoneNum) => {
  //   const result = Phone.SignInEx('TFF', seatType, txtPhoneNum)
  //   if (result === '0' || result === 0) {
  //     this.setState(
  //       {
  //         isSignIn: false,
  //         isShowSignIn: false
  //       },
  //       () => {
  //         try {
  //           // 签入操作
  //           loginLogSignIn()
  //         } catch (e) {
  //           console.log(e)
  //         }
  //       }
  //     )
  //     if (Phone.WorkNo === 16888 || Phone.WorkNo === '16888') {
  //       console.log('--------12345---------')
  //       this.setState({
  //         isListen: true
  //       })
  //     }
  //   } else {
  //     message.error(Phone.GetPromptByErrorCode(result))
  //   }
  //   // 生成日志
  //   Phone.selfWriteContent = `工号：${
  //     Phone.WorkNo
  //   }电话号码：${txtPhoneNum}签入返回码：${result}--${Phone.GetPromptByErrorCode(
  //     result
  //   )}`
  //   Phone.onSelfWriteToText()
  // }

  //  签入操作留痕--坐席助手
  autoGraphJoin = (seatType, txtPhoneNum, txtLabourNo) => {
    // const { userInfo } = this.props
    // const { isHelperInteract } = userInfo
    // console.log('userInfo:', JSON.stringify(userInfo))
    const result = Phone.SignInEx('TFF', seatType, txtPhoneNum)
    if (result === '0' || result === 0) {
      console.log('签入phone:', txtPhoneNum)
      console.log('签入坐席工号:', txtLabourNo)
      getUserInteract(txtLabourNo).then(suc => {
        console.log('getUserInteract:', JSON.stringify(suc))
        const { data: dataResult } = suc
        const {
          code,
          data: { isHelperInteract },
          timestamp
        } = dataResult
        console.log('isHelperInteract', isHelperInteract)
        let isDoWsObj = {}
        if (code === 200 && isHelperInteract && isHelperInteract === '1') {
          // 是否可以进行ws连接
          // const timestamp = moment().valueOf()
          isDoWsObj = {
            isTime: timestamp,
            txtPhoneNumVal: /* '55552002' || */ txtPhoneNum, // 坐席电话号
            isWebsocket: true
          }
          console.log('可以进行ws连接，坐席电话号是：', txtPhoneNum)
          console.log('可以进行ws连接，坐席工号是：', txtLabourNo)
        } else {
          message.error('坐席无转写权限')
          // 不进行ws连接
          isDoWsObj = {
            isWebsocket: false
          }
          console.log('没有进行ws连接，坐席电话号是：', txtPhoneNum)
          console.log('没有进行ws连接，坐席工号是：', txtLabourNo)
        }
        this.setState(
          {
            isSignIn: false,
            isShowSignIn: false,
            ...isDoWsObj
          },
          () => {
            try {
              // 签入操作
              loginLogSignIn()
            } catch (e) {
              console.log(e)
            }
          }
        )
        if (Phone.WorkNo === 16888 || Phone.WorkNo === '16888') {
          console.log('--------12345---------')
          this.setState({
            isListen: true
          })
        }
      })
    } else {
      message.error(Phone.GetPromptByErrorCode(result))
    }
    // 生成日志
    Phone.selfWriteContent = `工号：${
      Phone.WorkNo
    }电话号码：${txtPhoneNum}签入返回码：${result}--${Phone.GetPromptByErrorCode(
      result
    )}`
    Phone.onSelfWriteToText()
  }

  // 实际执行拨打电话的操作
  doDailProcess = modalFormResult => {
    const { phoneNumber, isInnerOrOuter } = modalFormResult
    this.setState({
      isCallOutValue: 1
    })
    // 11.27更改
    if (isInnerOrOuter === 1) {
      // 表示内呼操作
      const rtn = Phone.CallInnerEx(phoneNumber, 5)
      if (rtn === '0' || rtn === 0) {
        message.success('内呼成功')
      } else if (rtn === '20505' || rtn === 20505) {
        message.info('该账号未登录')
      } else if (rtn === '20515' || rtn === 20515) {
        message.error('座席忙, 请稍后再拨...')
      } else {
        const alr = Phone.GetPromptByErrorCode(rtn)
        message.error(alr)
      }
      // 生成日志
      Phone.selfWriteContent = `呼叫返回码：${rtn}--${Phone.GetPromptByErrorCode(
        rtn
      )}呼叫号码：${phoneNumber}`
      Phone.onSelfWriteToText()
    } else {
      // 外呼操作
      // 外呼操作中第二个参数传号码数字
      const rtn = Phone.CallOutEx3(5, 12345, phoneNumber, '', 0, 0, '')
      if (rtn === '0' || rtn === 0) {
        this.setState({
          isCallingBefore: true
        })
      } else {
        const alr = Phone.GetPromptByErrorCode(rtn)
        message.error(alr)
      }
      // 生成日志
      Phone.selfWriteContent = `呼叫返回码：${rtn}--${Phone.GetPromptByErrorCode(
        rtn
      )}呼叫号码：${phoneNumber}`
      Phone.onSelfWriteToText()
    }
  }

  // 实际执行拨打电话的操作
  doSwitchingProcess = modalFormResult => {
    const { phoneNumber, isInnerOrOuter } = modalFormResult
    this.setState({
      isCallOutValue: 1
    })
    if (isInnerOrOuter === 1) {
      /* 转座席
       * 第一个参数:呼叫媒体类型(默认为5，代表语音)
       * 第二个参数:转移模式Integer（IN）。 0：释放转。2：成功转。 3：指定转。 4：合并转
       * 第三个参数:目标座席工号
       */
      const rtn = Phone.TransToAgent(5, 2, phoneNumber)
      if (rtn === '0' || rtn === 0) {
        message.success('内呼成功')
      } else if (rtn === '20505' || rtn === 20505) {
        message.info('该账号未登录')
      } else if (rtn === '20515' || rtn === 20515) {
        message.error('座席忙, 请稍后再拨...')
      } else {
        const alr = Phone.GetPromptByErrorCode(rtn)
        message.error(alr)
      }
      // 生成日志
      Phone.selfWriteContent = `转接返回码：${rtn}--${Phone.GetPromptByErrorCode(
        rtn
      )}`
      Phone.onSelfWriteToText()
    } else {
      // 外呼操作
      const rtn = Phone.TransOutEx2(5, '', phoneNumber, 2, 0, '')
      if (rtn === '0' || rtn === 0) {
        this.setState({
          isCallingBefore: true
        })
      } else {
        const alr = Phone.GetPromptByErrorCode(rtn)
        message.error(alr)
      }
      // 生成日志
      Phone.selfWriteContent = `转接返回码：${rtn}--${Phone.GetPromptByErrorCode(
        rtn
      )}`
      Phone.onSelfWriteToText()
    }
  }

  // 实际转接电话的操作
  doSwitchOverProcess = modalFormResult => {
    // 转接分两块业务逻辑(转接成功后 座席(第一座席)通话都会挂断)
    const { phoneNumber } = modalFormResult
    // 进入队列
    const rtn = Phone.TransToQueueEx(5, 2, phoneNumber)
    if (rtn === '0' || rtn === 0) {
      message.error('转接成功')
    } else {
      message.error('对应席位没有空闲业务员')
    }
  }

  doSwitchOverTransfer = () => {
    const { isphoneNumber } = this.state
    const phoneNumber = Number(isphoneNumber) // 0429增加转接前是否空闲判断
    Phone.QueryAgentStatusEx(phoneNumber) // 如果坐席状态不是1空闲 则不会执行转接方法
    if (Phone.AgentInfoEx_CurState === 1) {
      Phone.SetAgentAutoEnterIdle(false)
      const rtn = Phone.TransToAgent(5, 2, phoneNumber)
      setTimeout(() => {
        Phone.QueryAgentStatusEx(phoneNumber)
        if (Phone.AgentInfoEx_CurState === 7) {
          message.error('坐席状态异常，转接失败')
          this.showPhoneBarModalTransfer()
        }
      }, 3000)

      this.setState({
        isOnlineSwitching: false,
        isOnlineSwitchingSearch: false
      })
      if (rtn !== 0) {
        const alr = Phone.GetPromptByErrorCode(rtn)
        message.error(alr)
      } // // 转接之后 关闭窗口 // this.setState({ //   isOnlineSwitching: false, //   isOnlineSwitchingSearch: false // }) // 生成日志
      Phone.selfWriteContent = `转接返回码：${rtn}--${Phone.GetPromptByErrorCode(
        rtn
      )}转接的号码：${phoneNumber}`
      Phone.onSelfWriteToText()
    } else {
      message.error('坐席状态异常，转接失败')
      // 转接失败后重新打开窗口获取最新坐席状态
      this.setState({
        isOnlineSwitching: false,
        isOnlineSwitchingSearch: false
      })
      this.showPhoneBarModalTransfer()
    }
  }

  // 监听通话  模态框点击onOk的处理事件
  doSwitchOverTransferNew = () => {
    const { isphoneNumber } = this.state
    const phoneNumber = Number(isphoneNumber)
    Phone.QueryAgentStatusEx(phoneNumber)

    if (Phone.AgentInfoEx_CurState === 5) {
      const rtn = Phone.Listen(Phone.DesktopNo, phoneNumber)
      if (rtn === 0 || rtn === '0') {
        message.success(`监听${phoneNumber}通话成功！`)
        // 监听成功后显示挂机按钮 挂机按钮用于退出监听通话
        this.setState({
          isCallingBefore: true,
          isShowListenBox: false,
          isListen: false
        })
      } else {
        message.error('坐席状态异常，监听失败')
        // 转接失败后重新打开窗口获取最新坐席状态
        this.setState({
          isShowListenBox: false
        })
        this.handleListen()
      }
    } else {
      message.error('坐席状态异常，监听失败')
      // 转接失败后重新打开窗口获取最新坐席状态
      this.setState({
        isShowListenBox: false
      })
      this.handleListen()
    }
  }

  // 保持通话  模态框点击onOk的处理事件
  keepCallOK = () => {
    const { isphoneNumber } = this.state
    const phoneNumber = Number(isphoneNumber)
    Phone.QueryAgentStatusEx(phoneNumber)

    if (Phone.AgentInfoEx_CurState === 1) {
      const rtn = Phone.CallInnerEx(phoneNumber, 5)
      if (rtn === 0 || rtn === '0') {
        message.success(`通话成功！`) // 监听成功后显示挂机按钮 挂机按钮用于退出监听通话
        this.setState({
          isShowKeepCall: false
        })
      } else {
        message.error('坐席状态异常，通话失败') // 转接失败后重新打开窗口获取最新坐席状态
        this.QueryBySkillQueuekeep()
        this.setState({
          isShowKeepCall: false
        })
      }
    } else {
      message.error('坐席状态异常，通话失败') // 转接失败后重新打开窗口获取最新坐席状态
      this.QueryBySkillQueuekeep()
      this.setState({
        isShowKeepCall: true
      })
    }
  }

  // 保持通话 模态框 点击onCancel的处理事件
  closeKeepCall = () => {
    this.setState({
      isShowKeepCall: false,
      giiValue: ''
    })
  }

  // 保持通话 输入框后的  点击 呼叫 的处理
  ListhenGiiKeepCall = () => {
    console.log('呼叫专家席')
    const { giiValue } = this.state

    // 表示内呼操作
    const rtn = Phone.CallInnerEx(giiValue, 5)
    if (rtn === '0' || rtn === 0) {
      message.success(`呼叫${giiValue}成功`)
      this.setState({
        isShowKeepCall: false
      })
    } else {
      message.error(Phone.GetPromptByErrorCode(rtn))
      this.QueryBySkillQueuekeep()
      this.setState({
        isShowKeepCall: true
      })
    }
    // 生成日志
    Phone.selfWriteContent = `呼叫返回码：${rtn}--${Phone.GetPromptByErrorCode(
      rtn
    )}呼叫号码：${giiValue}`
    Phone.onSelfWriteToText()
  }

  keepcallView = () => {
    this.QueryBySkillQueuekeep()
    this.setState({
      isShowKeepCall: true
    })
  }

  doSwitchOverQueue = () => {
    const { queueNum } = this.state
    const phoneNumber = Number(queueNum)
    const queuePhone = Phone.TransToQueueEx(phoneNumber)
    if (queuePhone === 0) {
      message.success('转队列成功，自动释放电话')
      this.setState({
        isShowQueue: false
      })
    } else {
      message.error(`转队列失败，${queuePhone}`)
    }
  }

  // 实际三方操作
  doShowCircleProcess = modalFormResult => {
    Phone.SetAgentAutoEnterIdle(false)
    // 三方操作分两块业务逻辑(三方成功后 座席(第一座席)通话不会挂断  三方通话)
    const { phoneNumber, isInnerOrOuter } = modalFormResult
    const phoneNumberNew =
      phoneNumber.indexOf(' ') === -1
        ? phoneNumber
        : `9${phoneNumber.split(' ')[1]}`

    console.log(
      '%c [ phoneNumberNew ]-1603',
      'font-size:13px; background:pink; color:#bf2c9f;',
      phoneNumberNew
    )
    if (isInnerOrOuter === 2) {
      // 内部三方业务逻辑(拨打另一座席号码(第二座席) 第一座席通话不挂断 三方通话)
      const rtn = Phone.InternalHelpEx(5, phoneNumberNew, 2)
      if (rtn === '0' || rtn === 0) {
        message.success('操作成功')
      } else {
        const alr = Phone.GetPromptByErrorCode(rtn)
        message.error(alr)
      }
      // 生成日志
      Phone.selfWriteContent = `三方返回码：${rtn}--${Phone.GetPromptByErrorCode(
        rtn
      )}三方呼叫的号吗：${phoneNumberNew}`
      Phone.onSelfWriteToText()
    } else if (isInnerOrOuter === 1) {
      // 外部三方业务逻辑(拨打另一外部号码(处理纠纷 相关部门) 第一座席通话不挂断 三方通话)
      const rtn = Phone.TransOutEx2(5, '', phoneNumberNew, 4, 0, '')
      if (rtn === '0' || rtn === 0) {
        message.success('呼叫成功')
      } else {
        const alr = Phone.GetPromptByErrorCode(rtn)
        message.error(alr)
      }
      // 生成日志
      Phone.selfWriteContent = `三方返回码：${rtn}--${Phone.GetPromptByErrorCode(
        rtn
      )}三方呼叫的号吗：${phoneNumberNew}`
      Phone.onSelfWriteToText()
    } else if (isInnerOrOuter === 3) {
      // 外部转接
      const rtn = Phone.TransOutEx2(5, '', phoneNumberNew, 2, 0, '')
      if (rtn !== 0) {
        const alr = Phone.GetPromptByErrorCode(rtn)
        message.error(alr)
      }
      // 生成日志
      Phone.selfWriteContent = `外部转接返回码：${rtn}--${Phone.GetPromptByErrorCode(
        rtn
      )}外部转接的号吗：${phoneNumberNew}`
      Phone.onSelfWriteToText()
    }
  }

  // 实际休息操作
  doShowRestProcess = modalFormResult => {
    const { phoneNumber } = modalFormResult
    const resetSeconds = phoneNumber * 60
    message.info(`休息时间${resetSeconds}秒`)
    // 座席调用此函数请求休息
    const rtn = Phone.Rest(resetSeconds)
    if (rtn === '0' || rtn === 0) {
      Phone.IsFree = false
      Phone.IsRestRequest = false
      message.success('休息成功')
      this.setState({
        isRest: false
      })
    } else {
      const alr = Phone.GetPromptByErrorCode(rtn)
      message.error(alr)
    }
    // 生成日志
    Phone.selfWriteContent = `休息返回码：${rtn}--${Phone.GetPromptByErrorCode(
      rtn
    )}`
    Phone.onSelfWriteToText()
  }

  // 显示Modal标题
  getModalTitle = () => {
    const {
      isShowSignIn,
      isShowDail,
      isShowSwitching,
      isShowSwitchOver,
      isShowCircle, // 三方
      isShowRest,
      isSanfang
    } = this.state
    let result = null
    if (isShowSignIn) {
      result = <span>坐席签入</span>
    }
    if (isShowDail) {
      result = <span>呼叫</span>
    }
    if (isShowSwitchOver) {
      result = <span>转专家坐席</span>
    }
    if (isShowSwitching) {
      result = <span>电话转接</span>
    }
    if (isShowCircle) {
      result = <span>三方转接</span>
    }
    if (isShowRest) {
      result = <span>我要休息</span>
    }
    if (isSanfang) {
      result = <span>三方1</span>
    }
    return result
  }

  // 根据不同类型，弹出不同的Modal组件
  getFormControl = () => {
    const {
      isShowSignIn, // 签入
      isShowDail, // 拨打电话
      isShowSwitching, // 转接
      // isShowListenBox, // 监听
      isShowCircle, // 三方
      isShowSwitchOver, // 转接
      isShowRest, // 休息
      isWaihuCallPhone, // 回访外呼默认回填号码
      dataFalge,
      isSanfang
    } = this.state
    let result = null
    // 签入
    if (isShowSignIn) {
      result = (
        <SignInControl
          wrappedComponentRef={frm => {
            this.modalFormControl = frm
          }}
        />
      )
    }
    // 三方和拨打电话操作
    if (isShowDail) {
      result = (
        <DailPhone
          wrappedComponentRef={frm => {
            this.modalFormControl = frm
          }}
          dataFalge={dataFalge}
          isWaihuCallPhone={isWaihuCallPhone}
        />
      )
    }
    // 转接
    if (isShowSwitching) {
      result = (
        <Switching
          wrappedComponentRef={frm => {
            this.modalFormControl = frm
          }}
        />
      )
    }
    if (isShowCircle) {
      result = (
        <CircleComponent
          wrappedComponentRef={frm => {
            this.modalFormControl = frm
          }}
        />
      )
    }
    // 转接和外呼
    if (isShowSwitchOver) {
      result = (
        <SwitchOver
          wrappedComponentRef={frm => {
            this.modalFormControl = frm
          }}
        />
      )
    }
    // 休息
    if (isShowRest) {
      result = (
        <RestControl
          wrappedComponentRef={frm => {
            this.modalFormControl = frm
          }}
        />
      )
    }

    // 三方
    if (isSanfang) {
      result = (
        <SanFang
          wrappedComponentRef={frm => {
            this.modalFormControl = frm
          }}
        />
      )
    }
    return result
  }

  // 按钮点击事件， 弹出窗口以便于用户输入
  // flag取值意义: 0表示三方  1表示转接  2表示拨打  4表示外呼操作  5表示休息操作
  // dataFalge:  区分外呼和呼叫
  showPhoneBarModal = (e, flag) => {
    e.preventDefault()
    const { isRest } = this.state
    let result = null
    /* if (dataFalge) {
      this.setState({
        dataFalge
      })
    } */
    switch (flag) {
      case 0:
        result = {
          isShowCircle: true
        }
        document.getElementById('root').style.height = '100vh'
        break
      case 1:
        result = {
          isShowSwitchOver: true
        }
        break
      case 2:
        result = {
          isShowDail: true
        }
        /* this.setState({
          isRevisit: '0'
        }) */
        break
      case 3: // 转接操作
        result = {
          isShowSwitching: true
        }
        break
      case 5:
        if (!isRest) {
          // 取消休息
          const rtn = Phone.CancelRest()
          if (rtn === '0' || rtn === 0) {
            Phone.IsFree = true
            Phone.IsRestRequest = true
            message.success('成功取消休息')
            this.setState({
              isRest: true
            })
          } else {
            const alr = Phone.GetPromptByErrorCode(rtn)
            message.error(alr)
          }
          // 生成日志
          Phone.selfWriteContent = `取消休息返回码：${rtn}--${Phone.GetPromptByErrorCode(
            rtn
          )}`
          Phone.onSelfWriteToText()
        } else {
          result = {
            isShowRest: true
          }
        }
        break
      default:
        break
    }

    if (result) {
      this.setState({
        ...result
      })
    }
    // 键盘enter 触发buttonclick事件  该功能暂时屏蔽
    // console.log('点击各个不同操作按钮 打开了模态框 调用原生事件')
    // document.onkeydown = ev => {
    //   const eventObj = ev || (window.event ? window.event : null)
    //   if (eventObj.keyCode === 13) {
    //     console.log(
    //       '按了enter键 开始执行确定操作 得判断必须是在这个模态框打开 这个事件才有效 否则 弹框出现一次后 键盘事件全局注册 其它地方的键盘事件依然触发 特别是工单编辑 测试一下 后续再考虑'
    //     )
    //     this.handleOKAndCloseModal()
    //   }
    // }
  }

  // 表示签入操作
  singinPhone = e => {
    e.preventDefault()
    const { isSignIn } = this.state
    const { userInfo } = this.props
    console.log(`工号:${userInfo.userNum}`)
    // 待签入操作
    if (isSignIn) {
      console.log('点击签入按钮 调用启用控件')
      const webActiveState = Phone.onSlefInitActive(userInfo.userNum)
      if (webActiveState) {
        this.setState({
          isShowSignIn: true
        })
      } else {
        Modal.confirm({
          title: '启用浏览器的Active控件--才能签入电话',
          content: '请重启浏览器, 启用Active控件',
          okText: '确认',
          cancelText: '取消'
        })
      }
    } else {
      // 签出操作
      this.signOut()
    }
  }

  signOut = () => {
    const rtn = Phone.SignOutEx()
    // 生成日志  ---- 与index签出成功事件(关闭写日志对象 逻辑冲突 删除)
    // Phone.selfWriteContent = `签出返回码：${rtn}--${Phone.GetPromptByErrorCode(
    //   rtn
    // )}`
    // Phone.onSelfWriteToText()
    if (rtn === '0' || rtn === 0) {
      message.success('签出成功')
      Phone.EndTask()
      this.setState(
        {
          isSignIn: true,
          isStillWork: false, // 是否正在工作
          idleTime: 240, // 工作时长读秒
          callLastTime: 0, // 通话持续时间
          isCalling: false, // 是否正在通话
          isCallOutValue: 0, // 是否外呼操作，外呼操作为1， 来电操作为0， 默认来电
          isKeepHangPhone: true, // 是否保持
          isMute: true, // 是否静音
          isShowRest: false, // 是否显示休息
          isWorking: true, // 显示为 工作态还是休息态
          isRest: true, // 显示为 休息还是取消休息
          isShowCircle: false, // 是否显示三方操作
          isShowBusy: false, // 是否显示示忙操作
          isShowSignIn: false, // 是否显示签入modal
          isShowDail: false, // 是否拨打电话(包含内呼和外呼)
          isShowSwitchOver: false, // 是否转接电话
          isWorState: false,
          isCallingBefore: false,
          isSayFree: true,
          isCallSuccess: false,
          isKeepRelease: false,
          isListen: false,
          isWebsocket: false // 坐席助手
        },
        () => {
          //  签出操作
          loginLogSignOut()
        }
      )

      if (this.getIntervalTime) {
        clearInterval(this.getIntervalTime)
      }
      if (this.getIdleTime) {
        clearInterval(this.getIdleTime)
      }
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
      }
      // if (this.timeoutIdQuit) {
      //   clearTimeout(this.timeoutIdQuit)
      // }

      /* if (this.isSetFn) {
        clearInterval(this.isSetFn)
      } */

      if (localStorage.getItem('setFn')) {
        clearInterval(localStorage.getItem('setFn'))
      }
    }
  }

  // 切换工作态
  toggleWorkingStatus = e => {
    e.preventDefault()
    this.toggleStatus()
  }

  toggleStatus = () => {
    clearInterval(this.getIdleTime)
    // 关闭6分钟工作态
    clearTimeout(this.timeoutId)
    this.setState(
      {
        idleTime: 240,
        isStillWork: false,
        isWorState: false,
        isWorking: true
      },
      () => {
        Phone.SetAgentAutoEnterIdle(true)
        const rtn = Phone.AgentEnterIdle()
        // 生成日志
        Phone.selfWriteContent = `手动退出话后返回码：${rtn}--${Phone.GetPromptByErrorCode(
          rtn
        )}`
        Phone.onSelfWriteToText()
      }
    )
  }

  // 切换静音状态
  toggleMuteStatus = e => {
    e.preventDefault()
    const { isMute } = this.state
    let result = true
    if (isMute) {
      const rtn = Phone.BeginMuteUserEx()
      if (rtn === '0' || rtn === 0) {
        message.success('静音成功')
      } else {
        result = false
        message.error('静音失败')
      }
      // 生成日志
      Phone.selfWriteContent = `静音返回码：${rtn}--${Phone.GetPromptByErrorCode(
        rtn
      )}`
      Phone.onSelfWriteToText()
    } else {
      const rtn = Phone.EndMuteUserEx()
      if (rtn === '0' || rtn === 0) {
        message.success('取消静音成功')
      } else {
        result = false
        message.error('取消静音失败')
      }
      // 生成日志
      Phone.selfWriteContent = `取消静音返回码：${rtn}--${Phone.GetPromptByErrorCode(
        rtn
      )}`
      Phone.onSelfWriteToText()
    }
    if (result) {
      this.setState({
        isMute: !isMute
      })
    }
  }

  // 示闲
  toggleRestStatus = () => {
    const { isShowBusy, isWorState } = this.state

    // 是否开始示忙
    if (!isShowBusy) {
      // 将座席状态设置为忙态。
      const rtn = Phone.SayBusy()
      if (rtn === '0' || rtn === 0) {
        this.setState({
          isShowBusy: !isShowBusy
        })
      } else {
        message.error('操作失败')
      }
      // 话后工作态 点击示忙按钮 直接退出工作态
      if (isWorState) {
        this.toggleStatus()
      }
      // 生成日志
      Phone.selfWriteContent = `示忙返回码：${rtn}--${Phone.GetPromptByErrorCode(
        rtn
      )}`
      Phone.onSelfWriteToText()
    } else {
      // 将座席状态设置为闲态。
      const rtn = Phone.SayFree()
      if (rtn === '0' || rtn === 0) {
        this.setState({
          isShowBusy: !isShowBusy,
          isSayFree: true
        })
      } else {
        message.error('操作失败')
      }
      // 生成日志
      Phone.selfWriteContent = `示闲返回码：${rtn}--${Phone.GetPromptByErrorCode(
        rtn
      )}`
      Phone.onSelfWriteToText()
    }
  }

  doChangeSkill = val => {
    let workSkillTypeNew = val
    if (val === 1 || val === '1') {
      workSkillTypeNew = '1,5,34'
    }
    this.setState(
      {
        defaultValueText: val,
        workSkillType: workSkillTypeNew
      },
      () => {
        this.ResetSkillExFn(workSkillTypeNew)
      }
    )
  }

  ResetSkillExFn = workSkillType => {
    const workSkillTypeX = workSkillType.indexOf('_')
    const exitDouHaoState = workSkillType.indexOf(',')
    let workSkillTypeNew = workSkillType
    if (workSkillTypeX > 0) {
      workSkillTypeNew = workSkillType.replace(/_/g, ',')
    }
    if (exitDouHaoState === -1 && workSkillTypeX === -1) {
      // 不存在逗号 调用之前的初始化方法
      if (workSkillType) {
        // 获取当前坐席工号
        const workNo = Phone.WorkNo // 开始重设技能
        Phone.ResetSkillEx_Begin() // 强制重设技能id为18
        Phone.ResetSkillEx_AddSkillID(18) // 添加坐席从下拉框选择的技能id
        Phone.ResetSkillEx_AddSkillID(workSkillType)
        Phone.ResetSkillEx(workNo)
      }
    } else {
      // 转变坐席技能id字符串为 坐席技能数组
      const workSkillIdArray = workSkillTypeNew.split(',') // 获取当前坐席工号
      const workNo = Phone.WorkNo // 开始重设技能
      Phone.ResetSkillEx_Begin() // 强制重设技能id为18
      Phone.ResetSkillEx_AddSkillID(18) // 遍历添加坐席技能id组
      workSkillIdArray.map(item => {
        // 添加坐席技能id
        Phone.ResetSkillEx_AddSkillID(item)
        return item
      })
      Phone.ResetSkillEx(workNo)
    }
  }

  handClickHeapRow = res => {
    const heapRow = Phone.TransToQueueEx(5, 2, res)
    if (heapRow === 0) {
      message.success('转队列成功，释放电话')
    } else {
      message.success(`转队列失败,${heapRow}`)
    }
  }

  OmittedNames = res => {
    if (res) {
      if (res.length <= 4) {
        return res
      }

      return (
        <Tooltip placement="top" title={res}>
          <span>{`${res.substr(0, 4)}...`}</span>
        </Tooltip>
      )
    }
    return null
  }

  // 一键签入操作
  quickSingin = () => {
    const { userInfo } = this.props
    // 获取到坐席电脑的ip
    let txtPhoneNumVal = ''
    seatInfoData.map(item => {
      if (item.ip === Phone.selfWindowIpString) {
        txtPhoneNumVal = item.openeye
      }
      return item
    })

    const result = {
      seatType: '4', // 坐席类型  PC+PHONE  4
      txtPhoneNum: txtPhoneNumVal, // openeye账号 55551340
      txtLabourNo: userInfo.userNum,
      password: userInfo.workPwd
    }
    if (result !== -1) {
      const { workSkillType } = this.state
      let workSkillTypeNew = workSkillType
      const {
        txtMostlyAddress,
        txtSubordinationAddress,
        // txtMostlyAddress36,
        // txtSubordinationAddress36,
        serverType,
        txtPhoneID
      } = PhoneBarConstants
      const { seatType, txtPhoneNum, txtLabourNo, password } = result
      // const { txtLabourNo, password } = result
      // 先初始化openeye控件
      Phone.MainCcsIp = txtMostlyAddress
      Phone.CcsID = serverType
      Phone.WorkNo = txtLabourNo
      Phone.Password = password
      Phone.MyID = txtPhoneID
      // Phone.BackCcsIP = txtSubordinationAddress
      const initialResult = Phone.Initial()
      // const timestamp = moment().valueOf()
      if (initialResult === '0' || initialResult === 0) {
        // this.setState({
        //   txtPhoneNumVal, // openeye账号 55551340
        //   isWebsocket: true,
        //   isTime: timestamp
        // })
        // 7-2
        console.log('点击一键签入按钮 电话初始化成功')
        const webActiveState = Phone.onSlefInitActive(userInfo.userNum)
        if (webActiveState) {
          console.log('一键签入按钮下 电话初始化成功创建日志文件')
        } else {
          Modal.confirm({
            title: '启用浏览器的Active控件--才能签入电话',
            content: '请重启浏览器, 启用Active控件',
            okText: '确认',
            cancelText: '取消'
          })
        }
        // 7-2
        if (workSkillTypeNew === '1' || workSkillTypeNew === 1) {
          workSkillTypeNew = '1,5,34'
        }
        if (workSkillTypeNew.indexOf('_') > 0) {
          workSkillTypeNew = workSkillTypeNew.replace(/_/g, ',')
        }
        const exitDouHaoState = workSkillTypeNew.indexOf(',')
        if (exitDouHaoState === -1) {
          // 不存在逗号 调用之前的初始化方法
          if (workSkillTypeNew) {
            // 获取当前坐席工号
            const workNo = Phone.WorkNo
            // 开始重设技能
            Phone.ResetSkillEx_Begin()
            // 强制重设技能id为18
            Phone.ResetSkillEx_AddSkillID(18)
            // 添加坐席从下拉框选择的技能id
            Phone.ResetSkillEx_AddSkillID(workSkillTypeNew)

            Phone.ResetSkillEx(workNo)
          }
          this.autoGraphJoin(seatType, txtPhoneNum, userInfo.userNum)
        } else {
          // 转变坐席技能id字符串为 坐席技能数组
          const workSkillIdArray = workSkillTypeNew.split(',')
          // 获取当前坐席工号
          const workNo = Phone.WorkNo
          // 开始重设技能
          Phone.ResetSkillEx_Begin()
          // 强制重设技能id为18
          Phone.ResetSkillEx_AddSkillID(18)
          // 遍历添加坐席技能id组
          workSkillIdArray.map(item => {
            // 添加坐席技能id
            Phone.ResetSkillEx_AddSkillID(item)
            return item
          })

          Phone.ResetSkillEx(workNo)
          this.autoGraphJoin(seatType, txtPhoneNum, userInfo.userNum)
        }
      } else {
        message.error(Phone.GetPromptByErrorCode(initialResult))
      }
    }
  }

  // 清除心跳包
  /* isSetFn = fn => {
    console.log('[ fn ]', fn)
    return fn
  } */

  // 保持
  keepPhone = e => {
    e.preventDefault()
    const { isMute, isKeep } = this.state
    if (!isKeep) {
      if (!isMute) {
        message.error('请先取消静音！')
      } else {
        const rtn = Phone.HoldEx()
        if (rtn === '0' || rtn === 0) {
          message.success('保持成功')
        } else {
          message.error(Phone.GetPromptByErrorCode(rtn))
        }
      }
    } else {
      // 查询座席当前保持的所有语音呼叫
      const rtn = Phone.QueryHoldListEx()
      if (rtn === '0' || rtn === 0) {
        // 获取callid
        const holdcallid = Phone.GetCallIDByIdx(0)
        console.log(
          '%c [ holdcallid ]-2236',
          'font-size:13px; background:pink; color:#bf2c9f;',
          holdcallid
        )
        const rtn1 = Phone.GetHoldEx(holdcallid)
        if (rtn1 === 0 || rtn1 === '0') {
          // message.success(`${Phone.GetPromptByErrorCode(rtn1)}`)
          message.success('已取消保持')
        } else {
          message.error(`保持通话出现异常！${rtn1}`)
        }
        console.log(`${Phone.GetPromptByErrorCode(rtn1)}`)
      } else {
        message.error(`${Phone.GetPromptByErrorCode(rtn)}rtn`)
      }
    }
    this.setState({
      isKeep: !isKeep
    })
  }

  // 扩展三方通话
  confJoinExFn = e => {
    console.log(
      '%c [ 扩展三方通话 ]-2231',
      'font-size:13px; background:pink; color:#bf2c9f;',
      '扩展三方通话'
    )
    e.preventDefault()
    const { isKeep } = this.state
    if (!isKeep) {
      message.error('请先保持呼叫！')
    } else {
      this.hangOutIdPhone()
      // 查询座席当前保持的所有语音呼叫
      const rtn = Phone.QueryHoldListEx()
      console.log(
        '%c [ rtn ]-2239',
        'font-size:13px; background:pink; color:#bf2c9f;',
        rtn
      )
      if (rtn === '0' || rtn === 0) {
        // 获取callid
        const holdcallid = Phone.GetCallIDByIdx(0)
        console.log(
          '%c [ holdcallid ]-2237',
          'font-size:13px; background:pink; color:#bf2c9f;',
          holdcallid
        )
        const rtn1 = Phone.ConfJoinEx(holdcallid)
        console.log(
          '%c [ rtn1 ]-2238',
          'font-size:13px; background:pink; color:#bf2c9f;',
          rtn1
        )
        if (rtn1 === 0 || rtn1 === '0') {
          this.setState({
            isKeep: !isKeep
          })
          message.success('三方通话成功！')
        } else {
          message.error(`保持通话出现异常！${rtn1}`)
        }
        console.log(`${Phone.GetPromptByErrorCode(rtn1)}`)
      } else {
        message.error(`${Phone.GetPromptByErrorCode(rtn)}rtn`)
      }
    }
  }

  // 三方外呼
  sanFangFn = e => {
    e.preventDefault()
    const { isKeep } = this.state
    if (!isKeep) {
      this.setState({
        isSanfang: true
      })
      document.getElementById('root').style.height = '100vh'
    } else {
      // 查询座席当前保持的所有语音呼叫
      const rtn = Phone.QueryHoldListEx()
      console.log(
        '%c [ rtn ]-2239',
        'font-size:13px; background:pink; color:#bf2c9f;',
        rtn
      )
      if (rtn === '0' || rtn === 0) {
        // 获取callid
        const holdcallid = Phone.GetCallIDByIdx(0)
        console.log(
          '%c [ holdcallid ]-2237',
          'font-size:13px; background:pink; color:#bf2c9f;',
          holdcallid
        )
        const rtn1 = Phone.ConfJoinEx(holdcallid)
        console.log(
          '%c [ rtn1 ]-2238',
          'font-size:13px; background:pink; color:#bf2c9f;',
          rtn1
        )
        if (rtn1 === 0 || rtn1 === '0') {
          this.setState({
            isKeep: !isKeep
          })
          message.success('三方通话成功！')
        } else {
          message.error(`保持通话出现异常！${rtn1}`)
        }
        console.log(`${Phone.GetPromptByErrorCode(rtn1)}`)
        // 生成日志
        Phone.selfWriteContent = `ConfJoinEx：${rtn}--${Phone.GetPromptByErrorCode(
          rtn
        )}`
        Phone.onSelfWriteToText()
      } else {
        message.error(`${Phone.GetPromptByErrorCode(rtn)}rtn`)
      }
    }
  }

  // 热转确认
  doShowSanFangProcess = modalFormResult => {
    const { isMute } = this.state
    const { phoneNumber, isInnerOrOuter } = modalFormResult
    const phoneNumberNew =
      phoneNumber.indexOf(' ') === -1
        ? phoneNumber
        : `9${phoneNumber.split(' ')[1]}`

    console.log(
      '%c [ phoneNumberNew ]-2411',
      'font-size:13px; background:pink; color:#bf2c9f;',
      phoneNumberNew
    )
    if (isMute) {
      if (isInnerOrOuter === 2) {
        const rtn = Phone.InternalHelpEx(5, phoneNumberNew, 2)
        if (rtn === '0' || rtn === 0) {
          message.success('操作成功')
        } else {
          const alr = Phone.GetPromptByErrorCode(rtn)
          message.error(alr)
        }
        // 生成日志
        Phone.selfWriteContent = `三方返回码：${rtn}--${Phone.GetPromptByErrorCode(
          rtn
        )}三方呼叫的号吗：${phoneNumberNew}`
        Phone.onSelfWriteToText()
      } else {
        const rtn1 = Phone.TransOutEx2(5, '', phoneNumberNew, 3, 0, '')
        console.log(
          '%c [ rtn1 ]-2365',
          'font-size:13px; background:pink; color:#bf2c9f;',
          rtn1
        )
        if (rtn1 === '0' || rtn1 === 0) {
          message.success('呼叫成功')
          this.setState({
            isKeep: true
          })
        } else {
          message.error(Phone.GetPromptByErrorCode(rtn1))
        }
        // 生成日志
        Phone.selfWriteContent = `TransOutEx2：${rtn1}--${Phone.GetPromptByErrorCode(
          rtn1
        )}`
        Phone.onSelfWriteToText()
      }
    } else {
      message.error('请先取消静音！')
    }
    /* const rtn = Phone.HoldEx() // 保持
      console.log(
        '%c [ rtn ]-2369',
        'font-size:13px; background:pink; color:#bf2c9f;',
        rtn
      )
      if (rtn === '0' || rtn === 0) {
        message.success('保持成功')
        // 外呼
        const rtn1 = Phone.TransOutEx2(5, '', phoneNumber, 3, 0, '')
        console.log(
          '%c [ rtn1 ]-2365',
          'font-size:13px; background:pink; color:#bf2c9f;',
          rtn1
        )
        if (rtn1 === '0' || rtn1 === 0) {
          message.success('呼叫成功')
          this.setState({
            isKeep: true
          })
        } else {
          message.error(Phone.GetPromptByErrorCode(rtn1))
        }
      } else {
        message.error(Phone.GetPromptByErrorCode(rtn))
      } */
  }

  releaseCallOne = e => {
    e.preventDefault()
    Phone.ReleaseCall(5)
  }

  releaseCallTwo = e => {
    e.preventDefault()
    Phone.ReleaseCallEx(5)
  }

  releaseCallThree = e => {
    e.preventDefault()
    Phone.QueryCallIDOnAgentEx(Phone.WorkNo)
    const callId = Phone.GetCallIDByIdx(1)
    if (callId !== 0) {
      const rtn = Phone.ReleaseCallByCallID(callId)

      message.success(Phone.GetPromptByErrorCode(rtn))
    }
  }

  releaseCallFour = e => {
    e.preventDefault()
    Phone.QueryCallIDOnAgentEx(Phone.WorkNo)
    const callId = Phone.GetCallIDByIdx(1)
    if (callId !== 0) {
      const rtn = Phone.ReleaseCallByCallIDEx(callId)

      message.success(Phone.GetPromptByErrorCode(rtn))
    }
  }

  render() {
    const {
      isShowSwitching, // 是否转接操作
      isStillWork, // 是否正在工作,工作中则读秒，没有工作不读秒
      idleTime, // 工作计时时间
      callLastTime, // 计时时间
      skillsArr,
      isKeepHangPhone, // 是否呼叫保持
      isMute, // 是否静音
      isWorking, // 显示为工作态还是休息中
      isRest, // 显示为休息还是取消休息
      isSignIn, // 是签入还是签出操作
      isShowDail, // 拨打操作
      isShowSwitchOver, // 转接操作
      isShowSignIn, // 签入操作
      isShowBusy, // 示忙示闲操作
      isShowRest, // 是否显示休息modal
      isShowCircle, // 三方操作
      isCalling, // 是否正在通话
      isDisabledAll, // 是否禁用工具条
      isOnlineSwitching, // 是否在线转接
      isOnlineSwitchingSearch,
      seleCss, // 在线转接 列表点击选中
      lastarrB, // 在线转接 企业
      lastarrM, // 在线转接 班长
      englishArr, // 在线转接 英文席
      leaderArr, // 领导
      offArr, // 交管局
      lastArrCdpf, // 在线转接 残联
      isShowListenBox, // 是否显示监听通话模态框
      allList, // 监听通话 总签入席
      giiValue, // 监听通话 输入框的值
      isNumWindeow, // 是否显示键盘
      inputValue, // 小键盘提交值
      isCallingBefore, // 是否 拨打成功前调用
      isShowQueue,
      defaultValueText,
      findInfoInputValue,
      tabFalse,
      tabTrue,
      tabDefault,
      isWorState,
      searchArr,
      isSayFree,
      isKeepRelease,
      isListen,
      isShowKeepCall,
      keepCallList,
      isWebsocket,
      txtPhoneNumVal,
      isTime,
      webSendData,
      isKeep,
      isSanfang
    } = this.state

    /**
     *
     * isStillWork: false, // 是否正在工作
      isCalling: false, // 是否正在通话
      isKeepHangPhone: true, // 是否保持
      isMute: true, // 是否静音
      isShowRest: false, // 是否显示休息
      isWorking: true, // 显示为 工作态还是休息态
      isRest: true, // 显示为 休息还是取消休息
      isShowCircle: false, // 是否显示三方操作
      isShowBusy: false, // 是否显示示忙操作
      isShowSignIn: false, // 是否显示签入modal
      isShowDail: false, // 是否拨打电话(包含内呼和外呼)
      isShowSwitchOver: false, // 是否转接电话
      isSignIn: true, // 是否签入操作
      isDisabledAll: false // 是否禁用全部按钮
     */

    return (
      <React.Fragment>
        {!isDisabledAll && (
          <React.Fragment>
            <div className="jz-nav-bar-wrapper">
              {!isSayFree && (
                <Button
                  type="primary"
                  icon="redo"
                  onClick={this.toggleRestStatus}
                  style={{
                    backgroundColor: '#1e93ea',
                    borderColor: '#40a9ff',
                    color: '#ffffff'
                  }}
                >
                  {/* <i className="work-state" style={{ color: '#ffffff' }} /> */}
                  连接异常检查环境后重试
                </Button>
              )}
              {isCalling && <span>{`通话: ${callLastTime}秒`}</span>}
              {isStillWork && !isCalling && (
                <span>{`话后: ${idleTime}秒`}</span>
              )}
              <Select
                style={{ width: 160 }}
                showSearch
                value={defaultValueText}
                placeholder="请选择技能"
                onChange={this.doChangeSkill}
              >
                {skillsArr.map(item => {
                  return (
                    <Option key={item.id} value={item.dictKey}>
                      {item.dictValue}
                    </Option>
                  )
                })}
              </Select>

              {/* 签入后,保持中,呼叫成功 */}
              {/* {!isSignIn && isKeepRelease && !isKeepHangPhone && (
                <Button type="primary" onClick={this.hangOutIdPhone}>
                  <i className="hang" />
                  挂机1
                </Button>
              )} */}

              {/* 签入后, 通话中显示  原挂机方法hangOutPhone 加入IVR之后挂机方法改为 transIVR */}
              {!isSignIn && isCalling && isKeepHangPhone && (
                <Button type="primary" onClick={this.transIVR}>
                  <i className="hang" />
                  评价
                </Button>
              )}

              {/* 签入后, 通话中显示 */}
              {!isSignIn && (isCalling || isCallingBefore) && isKeepHangPhone && (
                <Button type="primary" onClick={this.hangOutPhone}>
                  <i className="hang" />
                  挂机
                </Button>
              )}

              {/* 签入后, 非通话中, 示闲, 工作态, 取保持 显示 */}
              {/* 11.27号关闭 */}
              {!isSignIn &&
                (!isCalling || isShowBusy || !isWorking || !isKeepHangPhone) &&
                !isCallingBefore && (
                  <Button
                    type="primary"
                    onClick={e => this.showPhoneBarModal(e, 2)}
                  >
                    <i className="dial" />
                    呼叫
                  </Button>
                )}

              {/* 签入后, 通话中显示 */}
              {!isSignIn && isCalling && (
                <Button type="primary" onClick={this.hangOnPhone}>
                  <i className="wait" />
                  {isKeepHangPhone ? '求助' : '结束求助'}
                </Button>
              )}

              {!isSignIn && !isKeepHangPhone && !isKeepHangPhone && (
                <Button type="primary" onClick={this.keepcallView}>
                  <i className="wait" />
                  求助
                </Button>
              )}

              {/* 签入后, 通话中显示 */}
              {!isSignIn && isCalling && isKeepHangPhone && (
                <Button
                  type="primary"
                  onClick={e => this.showPhoneBarModal(e, 0)}
                >
                  <i className="circle" />
                  三方
                </Button>
              )}

              {!isSignIn && isCalling && (
                <Button type="primary" onClick={this.sanFangFn}>
                  <i className="circle" />
                  {!isKeep ? '热转' : '解除静音'}
                </Button>
              )}

              {/* {!isSignIn && isCalling && (
                <Button type="primary" onClick={this.releaseCallOne}>
                  释放1
                </Button>
              )}
              {!isSignIn && isCalling && (
                <Button type="primary" onClick={this.releaseCallTwo}>
                  释放2
                </Button>
              )}
              {!isSignIn && isCalling && (
                <Button type="primary" onClick={this.releaseCallThree}>
                  释放3
                </Button>
              )}
              {!isSignIn && isCalling && (
                <Button type="primary" onClick={this.releaseCallFour}>
                  释放4
                </Button>
              )} */}

              {/* {!isSignIn && isCalling && (
                <Button type="primary" onClick={this.keepPhone}>
                  {!isKeep ? '保持' : '取消保持'}
                </Button>
              )} */}

              {/* 签入后, 通话中显示 */}
              {/* {!isSignIn && isCalling && (
                <Button
                  type="primary"
                  onClick={e => this.showPhoneBarModal(e, 3)}
                >
                  <i className="circle" />
                  转接
                </Button>
              )} */}

              {/* 签入后, 通话中显示 在线转接 */}

              {!isSignIn && isCalling && isKeepHangPhone && (
                <Button type="primary" onClick={this.showPhoneBarModalTransfer}>
                  <i className="circle" />
                  转接
                </Button>
              )}

              {/* 监听通话 按钮 */}
              {!isSignIn && isListen && (
                <Button icon="redo" type="primary" onClick={this.handleListen}>
                  监听
                </Button>
              )}

              {/* <Button type="primary" onClick={this.handleListen}>
                监听
              </Button> */}

              {/* 签入后, 通话中显示 */}
              {/* {!isSignIn && isCalling && (
                <Button type="primary" onClick={this.showPhoneBarModalQueue}>
                  <i className="transfer" />
                  专席
                </Button>
              )} */}

              {/* 签入后, 通话中显示 */}

              {/* {!isSignIn && isCalling && (
                <Button
                  type="primary"
                  onClick={e => this.showPhoneBarModal(e, 1)}
                >
                  <i className="transfer" />
                  专席
                </Button>
              )} */}

              {/* 签入后, 通话中显示 */}
              {!isSignIn && isCalling && isKeepHangPhone && (
                <Button type="primary" onClick={this.toggleMuteStatus}>
                  <i className="dial" />
                  {isMute ? '静音' : '结束静音'}
                </Button>
              )}

              {/* 签入后, 非通话中显示, 工作态 */}
              {!isSignIn &&
                !isCalling &&
                (!isShowBusy ? (
                  <Button type="primary" onClick={this.toggleRestStatus}>
                    <i className="dial" style={{ color: '#ffffff' }} />
                    示忙
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={this.toggleRestStatus}
                    style={{
                      backgroundColor: '#1e93ea',
                      borderColor: '#40a9ff',
                      color: '#ffffff'
                    }}
                  >
                    <i className="dial" style={{ color: '#ffffff' }} />
                    示闲
                  </Button>
                ))}

              {/* 签入后, 非通话中显示, 工作态 */}
              {/* {!isSignIn &&
                (!isCalling || !isWorking) &&
                !isWorState &&
                (!isShowBusy ? (
                  <Button type="primary" onClick={this.toggleRestStatus}>
                    <i className="dial" style={{ color: '#ffffff' }} />
                    示忙
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={this.toggleRestStatus}
                    style={{
                      backgroundColor: '#1e93ea',
                      borderColor: '#40a9ff',
                      color: '#ffffff'
                    }}
                  >
                    <i className="dial" style={{ color: '#ffffff' }} />
                    示闲
                  </Button>
                ))} */}

              {/* 签入后, 非通话中显示, 示闲的时候显示 */}
              {!isSignIn && (!isCalling || isShowBusy) && isWorState && (
                <Button
                  type="primary"
                  onClick={this.toggleWorkingStatus}
                  style={{
                    backgroundColor: '#1e93ea',
                    borderColor: '#40a9ff',
                    color: '#ffffff'
                  }}
                >
                  <i className="work-state" style={{ color: '#ffffff' }} />
                  {isWorking ? '工作态' : '退出工作态'}
                </Button>
              )}

              {/* 签入后, 非通话中显示, 示闲的时候, 工作态显示 */}
              {!isSignIn &&
                (!isCalling || isShowBusy || !isWorking) &&
                !isWorState && (
                  <Button
                    type="primary"
                    onClick={e => this.showPhoneBarModal(e, 5)}
                  >
                    <i className="dial" />
                    {isRest ? '休息' : '取消休息'}
                  </Button>
                )}

              {/* 签入后, 通话中显示 二次拨号 */}
              {!isSignIn && isCalling && isKeepHangPhone && (
                <Button type="primary" onClick={this.showPhoneBarModalNmu}>
                  <i className="transfer" />
                  拨号
                </Button>
              )}

              {/* 注册 */}
              {/* 一键签入 */}
              {!isCalling && isSignIn && (
                <Button type="primary" onClick={this.quickSingin}>
                  一键签入
                </Button>
              )}
              {/* 注册 */}
              {/* 签入后, 非通话中 签入/签出 显示 */}
              {!isCalling && (
                <Button type="primary" onClick={this.singinPhone}>
                  <i className="transfer" />
                  {isSignIn ? '签入' : '签出'}
                </Button>
              )}

              {/* 签入后, 通话中显示 录音标记 */}
              {!isSignIn && isCalling && (
                <Button type="primary" onClick={this.addSigns}>
                  <Icon type="environment" />
                </Button>
              )}
            </div>

            {/* 组合模态框 */}
            <Modal
              title={this.getModalTitle()}
              width={600}
              visible={
                isShowDail ||
                isShowSwitchOver ||
                isShowSignIn ||
                isShowRest ||
                isShowCircle ||
                isShowSwitching ||
                isSanfang // 三方
              }
              destroyOnClose
              onOk={this.handleOKAndCloseModal}
              onCancel={this.handleCloseModal}
            >
              {this.getFormControl()}
            </Modal>

            {/* 在线转接 */}
            <Modal
              title="在线转接"
              width={1200}
              visible={isOnlineSwitching}
              onOk={this.doSwitchOverTransfer}
              onCancel={this.handleCloseModalTransfer}
            >
              <Form className="jz-modal-flex">
                <Form.Item className="zb-modal">
                  <Row gutter={24}>
                    <Col span={6}>
                      <Input
                        placeholder="请输入"
                        value={findInfoInputValue}
                        onChange={this.changeFindInfoValue}
                      />
                    </Col>
                    <Col span={2}>
                      <Button type="primary" onClick={this.searchNum}>
                        查找
                      </Button>
                    </Col>
                  </Row>
                  <Tabs defaultActiveKey={tabDefault}>
                    <TabPane
                      tab={<span>班长席</span>}
                      key="1"
                      disabled={lastarrM.length > 0 ? tabFalse : tabTrue}
                    >
                      <Row>
                        {lastarrM.map(item => {
                          return (
                            <Col
                              span={3}
                              className={
                                seleCss === item.gii
                                  ? 'selectionBorders'
                                  : 'borders'
                              }
                              onClick={() => this.handClickGroup(item.gii)}
                            >
                              <p>
                                <img src={Free} alt="Free" width="35" />
                              </p>
                              <p>{`${item.gii}:${item.name}`}</p>
                              <p>
                                <span className="kx">空闲</span>
                              </p>
                            </Col>
                          )
                        })}
                      </Row>
                    </TabPane>
                    <TabPane
                      tab={<span>企业服务热线</span>}
                      key="2"
                      disabled={lastarrB.length > 0 ? tabFalse : tabTrue}
                    >
                      <Row>
                        {lastarrB.map(item => {
                          return (
                            <Col
                              span={3}
                              className={
                                seleCss === item.gii
                                  ? 'selectionBorders'
                                  : 'borders'
                              }
                              onClick={() => this.handClickGroup(item.gii)}
                            >
                              <p>
                                <img src={Free} alt="Free" width="35" />
                              </p>
                              <p>{`${item.gii}:${item.name}`}</p>
                              <p>
                                <span className="kx">空闲</span>
                              </p>
                            </Col>
                          )
                        })}
                      </Row>
                    </TabPane>
                    <TabPane
                      tab={<span>英文席</span>}
                      key="3"
                      disabled={englishArr.length > 0 ? tabFalse : tabTrue}
                    >
                      <Row>
                        {englishArr.map(item => {
                          return (
                            <Col
                              span={3}
                              className={
                                seleCss === item.gii
                                  ? 'selectionBorders'
                                  : 'borders'
                              }
                              onClick={() => this.handClickGroup(item.gii)}
                            >
                              <p>
                                <img src={Free} alt="Free" width="35" />
                              </p>
                              <p>{`${item.gii}:${item.name}`}</p>
                              <p>
                                <span className="kx">空闲</span>
                              </p>
                            </Col>
                          )
                        })}
                      </Row>
                    </TabPane>
                    <TabPane
                      tab={<span>残联心理咨询</span>}
                      key="4"
                      disabled={lastArrCdpf.length > 0 ? tabFalse : tabTrue}
                    >
                      <Row>
                        {lastArrCdpf.map(item => {
                          return (
                            <Col
                              span={3}
                              className={
                                seleCss === item.gii
                                  ? 'selectionBorders'
                                  : 'borders'
                              }
                              onClick={() => this.handClickGroup(item.gii)}
                            >
                              <p>
                                <img src={Free} alt="Free" width="35" />
                              </p>
                              <p>{`${item.gii}:${item.name}`}</p>
                              <p>
                                <span className="kx">空闲</span>
                              </p>
                            </Col>
                          )
                        })}
                      </Row>
                    </TabPane>
                    <TabPane
                      tab={<span>领导接听</span>}
                      key="5"
                      disabled={leaderArr.length > 0 ? tabFalse : tabTrue}
                    >
                      <Row>
                        {leaderArr.map(item => {
                          return (
                            <Col
                              span={3}
                              className={
                                seleCss === item.gii
                                  ? 'selectionBorders'
                                  : 'borders'
                              }
                              onClick={() => this.handClickGroup(item.gii)}
                            >
                              <p>
                                <img src={Free} alt="Free" width="35" />
                              </p>
                              <p>{`${item.gii}:${item.name}`}</p>
                              <p>
                                <span className="kx">空闲</span>
                              </p>
                            </Col>
                          )
                        })}
                      </Row>
                    </TabPane>
                    <TabPane
                      tab={<span>政法民声热线</span>}
                      key="6"
                      disabled={offArr.length > 0 ? tabFalse : tabTrue}
                    >
                      <Row>
                        {offArr.map(item => {
                          return (
                            <Col
                              span={3}
                              className={
                                seleCss === item.gii
                                  ? 'selectionBorders'
                                  : 'borders'
                              }
                              onClick={() => this.handClickGroup(item.gii)}
                            >
                              <p>
                                <img src={Free} alt="Free" width="35" />
                              </p>
                              <p>{`${item.gii}:${item.name}`}</p>
                              <p>
                                <span className="kx">空闲</span>
                              </p>
                            </Col>
                          )
                        })}
                      </Row>
                    </TabPane>
                  </Tabs>
                </Form.Item>
              </Form>
            </Modal>
            {/* 监听通话 */}
            <Modal
              title="监听通话"
              width={1200}
              visible={isShowListenBox}
              onOk={this.doSwitchOverTransferNew}
              onCancel={this.handleCloseListenBox}
              style={{ overflow: 'hidden' }}
            >
              <Form className="jz-modal-flex" style={{}}>
                <Form.Item className="zb-modal">
                  <Row gutter={24}>
                    <Col span={6}>
                      <Input
                        placeholder="请输入"
                        value={giiValue}
                        onChange={this.giiValueChange}
                      />
                    </Col>
                    <Col span={2}>
                      <Button type="primary" onClick={this.ListhenGii}>
                        监听
                      </Button>
                    </Col>
                  </Row>
                  <Tabs
                    defaultActiveKey={tabDefault}
                    style={{ height: '438px', overflowY: 'scroll' }}
                  >
                    <TabPane
                      tab={<span>总签入席</span>}
                      key="1"
                      disabled={lastarrM.length > 0 ? tabFalse : tabTrue}
                    >
                      <Row>
                        {allList.map(item => {
                          return (
                            <Col
                              span={3}
                              className={
                                seleCss === item.gii
                                  ? 'selectionBorders'
                                  : 'borders'
                              }
                              onClick={() => this.handClickGroup(item.gii)}
                            >
                              <p>
                                <img src={Free} alt="Free" width="35" />
                              </p>
                              <p>{`${item.gii}:${item.name}`}</p>
                              <p>
                                <span className="kx">通话</span>
                              </p>
                            </Col>
                          )
                        })}
                      </Row>
                    </TabPane>
                  </Tabs>
                </Form.Item>
              </Form>
            </Modal>
            {/* 保持通话 */}
            <Modal
              title="疑难专席"
              width={1200}
              visible={isShowKeepCall}
              onOk={this.keepCallOK}
              onCancel={this.closeKeepCall}
              style={{ overflow: 'hidden' }}
            >
              <Form className="jz-modal-flex" style={{}}>
                <Form.Item className="zb-modal">
                  <Row gutter={24}>
                    <Col span={6}>
                      <Input
                        placeholder="请输入"
                        value={giiValue}
                        onChange={this.giiValueChange}
                      />
                    </Col>
                    <Col span={2}>
                      <Button type="primary" onClick={this.ListhenGiiKeepCall}>
                        呼叫
                      </Button>
                    </Col>
                  </Row>
                  <Tabs defaultActiveKey={tabDefault}>
                    <TabPane
                      tab={<span>疑难专席</span>}
                      key="1"
                      disabled={lastarrM.length > 0 ? tabFalse : tabTrue}
                    >
                      <Row>
                        {keepCallList.map(item => {
                          return (
                            <Col
                              span={3}
                              className={
                                seleCss === item.gii
                                  ? 'selectionBorders'
                                  : 'borders'
                              }
                              onClick={() => this.handClickGroup(item.gii)}
                            >
                              <p>
                                <img src={Free} alt="Free" width="35" />
                              </p>
                              <p>{`${item.gii}:${item.name}`}</p>
                              <p>
                                <span className="kx">空闲</span>
                              </p>
                            </Col>
                          )
                        })}
                      </Row>
                    </TabPane>
                  </Tabs>
                </Form.Item>
              </Form>
            </Modal>

            <Modal
              title="在线转接查询"
              width={1200}
              visible={isOnlineSwitchingSearch}
              onOk={this.doSwitchOverTransfer}
              onCancel={this.handleCloseModalTransferSearch}
            >
              <Form className="jz-modal-flex">
                <Form.Item className="zb-modal">
                  <Row>
                    {searchArr.map(item => {
                      return (
                        <Col
                          span={3}
                          className={
                            seleCss === item.gii
                              ? 'selectionBorders'
                              : 'borders'
                          }
                          onClick={() => this.handClickGroup(item.gii)}
                        >
                          <p>
                            <img src={Free} alt="Free" width="35" />
                          </p>
                          <p>{`${item.gii}:${item.name}`}</p>
                          <p>
                            <span className="kx">空闲</span>
                          </p>
                        </Col>
                      )
                    })}
                  </Row>
                </Form.Item>
              </Form>
            </Modal>

            {/* 转队列 */}
            <Modal
              title="转队列"
              width={600}
              visible={isShowQueue}
              onOk={this.doSwitchOverQueue}
              onCancel={this.handleCloseModalQueue}
            >
              <Form className="jz-modal-flex">
                <Form.Item className="zb-modal">
                  <Row>
                    <Col
                      span={3}
                      className={
                        seleCss === 99 ? 'selectionBorders' : 'borders'
                      }
                      onClick={() => this.handClickQueue(99)}
                    >
                      <p>
                        <Icon type="phone" />
                      </p>
                      <p>企业服务热线</p>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Modal>

            {/* 二次拨号 */}
            <Modal
              title="拨号键"
              width={300}
              visible={isNumWindeow}
              onCancel={this.handleCloseModalNum}
              footer={null}
            >
              <Row Row gutter={16}>
                {arrNum.map(item => {
                  return (
                    <Col span={8} onClick={() => this.handleBtnNum(item)}>
                      <p className="numBg">
                        <span>{item}</span>
                      </p>
                    </Col>
                  )
                })}
              </Row>
              <Row Row gutter={16}>
                <Col span={16}>
                  <Input value={inputValue} onChange={this.changeValue} />
                </Col>
                <Col span={8}>
                  <Button
                    type="primary"
                    onClick={this.handleSubmitNum}
                    style={{ marginRight: 12 }}
                  >
                    提交
                  </Button>
                </Col>
              </Row>
            </Modal>
          </React.Fragment>
        )}

        {isWebsocket && (
          <WS
            isTime={isTime}
            txtPhoneNumVal={txtPhoneNumVal}
            isWebsocket={isWebsocket}
            webSendData={webSendData}
            // isSetFn={isSetFn}
          />
        )}
      </React.Fragment>
    )
  }
}

PhoneBar.defaultProps = {
  isDail: false,
  dispatchDailPhone: () => {}
  // isAgent: () => {}
}

PhoneBar.propTypes = {
  isDail: PropTypes.bool,
  isRevisit: PropTypes.string.isRequired,
  dispatchDailPhone: PropTypes.func // 点击回拨拨出后调用事件，主要用来还原redux - redux
  // isAgent: PropTypes.func
}

function mapStateToProps(state) {
  return {
    userInfo: state.userReducer.currentUserInfo
  }
}

export default withRouter(connect(mapStateToProps)(PhoneBar))
