/* eslint-disable */
/*
 * eslint-disable
 * @Author: wangyafei
 * @Date: 2019-08-08 13:37:19
 * @Last Modified by: wangyafei
 * @Last Modified time: 2019-08-08 13:38:07
 */
import React from 'react'
import { Route } from 'react-router-dom'

const RouteWithLayout = ({ layout: ResLayout, component, ...rest }) =>
  ResLayout ? (
    <ResLayout>
      <Route
        {...rest}
        children={props => React.createElement(component, props)}
      />
    </ResLayout>
  ) : (
    <Route
      {...rest}
      children={props => React.createElement(component, props)}
    />
  )

export default RouteWithLayout
