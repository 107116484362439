/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:52:59
 * @Last Modified by: haolei
 * @Last Modified time: 2020-04-22 20:14:24
 */
import { combineReducers } from 'redux'
import loginReducer from './loginReducer'
import userReducer from './userReducer'
import tabsReducer from './tabsReducer'
import workOrderReducer from './workOrderReducer'
import historyReducer from './historyReducer'
import simpleReducer from './simpleReducer'
import baseReducer from './baseReducer'
import dailoutReducer from './dailoutReducer'
import elimination from './elimination' // 剔除工单
import noticeReducer from './noticeReducer' // 通知公告
import office from './office' // 承办单位
import officeSecond from './officeSecond' // 承办单位二级
import phoneReduce from './phoneBar'

const allReducers = {
  loginReducer,
  userReducer,
  tabsReducer,
  historyReducer,
  workOrderReducer,
  simpleReducer,
  baseReducer,
  dailoutReducer,
  elimination,
  noticeReducer,
  office,
  officeSecond,
  phoneReduce
}
const rootReducer = combineReducers(allReducers)
export default rootReducer
