/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:51:07
 * @Last Modified by: haolei
 * @Last Modified time: 2019-07-25 09:48:16
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-05-09 16:06:26
 * @Last Modified by: zss
 * @Last Modified time: 2021-05-14 15:06:21
 */
import React, { Component } from 'react'
import { HashRouter as Router, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Icon, notification } from 'antd'
import CookieUtils from './data/common'
import store from './redux/store'
import * as Constants from './redux/constants'
import * as actions from './redux/actions/userAction'
import asyncComponent from './utils/AsyncComponent'
import ResponsiveLayout from './layout/ResponsiveLayout'
import UserLayout from './layout/UserLayout'
import noRequireAuth from './components/hoc/no_require_auth'
import RouteWithLayout from './utils/RouterWithLayout'
import './app.less'

// Dashboard管理页面
const Dashboard = asyncComponent(() => import('./pages/Dashboard'))
// 用户相关页面
const LoginPage = asyncComponent(() => import('./pages/User/Login'))
const LoginNew = asyncComponent(() => import('./pages/User/LoginNew')) // SSO
const RegisterPage = asyncComponent(() => import('./pages/User/RegisterPage'))
const Case = asyncComponent(() => import('./pages/Case'))
const ClassicCase = asyncComponent(() => import('./pages/Case/ClassicCase'))
const CaseList = asyncComponent(() => import('./pages/Case/CaseList'))
const PasswordRecover = asyncComponent(() =>
  import('./pages/User/PasswordRecover')
)

@connect(state => {
  const { userReducer, loginReducer } = state
  return {
    ...userReducer,
    ...loginReducer
  }
}, actions)
class App extends Component {
  state = {}

  componentDidMount() {
    window.addEventListener('beforeunload', this.doBeforeCloseWindow, false)
    window.addEventListener('unload', this.doConfirmCloseWindow, false)
  }

  componentWillReceiveProps(nextProps) {
    const { isAuthenticated, currentUserInfo } = nextProps
    const { loginName } = currentUserInfo
    if (isAuthenticated && loginName) {
      if (localStorage.getItem('在线') && !window.isSwitch) {
        notification.open({
          message: '只允许登录一个账号！！！切勿重复操作！！！',
          duration: 5,
          style: {
            padding: '40px 24px'
          },
          icon: <Icon type="info-circle" style={{ color: '#faad14' }} />,
          onClick: () => {
            console.log('Notification Clicked!')
          }
        })
        store.dispatch({
          type: Constants.USER_OUT
        })
      } else {
        localStorage.setItem(
          '在线',
          `token:${CookieUtils.getJSON('AuthorizationUserInfo').access_token}`
        )
        window.isSwitch = true
      }
    }
  }

  doBeforeCloseWindow = e => {
    if (window.isConfirm === false) {
      const confirmationMessage = '您确认要关闭当前页面吗?'
      ;(e || window.event).returnValue = confirmationMessage // 这是一个旧方法
      return confirmationMessage
    }
  }

  doConfirmCloseWindow = () => {
    if (typeof Phone !== 'undefined' && Phone.EndTask) {
      Phone.SignOutEx()
      Phone.EndTask()
    }
    window.isSwitch = false
    localStorage.removeItem('在线')
  }

  // 从后台获取菜单，动态添加路由，摈弃原来添加路由的方式
  getRouters = menuList => {
    const routerDom = []
    for (let i = 0; i < menuList.length; i += 1) {
      const menuItem = menuList[i]
      if (menuItem.component) {
        routerDom.push(
          <RouteWithLayout
            key={menuItem.id}
            path={`${menuItem.href}`}
            exact
            component={asyncComponent(() => import(`.${menuItem.component}`))}
          />
        )
      }
      const { children } = menuItem
      if (children && children.length > 0) {
        const childrenDom = this.getRouters(children)
        for (let j = 0; j < childrenDom.length; j += 1) {
          routerDom.push(childrenDom[j])
        }
      }
    }
    return routerDom
  }

  render() {
    const { menuList } = this.props
    return (
      <Router>
        <Switch>
          {/* 用户登录 */}
          <RouteWithLayout
            layout={UserLayout}
            path="/login"
            exact
            component={noRequireAuth(LoginPage)}
          />
          {/* SSO认证页面 */}
          <RouteWithLayout
            layout={UserLayout}
            path="/SSOLogin"
            exact
            component={LoginNew}
          />
          {/* 用户注册 */}
          <RouteWithLayout
            layout={UserLayout}
            path="/register"
            exact
            component={noRequireAuth(RegisterPage)}
          />
          {/* 修改密码 */}
          <RouteWithLayout
            layout={UserLayout}
            path="/password"
            exact
            component={noRequireAuth(PasswordRecover)}
          />
          {/* 查看问题列表 */}
          <RouteWithLayout
            layout={UserLayout}
            path="/case_list"
            exact
            component={CaseList}
          />
          {/* 查看 */}
          <RouteWithLayout
            layout={UserLayout}
            path="/case/:id?"
            exact
            component={Case}
          />
          {/* 查看案例详情  */}
          <RouteWithLayout
            layout={UserLayout}
            path="/classic_case/:id?"
            exact
            component={ClassicCase}
          />
          <ResponsiveLayout>
            <Switch>
              {/* 首页 */}
              <RouteWithLayout path="/dashboard" exact component={Dashboard} />
              {this.getRouters(menuList)}
            </Switch>
          </ResponsiveLayout>
        </Switch>
      </Router>
    )
  }
}

App.defaultProps = {
  menuList: []
}

App.propTypes = {
  menuList: PropTypes.arrayOf(
    PropTypes.objectOf({
      id: PropTypes.string,
      userName: PropTypes.string
    })
  ),
  // currentUserInfo: PropTypes.shape().isRequired
}
export default App
