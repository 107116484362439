/*
 * @Author: wangyafei
 * @Description: 填写处理意见
 * @Date: 2018-11-22 16:21:47
 * @Last Modified by: haolei
 * @Last Modified time: 2019-11-22 18:01:47
 */
import React, { Component } from 'react'
import { Row, Col, Form, Select } from 'antd'
import axios from 'axios'
import { getDictByDictType } from '@/data/ajax'

const { Option } = Select

class DailPhone extends Component {
  state = {
    workSkillList: []
  }

  componentDidMount() {
    this.getDicts()
  }

  getDicts = () => {
    const ajaxGets = []
    // 技能列表
    ajaxGets.push(getDictByDictType('work_skill'))
    axios.all(ajaxGets).then(suc => {
      const [workSkillList] = suc
      this.setState({
        workSkillList
      })
    })
  }

  getItemsValues = () => {
    /* eslint-disable */
    const {
      form: { validateFieldsAndScroll }
    } = this.props
    // form表单结果
    let result
    validateFieldsAndScroll(
      {
        scroll: { offsetTop: 100 }
      },
      (err, values) => {
        if (!err) {
          result = {
            ...values
          }
        } else {
          result = -1
        }
      }
    )
    return result
  }

  render() {
    /* eslint-disable-next-line */
    const { form } = this.props
    const { getFieldDecorator } = form
    const { workSkillList } = this.state
    return (
      <Form className="jz-modal-flex">
        <Row gutter={16}>
          <Col span={2} />
          <Col span={20}>
            <Form.Item
              hasFeedback
              label={<span className="justify">请选择专席类型</span>}
              style={{ display: 'block' }}
            >
              {getFieldDecorator(`phoneNumber`, {
                initialValue:
                  workSkillList.length > 0 ? workSkillList[0].dictValue : ''
              })(
                <Select
                  style={{ width: '100%' }}
                  showSearch
                  allowClear
                  placeholder="请选择专席类型"
                >
                  {workSkillList.map(item => {
                    return (
                      <Option key={item.id} value={item.dictKey}>
                        {item.dictValue}
                      </Option>
                    )
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={2} />
        </Row>
      </Form>
    )
  }
}

export default Form.create()(DailPhone)
