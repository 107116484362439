/*
 * @Author: wangyafei
 * @Date: 2018-06-06 14:02:54
 * @Last Modified by: zss
 * @Last Modified time: 2021-06-03 11:19:21
 */
import axios from 'axios'
import CookieUtils from '../../data/common';

// cti3.8接口地址 华为地址
const ctiurl = 'https://132.77.149.133:8043';
// 映射地址
// const mapurl = 'https://172.24.198.102:8043'; // 测试环境呼叫地址  仅能通过openeye进行呼叫
// 172.24.198.103 172.24.198.104正式环境cti3.8
let mapurl = 'https://172.24.198.103:8043';
let type = 0; // 选择签入的地址
// if (process.env.NODE_ENV === 'development') {
//   ctiurl = '/api';
// }
// 来电新建工单
export function getUserSkill() {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/system/user/getUserSkill`
  })
}

// 是否外呼
export function Outbound(params) {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/accept/worlCall/outbound`,
    params
  })
}

// 挂断
export function HangUp(params) {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/accept/worlCall/hangUp`,
    params
  })
}

// 添加录音标记
export function addSign(params) {
  return axios({
    method: 'POST',
    url: `${BASE_URL}/accept/workCallSign/saveCallerSign`,
    data: params
  })
}

// 获取worlCall 解决coid为空事件
export function anSwer(params) {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/accept/worlCall/answer`,
    params
  })
}

// 工单新建
export function newCallWorkOrder(params) {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/accept/workOrder/newCallWorkOrder`,
    params
  })
}

// 退出登录记录
export function systemLoginLog() {
  return axios({
    method: 'POST',
    url: `${BASE_URL}/system/loginLog/loginOut`
  })
}

//  签入记录
export function loginLogSignIn() {
  return axios({
    method: 'POST',
    url: `${BASE_URL}/system/loginLog/signIn`
  })
}

//  签出记录
export function loginLogSignOut() {
  return axios({
    method: 'POST',
    url: `${BASE_URL}/system/loginLog/signOut`
  })
}

//  判断签入用户是否可进行长连接
export function getUserInteract(name) {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/system/user/getUserInteract?loginName=${name}`
  })
}

// token失效接口
export function revoketoken(token) {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/oauth/revoketoken?access_token=${token}`
  })
}

// 网络测通接口
export function networkConnectivity() {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/statistics/CenterSeat/networkConnectivity`
  })
}

// 三方外部联系册
export function queryAll(params) {
  return axios({
    method: 'POST',
    url: `${BASE_URL}/accept/addressBook/queryAll`,
    data: params
  })
}

const agentgatewayUrl = '/agentgateway/resource/';
/**
 * @description 话务工具3.8后端做了代理转发
 * 转发接口 `${BASE_URL}/accept/forward`
 * {
 *   "url": "${ctiurl}${agentgatewayUrl}recordplay/${agentid}/play", //如果url有拼接的参数的 需要把参数拼接上
 *   "method": "POST",
 *   "body": "请求体里的参数",
 *   "guid": "guid"
 * }
*/
/**
 * @description 签入
 * @param {string} agentid 座席工号 required
 * @param {string} password 座席密码 required
 * @param {stirng} phonenum 软电话码 required
 * @param {int} agenttype 签入的座席类型 默认4：pc+phone座席
 */
export function onlineagent(params) {
  const { agentid } = params;
  const obj = {...params};
  delete obj.agentid;
  // const loginurl = localStorage.getItem('login-url');
  // const url = ['https://172.24.198.103:8043', 'https://172.24.198.104:8043'];
  // if (loginurl && loginurl !== null && loginurl !== 'null') {
  //   mapurl = url.filter(i => i !== loginurl)?.[0];
  // } else if (type === 0) {
  //   mapurl = 'https://172.24.198.103:8043';
  //   type = 1;
  // } else {
  //   mapurl = 'https://172.24.198.104:8043';
  //   type = 0;
  // }
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}onlineagent/${agentid}`,
      method: 'PUT',
      body: JSON.stringify(obj)
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
  });
}

/**
 * @description 强制签入
 * @param {string} agentid 座席工号 required
 * @param {string} password 座席密码 required
 * @param {stirng} phonenum 软电话码 required
 * @param {int} agenttype 签入的座席类型 默认4：pc+phone座席
 */
export function forcelogin(params) {
  const { agentid } = params;
  const obj = {...params};
  delete obj.agentid;
  const loginurl = localStorage.getItem('login-url');
  const url = ['https://172.24.198.103:8043', 'https://172.24.198.104:8043'];
  if (loginurl && loginurl !== null && loginurl !== 'null') {
    mapurl = url.filter(i => i !== loginurl)?.[0];
  } else if (type === 0) {
    mapurl = 'https://172.24.198.103:8043';
    type = 1;
  } else {
    mapurl = 'https://172.24.198.104:8043';
    type = 0;
  }
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}onlineagent/${agentid}/forcelogin`,
      method: 'PUT',
      body: JSON.stringify(obj)
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
  });
}

/**
 * @description 话务工具3.8 座席重置技能对列
 * @param {string} agentid 座席工号
 * @param {boolean} autoflag 是否签入所有技能对列的标志 true自动签入坐席配置 false签入指定
 * @param {string} skillid 技能队列ID。当签入方式{autoflag}为false时，skillid如果为空则不签入任何技能队列；skillid为技能队列ID字符串组，例如1;2;3，
 * @param {int} phonelinkage 是否话机联动，接续和硬话机联动控制 支持有限 1：是 0：否
 */
export function resetskill(params) {
  const { agentid, autoflag, skillid, phonelinkage } = params;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}onlineagent/${agentid}/resetskill/${autoflag}?skillid=${skillid}&phonelinkage=${phonelinkage}`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}

/**
 * @description 座席签入平台接口
 * @param {string} agentid 座席工号 required
 * @param {string} password 座席密码 可为空 required
 * @param {boolean} autoanswer 是否自动应答，默认为true。
 * @param {boolean} autoenteridle 是否自动进入空闲态，默认为
 * @param {boolean} releasephone 座席挂机后是否进入非长通态(呼叫结束后话机挂断)：默认为true。
 * @param {int} agenttype 签入的座席类型，默认为4。4：pc+phone座席，11：pc+phone视频座席
 * @param {int} status 签入后的状态，默认为空闲态。3：示忙 4：空闲 5：整理态
 * @param {boolean} checkInWebm 是否签入Webm媒体服务器。默认为true。
 * @param {boolean} checkInMailm 是否签入Mailm媒体服务器。默认为false。
 */
export function loginex(params) {
  const { agentid } = params;
  const obj = { ...params };
  delete obj.agentid;
  // const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'PUT',
    url: `${ctiurl}${agentgatewayUrl}onlineagent/${agentid}/loginex`,
    data: obj,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}

/**
 * @description 座席签出
 * @param {string} agentid 座席工号
 */
export function logout(params) {
  const { agentid } = params;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}onlineagent/${agentid}/logout`,
      method: 'DELETE',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}

/**
 * @description 座席签入后，轮询方式获取单座席事件
 * @param {string} agentid 座席工号
 */
export function handshakes(params) {
  const { agentid } = params;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}agentevent/${agentid}`,
      method: 'GET',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}

/**
 * @description 座席页面更新时更新guid
 */
export function updateGuid(params) {
  const { agentid } = params;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}onlineagent/${agentid}/updateGuid`,
      method: 'PUT',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}

/**
 * @description 呼叫转移  对应控件方法TransToIVR
 * @param {String} agentid 座席工号 required
 * @param {int} devicetype 转移设备类型，技能队列为1，业务代表为2，IVR设备为3，系统接入码为4，外呼号码为5。 required
 * @param {String} address 转移地址，最大长度24。取值范围：  required
      ● 当devicetype为1时，配置技能队列的编号ID。
      ● 当devicetype为2时，配置待转移座席的座席工号。
      ● 当devicetype为3时，配置IVR流程接入码。
      ● 当devicetype为4时，配置系统接入码。
      ● 当devicetype为5时，配置外呼号码
 * @param {int} mode 转移模式。 required
      当devicetype值为1时取值：
      0：释放转
      2：成功转
      当devicetype值为2时取值：
      0：释放转
      2：成功转
      当devicetype值为3时取值：
      0：释放转
      1：挂起转
      说明
      挂起转可以转音视频IVR流程，不能
      转座席。
      当devicetype值为4时取值：
      0：释放转
      2：成功转
      当devicetype值为5时取值：
      1：释放转（呼出时不支持向指
      定外呼号码释放转）
      2：成功转
      3：通话转
      4：三方转
 */
export function transferCall(params) {
  const { agentid } = params;
  const obj = { ...params };
  delete obj.agentid;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}voicecall/${agentid}/transfer`,
      method: 'POST',
      body: JSON.stringify(obj),
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}

/**
 * @description  座席签入通话后，设置呼叫随路数据 评价满意度
 * @param {string} agentid 座席工号 required
 * @param {string} callid 被叫座席的座席工号。内容不可为空，1-24位数字或*或#
 * @param {string} calldata 随路数据
 */
export function setcalldataex(params) {
  const { agentid } = params;
  const obj = { ...params };
  delete obj.agentid;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}calldata/${agentid}/setcalldataex`,
      method: 'PUT',
      body: JSON.stringify(obj),
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}


/**
 * @description  座席登录以后进行内部呼叫
 * @param {string} agentid 座席工号 required
 * @param {string} called 被叫座席的座席工号。内容不可为空，1-24位数字或*或#
 */
export function callinner(params) {
  const { agentid } = params;
  const obj = { ...params };
  delete obj.agentid;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}voicecall/${agentid}/callinner`,
      method: 'PUT',
      body: JSON.stringify(obj),
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}

/**
 * @description  座席登录以后通过外呼接口呼叫外呼用户。
 * @param {string} agentid 座席工号 required
 * @param {string} caller 主叫号码 可以为空，为空时默认平台号码
 * @param {string} called 被叫号码。内容不可为空，1-24位数字或*或# required
      说明
      为简化后续配置，请为called增加前
      缀，前缀添加规则为99+4位VDN编
      号，例如VDN为“90”，则被叫前
      缀为“990090”。
 */
export function callout(params) {
  const { agentid } = params;
  const obj = { ...params };
  delete obj.agentid;
  // obj.called = `990004${params.called}`;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}voicecall/${agentid}/callout`,
      method: 'PUT',
      body: JSON.stringify(obj),
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}

/**
 * @description 坐席示闲操作
 * @param {string} agentid 座席工号 required
 */
export function sayfreeEvent(params) {
  const { agentid } = params;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}onlineagent/${agentid}/sayfree`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}

/**
 * @description 示忙操作
 * @param {string} agentid 座席工号 required
 * @param {int} reason 示忙原因码。为空或者等于0时，表示无示忙原因码。
 * @param {boolean} prolong 已示忙状态下是否重新设置示忙原因
 */
export function saybusyEvent(params) {
  const { agentid } = params;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}onlineagent/${agentid}/saybusy?reason=0&prolong=${false}`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}

/**
 * @description 取消示忙操作
 * @param {string} agentid 座席工号 required
 * @param {int} reason 示忙原因码。为空或者等于0时，表示无示忙原因码。
 * @param {boolean} prolong 已示忙状态下是否重新设置示忙原因
 */
export function cancelSaybusy(params) {
  const { agentid } = params;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}onlineagent/${agentid}/cancelbusy`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}

/**
 * @description 设置是否进去空闲态
 * @param {string} agentid 座席工号 required
 * @param {boolean} flag 是否进入空闲态标志位。取值范围：true 进入空闲态 required
 */
export function autoenteridle(params) {
  const { agentid, flag } = params;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}onlineagent/${agentid}/autoenteridle/${flag}`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}

/**
 * @description 进入工作状态
 * @param {stirng} agentid 座席工号 required
 */
export function intoWork(params) {
  const { agentid } = params;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}onlineagent/${agentid}/work`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}

/**
 * @description 退出工作态
 * @param {string} agentid 座席工号 required
 */
export function cancelwork(params) {
  const { agentid } = params;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}onlineagent/${agentid}/cancelwork`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}

/**
 * @description 休息  进入休息态
 * @param {string} agentid 座席工号 required
 * @param {long} time 休息时长。以秒为单位 required
 * @param {int} restcause 休息原因码，平台配置的休息原因码，取值范围：0-255，其中0代表无休息原因码。 required
 */
export function requestRest(params) {
  const { agentid, time, restcause } = params;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}onlineagent/${agentid}/rest/${time}/${restcause}?prolong=${false}`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}

/**
 * @description 取消休息 退出休息态
 * @param {string} agentid 座席工号 required
 */
export function cancelrest(params) {
  const { agentid } = params;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}onlineagent/${agentid}/cancelrest`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}

/**
 * @description 内部求助 座席签入后进行内部求助操作  三方操作
 * @param {string} agentid 座席号 required
 * @param {string} dstaddress 求助对象 座席工号或技能队列ID required
 * @param {int} devicetype 求助设备类型 求助设备类型，技能队列为1，座席为2，默认为2
 * @param {int} mode 求助模式，两方求助为1，三方求助为2，默认为1。
 * @param {boolean} holdflag 三方转时是否保持呼叫，取值为false时不保持，true为保持，默认为true
 */
export function innerHelp(param) {
  const { agentid } = param;
  const guid = CookieUtils.getJSON('Guid');
  const obj = { ...param };
  delete obj.agentid;
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}voicecall/${agentid}/innerhelp`,
      method: 'POST',
      body: JSON.stringify(obj),
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description 呼叫保持 座席对正在通话中的呼叫进行保持操作。 此时保持的呼叫侧为静音状态 解除保持后可进行语音通话
 * @param {string} agentid 座席工号 required
 */
export function callHold(param) {
  const { agentid } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}voicecall/${agentid}/hold`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description 保持取消 座席对已经保持的呼叫进行取消保持操作  取消对保持中的呼叫静音操作
 * @param {string} agentid 座席工号 required
 * @param {string} callid 呼叫唯一标识callid required
 */
export function cancelHold(param) {
  const { agentid, callid } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}voicecall/${agentid}/gethold?callid=${callid}`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description 连接保持 座席对正在通话中的通话和保持中的通话进行连接保持。
 * @param {string} agentid 座席工号 required
 * @param {string} callid 呼叫唯一标识callid required
 */
export function connecthold(param) {
  const { agentid, callid } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}voicecall/${agentid}/connecthold/${callid}`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description 三方通话 座席对正在通话中的通话和保持中的通话进行三方通话。三方通话可以将保持呼叫中的用户解除保持音
 * @param {string} agentid 座席工号 required
 * @param {string} callid 呼叫唯一标识callid required
 */
export function threePartyServices(param) {
  const { agentid } = param;
  const obj = { ...param };
  delete obj.agentid;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}voicecall/${agentid}/confjoin`,
      method: 'POST',
      body: JSON.stringify(obj),
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description 拆除指定的呼叫 座席拆除（挂断）指定callid的呼叫
 * @param {string} agentid 座席工号 required
 * @param {string} callid 待拆除的呼叫id required
 */
export function dropCallid(param) {
  const { agentid, callid } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}voicecall/${agentid}/dropcall/${callid}`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description 静音操作 座席对正在通话的呼叫进行静音操作。
 * @param {string} agentid 座席工号 required
 */
export function beginmute(param) {
  const { agentid } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}voicecall/${agentid}/beginmute`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description 取消静音操作 座席对已经静音的呼叫进行取消静音操作。
 * @param {string} agentid 座席工号 required
 */
export function endmute(param) {
  const { agentid } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}voicecall/${agentid}/endmute`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description 释放指定号码连接 挂断
 * @param {string} agentid 座席工号 required
 * @param {string} number 待释放的电话号码，取值范围：
      内部求助时，座席操作挂断被求助座席{number}为座席工号
      挂断座席本身{number}为该座席的软电话号码。
      被求助方只可主动挂断自己，不允许操作座席和用户。
 */
export function disconnect(param) {
  const { agentid, number } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}voicecall/${agentid}/disconnect/${number}`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}
/**
 * @description 查询签入技能队列所有在线座席详细信息
 * @param {string} agentid 座席工号 required
 */
export function allonlineagents(param) {
  const { agentid } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}agentgroup/${agentid}/allonlineagents`,
      method: 'GET',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description 查询签入技能队列坐席详细信息
 * @param {string} agentid 座席工号 required
 * @param {string} skillid 被查询技能队列ID。required
 */
export function agentbyskill(param) {
  const { agentid, skillid } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}agentgroup/${agentid}/agentbyskill/${skillid}`,
      method: 'GET',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description  查询指定座席详细座席信息 如查询班长席
 * @param {string} agentid 座席工号 required
 * @param {int} workNo 待查询的座席工号 该座席工号必须与前面agentid为同一vdn下面的座席。
 */
export function agentbyworkno(params) {
  const { agentid, workNo } = params;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}agentgroup/${agentid}/agentbyworkno/${workNo}`,
      method: 'GET',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
}

/**
 * @description 二次拨号 座席对正在通话中的通话进行二次拨号（针对座席进行拨号盘收号），支持输入#
 * @param {string} agentid 座席工号 required
 * @param {string} number 二次拨号号码 required
 */
export function seconddialex(param) {
  const { agentid, number } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}voicecall/${agentid}/seconddialex?number=${number}`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description 获取当前座席的状态。
 * @param {string} agentid 座席工号 required
 * @returns {object}
 * agentState
 *    0未知。对应CTI平台状态的9。
 *    1签入状态。未使用。
 *    2签出状态。对应CTI平台状态的0。
 *    3示忙状态。对应CTI平台状态的7。
 *    4空闲状态。对应CTI平台状态的1。
 *    5整理态。对应CTI平台状态的6。
 *    6同空闲态。未使用。
 *    7通话态。对应CTI平台状态的2、3、4、5。
 *    8休息状态。对应CTI平台状态的8。
 *    9预休息状态。
 * 控件时期对应的状态
 * AgentInfoEx_CurState 3.5状态对应码
 * NOT_LOGINED = 0, 未签入
 * IDLE = 1, 空闲状态
 * PREOCCUPY = 2, 预占用状态
 * OCCUPY = 3, 占用状态
 * ANSWERING = 4, 应答状态
 * TALKING = 5, 通话状态
 * WORKING = 6, 工作状态
 * SETBUSY = 7 , 忙状态
 * REST = 8, 请假休息
 * STUDING = 9, 学习
 * WORK_ADJUST = 10 调整态
 *
 * isWorking
 */
export function agentstatus(param) {
  const { agentid } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}onlineagent/${agentid}/agentstatus`,
      method: 'GET',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description 侦听 质检座席签入并空闲后，侦听指定座席
 * @param {string} agentid 座席工号 required
 * @param {string} workNo 待侦听座席工号  该座席工号必须与前面agentid为同一vdn下面的座席。
 */
export function addsupervise(param) {
  const { agentid, workNo } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}qualitycontrol/${agentid}/addsupervise/${workNo}`,
      method: 'PUT',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description 座席对正在通话中的呼叫进行挂断操作，只支持挂断当前通话
 * @param {string} agentid 座席号 required
 */
export function ringoff(param) {
  const { agentid } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}voicecall/${agentid}/release`,
      method: 'DELETE',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description 开始放音 对座席开始放音操作
 * @param {string} agentid 座席工号 required
 * @param {string} voicepath 文件路径 required
 *
 */
export function playStart(param) {
  const { agentid } = param;
  const obj = { ...param };
  const guid = CookieUtils.getJSON('Guid');
  delete obj.agentid;
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}recordplay/${agentid}/play`,
      method: 'PUT',
      body: JSON.stringify(obj),
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description 暂停放音 录音播放时进行暂停操作
 * @param {string} agentid 座席工号 required
 *
 */
export function pauseplay(param) {
  const { agentid } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}recordplay/${agentid}/pauseplay`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}


/**
 * @description 放音快进 录音播放时在当前位置进行快进操作
 * @param {string} agentid 座席工号 required
 * @param {string} time 快进的时长 required
 *
 */
export function foreFastPlay(param) {
  const { agentid, time } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}recordplay/${agentid}/forefast/${time}`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description 放音快退 录音播放时在当前位置进行快退操作
 * @param {string} agentid 座席工号 required
 * @param {string} time 快进的时长 required
 *
 */
export function backFastPlay(param) {
  const { agentid, time } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}recordplay/${agentid}/backfast/${time}`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description 恢复放音 对座席进行恢复继续放音操作
 * @param {string} agentid 座席工号 required
 *
 */
export function resumeplay(param) {
  const { agentid } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}recordplay/${agentid}/resumeplay`,
      method: 'POST',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}

/**
 * @description 停止放音 停止播放录音操作
 * @param {string} agentid 座席工号 required
 *
 */
export function stopplay(param) {
  const { agentid } = param;
  const guid = CookieUtils.getJSON('Guid');
  return axios({
    method: 'POST',
    url: `${BASE_URL}/phone/forward`,
    data: {
      url: `${mapurl}${agentgatewayUrl}recordplay/${agentid}/stopplay`,
      method: 'DELETE',
      body: '{}',
      guid
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
}
