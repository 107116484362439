/*
 * @Author: wangyafei
 * @Description: 所有 Redux 状态常量
 * @Date: 2018-06-06 13:52:41
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2019-11-08 17:10:44
 * @Last Modified by: gy
 * @Last Modified time: yyyy-08-Th 01:59:11
 */

// 用户登录，退出常量
export const USER_LOGGING_IN = 'USER_LOGGING_IN'
export const USER_LOGGED_IN = 'USER_LOGGED_IN'
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'
// 用户信息刷新
export const USER_INFO_REFRESH = 'USER_INFO_REFRESH'

// 菜单常量
export const USER_MENUS = 'USER_MENUS'
export const CLEAR_USER_MENUS = 'CLEAR_USER_MENUS'

// 历史工单关联常量
export const WORK_ORDER_ATTACH_HISTORY = 'WORK_ORDER_ATTACH_HISTORY'

// 输入电话号码获取历史数据
export const GET_HISTORY_BY_PHONE = 'GET_HISTORY_BY_PHONE'

// 关闭当前tab页面
export const PAGE_CURRENT_CLOSE = 'PAGE_CURRENT_CLOSE'
export const PAGE_CURRENT_CLOSE_RESET = 'PAGE_CURRENT_CLOSE_RESET'

// 刷新refreshKey
export const REFESH_REFRESHKEY = 'REFESH_REFRESHKEY'

// 刷新页面
export const PAGE_CURRENT_PARENT_REFRESH = 'PAGE_CURRENT_PARENT_REFRESH'

// 简易工单切换为供暖
export const SUPPLY_HEATING = 'SUPPLY_HEATING'

// 工单来源切换事件
export const CHANGE_SOURCE_TYPE = 'CHANGE_SOURCE_TYPE'

// 外呼事件
export const DIAL_PHONE = 'DIAL_PHONE'

// 页面打开事件
export const PAGE_OPEN = 'PAGE_OPEN'

// 剔除工单刷新页面
export const ELIMINATION_REFRESH = 'ELIMINATION_REFRESH'

// 通知公告列表刷新
export const NOTICE_LIST = 'NOTICE_LIST'

// 政务平台回复刷新
export const HSOA_WOKER = 'HSOA_WOKER'

// 回访刷新
export const REVISIT_WAIT_FOR = 'REVISIT_WAIT_FOR'

// 回访中刷新
export const REVISIT_ING = 'REVISIT_ING'

// 首页
export const HOME_STATE = 'HOME_STATE'

// 全局
export const USER_OUT = 'USER_OUT'

// 工具条唯一标识事件
export const SET_CALL = 'SET_CALL'

// 承办单位一级
export const UNDERTAKE_OFFICE = 'UNDERTAKE_OFFICE'
// 承办单位二级
export const UNDERTAKE_OFFICESECOND = 'UNDERTAKE_OFFICESECOND'

// 上报审核
export const REPORT_AUDIT = 'REPORT_AUDIT'

// 禁止自动派单
export const AUTO_SEND = 'AUTO_SEND'

//  主动剔除
export const DONE_ELIMINATION = 'DONE_ELIMINATION'

// 坐席助手
export const AGENT_ASSISTANT = 'AGENT_ASSISTANT'

// 一键填单响应数据
export const FILLIN_FORM = 'FILLIN_FORM'

// 网络回复审核
export const NETWORK_REPLY = 'NETWORK_REPLY'

// 重复反应告知单签收
export const REPEAT_SIGN = 'REPEAT_SIGN'

// 回访详情
export const REVISIT_DETAIL = 'REVISIT_DETAIL'

// 协办
export const XB_STATUS = 'XB_STATUS'

// 设置外呼状态
export const SET_WAIHU = 'SET_WAIHU'

// 告知性办结诉求回复审核按钮控制判断
export const NOTIFY_COMPLETION = 'NOTIFY_COMPLETION'
