/*
 * @Autor: gy
 * @Date: 2021-04-23 16:17:05
 * @Description: 智能语音
 * @LastEditors: gy
 * @LastEditTime: 2021-08-24 19:01:26
 */

import React from 'react'
import PropTypes from 'prop-types'
// import Websocket from 'react-websocket'
import sha1 from 'sha1'
import store from '@/redux/store'
import { connect } from 'react-redux'
import * as Constants from '@/redux/constants'
import { toUpper } from 'lodash'
// import { message } from 'antd'
import moment from 'moment'
import WebsocketFn from './WebsocketFn'

// const limitConnect = 3 // 断线重连次数
// const timeConnect = 0

class WS extends React.Component {
  state = {
    uuid: '',
    account: '100000', // 坐席助手分配给首信的企业账号
    password: '100000suntek' // 坐席助手分配给首信系统的授权码
  }

  componentDidMount() {
    const { isWebsocket, isTime, txtPhoneNumVal /* isSetFn */ } = this.props
    console.log('WSphone:', txtPhoneNumVal)
    console.log('[ isTime ]', isTime)
    const { account, password } = this.state
    const sha = sha1(account + password + isTime)
    this.setState({
      txtPhoneNumVal
    })
    this.socket = new WebsocketFn({
      socketUrl: `ws://132.77.149.207:8192/aiaengine/innerApi.ws?telNo=${txtPhoneNumVal}&timestamp=${isTime}&account=${account}&signature=${toUpper(
        sha
      )}&infoType=10011111`,
      timeout: 5000,
      socketMessage: receive => {
        console.log('[ 返回的数据 ]', receive) // 后端返回的数据，渲染页面
        const { data } = receive
        this.handleWebSocketData(data)
      },
      socketClose: msg => {
        console.log('[ 断开连接 ]', msg)
        // this.socket.close()
        if (this.taskRemindInterval) {
          clearInterval(this.taskRemindInterval)
        }
        /* const nextIsTime = moment().valueOf()
        const shaNew = sha1(account + password + nextIsTime)
        const socketUrl = `ws://132.77.149.207:8192/aiaengine/innerApi.ws?telNo=${txtPhoneNumVal}&timestamp=${nextIsTime}&account=${account}&signature=${toUpper(
          shaNew
        )}&infoType=10011111`
        console.log(
          '%c [ socketClose ]',
          'font-size:13px; background:pink; color:#bf2c9f;',
          socketUrl
        )
        this.socket.onParams(socketUrl) */
      },
      socketError: () => {
        console.log('WebSocket连接失败')
        // this.socket.close()
        if (this.taskRemindInterval) {
          clearInterval(this.taskRemindInterval)
        }
      },
      socketOpen: () => {
        console.log('[ 接入成功 ]', '接入成功')
        try {
          if (isWebsocket) {
            // 首次需要注册send
            this.register()
          }
          // 一分钟发送一个心跳包
          this.taskRemindInterval = setInterval(() => {
            this.onHeart()
          }, 60000)
          // isSetFn(this.taskRemindInterval)
          localStorage.setItem('setFn', this.taskRemindInterval)
          /* store.dispatch({
            type: Constants.AGENT_ASSISTANT,
            payload: {
              isAgent: this.taskRemindInterval
            }
          }) */
        } catch (e) {
          console.log('[ open-e ]', e)
        }
      }
    })
    // 重试创建socket连接
    try {
      const { param } = this.socket
      const { socketUrl } = param || {}
      this.socket.connection(socketUrl)
    } catch (e) {
      console.log('[ e ]', e)
      // 捕获异常，防止js error
      // donothing
    }
  }

  componentWillReceiveProps(nextProps) {
    const { account, password } = this.state
    const { webSendData, txtPhoneNumVal } = this.props
    const {
      webSendData: nextWebSendData,
      txtPhoneNumVal: nextTxtPhoneNumVal,
      isTime: nextIsTime
    } = nextProps
    if (this.socket) {
      const { socket } = this.socket
      const { readyState } = socket || {}
      if (readyState === 1) {
        if (webSendData !== nextWebSendData) {
          this.socket.sendMessage(nextWebSendData)
        }
      }
    }
    console.log('[ isTime ]', moment(nextIsTime).format('YYYY-MM-DD hh:mm:ss'))
    console.log('nextphone', nextTxtPhoneNumVal)
    if (txtPhoneNumVal !== nextTxtPhoneNumVal) {
      const sha = sha1(account + password + nextIsTime)
      console.log(
        '%c [ sha ]',
        'font-size:13px; background:pink; color:#bf2c9f;',
        sha
      )
      const socketUrl = `ws://132.77.149.207:8192/aiaengine/innerApi.ws?telNo=${nextTxtPhoneNumVal}&timestamp=${nextIsTime}&account=${account}&signature=${toUpper(
        sha
      )}&infoType=10011111`
      this.setState({
        txtPhoneNumVal: nextTxtPhoneNumVal
      })
      this.socket.connection(socketUrl)
    }
  }

  // 收到服务器数据后的回调函数testte
  handleWebSocketData = data => {
    try {
      console.log('responseData', data)
      const responseData = JSON.parse(data)
      if (responseData.cmd === 'infoRecommend') {
        if (
          responseData.slotList &&
          responseData.slotList.length > 0 &&
          responseData.orderId
        ) {
          // 一键填单
          const values = { orderId: responseData.orderId }
          responseData.slotList.map(itm => {
            values[itm.slotKey] = itm.slotValue
            return itm
          })
          const strValues = JSON.stringify(values)
          console.log('[ strValues ]', strValues)
          store.dispatch({
            type: Constants.FILLIN_FORM,
            payload: {
              fillinFormData: values
            }
          })
          console.log('[ values ]', JSON.stringify(values))
        } else {
          console.log('[ 校验参数 ]', '校验参数')
          // 校验参数
          const { uuid } = this.state
          let info = ''
          if (!responseData.orderId) {
            info = '(orderId)此参数不符合要求'
          }
          if (
            !('slotList' in responseData) ||
            responseData.slotList.length <= 0
          ) {
            info = '(slotList)此参数不符合要求'
          }
          const params = {
            cmd: 'infoRecommendResult',
            msgId: uuid,
            result: '1',
            info
          }
          this.socket.sendMessage(params)
        }
      } else if (responseData.result && responseData.result !== '0') {
        // message.error(`WS响应失败 ${responseData.info}`)
        console.log('[ WS响应失败 ]', responseData.info)

        if (this.taskRemindInterval) {
          clearInterval(this.taskRemindInterval)
        }

        /* if (this.socket) {
          this.socket.onclose()
        } */
      }
    } catch (e) {
      console.log('[ e ]', e)
      // message.error(`响应失败`)
      if (this.taskRemindInterval) {
        clearInterval(this.taskRemindInterval)
      }
      // const { setInterValFn } = this.state
      // clearInterval(setInterValFn)
    }
  }

  // 注册
  register = () => {
    const { txtPhoneNumVal } = this.state
    const uuid = this.guid()
    const the = this
    this.setState(
      {
        uuid
      },
      () => {
        const params = {
          msgId: uuid,
          cmd: 'login',
          agentId: txtPhoneNumVal
        }
        console.log('resgisterData', params)
        if (the.socket) {
          const { socket } = the.socket
          const { readyState } = socket || {}
          if (readyState === 1) {
            the.socket.sendMessage(params)
          }
        }
      }
    )
  }

  // 心跳包
  onHeart = () => {
    const { uuid, txtPhoneNumVal } = this.state
    const params = {
      msgId: uuid,
      cmd: 'heartBeat',
      telNo: txtPhoneNumVal
    }
    console.log('[ 心跳包 ]', '心跳包')
    if (this.socket) {
      const { socket } = this.socket
      const { readyState } = socket || {}
      if (readyState === 1) {
        this.socket.sendMessage(params)
      }
    }
  }

  guid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      // eslint-disable-next-line no-bitwise
      const r = (Math.random() * 16) | 0
      // eslint-disable-next-line no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  render() {
    return null
  }
}

WS.defaultProps = {
  txtPhoneNumVal: '',
  webSendData: {}
  // isSetFn: () => {}
}

WS.propTypes = {
  txtPhoneNumVal: PropTypes.string, // 坐席电话号
  isWebsocket: PropTypes.bool.isRequired,
  isTime: PropTypes.number.isRequired,
  webSendData: PropTypes.string
  // isSetFn: PropTypes.func
}

function mapStateToProps(state) {
  return {
    isAgent: state.dailoutReducer.isAgent,
    fillinFormData: state.dailoutReducer.fillinFormData
  }
}

export default connect(mapStateToProps)(WS)
