/* eslint-disable react/prop-types */
/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:53:25
 * @Last Modified by: wangyafei
 * @Last Modified time: 2018-11-19 19:09:32
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

export default function(ComposedComponent) {
  class NotAuthentication extends Component {
    PropTypes = {
      router: PropTypes.object
    }

    componentWillMount() {
      const { authenticated, history } = this.props
      if (authenticated) {
        history.push('/')
      }
    }

    componentWillUpdate(nextProps) {
      const { history } = this.props
      if (nextProps.authenticated) {
        history.push('/')
      }
    }

    render() {
      return <ComposedComponent {...this.props} />
    }
  }
  function mapStateToProps(state) {
    return { authenticated: state.loginReducer.isAuthenticated }
  }
  return connect(mapStateToProps)(NotAuthentication)
}
