/*
 * @Author: wangyafei
 * @Date: 2019-08-19 11:47:36
 * @Last Modified by:   wangyafei
 */
import requireAuth from '../components/hoc/require_auth'
import asyncComponent from '../utils/AsyncComponent'

// 首页
const Dashboard = requireAuth(
  asyncComponent(() => import('../pages/Dashboard'))
)
// 音频查询
const AudioList = requireAuth(
  asyncComponent(() => import('../pages/Common/AudioList'))
)
/** ************** A3部分 *************** */
// A3 待签收页面 - 一般用户
const A3WaitForNormalSign = requireAuth(
  asyncComponent(() => import('../pages/A3/WaitForNormalSign'))
)
// A3 待签收页面 - 管理员 - 获取页面
const A3WaitForAdminGet = requireAuth(
  asyncComponent(() => import('../pages/A3/WaitForAdminGet'))
)
// A3 待签收页面 - 管理员 - 获取详情页面
const A3GetDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/A3/GetDetail'))
)
// A3 待签收页面 - 管理员 - 签收页面
const A3WaitForAdminSign = requireAuth(
  asyncComponent(() => import('../pages/A3/WaitForAdminSign'))
)
// A3 工单签收页面
const A3SignDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/A3/SignDetail'))
)
// A3 待处理页面
const A3WaitForHandle = requireAuth(
  asyncComponent(() => import('../pages/A3/WaitForHandle'))
)
// A3 待处理详情页面
const A3WaitForHandleDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/A3/WaitForHandleDetail'))
)
// A3工单查询页面
const A3WorkOrderList = requireAuth(
  asyncComponent(() => import('../pages/WorkOrderSearch/A3WorkOrderList'))
)
// A3工单查询 补充回复页面
const A3WorkOrderDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/A3/WorkOrderDetail'))
)
// A3 A6退回审核
const A3A6BackList = requireAuth(
  asyncComponent(() => import('../pages/A3/A3A6BackList'))
)
// A3 A6退回审核 详情页面
const A3A6BackDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/A3/A3A6BackDetail'))
)
// A3 A6延期审核
const A3A6PostPoneList = requireAuth(
  asyncComponent(() => import('../pages/A3/A3A6PostPoneList'))
)
// A3 A6延期审核 详情页面
const A3A6PostPoneDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/A3/A3A6PostPoneDetail'))
)
// A3 A6完成审核
const A3A6DoneList = requireAuth(
  asyncComponent(() => import('../pages/A3/A3A6DoneList'))
)
// A3 A6完成审核 详情页面
const A3A6DoneDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/A3/A3A6DoneDetail'))
)
// A3 分中心催办列表
const A3UrgeList = requireAuth(
  asyncComponent(() => import('../pages/A3/UrgeList'))
)
// A3 督办通知列表
const A3SuperviseList = requireAuth(
  asyncComponent(() => import('../pages/A3/SuperviseList'))
)
// A3 告知单列表
const A3InformList = requireAuth(
  asyncComponent(() => import('../pages/A3/InformList'))
)
/** ************** A6部分 *************** */
// A6 待签收页面 - 一般用户
const A6WaitForNormalSign = requireAuth(
  asyncComponent(() => import('../pages/A6/WaitForNormalSign'))
)
// A6 待签收页面 - 管理员 - 获取页面
const A6WaitForAdminGet = requireAuth(
  asyncComponent(() => import('../pages/A6/WaitForAdminGet'))
)
// A6 待签收页面 - 管理员 - 获取详情页面
const A6GetDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/A6/GetDetail'))
)
// A6 待签收页面 - 管理员 - 签收页面
const A6WaitForAdminSign = requireAuth(
  asyncComponent(() => import('../pages/A6/WaitForAdminSign'))
)
// A6 工单签收页面
const A6SignDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/A6/SignDetail'))
)
// A6 待处理页面
const A6WaitForHandle = requireAuth(
  asyncComponent(() => import('../pages/A6/WaitForHandle'))
)
// A6 待处理详情页面
const A6WaitForHandleDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/A6/WaitForHandleDetail'))
)
// A6 工单查询页面
const A6WorkOrderList = requireAuth(
  asyncComponent(() => import('../pages/WorkOrderSearch/A6WorkOrderList'))
)
// A6 催办列表
const A6UrgeList = requireAuth(
  asyncComponent(() => import('../pages/A6/UrgeList'))
)
// A6 督办通知列表
const A6SuperviseList = requireAuth(
  asyncComponent(() => import('../pages/A6/SuperviseList'))
)
/** ************** 诉求部分 *************** */
// 不稳定组 - 诉求分配
const AppealUnstableGroup = requireAuth(
  asyncComponent(() => import('../pages/AppealList/UnstableGroup'))
)
// 机动组 - 诉求分配
const AppealFlexibleGroup = requireAuth(
  asyncComponent(() => import('../pages/AppealList/FlexibleGroup'))
)
// 区分中心组 - 诉求分配
const AppealCenterGroup = requireAuth(
  asyncComponent(() => import('../pages/AppealList/CenterGroup'))
)
// 委办局组 - 诉求分配
const AppealBrandGroup = requireAuth(
  asyncComponent(() => import('../pages/AppealList/BrandGroup'))
)
// 街乡镇组 - 诉求分配
const AppealDistrictGroup = requireAuth(
  asyncComponent(() => import('../pages/AppealList/DistrictGroup'))
)
// 公服企业组 - 诉求分配
const AppealIndustryGroup = requireAuth(
  asyncComponent(() => import('../pages/AppealList/IndustryGroup'))
)
// 疑难工单列表 - 诉求分配
const AppealPuzzleGroup = requireAuth(
  asyncComponent(() => import('../pages/AppealList/PuzzleGroup'))
)
// 投诉举报工单列表 - 诉求分配
const AppealComplainGroup = requireAuth(
  asyncComponent(() => import('../pages/AppealList/ComplainGroup'))
)
// 企业一般工单列表 - 诉求分配
const AppealEnterpriseNormalGroup = requireAuth(
  asyncComponent(() => import('../pages/AppealList/EnterpriseNormalGroup'))
)
// 企业不稳定工单列表 - 诉求分配2023.4.10新+
const EnterpriseInstabilityGroup = requireAuth(
  asyncComponent(() => import('../pages/AppealList/EnterpriseInstabilityGroup'))
)
// 企业疑难工单列表 - 诉求分配
const AppealEnterprisePuzzleGroup = requireAuth(
  asyncComponent(() => import('../pages/AppealList/EnterprisePuzzleGroup'))
)
// 诉求分配详情页面
const AppealDistributeOrderDetail = requireAuth(
  asyncComponent(() =>
    import('../pages/WorkOrder/Appeal/DistributeOrderDetail')
  )
)
// 普通退回 - 诉求退回
const AppealNormalReturn = requireAuth(
  asyncComponent(() => import('../pages/AppealList/NormalReturn'))
)
// 疑难退回 - 诉求退回
const AppealPuzzleReturn = requireAuth(
  asyncComponent(() => import('../pages/AppealList/PuzzleReturn'))
)
// 企业一般退回 - 诉求退回
const AppealEnterpriseNormalReturn = requireAuth(
  asyncComponent(() => import('../pages/AppealList/EnterpriseNormalReturn'))
)
// 企业疑难退回 - 诉求退回
const AppealEnterprisePuzzleReturn = requireAuth(
  asyncComponent(() => import('../pages/AppealList/EnterprisePuzzleReturn'))
)
// 投诉退回 - 诉求退回
const AppealComplainReturn = requireAuth(
  asyncComponent(() => import('../pages/AppealList/ComplainReturn'))
)
// 中高风险地区退回
const AppealRiskReturn = requireAuth(
  asyncComponent(() => import('../pages/AppealList/AppealRiskReturn'))
)
// 供暖退回
const HeatingReturn = requireAuth(
  asyncComponent(() => import('../pages/AppealList/HeatingReturn'))
)
// 微信自动派单首次退回
const WxAutomaticFirstReturn = requireAuth(
  asyncComponent(() => import('../pages/AppealList/WxAutomaticFirstReturn'))
)
// 诉求退回页面详情
const AppealReturnOrderDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/Appeal/ReturnOrderDetail'))
)
// 普通延期 - 延期审核
const AppealNormalPostPone = requireAuth(
  asyncComponent(() => import('../pages/AppealList/NormalPostPone'))
)
// 疑难延期 - 延期审核
const AppealPuzzlePostPone = requireAuth(
  asyncComponent(() => import('../pages/AppealList/PuzzlePostPone'))
)
// 企业普通延期 - 延期审核
const AppealEnterpriseNormalPostPone = requireAuth(
  asyncComponent(() => import('../pages/AppealList/EnterpriseNormalPostPone'))
)
// 企业疑难延期 - 延期审核
const AppealEnterprisePuzzlePostPone = requireAuth(
  asyncComponent(() => import('../pages/AppealList/EnterprisePuzzlePostPone'))
)
// 投诉延期 - 延期审核
const AppealComplainPostPone = requireAuth(
  asyncComponent(() => import('../pages/AppealList/ComplainPostPone'))
)
// 诉求延期详情页面
const AppealPostPoneOrderDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/Appeal/PostPoneOrderDetail'))
)
// 普通回复 - 回复审核
const AppealNormalReply = requireAuth(
  asyncComponent(() => import('../pages/AppealList/NormalReply'))
)
// 疑难回复 - 回复审核
const AppealPuzzleReply = requireAuth(
  asyncComponent(() => import('../pages/AppealList/PuzzleReply'))
)
// 企业普通回复 - 回复审核
const AppealEnterpriseNormalReply = requireAuth(
  asyncComponent(() => import('../pages/AppealList/EnterpriseNormalReply'))
)
// 企业疑难回复 - 回复审核
const AppealEnterprisePuzzleReply = requireAuth(
  asyncComponent(() => import('../pages/AppealList/EnterprisePuzzleReply'))
)
// 投诉回复 - 回复审核
const AppealComplainReply = requireAuth(
  asyncComponent(() => import('../pages/AppealList/ComplainReply'))
)
// 中高风险地区回复
const AppealRiskReply = requireAuth(
  asyncComponent(() => import('../pages/AppealList/AppealRiskReply'))
)
// 供暖回复
const HeatingReply = requireAuth(
  asyncComponent(() => import('../pages/AppealList/HeatingReply'))
)
// 回复审核页面详情
const AppealReplyOrderDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/Appeal/ReplyOrderDetail'))
)
// 诉求工单查询
const AppealWorkOrderList = requireAuth(
  asyncComponent(() => import('../pages/AppealList/AppealWorkOrderList'))
)
// 诉求席自动派单 AutomaticDispatch

const AutomaticDispatch = requireAuth(
  asyncComponent(() => import('../pages/AppealList/AutomaticDispatch'))
)

// NetworkPersonalGroup 网络个人组
const NetworkPersonalGroup = requireAuth(
  asyncComponent(() => import('../pages/AppealList/NetworkPersonalGroup'))
)

// NetworkEnterpriseGroup 网络企业组
const NetworkEnterpriseGroup = requireAuth(
  asyncComponent(() => import('../pages/AppealList/NetworkEnterpriseGroup'))
)
// NetworkExtension 网络延期
const NetworkExtension = requireAuth(
  asyncComponent(() => import('../pages/AppealList/NetworkExtension'))
)
// NetworkRecovery 网络回复
const NetworkRecovery = requireAuth(
  asyncComponent(() => import('../pages/AppealList/NetworkRecovery'))
)
// NetworkReturn 网络退回
const NetworkReturn = requireAuth(
  asyncComponent(() => import('../pages/AppealList/NetworkReturn'))
)

// NetworkPersonalGroupPuzzle 网络疑难工单组
const NetworkPersonalGroupPuzzle = requireAuth(
  asyncComponent(() => import('../pages/AppealList/NetworkPersonalGroupPuzzle'))
)

// NetworkRecoveryPuzzle 网络疑难回复
const NetworkRecoveryPuzzle = requireAuth(
  asyncComponent(() => import('../pages/AppealList/NetworkRecoveryPuzzle'))
)
// NetworkReturnPuzzle 网络疑难退回
const NetworkReturnPuzzle = requireAuth(
  asyncComponent(() => import('../pages/AppealList/NetworkReturnPuzzle'))
)

// NetOrderInfo 网络详诉求详情
const NetOrderInfo = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/AppealNetInfo/NetOrderInfo'))
)

// NetReturnInfo 网络退回详情

const NetReturnInfo = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/AppealNetInfo/NetReturnInfo'))
)

// NetderlayInfo 网络延期详情

const NetderlayInfo = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/AppealNetInfo/NetderlayInfo'))
)

// NetreplyInfo  网络回复详情
const NetreplyInfo = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/AppealNetInfo/NetreplyInfo'))
)

/* ************** 受理部分 *************** */
// 弹出工单 (新建工单页面有五个：1-新建工单NewWorkOrder  2-微信受理工单WxNewWorkOrder  3-微信拆分工单wxNewWokeOrderCopy   4-来电工单CallWorkOrder  5-网络待受理(政务服务好差评、政务平台、互联网+监管)受理页CurrencyWorkOrder)
const AccPopupOrder = requireAuth(
  asyncComponent(() => import('../pages/Accept/PopupOrder'))
)
// 新建工单(不包括弹出工单) (新建工单页面有五个：1-新建工单NewWorkOrder  2-微信受理工单WxNewWorkOrder  3-微信拆分工单wxNewWokeOrderCopy   4-来电工单CallWorkOrder  5-网络待受理(政务服务好差评、政务平台、互联网+监管)受理页CurrencyWorkOrder)
const AccNewOrder = requireAuth(
  asyncComponent(() => import('../pages/Accept/NewOrder'))
)
// 未提交工单列表
const AccUncommitOrder = requireAuth(
  asyncComponent(() => import('../pages/Accept/UncommitOrder'))
)
// 未提交工单详情
const AccUncommitOrderDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/Accept/UncommitOrderDetail'))
)
// 受理席诉求退回列表
const AccReturnByAppeal = requireAuth(
  asyncComponent(() => import('../pages/Accept/ReturnByAppeal'))
)
// 受理席诉求退回列表详情
const AccReturnByAppealDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/Accept/ReturnByAppealDetail'))
)
// 班长查看诉求退回列表
const AccMonitorView = requireAuth(
  asyncComponent(() => import('../pages/Accept/MonitorView'))
)
// 班长查看诉求退回列表详情
const AccMonitorViewDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/Accept/MonitorViewDetail'))
)
// 投诉举报
const AccComplain = requireAuth(
  asyncComponent(() => import('../pages/Accept/Complain'))
)
// 投诉举报详情页面
const AccComplainDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/Accept/ComplainDetail'))
)
// 投诉举报退回
const AccComplainReturn = requireAuth(
  asyncComponent(() => import('../pages/Accept/ComplainReturn'))
)
// 投诉举报退回详情页面
const AccComplainReturnDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/Accept/ComplainReturnDetail'))
)
// 工单查询
const AccOrderList = requireAuth(
  asyncComponent(() => import('../pages/WorkOrderSearch/WorkOrderList'))
)
// 工单详情
const AccOrderListDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/Accept/OrderListDetail'))
)
// 催办单查询
const AccUrgeList = requireAuth(
  asyncComponent(() => import('../pages/Accept/UrgeList'))
)
// 督办单查询
const AccSuperviseList = requireAuth(
  asyncComponent(() => import('../pages/Accept/SuperviseList'))
)

// 404页面
const NotFound = requireAuth(
  asyncComponent(() => import('../pages/Error/NotFound'))
)

// 通用详情页面，只查看无任何操作
const CommonDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/Accept/CommonDetail'))
)

// 网络诉求退回
const ReturnNetworkAppeal = requireAuth(
  asyncComponent(() => import('../pages/Accept/ReturnNetworkAppeal'))
)

// 网络退回工单转派
const NetworkReturnTransfer = requireAuth(
  asyncComponent(() => import('../pages/Accept/NetworkReturnTransfer'))
)

// 企业退回工单转派 EnReturnTransfer
const EnReturnTransfer = requireAuth(
  asyncComponent(() => import('../pages/Enterprise/EnReturnTransfer'))
)

// 企业退回工单转派 EnReturnTransfer
const ReturnEnterpriseAppeal = requireAuth(
  asyncComponent(() => import('../pages/Enterprise/ReturnEnterpriseAppeal'))
)

/* ************** 回访部分 *************** */
// 待回访工单列表
const WaitForRevisit = requireAuth(
  asyncComponent(() => import('../pages/Revisit/WaitForRevisit'))
)
// 待回访工单详情页面
const WaitForRevisitDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/Revisit'))
)
// 回访中工单列表
const Revisiting = requireAuth(
  asyncComponent(() => import('../pages/Revisit/Revisiting'))
)
// 回访查询分派
const RevisitSearchAndDispatch = requireAuth(
  asyncComponent(() => import('../pages/Revisit/RevisitSearchAndDispatch'))
)
// 回访退回再分派
const RevisitBackAndDispatch = requireAuth(
  asyncComponent(() => import('../pages/Revisit/RevisitBackAndDispatch'))
)
// 回访完结再分派
const RevisitDoneAndDispatch = requireAuth(
  asyncComponent(() => import('../pages/Revisit/RevisitDoneAndDispatch'))
)

// 回访统计
const RevisitVisit = requireAuth(
  asyncComponent(() => import('../pages/Revisit/RevisitVisit'))
)

// 坐席回访日报  22.6.6新+
const VisitDaily = requireAuth(
  asyncComponent(() => import('../pages/Revisit/VisitDaily'))
)

// 承办单位回访统计
const RevisitUndertaker = requireAuth(
  asyncComponent(() => import('../pages/Revisit/RevisitUndertaker'))
)

// 回访查询es  11.29gy新+
// 回访查询分派es
const RevisitSearchAndDispatchES = requireAuth(
  asyncComponent(() => import('../pages/Revisit/RevisitSearchAndDispatchES'))
)
// 回访退回再分派es
const RevisitBackAndDispatchES = requireAuth(
  asyncComponent(() => import('../pages/Revisit/RevisitBackAndDispatchES'))
)
// 回访完结再分派es
const RevisitDoneAndDispatchES = requireAuth(
  asyncComponent(() => import('../pages/Revisit/RevisitDoneAndDispatchES'))
)
// 待回访工单列表
const WaitForRevisitES = requireAuth(
  asyncComponent(() => import('../pages/Revisit/WaitForRevisitES'))
)
// 回访中工单列表
const RevisitingES = requireAuth(
  asyncComponent(() => import('../pages/Revisit/RevisitingES'))
)

/* ************** 统计分析 *************** */
// 派单情况明细
const StatisticsCallDispatchDetails = requireAuth(
  asyncComponent(() => import('../pages/Statistics/CallDispatchDetails'))
)
// 退单情况明细
const StatisticsBackDetails = requireAuth(
  asyncComponent(() => import('../pages/Statistics/BackDetails'))
)
// 微信留言统计
const WxLeavingMsg = requireAuth(
  asyncComponent(() => import('../pages/Statistics/WxLeavingMsg'))
)
// 微信留言统计
const WxVisitStatistic = requireAuth(
  asyncComponent(() => import('../pages/Statistics/WxVisitStatistic'))
)

/* ************** 企业部分 *************** */
// 派单情况明细
const EnterpriseUrgeList = requireAuth(
  asyncComponent(() => import('../pages/Enterprise/UrgeList'))
)
// 退单情况明细
const EnterpriseSuperviseList = requireAuth(
  asyncComponent(() => import('../pages/Enterprise/SuperviseList'))
)
/* ************* 系统管理子系统 ****************** */
// 用户管理
const SystemUserManager = requireAuth(
  asyncComponent(() => import('../pages/System/UserManager'))
)
// 菜单管理
const SystemMenuManager = requireAuth(
  asyncComponent(() => import('../pages/System/MenuManager'))
)
// 角色管理
const SystemRoleManager = requireAuth(
  asyncComponent(() => import('../pages/System/RoleManager'))
)
// 机构管理
const SystemDeptManager = requireAuth(
  asyncComponent(() => import('../pages/System/DeptManager'))
)
// 字典管理
const SystemDictManager = requireAuth(
  asyncComponent(() => import('../pages/System/DictManager'))
)
// 区域管理
const SystemAreaManage = requireAuth(
  asyncComponent(() => import('../pages/System/AreaManager'))
)
// 操作日志管理
const SystemLogManager = requireAuth(
  asyncComponent(() => import('../pages/System/LogManager'))
)
// 标签管理
const SystemLabelManager = requireAuth(
  asyncComponent(() => import('../pages/System/LabelManager'))
)
// 问题分类管理
const SystemRequestTypeManager = requireAuth(
  asyncComponent(() => import('../pages/System/RequestTypeManager'))
)
// 操作类型管理
const SystemHandleTypeManager = requireAuth(
  asyncComponent(() => import('../pages/System/HandleTypeManager'))
)

// 系统运维
const SystemOperation = requireAuth(
  asyncComponent(() => import('../pages/System/SystemOperation'))
)

// 系统运维
const SystemAudioList = requireAuth(
  asyncComponent(() => import('../pages/System/AudioList'))
)

/* ************* 通告管理 ****************** */
// 查看公告
const ViewNotice = requireAuth(
  asyncComponent(() => import('../pages/Notice/ViewNotice'))
)

// 公告管理
const NoticeManagement = requireAuth(
  asyncComponent(() => import('../pages/Notice/NoticeManagement'))
)

// 添加公告
const AddNotice = requireAuth(
  asyncComponent(() => import('../pages/Notice/AddNotice'))
)

// 公告详情
const NoticeDetails = requireAuth(
  asyncComponent(() => import('../pages/Notice/NoticeDetails'))
)

/* ************* 定制导出 ****************** */
// 城管定制导出ES
/* const CustomExportES = requireAuth(
  asyncComponent(() => import('../pages/Export/CustomExportES'))
) */

// 城管定制导出
const CustomExport = requireAuth(
  asyncComponent(() => import('../pages/Export/CustomExport'))
)

// 物价局定制导出
const PriceExport = requireAuth(
  asyncComponent(() => import('../pages/Export/PriceExport'))
)

// 商委定制导出
const BusinessExport = requireAuth(
  asyncComponent(() => import('../pages/Export/BusinessExport'))
)

// 农业局定制导出
const AgricultureExport = requireAuth(
  asyncComponent(() => import('../pages/Export/AgricultureExport'))
)

// 交管局
const TrafficExport = requireAuth(
  asyncComponent(() => import('../pages/Export/TrafficExport'))
)

// 规自委
const RulesExport = requireAuth(
  asyncComponent(() => import('../pages/Export/RulesExport'))
)

// 住建委
const LiveBuildExport = requireAuth(
  asyncComponent(() => import('../pages/Export/LiveBuildExport'))
)

// 城管委
const CityManagementExport = requireAuth(
  asyncComponent(() => import('../pages/Export/CityManagementExport'))
)

// 纪委
const CommissionForDisciplineInspectionExport = requireAuth(
  asyncComponent(() =>
    import('../pages/Export/CommissionForDisciplineInspectionExport')
  )
)

// 人民防空办公室
const CivilAirDefenseOfficeExport = requireAuth(
  asyncComponent(() => import('../pages/Export/CivilAirDefenseOfficeExport'))
)

// 交通综合治理定制导出
const ComprehensiveTrafficControl = requireAuth(
  asyncComponent(() => import('../pages/Export/ComprehensiveTrafficControl'))
)

// 市水务局定制导出
const WaterAuthorityExport = requireAuth(
  asyncComponent(() => import('../pages/Export/WaterAuthorityExport'))
)

// 政务服务局体系处定制导出
const GovernmentAffairsExport = requireAuth(
  asyncComponent(() => import('../pages/Export/GovernmentAffairsExport'))
)

// 疏整促定制导出
const ShuZhengCuExport = requireAuth(
  asyncComponent(() => import('../pages/Export/ShuZhengCuExport'))
)

// 卫生健康委员会定制导出
const HealthCommitteeExport = requireAuth(
  asyncComponent(() => import('../pages/Export/HealthCommitteeExport'))
)

// 私装地锁定制导出
const PrivateOutfitExport = requireAuth(
  asyncComponent(() => import('../pages/Export/PrivateOutfitExport'))
)

// 药监局定制导出
const DrugAdministrationExport = requireAuth(
  asyncComponent(() => import('../pages/Export/DrugAdministrationExport'))
)

// 市团委定制导出
const CityYouthCorpsCommitteeExport = requireAuth(
  asyncComponent(() => import('../pages/Export/CityYouthCorpsCommitteeExport'))
)

// 市教委定制导出
const CityEduCommissionExport = requireAuth(
  asyncComponent(() => import('../pages/Export/CityEduCommissionExport'))
)

// 市体育局定制导出
const CitySportsBureauExport = requireAuth(
  asyncComponent(() => import('../pages/Export/CitySportsBureauExport'))
)

// 交通委员会定制导出
const TrafficCommitteeExport = requireAuth(
  asyncComponent(() => import('../pages/Export/TrafficCommitteeExport'))
)

// 市城市管理委定制导出
const CityManagementCommittee = requireAuth(
  asyncComponent(() => import('../pages/Export/CityManagementCommittee'))
)

// 城市管理委央产小区供暖-定制导出
const CityManagementHeatingSupply = requireAuth(
  asyncComponent(() => import('../pages/Export/CityManagementHeatingSupply'))
)

// 城市管理委央产小区供电-定制导出
const CityManagementPowerSupply = requireAuth(
  asyncComponent(() => import('../pages/Export/CityManagementPowerSupply'))
)

// 知识产权定制导出
const IntellectualProperty = requireAuth(
  asyncComponent(() => import('../pages/Export/IntellectualProperty'))
)

// 民政局定制导出
const CivilAffairsBureauExport = requireAuth(
  asyncComponent(() => import('../pages/Export/CivilAffairsBureauExport'))
)

// 消防救援总队定制导出
const FireRescueTeamExport = requireAuth(
  asyncComponent(() => import('../pages/Export/FireRescueTeamExport'))
)

// 政务服务好差评定制导出
const GoodBadRatingExport = requireAuth(
  asyncComponent(() => import('../pages/Export/GoodBadRatingExport'))
)

// 市民族宗教委定制导出
const NationalReligionExport = requireAuth(
  asyncComponent(() => import('../pages/Export/NationalReligionExport'))
)

// 市妇联定制导出
const WomenFederationExport = requireAuth(
  asyncComponent(() => import('../pages/Export/WomenFederationExport'))
)

// 市残联定制导出
const DisabledPersonsFederationExport = requireAuth(
  asyncComponent(() =>
    import('../pages/Export/DisabledPersonsFederationExport')
  )
)

// 市委统战部定制导出
const ShiTongZhanBuExport = requireAuth(
  asyncComponent(() => import('../pages/Export/ShiTongZhanBuExport'))
)

// 健康宝手机号解绑定制导出
const HealthPhoneUnboundExport = requireAuth(
  asyncComponent(() => import('../pages/Export/HealthPhoneUnboundExport'))
)

// 市教委高校返京定制导出
const CollegesAndUniversitiesExport = requireAuth(
  asyncComponent(() => import('../pages/Export/CollegesAndUniversitiesExport'))
)

// 健康宝弹窗定制导出
const HealthKitPopupExport = requireAuth(
  asyncComponent(() => import('../pages/Export/HealthKitPopupExport'))
)

// 市委宣传部定制导出
const PublicityDepartmentExport = requireAuth(
  asyncComponent(() => import('../pages/Export/PublicityDepartmentExport'))
)

// 市城管委管廊处定制导出
const PipeGalleryOfficeExport = requireAuth(
  asyncComponent(() => import('../pages/Export/PipeGalleryOfficeExport'))
)

// 研究室全量工单定制导出
const LabAllWorkOrderExport = requireAuth(
  asyncComponent(() => import('../pages/Export/LabAllWorkOrderExport'))
)
/* ************** 网络对接工单 *************** */
// 待受理工单列表
const NetPendingList = requireAuth(
  asyncComponent(() => import('../pages/WorkOrderSearch/NetPendingList'))
)

// 微信网络工单详情(待受理)
const WXWorkOrderDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/WeChat/WorkOrderDetail'))
)

// 上报审核列表
const NetReportList = requireAuth(
  asyncComponent(() => import('../pages/WorkOrderSearch/NetReportList'))
)

// 微信回复审核列表页
const WxReplyList = requireAuth(
  asyncComponent(() => import('../pages/WorkOrderSearch/WxReplyList'))
)

// 微信回复审核详情页
const WxReplyDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/WeChat/WxReplyDetail'))
)

// 对接工单查询列表
const NetSearchList = requireAuth(
  asyncComponent(() => import('../pages/WorkOrderSearch/NetSearchList'))
)
// 微信受理详情 (新建工单页面有四个：1-新建工单NewWorkOrder  2-微信受理工单WxNewWorkOrder  3-微信拆分工单wxNewWokeOrderCopy   4-来电工单CallWorkOrder)
const WxNewWorkOrder = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/WxNewWorkOrder'))
)
// 微信拆分工单
const wxNewWokeOrderCopy = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/wxNewWokeOrderCopy'))
)

// 首都之窗待受理
const WebsiteWokerOrder = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/WeChat/WebsiteWokerOrder'))
)

//  信件分配
const MailAssignment = requireAuth(
  asyncComponent(() => import('../pages/WorkOrderSearch/MailAssignment'))
)

// 政务平台详情页面
const HsoaWokerOrder = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/WeChat/HsoaWokerOrder'))
)

// 互联网+监管
const NetSuperviseWokerOrder = requireAuth(
  asyncComponent(() =>
    import('../pages/WorkOrder/WeChat/NetSuperviseWokerOrder')
  )
)
// 网络回复通用受理详情页面
const CurrencyWorkOrder = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/CurrencyWorkOrder'))
)

// 好差评工单详情(待受理)
const GoodBadRatingDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/WeChat/GoodBadRatingDetail'))
)
/* --------------网络对接回复审核----------------*/

// 电子政务回复审核
const NetReplyHsoaWokerOrder = requireAuth(
  asyncComponent(() =>
    import('../pages/WorkOrder/NetReply/NetReplyHsoaWokerOrder')
  )
)

// 互联网+监管回复审核
const NetReplySuperviseWokerOrder = requireAuth(
  asyncComponent(() =>
    import('../pages/WorkOrder/NetReply/NetReplySuperviseWokerOrder')
  )
)

// 首都之窗回复审核
const NetReplyWebsiteWokerOrder = requireAuth(
  asyncComponent(() =>
    import('../pages/WorkOrder/NetReply/NetReplyWebsiteWokerOrder')
  )
)

/* ------------------------操作信息-------------------- */
// 操作记录
const Operation = requireAuth(
  asyncComponent(() => import('../pages/Roam/Operation'))
)
// 工单流转
const RoamInfo = requireAuth(
  asyncComponent(() => import('../pages/Roam/RoamInfo'))
)

// 接派一体化情况
const AutoDispatchOrder = requireAuth(
  asyncComponent(() => import('../pages/Roam/AutoDispatchOrder'))
)

/* -----------疫情数据录入-------------*/
const EpidemicData = requireAuth(
  asyncComponent(() => import('../pages/EpidemicData'))
)
/* ------------社区/小区---------------------*/

const Community = requireAuth(
  asyncComponent(() => import('../pages/CityData/Community'))
)
const Village = requireAuth(
  asyncComponent(() => import('../pages/CityData/Village'))
)

/* ------------年龄性别统计数据录入---------------------*/
const AgeGender = requireAuth(
  asyncComponent(() => import('../pages/AgeGender'))
)
/* ------------央产房数据---------------------*/
const YcfMonthlyData = requireAuth(
  asyncComponent(() => import('../pages/YcfMonthlyData'))
)
// 实时工单
const DpOrderManage = requireAuth(
  asyncComponent(() => import('../pages/DpOrderManage'))
)
// 实时工单主题
const DpItemsManage = requireAuth(
  asyncComponent(() => import('../pages/DpOrderManage/dpItemsManage'))
)

// 积水分布情况查看
const PondingDistribution = requireAuth(
  asyncComponent(() => import('../pages/Ponding/PondingDistribution'))
)
// 积水点位信息管理
const PondingPoints = requireAuth(
  asyncComponent(() => import('../pages/Ponding/PondingPoints'))
)
// 积水工单信息管理
const PondingOrders = requireAuth(
  asyncComponent(() => import('../pages/Ponding/PondingOrders'))
)

// 生活基础分布情况查看
const LifeBaseDistribution = requireAuth(
  asyncComponent(() => import('../pages/LifeBase/LifeBaseDistribution'))
)
// 生活基础点位信息管理
const LifeBasePoints = requireAuth(
  asyncComponent(() => import('../pages/LifeBase'))
)
// 生活基础工单信息管理
const LifeBaseOrders = requireAuth(
  asyncComponent(() => import('../pages/LifeBase/LifeBaseOrders'))
)

/* ************* 110对接专区 ****************** */
// 退回转派审核
const TransferReview110 = requireAuth(
  asyncComponent(() =>
    import('../pages/ReplyReview110/Push110Query/TransferReview110')
  )
)
// 回复审核
const ReplyReview110 = requireAuth(
  asyncComponent(() =>
    import('../pages/ReplyReview110/Push110Query/ReplyReview110')
  )
)
// 回复审核详情
const PoliceDetail = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/Police'))
)
// 推送至110工单查询
const Push110Query = requireAuth(
  asyncComponent(() =>
    import('../pages/ReplyReview110/Push110Query/Push110Query')
  )
)
// 待受理警单
const PendingReceipt = requireAuth(
  asyncComponent(() =>
    import('../pages/ReplyReview110/AcceptReview/PendingReceipt')
  )
)
// 受理警单查询
const AcceptReceipt = requireAuth(
  asyncComponent(() =>
    import('../pages/ReplyReview110/AcceptReview/AcceptReceipt')
  )
)
// 110专区工单详情
const PoliceOrderDetail = requireAuth(
  asyncComponent(() =>
    import('../pages/ReplyReview110/Push110Query/PoliceOrderDetail')
  )
)

/* ************* 挂账不纳入管理 ****************** */
// 挂账不纳入列表
const CardOutList = requireAuth(
  asyncComponent(() => import('../pages/CardOut/CardOutList'))
)
// 挂账不纳入申请列表
const CardOutApplyList = requireAuth(
  asyncComponent(() => import('../pages/CardOut/CardOutApplyList'))
)
// 挂账不纳入列表详情页
const CardOutListDetail = requireAuth(
  asyncComponent(() => import('../pages/CardOut/CardOutListDetail'))
)
// 挂账不纳入市中心审批列表
const CardOutApprovalList = requireAuth(
  asyncComponent(() => import('../pages/CardOut/CardOutApprovalList'))
)
// 挂账不纳入分中心审批列表
const CardOutApprovalSubList = requireAuth(
  asyncComponent(() => import('../pages/CardOut/CardOutApprovalSubList'))
)
// 挂账市中心审核页
const CardOutListDetailCenter = requireAuth(
  asyncComponent(() => import('../pages/CardOut/CardOutListDetailCenter'))
)
// 挂账分中心审核页
const CardOutListDetailSub = requireAuth(
  asyncComponent(() => import('../pages/CardOut/CardOutListDetailSub'))
)
// 挂账不纳入随机审核列表
const RandomAuditList = requireAuth(
  asyncComponent(() => import('../pages/CardOut/RandomAuditList'))
)
// 挂账不纳入审核结果查看
const ReviewOfAuditResults = requireAuth(
  asyncComponent(() => import('../pages/CardOut/ReviewOfAuditResults'))
)
// 挂账不考核流转记录 22.8.8 +  修改为挂账、不纳入流转记录 23.12.14
const CardCirculationRecord = requireAuth(
  asyncComponent(() => import('../pages/CardOut/CardCirculationRecord'))
)
// 主动剔除人群管理页面 22.8.8 +
const ExcludedCrowdManagement = requireAuth(
  asyncComponent(() => import('../pages/CardOut/ExcludedCrowdManagement'))
)
// 剔除事项字典管理 22.8.9 +
const EliminateDictionary = requireAuth(
  asyncComponent(() => import('../pages/CardOut/EliminateDictionary'))
)
// 申请不考核事项管理 22.8.9
const NoAssessment = requireAuth(
  asyncComponent(() => import('../pages/CardOut/NoAssessment'))
)
// 挂账不考核获取权限管理 22.4.14新+
const CardOutPermission = requireAuth(
  asyncComponent(() => import('../pages/CardOut/CardOutPermission'))
)
// 申报不考核期限设置 22.4.18新+
const CardOutTermLimit = requireAuth(
  asyncComponent(() => import('../pages/CardOut/CardOutTermLimit'))
)
// 复审结果批量上传修改结果功能 23.1.9新+
const ReviewResultBatchUpload = requireAuth(
  asyncComponent(() => import('../pages/CardOut/ReviewResultBatchUpload'))
)
// 复审文件上传
const HangTickRectification = requireAuth(
  asyncComponent(() => import('../pages/CardOut/HangTickRectification'))
)
// 考核月份更新功能 23.2.10新+  修改为考核月份批量修改  23.12.14
const AssessmentMonthUpdate = requireAuth(
  asyncComponent(() => import('../pages/CardOut/AssessmentMonthUpdate'))
)
// 复审账号修改功能 23.6.5新+
const ReviewAccountModification = requireAuth(
  asyncComponent(() => import('../pages/CardOut/ReviewAccountModification'))
)

// 添加考核期  23.12.19新+
const AddAssessMonth = requireAuth(
  asyncComponent(() => import('../pages/CardOut/AddAssessMonth'))
)

// 主动治理台账管理 24.7.22新+
const ActiveGovernance = requireAuth(
  asyncComponent(() => import('../pages/CardOut/ActiveGovernance'))
)

/* ************* 工单处理情况查询 ****************** */
const WorkOrderQuery = requireAuth(
  asyncComponent(() => import('../pages/WorkOrderProcessingQuery'))
)

/* ************* 系统监控 ****************** */
// 数据源监控
const DataSource = requireAuth(
  asyncComponent(() => import('../pages/Monitoring/DataSource'))
)
// 市中心公共池子监控
const PublicPoolMonitoring = requireAuth(
  asyncComponent(() => import('../pages/Monitoring/PublicPoolMonitoring'))
)
// 市中心个人池子监控
const PersonalPoolMonitoring = requireAuth(
  asyncComponent(() => import('../pages/Monitoring/PersonalPoolMonitoring'))
)
// 工单监控
const OrderMonitoring = requireAuth(
  asyncComponent(() => import('../pages/Monitoring/OrderMonitoring'))
);

const phoneRecord = requireAuth(
  asyncComponent(() => import('@/pages/Monitoring/phoneRecord/index.jsx'))
);

const phoneMonitor = requireAuth(
  asyncComponent(() => import('@/pages/Monitoring/phoneMonitor/index.jsx'))
);

// 微信同步情况监测 22.2.10gy
const WxSyncMonitor = requireAuth(
  asyncComponent(() => import('../pages/Monitoring/WxSyncMonitor'))
)

/* ***************短信回访************* */
// 回访失败
const FailureToReturnVisit = requireAuth(
  asyncComponent(() => import('../pages/SmsReturnVisit/FailureToReturnVisit'))
)
//  定时回访
const SmsSettings = requireAuth(
  asyncComponent(() => import('../pages/SmsReturnVisit/SmsSettings'))
)

/* ***************承办单位待办工单统计************* */
// 承办单位待办工单统计subcenterUnhandleorderList
const TodoListStatusList = requireAuth(
  asyncComponent(() => import('../pages/TodoListStatus/TodoListStatusList'))
)

/* ***************市中心层面的承办单位待办明细************* */
// 市中心层面的承办单位待办明细
const SubcenterUnhandleorderList = requireAuth(
  asyncComponent(() =>
    import('../pages/TodoListStatus/subcenterUnhandleorderList')
  )
)

/* ***************承办单位待办明细************* */
// 承办单位待办明细
const OfficeUnhandleorderList = requireAuth(
  asyncComponent(() =>
    import('../pages/TodoListStatus/OfficeUnhandleorderList')
  )
)

/** *************坐席查询记录************* */
const userOperationRecord = requireAuth(
  asyncComponent(() => import('../pages/CityCenter/record'))
)

/** *************市中心待办工单明细************* */
const details = requireAuth(
  asyncComponent(() => import('../pages/CityCenter/details'))
)

/** *************市中心待办工单统计************* */
const CityCentertList = requireAuth(
  asyncComponent(() => import('../pages/CityCenter/CityCentertList'))
)

/* **************质检************* */

// 抽样质检
const SamplingQuality = requireAuth(
  asyncComponent(() => import('../pages/Quality/SamplingQuality'))
)
// 待质检分配
const BeQualityTesting = requireAuth(
  asyncComponent(() => import('../pages/Quality/BeQualityTesting'))
)

// 质检任务管理
const QualityInspectionTask = requireAuth(
  asyncComponent(() => import('../pages/Quality/QualityInspectionTask'))
)

/* ********************************** 运维管控系统 **************************************** */
/* **********************运维审计********************* */
// 数据监控
const DataMonitoring = requireAuth(
  asyncComponent(() =>
    import('../pages/Operations/OperationalAudit/DataMonitoring')
  )
)

// 服务监控
const ServiceMonitoring = requireAuth(
  asyncComponent(() =>
    import('../pages/Operations/OperationalAudit/ServiceMonitoring')
  )
)

// 交换监控
const ExchangeMonitoring = requireAuth(
  asyncComponent(() =>
    import('../pages/Operations/OperationalAudit/ExchangeMonitoring')
  )
)

// 数据审计
const DataAudit = requireAuth(
  asyncComponent(() => import('../pages/Operations/OperationalAudit/DataAudit'))
)

// 异常报警
const AbnormalAlarm = requireAuth(
  asyncComponent(() =>
    import('../pages/Operations/OperationalAudit/AbnormalAlarm')
  )
)

/* **********************统计分析********************* */
// 用户登录统计
const UserLoginStatic = requireAuth(
  asyncComponent(() =>
    import('../pages/Operations/StaticAnalysis/UserLoginStatic')
  )
)

// IP地址访问统计
const IPLocationAccessStatic = requireAuth(
  asyncComponent(() =>
    import('../pages/Operations/StaticAnalysis/IPLocationAccessStatic')
  )
)

// 服务访问统计
const ServiceAccessStatic = requireAuth(
  asyncComponent(() =>
    import('../pages/Operations/StaticAnalysis/ServiceAccessStatic')
  )
)

/* **********************服务资源管理********************* */
// 列表
const ServiceList = requireAuth(
  asyncComponent(() =>
    import('../pages/Operations/ServiceResourceManage/ServiceList')
  )
)

// 服务资源统计
const ServiceResourceStatic = requireAuth(
  asyncComponent(() =>
    import('../pages/Operations/ServiceResourceManage/ServiceResourceStatic')
  )
)

/* **********************多应用服务管理********************* */
const MultiAppList = requireAuth(
  asyncComponent(() =>
    import('../pages/Operations/MultiAppService/MultiAppList')
  )
)

/* **********************运维行为管理********************* */
const OperationBehaviorList = requireAuth(
  asyncComponent(() =>
    import('../pages/Operations/OperationBehaviorManage/OperationBehaviorList')
  )
)

/* **********************监控运维管理********************* */
const MonitorOperations = requireAuth(
  asyncComponent(() =>
    import('../pages/Operations/MonitorOperationsManage/MonitorOperations')
  )
)

/* **********************日志管理********************* */
const LogManage = requireAuth(
  asyncComponent(() => import('../pages/Operations/LogManage'))
)

/* ********************************** 典型案例 **************************************** */
// 案例详情
const ClassicDetails = requireAuth(
  asyncComponent(() => import('../pages/ClassicCases/ClassicDetails'))
)

// 案例发布
const AddCase = requireAuth(
  asyncComponent(() => import('../pages/ClassicCases/AddCase'))
)

// 案例列表
const CaseListCen = requireAuth(
  asyncComponent(() => import('../pages/ClassicCases/ViewCase'))
)

// 案例管理
const CaseManage = requireAuth(
  asyncComponent(() => import('../pages/ClassicCases/CaseManagement'))
)

/* ********************************** 考核管理 **************************************** */

// 考核自动统计核算
const AutomaticStatistics = requireAuth(
  asyncComponent(() => import('../pages/Assessment/AutomaticStatistics'))
)

// 挂账剔除情况统计
const AccountEliminationStatistics = requireAuth(
  asyncComponent(() =>
    import('../pages/Assessment/AccountEliminationStatistics')
  )
)
// 一级问题分类工单量
const LevelProblemWorkOrder = requireAuth(
  asyncComponent(() => import('../pages/Assessment/LevelProblemWorkOrder'))
)

// 二级问题分类工单量
const SecondaryProblemWorkOrder = requireAuth(
  asyncComponent(() => import('../pages/Assessment/SecondaryProblemWorkOrder'))
)

// 各区(含街乡镇)承办量
const DistrictStatistics = requireAuth(
  asyncComponent(() => import('../pages/Assessment/DistrictStatistics'))
)

// 直派街乡镇承办量
const StreetStatistics = requireAuth(
  asyncComponent(() => import('../pages/Assessment/StreetStatistics'))
)
// 七有五性工单量
const SevenHaveFive = requireAuth(
  asyncComponent(() => import('../pages/Assessment/SevenHaveFive'))
)

/* ********************************** 主动剔除库 **************************************** */
// 待主动剔除库
const WaitEliminateLibraryList = requireAuth(
  asyncComponent(() =>
    import('../pages/EliminateLibrary/WaitEliminateLibraryList')
  )
)
// 已主动剔除库
const AlreadyEliminateLibraryList = requireAuth(
  asyncComponent(() =>
    import('../pages/EliminateLibrary/AlreadyEliminateLibraryList')
  )
)
// 主动剔除工单查询
const EliminateLibraryQuery = requireAuth(
  asyncComponent(() =>
    import('../pages/EliminateLibrary/EliminateLibraryQuery')
  )
)
// 主动剔除情况统计
const EliminateLibraryList = requireAuth(
  asyncComponent(() => import('../pages/EliminateLibrary/EliminateLibraryList'))
)

/* *************************************** 测试 **************************************** */
const Test = requireAuth(asyncComponent(() => import('../pages/Test')))

/* *************************************** 每月一题 **************************************** */
// 待处理告知单
const PendingNotice = requireAuth(
  asyncComponent(() => import('../pages/QuestionPerMonth/PendingNotice'))
)
// 已确认告知单查询  修改为  每月一题查看（主责单位）
const ConfirmedNotificationInquiry = requireAuth(
  asyncComponent(() =>
    import('../pages/QuestionPerMonth/ConfirmedNotificationInquiry')
  )
)
// 告知单查看  修改为  每月一题查看（配合单位）
const NoticeCheck = requireAuth(
  asyncComponent(() => import('../pages/QuestionPerMonth/NoticeCheck'))
)
// 每月一题查看
const CheckOneQuestion = requireAuth(
  asyncComponent(() => import('../pages/QuestionPerMonth/CheckOneQuestion'))
)
// 每月一题统计
const MonthlyStatistics = requireAuth(
  asyncComponent(() => import('../pages/QuestionPerMonth/MonthlyStatistics'))
)

/* *************************************** 特殊人群手机号维护 **************************************** */
const SpecialGroupPhoneMaintain = requireAuth(
  asyncComponent(() => import('../pages/System/SpecialGroupPhoneMaintain'))
)

/* *************************************** 中心定制导出 **************************************** */
// 两个交叉
const TwoCrossExport = requireAuth(
  asyncComponent(() => import('../pages/Centre/TwoCrossExport/TwoCrossExport'))
)

// 央产点位工单查询
const YCQuery = requireAuth(
  asyncComponent(() => import('../pages/Centre/YCQuery'))
)

// 自动转派工单查询   11.4gy+
const HeatingList = requireAuth(
  asyncComponent(() => import('../pages/WorkOrderSearch/HeatingList'))
)

/* *************************************** 在线填报 **************************************** */
// 街乡镇联系册填报
const StreetContactBookFill = requireAuth(
  asyncComponent(() => import('../pages/OnlineFilling/StreetContactBookFill'))
)

// 街乡镇联系册查询（区级）
const StreetContactBookQueryArea = requireAuth(
  asyncComponent(() =>
    import('../pages/OnlineFilling/StreetContactBookQueryArea')
  )
)

// 街乡镇联系册查询（市级）
const StreetContactBookQueryCity = requireAuth(
  asyncComponent(() =>
    import('../pages/OnlineFilling/StreetContactBookQueryCity')
  )
)

// 11.4gy新+
// 回访录音查询
const RevisitAudioList = requireAuth(
  asyncComponent(() => import('../pages/Common/RevisitAudioList'))
)
//

// 企业基础信息查询
const CompanyInfoQuery = requireAuth(
  asyncComponent(() => import('../pages/WorkOrderSearch/CompanyInfoQuery'))
)

// 专项问题督办单查询
const SpecialIssuesList = requireAuth(
  asyncComponent(() => import('../pages/Accept/SpecialIssuesList'))
)

// 行业在线确认单查询页面
const WaitConfirmQuery = requireAuth(
  asyncComponent(() =>
    import('../pages/IndustryOnlineConfirm/WaitConfirmQuery')
  )
)

// 行业在线确认单查询页面
const ConfirmedQuery = requireAuth(
  asyncComponent(() => import('../pages/IndustryOnlineConfirm/ConfirmedQuery'))
)

// 行业在线确认单查询（市级）
const CityConfirmedQuery = requireAuth(
  asyncComponent(() =>
    import('../pages/IndustryOnlineConfirm/CityConfirmedQuery')
  )
)

// 行业在线确认单管理员查看页面
const ConfirmedAdmin = requireAuth(
  asyncComponent(() => import('../pages/IndustryOnlineConfirm/ConfirmedAdmin'))
)

// 重复反映告知单查询
const RepeatReflectInformQuery = requireAuth(
  asyncComponent(() =>
    import('../pages/WorkOrderSearch/RepeatReflectInformQuery')
  )
)

// 自办考核明细
const SelfFinishDetail = requireAuth(
  asyncComponent(() =>
    import('../pages/AssessmentWorkOrderDetails/SelfFinishDetail')
  )
)

// 七有五性考核明细
const SevenHaveFiveNaturesDetail = requireAuth(
  asyncComponent(() =>
    import('../pages/AssessmentWorkOrderDetails/SevenHaveFiveNaturesDetail')
  )
)

// 行业部门考核明细查询
const IndustryDepartmentDetail = requireAuth(
  asyncComponent(() =>
    import('../pages/AssessmentWorkOrderDetails/IndustryDepartmentDetail')
  )
)

/* ********************************** 体系建设-在线填报(优化在线填报现有功能) ******************************** */
// 接诉即办人员信息管理
const JsjbUser = requireAuth(
  asyncComponent(() => import('../pages/OnlineFilling/OnlineFilling'))
)
// 接诉即办机构信息管理
const JsjbDept = requireAuth(
  asyncComponent(() => import('../pages/OnlineFilling/OnlineFillingDept'))
)

/* ********************************** 专项调度 2023.6.12新+  ******************************** */
const SpecialProjectDispatch = requireAuth(
  asyncComponent(() => import('../pages/Accept/SpecialProjectDispatch'))
)

/* ********************************** 协办 2023.6.25新+ ******************************** */
// 待签收
const CollaborationWaitSign = requireAuth(
  asyncComponent(() => import('../pages/Collaboration/CollaborationWaitSign'))
)

// 待处理
const CollaborationWaitHandle = requireAuth(
  asyncComponent(() => import('../pages/Collaboration/CollaborationWaitHandle'))
)

// 发起协办工单查询
const SendCollaborationQuery = requireAuth(
  asyncComponent(() => import('../pages/Collaboration/SendCollaborationQuery'))
)

// 承办协办工单查询
const OrganizerCollaborationQuery = requireAuth(
  asyncComponent(() =>
    import('../pages/Collaboration/OrganizerCollaborationQuery')
  )
)

// 协办全量工单查询
const CollaborationAllQuery = requireAuth(
  asyncComponent(() => import('../pages/Collaboration/CollaborationAllQuery'))
)

// 协办详情页
const CollaborativeDetail = requireAuth(
  asyncComponent(() =>
    import('../pages/Collaboration/PublicFile/CollaborativeDetail')
  )
)

// 数据导出日志查询
const DataExport = requireAuth(
  asyncComponent(() => import('../pages/DataExport'))
)

// 定制导出配置页面 -- 23.11.2新+
const ExportConfig = requireAuth(
  asyncComponent(() => import('../pages/Export/ExportConfig'))
)

// 定制导出配置页面 -- 23.11.6新+
const ExportMenu = requireAuth(
  asyncComponent(() => import('../pages/Export/ExportConfig/ExportMenu'))
)

// 定制导出数据管理页面 -- 23.11.21新+
const ExportDataManage = requireAuth(
  asyncComponent(() => import('../pages/Export/ExportConfig/ExportDataManage'))
)

// 督办体系建设
const WorkSystemBuild = requireAuth(
  asyncComponent(() => import('../pages/WorkSystemBuild'))
)

// 表扬告知单
const PraiseNotifyForm = requireAuth(
  asyncComponent(() => import('../pages/PraiseNotifyForm'))
)

// 埋点日志查询
const LogQuery = requireAuth(asyncComponent(() => import('../pages/LogQuery')))
// 工单要素质检管理
const WorkOrderInspection = requireAuth(
  asyncComponent(() => import('../pages/WorkOrder/WorkOrderInspection'))
)
/* ********************************** 销账系统 ************************************ */
const MonthlyRollup = requireAuth(
  asyncComponent(() => import('../pages/WriteOffset/MonthlyRollup'))
)
const FullRollup = requireAuth(
  asyncComponent(() => import('../pages/WriteOffset/FullRollup'))
)
const RollupList = requireAuth(
  asyncComponent(() => import('../pages/WriteOffset/RollupList'))
)
const WriteOffSpace = requireAuth(
  asyncComponent(() => import('../pages/WriteOffset/WriteOffSpace'))
)
const DetailList = requireAuth(
  asyncComponent(() => import('../pages/WriteOffset/DetailInfo/detail'))
)
const PortrayalManage = requireAuth(
  asyncComponent(() => import('../pages/System/PortrayalManage'))
)

// 新国际版网站查询
const International = requireAuth(
  asyncComponent(() => import('../pages/International'))
)

// key为与后端返回菜单的name相对应
const menuJson = {
  dashboard: Dashboard, // 首页
  com_audio: AudioList, // 录音查询
  404: NotFound, // 404页面
  // 企业督办、催办列表
  enterprise_urge_list: EnterpriseUrgeList, // 企业催办列表 1 4
  enterprise_supervise_list: EnterpriseSuperviseList, // 企业督办列表 1
  // 回访部分页面
  revisit_wait_for: WaitForRevisit, // 待回访工单列表 1 4
  wait_for_revisit_detail: WaitForRevisitDetail, // 待回访工单详情页面 2/1
  revisiting: Revisiting, // 回访中工单 1 4
  revisit_search_and_dispatch: RevisitSearchAndDispatch, // 回访查询再分派 1 /4
  revisit_back_and_dispatch: RevisitBackAndDispatch, // 回访退回再分派 1 /4
  revisit_done_and_dispatch: RevisitDoneAndDispatch, // 回访完结再分派 1  4
  revisit_visit: RevisitVisit, // 回访统计模块
  visit_daily: VisitDaily, // 坐席回访日报 22.6.6新+
  revisit_undertaker: RevisitUndertaker, // 承办单位回访统计

  // 回访查询es  11.29gy新+
  revisit_search_and_dispatch_es: RevisitSearchAndDispatchES, // 回访查询再分派es
  revisit_back_and_dispatch_es: RevisitBackAndDispatchES, // 回访退回再分派es
  revisit_done_and_dispatch_es: RevisitDoneAndDispatchES, // 回访完结再分派es
  revisit_wait_for_es: WaitForRevisitES, // 待回访工单列表es
  revisiting_es: RevisitingES, // 回访中工单es

  // 诉求部分页面
  appeal_distribute_unstable: AppealUnstableGroup, // 1   4
  appeal_distribute_flexible: AppealFlexibleGroup, // 1
  appeal_distribute_center: AppealCenterGroup, // 1
  appeal_distribute_brand: AppealBrandGroup, // 1
  appeal_distribute_district: AppealDistrictGroup, // 1
  appeal_distribute_industry: AppealIndustryGroup, // 1
  appeal_distribute_puzzle: AppealPuzzleGroup, // 1
  appeal_distribute_complain: AppealComplainGroup, // 1
  appeal_enterprise_normal: AppealEnterpriseNormalGroup, // 企业一般工单组 1
  appeal_enterprise_instability_group: EnterpriseInstabilityGroup, // 企业不稳定工单组 2023.4.10新+
  appeal_enterprise_puzzle: AppealEnterprisePuzzleGroup, // 企业疑难工单组 1
  appeal_distribute_order_detail: AppealDistributeOrderDetail, // 诉求分配详情页面 2
  appeal_return_normal: AppealNormalReturn, // 1
  appeal_return_puzzle: AppealPuzzleReturn, // 1
  appeal_enter_return_normal: AppealEnterpriseNormalReturn, // 企业一般退回  1
  appeal_enter_return_puzzle: AppealEnterprisePuzzleReturn, // 企业疑难退回 1
  appeal_return_complain: AppealComplainReturn, // 1
  appeal_risk_return: AppealRiskReturn, // 中高风险地区退回
  heating_return: HeatingReturn, // 供暖退回
  wx_automatic_first_return: WxAutomaticFirstReturn, // 微信自动派单首次退回
  appeal_return_order_detail: AppealReturnOrderDetail, // 诉求退回详情页面 2/1
  appeal_normal_postpone: AppealNormalPostPone, // 1
  appeal_puzzle_postpone: AppealPuzzlePostPone, // 1
  appeal_enter_normal_postpone: AppealEnterpriseNormalPostPone, // 企业普通延期 1
  appeal_enter_puzzle_postpone: AppealEnterprisePuzzlePostPone, // 企业疑难延期 1
  appeal_complain_postpone: AppealComplainPostPone, // 1
  appeal_postpone_order_detail: AppealPostPoneOrderDetail, // 诉求延期详情页面 2/1
  appeal_normal_reply: AppealNormalReply, // 1
  appeal_puzzle_reply: AppealPuzzleReply, // 1
  appeal_enter_normal_reply: AppealEnterpriseNormalReply, // 企业一般回复 1
  appeal_enter_puzzle_reply: AppealEnterprisePuzzleReply, // 企业疑难回复 1
  appeal_risk_reply: AppealRiskReply, // 中高风险地区回复
  heating_reply: HeatingReply, // 供暖回复
  appeal_complain_reply: AppealComplainReply, // 1
  appeal_reply_order_detail: AppealReplyOrderDetail, // 诉求回复详情页面 2/1
  appeal_work_order_list: AppealWorkOrderList, // 诉求工单查询页面 1
  appeal_automatic_dispatch: AutomaticDispatch, // 诉求席自动派单 1  4
  appeal_network_personal_group: NetworkPersonalGroup, // 网络个人组 1  4
  appeal_network_enterprise_group: NetworkEnterpriseGroup, // 网络企业组 1

  appeal_network_extension: NetworkExtension, // 网络延期 1
  appeal_network_recovery: NetworkRecovery, // 网络回复 1
  appeal_network_return: NetworkReturn, // 网络退回 1

  appeal_network_personal_group_puzzle: NetworkPersonalGroupPuzzle, // 网络疑难工单组
  appeal_network_recovery_puzzle: NetworkRecoveryPuzzle, // 网络疑难回复 1
  appeal_network_return_puzzle: NetworkReturnPuzzle, // 网络疑难退回 1

  appeal_net_order_info: NetOrderInfo, // 网络诉求详情 2/1
  appeal_net_return_info: NetReturnInfo, // 网络退回详情 2/1
  appeal_net_delay_info: NetderlayInfo, // 网络延期详情 2/1
  appeal_net_reply_info: NetreplyInfo, // 网络回复详情 2/1
  // 受理部分页面
  acc_popup_order: AccPopupOrder, // 2/1
  acc_new_order: AccNewOrder, // 2/1
  acc_uncommit_order: AccUncommitOrder, // 2/1
  acc_uncommit_order_detail: AccUncommitOrderDetail, // 2/1
  acc_return_by_appeal: AccReturnByAppeal, // 1
  acc_return_by_appeal_detail: AccReturnByAppealDetail, // 2/1
  acc_monitor_view: AccMonitorView, // 1
  acc_monitor_view_detail: AccMonitorViewDetail, // 2/1
  acc_complain: AccComplain, // 投诉举报 1
  acc_complain_detail: AccComplainDetail, // 投诉举报详情页面 2/1
  acc_complain_return: AccComplainReturn, // 投诉举报退回 // 1
  acc_complain_return_detail: AccComplainReturnDetail, // 投诉举报退回详情页面 2/1

  acc_order_list: AccOrderList, // 3   4
  acc_order_list_detail: AccOrderListDetail, // 3 工单查询页面
  acc_urge_list: AccUrgeList, // 1  4
  acc_supervise_list: AccSuperviseList, // 1  4
  acc_return_net_work_appeal: ReturnNetworkAppeal, //  网络诉求退回 ReturnNetworkAppeal 1
  acc_network_return_transfe: NetworkReturnTransfer, //  网络退回工单转派 NetworkReturnTransfer 1
  acc_monitor_enterprise: EnReturnTransfer, // 企业退回工单转派(新添加)
  acc_enterprise_return_transfe: ReturnEnterpriseAppeal, // 企业诉求退回
  // A3 部分页面
  a3_wait_for_normal_sign: A3WaitForNormalSign, // A3 一般用户获取页面 1
  a3_wait_for_admin_get: A3WaitForAdminGet, // A3 管理员获取页面 1  4
  a3_get_detail: A3GetDetail, // A3 管理员获取详情页面 2/1
  a3_wait_for_admin_sign: A3WaitForAdminSign, // A3 管理员签收页面 1
  a3_sign_detail: A3SignDetail, // 待签收详情页面 2/1
  a3_wait_for_handle: A3WaitForHandle, // A3分中心-待处理页面 1  4
  a3_wait_for_handle_detail: A3WaitForHandleDetail, // A3 待处理详情页面 2/1
  a3_work_order_list: A3WorkOrderList, // A3 工单查询页面 3
  a3_work_order_detail: A3WorkOrderDetail, // A3 工单查询页面 补充回复页面 3
  a3_a6_back_list: A3A6BackList, // A3 A6 退回审核页面 1
  a3_a6_back_detail: A3A6BackDetail, // 2/1
  a3_a6_postpone_list: A3A6PostPoneList, // A3 A6 延期审核 1  4
  a3_a6_postpone_detail: A3A6PostPoneDetail, // 2 /1
  a3_a6_done_list: A3A6DoneList, // A3 A6 完成审核列表 1  4
  a3_a6_done_detail: A3A6DoneDetail, // 2/1
  a3_urge_list: A3UrgeList, // A3 催办列表 1  4
  a3_supervise_list: A3SuperviseList, // A3 督办列表 1  4
  a3_inform_list: A3InformList, // A3 告知单列表 1  4
  // A6 部分页面
  a6_wait_for_normal_sign: A6WaitForNormalSign, // A6 一般用户获取页面 1
  a6_wait_for_admin_get: A6WaitForAdminGet, // A6 管理员获取页面 1
  a6_get_detail: A6GetDetail, // A6 管理员获取详情页面 2/1
  a6_wait_for_admin_sign: A6WaitForAdminSign, // A6 管理员签收页面 1
  a6_sign_detail: A6SignDetail, // 待签收详情页面 2/1
  a6_wait_for_handle: A6WaitForHandle, // A6 待处理页面 1  4
  a6_wait_for_handle_detail: A6WaitForHandleDetail, // A6 待处理详情页面 2/1
  a6_work_order_list: A6WorkOrderList, // A6 工单查询页面  4
  a6_urge_list: A6UrgeList, // A6 催办列表 1  4
  a6_supervise_list: A6SuperviseList, // A6 督办通知列表 1  4
  common_detail: CommonDetail, // 通用工单详情页面，只查看不做任何操作 2/1

  // 统计分析
  statistics_call_dispatch_details: StatisticsCallDispatchDetails, // 来电派单情况明细  4
  statistics_back_details: StatisticsBackDetails, //  退单情况明细  4
  wx_leaving_msg: WxLeavingMsg, //  微信留言统计
  wx_visit_statistic: WxVisitStatistic, // 微信页面访问统计

  /* ************* 系统管理子系统 ****************** */
  user: SystemUserManager, // 用户管理 1
  menu: SystemMenuManager, // 菜单管理 1
  role: SystemRoleManager, // 角色管理 3
  dept: SystemDeptManager, // 部门管理 .5
  dicts: SystemDictManager, // 字典管理 .5
  area: SystemAreaManage, // 区域管理 1
  logs: SystemLogManager, // 日志管理 1
  label: SystemLabelManager, // 标签管理 1
  request_type: SystemRequestTypeManager, // 问题分类管理 3
  handle_type: SystemHandleTypeManager, // 操作类型管理 3
  system_operation: SystemOperation, // 系统运维
  operation_audio: SystemAudioList, // 运维录音

  /* ************* 公告管理 ****************** */
  // 新加
  view_notice: ViewNotice, // 查看公告
  notice_management: NoticeManagement, // 公告管理
  add_notice: AddNotice, // 添加公告
  notice_details: NoticeDetails, // 公告详情

  /* ************* 定制导出 ****************** */
  export: CustomExport, // 城管定制导出
  // exportES: CustomExportES, // 城管定制导出ES
  price_export: PriceExport, // 物价局定制导出
  business_export: BusinessExport, // 商委定制导出
  agriculture_export: AgricultureExport, // 农业局定制导出
  traffic_export: TrafficExport, // 交管局
  guiziwei_export: RulesExport, // 规自委
  zhujianwei_export: LiveBuildExport, // 住建委
  chengguanwei_export: CityManagementExport, // 城管委
  jiwei_export: CommissionForDisciplineInspectionExport, // 纪委
  renminfangkong_export: CivilAirDefenseOfficeExport, // 人民防空办公室
  jiaotong_export: ComprehensiveTrafficControl, // 交通综合治理定制导出
  water_authority_export: WaterAuthorityExport, // 市水务局定制导出
  zhengwufuwuju_export: GovernmentAffairsExport, // 政务服务局体系处定制导出
  shuzhengcu_export: ShuZhengCuExport, // 疏整促定制导出
  health_export: HealthCommitteeExport, // 卫生健康委员会定制导出
  privateOut_export: PrivateOutfitExport, // 私装地锁定制导出
  drug_export: DrugAdministrationExport, // 药监局定制导出
  shituanwei_export: CityYouthCorpsCommitteeExport, // 市团委定制导出
  shijiaowei_export: CityEduCommissionExport, // 市教委定制导出
  shitiyuju_export: CitySportsBureauExport, // 市体育局定制导出
  traffic_committee_export: TrafficCommitteeExport, // 交通委员会定制导出
  shichengwei_export: CityManagementCommittee, // 市城市管理委定制导出
  shichengwei_heating_export: CityManagementHeatingSupply, // 城市管理委央产小区供暖-定制导出
  shichengwei_power_export: CityManagementPowerSupply, // 城市管理委央产小区供电-定制导出
  zhishichanquan_export: IntellectualProperty, // 知识产权定制导出
  minzhengju_export: CivilAffairsBureauExport, // 民政局定制导出
  xiaofang_export: FireRescueTeamExport, // 消防救援总队定制导出
  haochaping_export: GoodBadRatingExport, // 政务服务好差评定制导出
  shiminzuzongjiao_export: NationalReligionExport, // 市民族宗教委定制导出
  shifulian_export: WomenFederationExport, // 市妇联定制导出
  shicanlian_export: DisabledPersonsFederationExport, // 市残联定制导出
  tongzhanbu_export: ShiTongZhanBuExport, // 市委统战部定制导出
  health_phone_unbound_export: HealthPhoneUnboundExport, // 健康宝手机号解绑定制导出
  shijiaoweigaoxiao_export: CollegesAndUniversitiesExport, // 市教委高校返京定制导出
  health_kit_popup_export: HealthKitPopupExport, // 健康宝弹窗定制导出
  publicity_department_export: PublicityDepartmentExport, // 市委宣传部定制导出
  guanlangchu_export: PipeGalleryOfficeExport, // 市城管委管廊处定制导出
  lab_export: LabAllWorkOrderExport, // 研究室全量工单定制导出

  /* ************* 网络对接工单 ****************** */
  wx_work_order_detail: WXWorkOrderDetail, // 微信工单详情  受理
  new_work_order_detail: WXWorkOrderDetail,
  wx_work_order_detail2: WXWorkOrderDetail, // 微信工单详情  受理  疫情举报
  net_report_woker_order: WXWorkOrderDetail, // 互联网+监管
  net_pending_list: NetPendingList, // 网络对接工单 待受理
  net_report_list: NetReportList, // 网络对接工单 上报审核列表
  net_search_list: NetSearchList, // 微信工单详情 查询列表
  wx_net_woker_order: WxNewWorkOrder, // 微信受理工单详情
  wx_reply_list: WxReplyList, // 微信回复审核列表页
  wx_reply_detail: WxReplyDetail, // 微信回复审核详情页
  wx_reply_detail2: WxReplyDetail, // 微信回复审核详情页  疫情举报
  wx_new_woke_order_copy: wxNewWokeOrderCopy, // 微信拆分工单
  website_woker_order: WebsiteWokerOrder, // 首都之窗待受理详情
  website_woker_order1: WebsiteWokerOrder, // 首都之窗回复完结详情
  net_mail_assignment: MailAssignment, // 信件分配
  hsoa_woker_order: HsoaWokerOrder, // 政务平台待受理详情页面
  net_supervise_woker_order: NetSuperviseWokerOrder, //  互联网+监管受理详情
  currency_work_order: CurrencyWorkOrder, // 网络回复通用受理详情页面
  headline_work_order: WXWorkOrderDetail, // 网络回复-今日头条受理工单
  weiBo_work_order: WXWorkOrderDetail, // 微博待受理详情
  peopleOnline_work_order: WXWorkOrderDetail, // 人民网待受理详情
  goodBadRating_work_order: GoodBadRatingDetail, // 好差评待受理详情
  beiJingTong_work_order: WXWorkOrderDetail, // 北京通待受理详情页面
  jingTong_work_order: WebsiteWokerOrder, // 京通待受理详情页面
  jingTong_work_order1: WebsiteWokerOrder, // 京通回复完结详情页面
  beiJingTime_work_order: WXWorkOrderDetail, // 北京时间待受理详情页面
  gjb_work_order: WebsiteWokerOrder, // 国际版待受理详情页面
  jingTong_qy_work_order: WebsiteWokerOrder, // 京通企业待受理详情页面
  // 网络对接工单回复审核部分
  net_reply_hsoa_woker_order: NetReplyHsoaWokerOrder, // 政务平台回复审核
  net_reply_supervise_woker_order: NetReplySuperviseWokerOrder, //  互联网+监管
  net_reply_website_woker_order: NetReplyWebsiteWokerOrder, // 首都之窗
  // 上报审核
  net_report_website_woker_order: NetReplyWebsiteWokerOrder, // 首都之窗
  net_report_hsoa_woker_order: NetReplyHsoaWokerOrder, // 政务平台回复审核
  net_report_supervise_woker_order: NetReplySuperviseWokerOrder, //  互联网+监管

  /* ------流转信息------------ */
  operation_records: Operation, // 操作信息
  work_order_flow: RoamInfo, //
  auto_dispatch_order: AutoDispatchOrder, // 接派一体化情况

  /* ----------疫情数据--------------- */
  epidemic_data: EpidemicData,

  /* ------------社区/小区---------------------*/

  dp_community_list: Community, // 社区
  dp_village_list: Village, // 小区

  /* ------------年龄性别统计数据录入---------------------*/
  age_gender: AgeGender,

  // 央产房数据导入
  dp_ycf_monthly_data: YcfMonthlyData,
  // 实时工单
  dpOrderManage: DpOrderManage,
  // 实时工单主题
  dpItemsManage: DpItemsManage,

  // 积水分布情况查看
  ponding_distribution: PondingDistribution,
  // 积水点位信息管理
  ponding_points: PondingPoints,
  // 积水工单信息管理
  ponding_orders: PondingOrders,

  // 基础生活分布情况查看
  basic_life_distribution: LifeBaseDistribution,
  // 基础生活点位信息管理
  basic_life_points: LifeBasePoints,
  // 基础生活工单信息管理
  basic_life_orders: LifeBaseOrders,

  /* ------------ 110对接专区 ------------ */
  transfer_review_110: TransferReview110, // 退回转派审核
  reply_review_110: ReplyReview110, // 回复审核
  police_worker_detail: PoliceDetail, // 回复转派审核详情
  police_worker_detail_back: PoliceDetail, // 回复审核详情
  push_110_query: Push110Query, // 推送至110查询
  pending_receipt: PendingReceipt, // 待受理警单
  accept_receipt: AcceptReceipt, // 受理警单查询
  police_order_detail: PoliceOrderDetail, // 待受理工单详情

  /* ------------ 挂账不纳入管理 ------------ */
  card_out_list: CardOutList, // 挂账不纳入可申请列表
  card_out_apply_list: CardOutApplyList, // 挂账不纳入已申请列表
  card_out_list_detail: CardOutListDetail, // 挂账不纳入列表详情页
  card_out_list_detailY: CardOutListDetail, // 挂账不纳入已申请列表详情页
  card_out_approval_list: CardOutApprovalList, // 挂账不纳入 市中心审批列表
  card_out_approval_sub_list: CardOutApprovalSubList, // 挂账不纳入 分中心审批列表
  card_out_list_detail_center: CardOutListDetailCenter, // 挂账不纳入 市中心审核页/详情页
  card_out_list_detail_sub: CardOutListDetailSub, // 挂账不纳入 分中心审核页/详情页
  random_audit_list: RandomAuditList, // 挂账、不纳入随机审核列表
  review_of_audit_results: ReviewOfAuditResults, // 挂账、不纳入审核结果查看
  review_of_audit_results_detail: CardOutListDetailCenter, // 挂账不纳入 市中心审核结果详情页
  wait_eliminate_lib_list: WaitEliminateLibraryList, // 待主动剔除库
  already_eliminate_lib_list: AlreadyEliminateLibraryList, // 已主动剔除库
  eliminate_lib_query: EliminateLibraryQuery, // 主动剔除工单查询
  eliminate_statistics: EliminateLibraryList, // 主动剔除情况统计
  tc_details: CardOutListDetail, // 剔除列表详情页
  card_out_permission: CardOutPermission, // 挂账不考核获取权限管理 22.4.14新+  修改为挂账、不纳入复审规避设置 23.12.14
  card_out_term_limit: CardOutTermLimit, // 申报不考核期限设置 22.4.18新+
  card_circulation_record: CardCirculationRecord, //  挂账不考核流转记录 22.8.8新增 修改为挂账、不纳入流转记录 23.12.14
  excluded_crowd_management: ExcludedCrowdManagement, //  主动剔除特殊人群管理 22.8.8新增
  eliminate_dictionary: EliminateDictionary, // 剔除事项字典管理 22.8.9新增
  no_assessment: NoAssessment, // 申请不考核事项管理 22.8.9新增
  review_result_batch_upload: ReviewResultBatchUpload, // 复审结果批量上传修改结果功能 23.1.9新增
  hang_tick_rectification: HangTickRectification, // 复审文件上传
  assessment_month_update: AssessmentMonthUpdate, // 考核月份更新功能 23.2.10新增   修改为考核月份批量修改  23.12.14
  review_account_modification: ReviewAccountModification, // 复审账号修改功能 23.6.5新增
  add_assess_month: AddAssessMonth, // 添加考核期 23.12.19新增
  active_governance: ActiveGovernance, // 主动治理台账管理 24.7.22新增

  /* ************* 工单处理情况查询 ****************** */
  work_order_query: WorkOrderQuery,

  /* ************* 系统监控 ****************** */
  data_source_monitoring: DataSource,
  public_pool_monitoring: PublicPoolMonitoring,
  personal_pool_monitoring: PersonalPoolMonitoring,
  order_monitoring: OrderMonitoring,
  phone_record: phoneRecord,
  phone_monitor: phoneMonitor,
  wx_sync_monitor: WxSyncMonitor, // 22.2.10gy

  /* ****************短信回访************************** */

  failure_to_return_visit: FailureToReturnVisit, //  回访失败
  sms_settings: SmsSettings, // 短信设置

  /* ***************承办单位待办工单统计************* */

  subcenter_unhandleorder: TodoListStatusList, // 承办单位待办工单统计

  /* ***************市中心层面的承办单位待办明细************* */

  subcenter_unhandleorder_list: SubcenterUnhandleorderList, // 市中心层面的承办单位待办明细

  /* ***************承办单位待办明细************* */

  office_unhandleorder_list: OfficeUnhandleorderList, // 承办单位待办明细

  // statistics_back_details: StatisticsBackDetails /** ***************市中心待办工单统计***************************/, // 退单情况明细

  /* ****************市中心待办工单明细************************** */
  center_un_handle_order: CityCentertList, // 市中心待办工单统计
  center_un_handle_order_list: details, // 市中心待办工单明细
  /* ****************坐席员操作记录************************** */
  user_operation_record: userOperationRecord, // 坐席员操作记录
  /* ****************质检************************** */
  sampling_quality: SamplingQuality, // 抽样质检
  be_quality_testing: BeQualityTesting, // 待质检分配
  quality_inspection_task: QualityInspectionTask, // 质检任务管理

  /* ************************ 运维管控系统 ************************** */
  /* ***********运维审计*********** */
  data_monitoring: DataMonitoring, // 数据监控
  service_monitoring: ServiceMonitoring, // 服务监控
  exchange_monitoring: ExchangeMonitoring, // 交换监控
  data_audit: DataAudit, // 数据审计
  abnormal_alarm: AbnormalAlarm, // 异常报警
  /* ***********统计分析*********** */
  user_login: UserLoginStatic, // 用户登录统计
  ip_location_accesss: IPLocationAccessStatic, // IP地址访问统计
  service_access: ServiceAccessStatic, // 服务访问统计
  /* ***********服务资源管理*********** */
  service_list: ServiceList, // 列表
  service_resources: ServiceResourceStatic, // 服务资源统计
  /* ***********多应用服务管理*********** */
  multiApp_list: MultiAppList, // 列表
  /* ***********运维行为管理*********** */
  OperationBehavior_list: OperationBehaviorList, // 列表
  /* ***********监控运维管理*********** */
  monitor_operations: MonitorOperations, // 运维监控
  /* ***********日志管理*********** */
  log_manage: LogManage, // 日志管理

  /* ************************案例分析************************** */
  classic_details: ClassicDetails, // 案例详情
  add_case: AddCase, // 案例发布
  case_listCen: CaseListCen, // 案例列表
  case_manage: CaseManage, // 案例管理

  /* ************************案例分析************************** */
  automatic_statistics: AutomaticStatistics, // 考核自动统计核算
  account_elimination_statistics: AccountEliminationStatistics, // 挂账剔除情况
  level_problem_work_order: LevelProblemWorkOrder, // 一级问题工单量
  secondary_problem_work_order: SecondaryProblemWorkOrder, // 二级问题分类工单量
  district_statistics: DistrictStatistics, // 各区(含街乡镇)承办量
  street_statistics: StreetStatistics, // 直派街乡镇承办量
  seven_have_five: SevenHaveFive, // 七有五性工单量

  /* *************************************** 测试 **************************************** */
  test: Test, // 测试
  /* ************************每月一题************************** */
  pending_notice: PendingNotice, // 待处理告知单
  confirmed_notification_inquiry: ConfirmedNotificationInquiry, // 已确认告知单查询修改为每月一题查看（主责单位）
  notice_check: NoticeCheck, // 告知单查看修改为每月一题查看（配合单位）
  check_one_question: CheckOneQuestion, // 每月一题查看
  monthly_statistics: MonthlyStatistics, // 每月一题统计

  /* *************************************** 特殊人群手机号维护 **************************************** */
  special_group_phone_maintain: SpecialGroupPhoneMaintain, // 特殊人群手机号维护

  /* *************************************** 中心定制导出 **************************************** */
  two_cross_export: TwoCrossExport, // 两个交叉
  yc_query: YCQuery, // 央产点位工单查询
  heating_list_query: HeatingList, // 自动转派工单查询

  /* *************************************** 在线填报 **************************************** */
  fill_in_street_contact_book: StreetContactBookFill, // 街乡镇联系册填报
  street_contact_book_query_area: StreetContactBookQueryArea, // 街乡镇联系册查询（区级）
  street_contact_book_query_city: StreetContactBookQueryCity, // 街乡镇联系册查询（市级）

  revisit_audio_list: RevisitAudioList, // 回访录音查询

  company_info_query: CompanyInfoQuery, // 企业基础信息查询

  special_issues_query: SpecialIssuesList, // 专项问题督办单查询

  /* *************************************** 行业部门 **************************************** */
  industry_online_wait_confirm: WaitConfirmQuery, // 行业在线待确认查询
  industry_online_confirmed: ConfirmedQuery, // 行业在线已确认查询
  industry_online_query: CityConfirmedQuery, // 行业在线确认单查询（市级）
  industry_online_admin: ConfirmedAdmin, // 行业在线确认单管理员查看页面
  industry_online_query_es: CityConfirmedQuery, // 行业在线确认单查询（市级）es
  industry_online_wait_confirm_es: WaitConfirmQuery, // 行业在线待确认查询 es
  industry_online_confirmed_es: ConfirmedQuery, // 行业在线已确认查询 es
  industry_online_admin_es: ConfirmedAdmin, // 行业在线确认单管理员查看页面 es

  /* *************************************** 重复反映告知单查询 **************************************** */
  repeat_reflect_inform_query: RepeatReflectInformQuery,

  /* *************************************** 考核工单明细 **************************************** */
  self_finish_detail: SelfFinishDetail, // 自办考核明细
  seven_have_five_natures_detail: SevenHaveFiveNaturesDetail, // 七有五性明细
  industry_department_detail: IndustryDepartmentDetail, // 行业部门考核明细查询

  /* ********************************** 体系建设-在线填报(优化在线填报现有功能) ******************************** */
  jsjb_user: JsjbUser, // 接诉即办人员信息管理
  jsjb_dept: JsjbDept, // 接诉即办机构信息管理

  /* ********************************** 专项调度 ******************************** */
  special_project_dispatch: SpecialProjectDispatch,

  /* ********************************** 协办模块 ******************************** */
  collaboration_wait_sign: CollaborationWaitSign, // 待签收
  collaboration_wait_handle: CollaborationWaitHandle, // 待处理
  send_collaboration_query: SendCollaborationQuery, // 发起协办工单查询
  organizer_collaboration_query: OrganizerCollaborationQuery, // 承办协办工单查询
  collaboration_all_query: CollaborationAllQuery, // 协办全量工单查询
  collaborative_detail: CollaborativeDetail, // 协同详情页

  data_export_log: DataExport, // 数据导出日志查询

  /* ********************************** 定制导出模块 ******************************** */
  export_config: ExportConfig, // 定制导出配置页
  // exportPath: ExportMenu, // 定制导出页
  export_data_manage: ExportDataManage, // 定制导出数据管理页面

  /* ********************************** 督办体系建设 ******************************** */
  work_system_build: WorkSystemBuild,

  /* *******表扬告知单******** */
  praise_notify: PraiseNotifyForm,

  /* **************埋点日志*******************  */
  buried_log_info: LogQuery,
  /* **工单要素质检管理** */
  work_order_inspection: WorkOrderInspection,
  /* ********************************** 销账系统 ************************************ */
  monthly_billing_list: MonthlyRollup, // 月度挂账
  full_charge_list_library: FullRollup, // 全量挂账
  hanging_list_library: RollupList, //  三级挂账清单
  preliminary_list: WriteOffSpace, // 销账区级初审清单
  list_library_detail: DetailList, // 挂账详情
  list_library_full_detail: DetailList,
  list_library_examine_detail: DetailList,
  international_search: International, // 新国际版查询
  portrayal_manage: PortrayalManage // 用户画像管理
}

for (let i = 0; i <= 100; i += 1) {
  menuJson[`export_menu_${i}`] = ExportMenu
}

export default menuJson
