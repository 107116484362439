/* eslint-disable react/prop-types */
/* eslint-disable no-irregular-whitespace */
/*
 * @Author: wangyafei
 * @Description: 修改密码
 * @Date: 2018-11-22 16:21:47
 * @Last Modified by: wangyafei
 * @Last Modified time: 2019-09-05 15:10:41
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2019-09-03 17:21:00
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2019-09-04 10:09:13
 * @Last Modified by: wangyafei
 * @Last Modified time: 2019-10-31 10:33:24
 */
import React, { Component } from 'react'
import { Form, Icon, Input } from 'antd'
import { connect } from 'react-redux'
// import { regExpFromString } from '@/utils/utils'

class PasswordChange extends Component {
  state = {}

  // 与确认密码进行对比
  /* validateToNextPassword = (rule, value, callback) => {
    // eslint-disable-next-line react/prop-types
    const { form } = this.props
    const { confirmDirty } = this.state
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true })
    }
    callback()
  }

  compareToFirstPassword = (rule, value, callback) => {
    // eslint-disable-next-line react/prop-types
    const { form } = this.props
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('两次密码输入不一致!')
    } else {
      callback()
    }
  } */

  rulesFn = value => {
    let count = 0 // 满足的规则数量
    const digital = /[0-9]/ // 数字正则
    const capital = /[A-Z]/ // 大写字母正则
    const lowercase = /[a-z]/ // 小写字母正则
    /*  var spec=/[~!@#$%^&*()_+|<>,.?\/:;'\\[\\]{}\"]/; //特殊字符正则 */
    const spec = /[,.<>{}~!@#$%^&*_]/ // 特殊字符正则
    /* 连续的顺序数字和连续5位重复数字 */
    const pattern = /(?=(01234|12345|23456|34567|45678|56789|98765|87654|76543|65432|54321|43210|0000|1111|2222|3333|4444|5555|6666|7777|8888|9999))/

    if (!value) {
      return false
    }
    if (value.length < 8 || value.length > 32) {
      return false
    }

    // 判断密码是否包含数字
    if (digital.test(value) && !pattern.test(value)) {
      count += 1
    }

    // 判断密码是否包字母
    if (lowercase.test(value) || capital.test(value)) {
      count += 1
    }

    // 判断密码是否包含大小写
    if (lowercase.test(value) && capital.test(value)) {
      count += 1
    }

    // 判断密码是否包含特殊字符
    if (spec.test(value)) {
      count += 1
    }

    if (count > 3) {
      return true
    }
    return false
  }

  // 旧密码校验
  oldPasswordFn = (rule, value, callback) => {
    const { form } = this.props
    if (!value) {
      callback()
    } else if (!this.rulesFn(value) && value !== '123456Aa!') {
      callback(
        '密码至少为8位大小写字母、数字及特殊字符组合，不能连续多位数字，不能连续重复多位数字'
      )
    } else if (
      form.getFieldValue('newPassword') &&
      value !== form.getFieldValue('newPassword')
    ) {
      form.setFieldsValue({
        newPassword: form.getFieldValue('newPassword'),
        confirm: form.getFieldValue('confirm')
      })
      callback()
    } else if (
      form.getFieldValue('newPassword') &&
      value === form.getFieldValue('newPassword')
    ) {
      callback('新旧密码不能相同!')
    } else {
      callback()
    }
  }

  // 新密码校验
  validateToNextPassword = (rule, value, callback) => {
    const { form, userInfo } = this.props

    if (!value) {
      callback()
    } else if (value && value === form.getFieldValue('oldPassword')) {
      callback('新旧密码不能相同!')
    } else if (
      form.getFieldValue('confirm') &&
      value &&
      value === form.getFieldValue('confirm')
    ) {
      form.setFieldsValue({
        confirm: form.getFieldValue('confirm'),
        oldPassword: form.getFieldValue('oldPassword')
      })
      callback()
    } else if (
      form.getFieldValue('confirm') &&
      value &&
      value !== form.getFieldValue('confirm')
    ) {
      callback('两次密码输入不一致!')
    } else if (value) {
      if (value.indexOf(userInfo?.userNum) !== -1) {
        callback('密码口令含账号信息！')
      }
      if (!this.rulesFn(value)) {
        callback(
          '密码长度限制为8-32位，必须包含大小写字母、数字及特殊字符，不能连续多位数字，不能连续重复多位数字'
        )
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props
    if (!value) {
      callback()
    } else if (value !== form.getFieldValue('newPassword')) {
      callback('两次密码输入不一致!')
    } else if (
      form.getFieldValue('oldPassword') === form.getFieldValue('newPassword')
    ) {
      callback('新旧密码不能相同!')
    } else if (
      value === form.getFieldValue('newPassword') &&
      form.getFieldValue('oldPassword') !== form.getFieldValue('newPassword')
    ) {
      form.setFieldsValue({
        newPassword: form.getFieldValue('newPassword'),
        oldPassword: form.getFieldValue('oldPassword')
      })
      if (!this.rulesFn(value)) {
        callback(
          '密码长度限制为8-32位，必须包含大小写字母、数字及特殊字符，不能连续多位数字，不能连续重复多位数字'
        )
      } else {
        callback()
      }
      callback()
    } else {
      callback()
    }
  }

  handleConfirmBlur = e => {
    const { value } = e.target
    const { confirmDirty } = this.state
    this.setState({ confirmDirty: confirmDirty || !!value })
  }

  getItemsValues = flag => {
    const {
      // eslint-disable-next-line react/prop-types
      form: { validateFieldsAndScroll, getFieldsValue }
    } = this.props

    if (flag) {
      return getFieldsValue()
    }
    // form表单结果
    let result
    validateFieldsAndScroll(
      {
        scroll: { offsetTop: 100 }
      },
      (err, values) => {
        if (!err) {
          result = {
            ...values
          }
        } else {
          result = -1
        }
      }
    )
    return result
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { form, userInfo } = this.props
    const { getFieldDecorator } = form
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('id', {
            initialValue: userInfo.id
          })(<Input type="hidden" />)}
        </Form.Item>
        <Form.Item className="form-row-item">
          {getFieldDecorator('oldPassword', {
            rules: [
              {
                required: true,
                message: '此项为必填项'
                /* pattern: regExpFromString(
                  '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[^]{8,16}$'
                ),
                message:
                  '密码必须由大小写字母、数字三种组成,最少八位,支持特殊字符' */
              }
              /* {
                validator: this.oldPasswordFn
              } */
            ]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.5)' }} />}
              type="password"
              placeholder="原密码"
            />
          )}
        </Form.Item>
        <Form.Item className="form-row-item">
          {getFieldDecorator('newPassword', {
            rules: [
              {
                required: true,
                message: '此项为必填项'
                /* pattern: regExpFromString(
                  '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[^]{8,16}$'
                ),
                message:
                  '密码必须由大小写字母、数字三种组成,最少八位,支持特殊字符' */
              },
              {
                validator: this.validateToNextPassword
              }
            ]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.5)' }} />}
              onBlur={this.handleConfirmBlur}
              type="password"
              placeholder="新密码"
            />
          )}
        </Form.Item>
        <Form.Item className="form-row-item">
          {getFieldDecorator('confirm', {
            rules: [
              {
                required: true,
                message: '两次密码输入不一致!'
              },
              {
                validator: this.compareToFirstPassword
              }
            ]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.5)' }} />}
              type="password"
              placeholder="确认密码"
            />
          )}
        </Form.Item>
      </Form>
    )
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userReducer.currentUserInfo
  }
}

export default connect(mapStateToProps)(Form.create()(PasswordChange))
