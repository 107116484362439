/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:52:37
 * @Last Modified by: wangyafei
 * @Last Modified time: 2018-11-19 17:03:36
 */
import React, { Component } from 'react'

export default function asyncComponent(getComponent) {
  class AsyncComponent extends Component {
    static Component = null

    state = { ComponentAsync: AsyncComponent.Component }

    componentWillMount() {
      if (!AsyncComponent.Component) {
        getComponent().then(ComponentAsync => {
          AsyncComponent.Component = ComponentAsync.default
          this.setState({
            ComponentAsync: ComponentAsync.default
          })
        })
      }
    }

    render() {
      const { ComponentAsync } = this.state
      if (ComponentAsync) {
        return <ComponentAsync {...this.props} />
      }
      return null
    }
  }
  return AsyncComponent
}
