/*
 * @Author: wangyafei
 * @Description: 填写处理意见
 * @Date: 2018-11-22 16:21:47
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-01-10 22:34:48
 */
import React, { Component } from 'react'
import { Row, Col, Form, Input, Radio, message, AutoComplete } from 'antd'
import { queryAll } from '../api'

const { Option: Opt } = AutoComplete

class CircleComponent extends Component {
  state = {
    queryAllList: []
  }

  componentDidMount() {
    this.queryAllFn()
  }

  queryAllFn = () => {
    queryAll({ type: '2' }).then(suc => {
      const { data: dataResult } = suc
      const { code, data, msg } = dataResult
      if (code === 200) {
        for (let i = 0; i < data.length; i += 1) {
          Object.assign(data[i], {
            all: `${data[i].orgName} ${data[i].phoneNum}`
          })
        }
        this.setState({
          queryAllList: data
        })
      } else {
        message.error(msg)
      }
    })
  }

  getItemsValues = () => {
    /* eslint-disable */
    const {
      form: { validateFieldsAndScroll }
    } = this.props
    // form表单结果
    let result
    validateFieldsAndScroll(
      {
        scroll: { offsetTop: 100 }
      },
      (err, values) => {
        if (!err) {
          result = {
            ...values
          }
        } else {
          result = -1
        }
      }
    )
    return result
  }

  doChangeVal = e => {
    const val = e.target.value
    this.setState({
      selectedValue: val
    })
    /* eslint-disable-next-line */
    const { form } = this.props
    if (val === 1 || val === 3) {
      form.setFieldsValue({
        phoneNumber: 9
      })
    } else {
      form.setFieldsValue({
        phoneNumber: ''
      })
    }
  }

  render() {
    /* eslint-disable-next-line */
    const { form } = this.props
    const { getFieldDecorator } = form
    const { queryAllList } = this.state

    const options =
      queryAllList &&
      queryAllList.length > 0 &&
      queryAllList.map(it => {
        return (
          <Opt key={it.phoneNum} value={it.all}>
            {it.all}
          </Opt>
        )
      })

    return (
      <Form className="jz-modal-flex">
        <Row gutter={16}>
          <Col span={2} />
          <Col span={20}>
            <Form.Item
              label={<span className="justify">请选择三方类型</span>}
              style={{ display: 'block', marginBottom: 0, display: 'flex' }}
            >
              {getFieldDecorator(`isInnerOrOuter`, {
                initialValue: 2
              })(
                <Radio.Group onChange={this.doChangeVal}>
                  <Radio value={2}>三方内部</Radio>
                  <Radio value={1}>三方外部</Radio> 
                  <Radio value={3}>外部转接</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          <Col span={2} />
        </Row>
        <Row gutter={16}>
          <Col span={2} />
          {form.getFieldValue('isInnerOrOuter') === 3 ? (
            <Col span={20}>
              <Form.Item
                hasFeedback
                label={<span className="justify">请输入外部转接号码</span>}
                style={{ display: 'block' }}
                id="phone-control"
              >
                {getFieldDecorator(`phoneNumber`, {
                  rules: [
                    {
                      required: true,
                      message: '号码/账号必填'
                    }
                  ]
                })(
                  <AutoComplete
                    dataSource={options}
                    placeholder="请选择号码/账号"
                    dropdownMenuStyle={{ maxHeight: 'initial' }}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    /* filterOption={(inputValue, option) =>
                        option.props.children.indexOf(inputValue) !== -1
                      } */
                  />
                )}
              </Form.Item>
            </Col>
          ) : (
            <Col span={20}>
              <Form.Item
                hasFeedback
                
                label={
                  <span className="justify">
                    {form.getFieldValue('isInnerOrOuter') === 1
                      ? '请输入号码'
                      : '请输入工号'}
                  </span>
                }
                style={{ display: 'block' }}
              >
                {getFieldDecorator(`phoneNumber`, {
                  rules: [
                    {
                      required: true,
                      message: '号码/账号必填'
                    }
                  ]
                })(<Input placeholder="请输入号码/账号" />)}
              </Form.Item>
            </Col>
          )}
          <Col span={2} />
        </Row>
      </Form>
    )
  }
}

export default Form.create()(CircleComponent)
