/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:53:02
 * @Last Modified by: gy
 * @Last Modified time: yyyy-08-Th 02:06:12
 * 剔除工单redux
 */
import * as constants from '../constants'

const initialState = {
  isLonge: false,
  data: '', // 剔除工单 刷新
  Revisit: '', // 回访工单刷新
  Revisiting: '', // 回访中刷新
  HsoaWoker: '', //   网络回复工单刷新
  Home: '', // 首页
  ReportAudit: '', // 上报审核
  Automatic: '', // 自动派单
  DoneTC: '', // 主动剔除
  NetworkReply: '', // 网络回复审核
  RepeatSign: '', // 重复反映告知单签收
  RevisitDetail: false, // 回访详情
  XbStatus: false, // 协办
  NotifyCompletionState: false // 告知性办结
}

export default function elimination(state = initialState, { type, isLonge }) {
  switch (type) {
    case constants.ELIMINATION_REFRESH:
      return { ...state, data: isLonge }
    case constants.HSOA_WOKER:
      return { ...state, HsoaWoker: isLonge }
    case constants.REVISIT_WAIT_FOR:
      return { ...state, Revisit: isLonge }
    case constants.REVISIT_ING:
      return { ...state, Revisiting: isLonge }
    case constants.HOME_STATE:
      return { ...state, Home: isLonge }
    case constants.REPORT_AUDIT:
      return { ...state, ReportAudit: isLonge }
    case constants.AUTO_SEND:
      return { ...state, Automatic: isLonge }
    case constants.DONE_ELIMINATION:
      return { ...state, DoneTC: isLonge }
    case constants.NETWORK_REPLY:
      return { ...state, NetworkReply: isLonge }
    case constants.REPEAT_SIGN:
      return { ...state, RepeatSign: isLonge }
    case constants.REVISIT_DETAIL:
      return { ...state, RevisitDetail: isLonge }
    case constants.XB_STATUS:
      return { ...state, XbStatus: isLonge }
    case constants.NOTIFY_COMPLETION:
      return { ...state, NotifyCompletionState: isLonge }
    default:
      return state
  }
}
