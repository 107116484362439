/* eslint-disable no-unused-expressions */
import React from 'react'
// import PropTypes from 'prop-types'

class WebsocketFn extends React.Component {
  constructor(param = {}) {
    super()
    this.param = param
    this.reconnectCount = 0
    this.socket = null
    this.taskRemindInterval = null
    this.isSucces = true
    this.socketUrlParams = null
  }

  onParams = socketUrl => {
    console.log(
      '%c [ onParams ]',
      'font-size:13px; background:pink; color:#bf2c9f;',
      socketUrl
    )
    this.socketUrlParams = socketUrl
    // return socketUrl
  }

  connection = socketUrl => {
    console.log(
      '%c [ socketUrl ]',
      'font-size:13px; background:pink; color:#bf2c9f;',
      socketUrl
    )
    const { timeout = 0 } = this.param
    this.socket = new WebSocket(socketUrl)

    this.socket.onopen = this.onopen
    this.socket.onmessage = this.onmessage
    this.socket.onclose = this.onclose
    this.socket.onerror = this.onerror
    this.socket.sendMessage = this.sendMessage
    this.socket.closeSocket = this.closeSocket
    // 检测返回的状态码 如果socket.readyState不等于1则连接失败，关闭连接
    if (timeout) {
      const time = setTimeout(() => {
        if (this.socket && this.socket.readyState !== 1) {
          this.socket.close()
        }
        clearInterval(time)
      }, timeout)
    }
  }

  // 连接成功触发
  onopen = () => {
    const { socketOpen } = this.param
    this.isSucces = false // 连接成功将标识符改为false
    socketOpen && socketOpen()
  }

  // 后端向前端推得数据
  onmessage = data => {
    const { socketMessage } = this.param
    socketMessage && socketMessage(data)
    // 打印出后端推得数据
    console.log('打印出后端推得数据responseData', JSON.stringify(data))
  }

  // 关闭连接触发
  onclose = e => {
    this.isSucces = true // 关闭将标识符改为true
    console.log('关闭socket收到的数据')
    const { socketClose } = this.param
    socketClose && socketClose(e)
    // if (this.onParams()) {
    // 否则就30秒重连一次，直到重连成功为止
    /*  this.taskRemindInterval = setInterval(() => {
      if (this.isSucces) {
        this.connection(this.socketUrlParams)
      } else {
        clearInterval(this.taskRemindInterval)
      }
    }, 30000) */
    // }
  }

  onerror = e => {
    // socket连接报错触发
    const { socketError } = this.param
    this.socket = null
    socketError && socketError(e)
  }

  sendMessage = value => {
    const Message = JSON.stringify(value)
    console.log(
      '%c [ Message ]',
      'font-size:13px; background:pink; color:#bf2c9f;',
      Message
    )
    // 向后端发送数据
    if (this.socket) {
      this.socket.send(Message)
    }
  }
}

WebsocketFn.propTypes = {}

export default WebsocketFn
