/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:52:44
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-04-20 16:30:26
 */
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale-provider/zh_CN'
import moment from 'moment'
import store from './redux/store'
import CookieUtils from './data/common'
import * as Constants from './redux/constants'
import App from './App'
import 'moment/locale/zh-cn'
import './less/site.theme.less'
import './less/site.less'
// 地图组件样式
import './less/map.less'

moment.locale('zh_cn')

// 获取localStorage中暂存的用户登录信息
const tokenInfo = CookieUtils.getJSON('AuthorizationUserInfo')
// 如果用户登录过，则dispatch一个事件，促使redux store改变
if (
  tokenInfo &&
  tokenInfo.access_token &&
  tokenInfo.refresh_token &&
  tokenInfo.access_token !== 'undefined' &&
  tokenInfo.refresh_token !== 'undefined'
) {
  store.dispatch({
    type: Constants.USER_LOGGED_IN,
    payload: tokenInfo
  })
}
ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
)
