/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:53:02
 * @Last Modified by: gy
 * @Last Modified time: 2020-04-24 10:55:47
 */
import * as constants from '../constants'
import CookieUtils from '@/data/common'

const initialState = {
  data: null,
  currentUserInfo: {},
  isAuthenticated: false
}
export default function userUpdate(state = initialState, { type, payload }) {
  switch (type) {
    case constants.USER_LOGGING_IN:
      return { ...initialState, isAuthenticated: false }
    case constants.USER_LOGGED_IN:
      CookieUtils.set('AuthorizationUserInfo', payload)
      return { data: payload, isAuthenticated: true }
    case constants.USER_LOGGED_OUT:
      CookieUtils.remove('AuthorizationUserInfo')
      return { ...initialState, isAuthenticated: false }
    case constants.USER_OUT:
      return { ...initialState, isAuthenticated: false }
    default:
      return state
  }
}
