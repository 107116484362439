/*
 * @Author: wangyafei
 * @Date: 2019-10-25 10:21:17
 * @Last Modified by: gy
 * @Last Modified time: 2020-07-21 15:27:50
 */
import {
  PAGE_CURRENT_CLOSE_RESET,
  PAGE_CURRENT_CLOSE,
  PAGE_OPEN
} from '../constants'

/**
 * 用户信息
 * @param {*} payload
 */
export const dispatchResetPageCurrent = payload => {
  return dispatch =>
    dispatch({
      type: PAGE_CURRENT_CLOSE_RESET,
      payload
    })
}

export const dispatchCloseCurrentPage = payload => {
  return dispatch =>
    dispatch({
      type: PAGE_CURRENT_CLOSE,
      payload
    })
}

export const dispatchOpenPage = payload => {
  return dispatch =>
    dispatch({
      type: PAGE_OPEN,
      payload
    })
}
