/*
 * @Author: wangyafei
 * @Description: 填写处理意见
 * @Date: 2018-11-22 16:21:47
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2021-01-22 11:17:07
 */
import React, { Component } from 'react'
import { Form, Input, Select } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const { Option } = Select

class SignInControl extends Component {
  state = {}

  getItemsValues = () => {
    const {
      form: { validateFieldsAndScroll }
    } = this.props
    // form表单结果
    let result
    validateFieldsAndScroll(
      {
        scroll: { offsetTop: 100 }
      },
      (err, values) => {
        if (!err) {
          result = {
            ...values
          }
        } else {
          result = -1
        }
      }
    )
    return result
  }

  render() {
    /* eslint-disable-next-line */
    const { form, userInfo } = this.props
    const { getFieldDecorator } = form
    const formItemLayout = {
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 12
      }
    }

    return (
      <Form {...formItemLayout} className="jz-modal-flex">
        <Form.Item
          hasFeedback
          label={<span className="justify">业务代表工号</span>}
          style={{ display: 'block', marginBottom: 12 }}
        >
          {getFieldDecorator(`txtLabourNo`, {
            rules: [
              {
                required: true,
                message: '业务代表工号必填'
              }
            ],
            initialValue: userInfo.userNum
          })(<Input placeholder="请输入业务代表工号" />)}
        </Form.Item>

        <Form.Item
          hasFeedback
          label={<span className="justify">座席类型</span>}
          style={{ display: 'block', marginBottom: 12 }}
        >
          {getFieldDecorator(`seatType`, {
            rules: [
              {
                required: true,
                message: '座席类型必填'
              }
            ],
            initialValue: '4'
          })(
            <Select placeholder="请选择坐席类型">
              <Option value="2">1B1D</Option>
              <Option selected value="4">
                PC+PHONE
              </Option>
              <Option value="5">2B1D</Option>
              <Option value="6">6B1D</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item
          hasFeedback
          label={<span className="justify">密码</span>}
          style={{ display: 'block', marginBottom: 12 }}
        >
          {getFieldDecorator(`password`, {
            initialValue: userInfo.workPwd
          })(<Input placeholder="请输入签入密码" />)}
        </Form.Item>
        <Form.Item
          hasFeedback
          label={<span className="justify">坐席电话</span>}
          style={{ display: 'block', marginBottom: 12 }}
        >
          {getFieldDecorator(`txtPhoneNum`, {
            rules: [
              {
                required: true,
                message: '坐席电话必填'
              }
            ],
            // initialValue: typeof Phone.TransToAgent === 'undefined' ? '600' : '5555'
            initialValue: '5555'
          })(<Input placeholder="请输入坐席电话" />)}
        </Form.Item>
        {/* 上线隐藏 */}
        {/* <Form.Item
          hasFeedback
          label={<span className="justify">签入CTI版本</span>}
          style={{ display: 'block', marginBottom: 12 }}
        >
          {getFieldDecorator(`CTI`, {
            rules: [
              {
                required: true,
                message: '签入CTI版本必填'
              }
            ]
          })(
            <Radio.Group>
              <Radio value={1}>CTI3.5</Radio>
              <Radio value={2}>CTI3.6</Radio>
            </Radio.Group>
          )}
        </Form.Item> */}
      </Form>
    )
  }
}

SignInControl.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func
  }).isRequired
}

function mapStateToProps(state) {
  return {
    userInfo: state.userReducer.currentUserInfo
  }
}

export default connect(mapStateToProps)(Form.create()(SignInControl))
