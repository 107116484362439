import * as constants from '../constants'

const initialState = {
  data: null,
  officeList: {},
  Office: false
}

export default function Offices(
  state = initialState,
  { type, payload, Office }
) {
  switch (type) {
    case constants.UNDERTAKE_OFFICE:
      return {
        ...initialState,
        officeList: payload,
        Office
      }
    default:
      return { ...state, Office: false }
  }
}
