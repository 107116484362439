/*
 * @Author: lvchengjun 13021966637@163.com
 * @Date: 2023-12-25 09:49:30
 * @LastEditors: lvchengjun 13021966637@163.com
 * @LastEditTime: 2023-12-25 11:24:14
 * @FilePath: \capinfo-platform-admin\src\redux\reducers\phoneBar.js
 * @Description: 话务工具
 */
import * as constants from '../constants'

const initialState = {
  isWaihuMeta: 0 // 是否外呼操作，外呼操作为1， 来电操作为0， 默认来电
}

export default function phoneReduce(state = initialState, { type, payload }) {
  switch (type) {
    case constants.SET_WAIHU:
      return { ...initialState, ...payload }
    default:
      return state
  }
}
