/*
 * @Autor: gy
 * @Date: 2022-09-29 10:05:17
 * @Description:   不稳定因素、突发事件弹窗
 * @LastEditors: gy
 * @LastEditTime: 2023-07-06 13:16:02
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Icon } from 'antd'
import isEqual from 'lodash/isEqual'
import { withRouter } from 'react-router-dom'
// import { updateStateBy } from './api'
import './readMessge.less'

const audiowsControl = document.getElementById('audioDiv')

class ReadMssage extends Component {
  state = {
    readMessage: [], // 不稳定因素弹窗
    readMessageList: [], // 判断是否更新
    isShowImg: true, // 小机器人开关
    isShowBox: false, // 盒子显示隐藏
    isBell: true
  }

  componentDidMount() {
    const { isBell, readMessage } = this.props

    /* const readMessage = [
      // 测试数据
      {
        orderId: '6201ceaa53f946feb8f507db3bc5831a',
        orderNum: '热线-220929-000030',
        title: '1',
        createTime: '2022-09-29 12:44:01',
        isAccident: '1',
        isInstable: '0'
      },
      {
        orderId: '3405a397f5ec4649a248841391f136e2',
        orderNum: '热线-220929-000029',
        title: '1',
        createTime: '2022-09-29 12:12:30',
        isInstable: '1',
        isAccident: '0'
      },
      {
        orderId: '4c981e288b0943daa52bf768a3fcb7c5',
        orderNum: '热线-220929-000028',
        title: '1',
        createTime: '2022-09-29 12:07:35',
        isAccident: '1',
        isInstable: '1'
      },
      {
        orderId: 'c6afab664b6b46d886e402651f571964',
        orderNum: '热线-220929-000027',
        title: '0',
        createTime: '2022-09-29 11:57:39',
        isInstable: '1',
        isAccident: '0'
      }
    ] */
    if (readMessage !== '') {
      this.setState(
        {
          readMessageList: readMessage,
          isBell
        },
        () => {
          this.getMessageQueuing(readMessage)
          if (isBell === false) {
            audiowsControl.currentTime = 0
            audiowsControl.pause()
          }
        }
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    const { readMessage, isBell } = nextProps
    const { readMessageList, isBell: isBellState } = this.state
    if (!isEqual(readMessage, readMessageList)) {
      if (readMessage !== '') {
        this.setState(
          {
            readMessageList: readMessage
          },
          () => {
            this.getMessageQueuing(readMessage)
          }
        )
      }
    }

    if (!isEqual(isBell, isBellState)) {
      this.setState({ isBell }, () => {
        if (isBell === false) {
          audiowsControl.currentTime = 0
          audiowsControl.pause()
        }
      })
    }
  }

  componentWillUnmount() {}

  // 消息队列
  getMessageQueuing = data => {
    const { isBell, readMessage } = this.state
    if (data && data.length > 0) {
      data.map(item => {
        readMessage.push({ ...item })
        this.setState(
          {
            readMessage: [...new Set(readMessage)],
            isShowBox: true
          },
          () => {
            if (isBell) {
              audiowsControl.currentTime = 0
              audiowsControl.play()
              audiowsControl.addEventListener(
                'ended',
                () => {
                  audiowsControl.play()
                },
                false
              )
              setTimeout(() => {
                const audioControl = document.getElementById('audioWs')
                audioControl.currentTime = 0
                audioControl.pause()
              }, 10000)
            }
          }
        )
        return item
      })
    }
  }

  // 关闭处理
  msgClose = (e, data, index) => {
    e.preventDefault()
    const { readMessage } = this.state
    this.setState({ readMessage }, () => {
      setTimeout(() => {
        readMessage.splice(index, 1)
        this.setState(
          {
            readMessage
          },
          () => {
            if (readMessage.length <= 0) {
              this.setState(
                {
                  isShowBox: false
                },
                () => {
                  audiowsControl.currentTime = 0
                  audiowsControl.pause()
                }
              )
            }
          }
        )
      }, 300)
    })
  }

  // 关闭调取接口
  getTitleimg = () => {
    const { isShowImg: ShowImg } = this.state
    this.setState({
      isShowImg: !ShowImg
    })
  }

  // 全部关闭
  allClose = () => {
    this.setState(
      {
        isShowBox: false
      },
      () => {
        audiowsControl.currentTime = 0
        audiowsControl.pause()
      }
    )
  }

  // 标题展示
  titShow = data => {
    let tit = ''
    if (data.isAccident === '1' && data.isInstable === '1') {
      tit = '突发、不稳定因素'
    } else if (data.isInstable === '1') {
      tit = '不稳定因素'
    } else if (data.isAccident === '1') {
      tit = '突发事件'
    }
    return tit
  }

  // 点击标签 跳转工单查询
  queryOpen = data => {
    const { history } = this.props
    history.push(
      `/acc_order_list?isAccident=${data.isAccident}&isInstable=${data.isInstable}`
    )
  }

  // 点击工单编号 跳转工单详情
  detailOpen = data => {
    const { history } = this.props
    history.push(
      encodeURI(
        `/acc_order_list_detail?orderId=${data.orderId}${
          data.unId ? `&unId=${data.unId}` : ''
        }${data.dispatchId ? `&dispatchId=${data.dispatchId}` : ''}${
          data.handleId ? `&handleId=${data.handleId}` : ''
        }${data.createType ? `&createType=${data.createType}` : ''}&orderNum=${
          data.orderNum
        }`
      )
    )
  }

  // 一件隐藏
  onSwitch = () => {
    const { isShowBox } = this.state
    this.setState({
      isShowBox: !isShowBox
    })
  }

  render() {
    const { isShowBox, readMessage } = this.state
    return (
      <React.Fragment>
        {readMessage && readMessage.length > 0 && (
          <React.Fragment>
            {isShowBox ? (
              <div className="readMssge-wrapper">
                <div
                  className="switchDiv"
                  role="presentation"
                  onClick={this.onSwitch}
                >
                  <Button type="primary">一键隐藏&gt;&gt;</Button>
                </div>
                {readMessage.map((it, index) => {
                  return (
                    <Card
                      title={
                        <span
                          role="presentation"
                          onClick={() => this.queryOpen(it)}
                        >
                          {this.titShow(it)}
                        </span>
                      }
                      extra={
                        <Icon
                          type="close-circle"
                          style={{ color: '#4977c2' }}
                          onClick={e => this.msgClose(e, it.orderId, index)}
                        />
                      }
                    >
                      <p>
                        <span>工单编号：</span>
                        <span
                          style={{ color: '#4977c2', cursor: 'pointer' }}
                          role="presentation"
                          onClick={() => this.detailOpen(it)}
                        >
                          {it.orderNum}
                        </span>
                      </p>
                      <p>
                        <span>工单标题：</span>
                        <span>{it.title}</span>
                      </p>
                      <p>
                        <span>工单创建时间：</span>
                        <span>{it.createTime}</span>
                      </p>
                    </Card>
                  )
                })}
              </div>
            ) : (
              <div
                className="switchDiv"
                role="presentation"
                onClick={this.onSwitch}
                style={{
                  width: '110px',
                  bottom: 35,
                  right: 10,
                  top: 'inherit',
                  background: 'transparent'
                }}
              >
                <Button
                  type="primary"
                  style={{ background: '#ffb836', border: '1px solid #ffb836' }}
                >
                  一键展开&lt;&lt;
                </Button>
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

ReadMssage.defaultProps = {
  readMessage: [],
  isBell: ''
}

ReadMssage.propTypes = {
  readMessage: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  isBell: PropTypes.string
}

export default withRouter(ReadMssage)
