import React from 'react'
import {
  Button,
  Modal,
  message,
  Select,
  Row,
  Col,
  Form,
  Input,
  Tabs,
  Icon,
  Spin
} from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import * as actions from '@/redux/actions/phonebar'
import { debounce } from 'lodash'
import { nanoid } from 'nanoid'
import * as useApi from './api'
import store from '../../redux/store'
import { SET_WAIHU } from '../../redux/constants'
import DailPhone from './components/DailPhone'
import SignInControl from './components/SignInControl'
import RestControl from './components/RestControl'
import CircleComponent from './components/CircleComponent'
import SanFang from './components/SanFang'
import seatInfoData from './SeatInfo'
import WS from './WS'
import CookieUtils from '../../data/common'
import { saveAs } from './FileSaver'
import './components/style/OnlineSwitching.less'

const Free = require('../../images/tool/free.png')

const { Option } = Select
const { TabPane } = Tabs

const arrNum = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#']

@connect(
  state => {
    return {
      ...state.dailoutReducer,
      userInfo: state.userReducer.currentUserInfo
    }
  },
  {
    ...actions
  }
)
class PhoneBar extends React.PureComponent {
  state = {
    isWaihu: false, //  是否点击外呼
    orderId: '', // 是否点击外呼工单Id
    isStillWork: false, // 是否正在工作
    idleTime: 240, // 工作时长读秒
    callLastTime: 0, // 通话持续时间
    skillsArr: [],
    workSkillType: '', // 技能
    isCalling: false, // 是否正在通话
    // isCallOutValue: 0, // 是否外呼操作，外呼操作为1， 来电操作为0， 默认来电
    isKeepHangPhone: true, // 是否保持
    isMute: true, // 是否静音
    isShowRest: false, // 是否显示休息
    isWorking: true, // 显示为 工作态还是休息态
    isRest: true, // 显示为 休息还是取消休息
    isShowCircle: false, // 是否显示三方操作
    isShowBusy: false, // 是否显示示忙操作
    isShowSignIn: false, // 是否显示签入modal
    isShowDail: false, // 是否拨打电话(包含内呼和外呼)
    isSignIn: true, // 是否签入操作
    isOnlineSwitching: false, // 是否在线转接
    isOnlineSwitchingSearch: false, // 是否在线转接 查询
    isphoneNumber: '', // 是否选择了转接坐席
    seleCss: '', // 在线转接 列表点击选中
    // lastarrB: [], // 在线转接 企业
    lastarrM: [], // 在线转接 班长
    englishArr: [], // 在线转接 英文席
    leaderArr: [], // 在线转接 领导
    // lastArrCdpf: [], // 在线转接 残联
    offArr: [], // 在线转接 交通局
    isShowListenBox: false, // 是否显示监听通话模态框
    isShowKeepCall: false, // 是否显示保持通话模态框
    allList: [], // 总签入席 所有列表
    keepCallList: [], // 保持通话 所有列表
    giiValue: '', // 监听通话 inputValue
    searchArr: [],
    hangUp: '', // 挂断
    inputValue: '', // 二次拨号 提交框value值
    isNumWindeow: false, // 是否展示小键盘
    isCallingBefore: false,
    findInfoInputValue: '',
    tabFalse: false,
    tabTrue: true,
    tabDefault: '1',
    isWorState: false,
    coidID: '', // 录音成功扩展事 callID
    isSayFree: true,
    isCallSuccess: false, // 7-3新加 处理话后 外呼不成功 工作态倒计时重置bug
    isListen: false, // 是否显示监听按钮
    isWebsocket: false, // 建立ws连接 21.4.23新+
    isTime: '', // 时间戳
    isWaihuCallPhone: '', // 回访外呼默认回填号码
    isSanfang: false, // 三方
    isKeep: false, // 保持按钮
    loading: false, // 控制modal是否处于请求中
    callid: '', // 电话呼叫的标识 用来进行保持呼叫
    citizenphone: '', // 市民呼入电话
    callednum: '',
    hotTransferTime: 0, // 热转时间
    callidOut: '', // 热转呼叫id
    callidHelp: '', // 求助callid
    signInto: -1, // 签入的cti是哪个地址
    markType: '', // 三方外转触发
    count: 0 // 记录坐席事件重试次数
  }

  componentDidMount() {
    const guid = CookieUtils.getJSON('Guid')
    this.getSkills()
    if (guid) {
      this.setState({
        isSignIn: false
      })
      this.handleLoopEvent()
    }

    window.PhoneEventListener = {}
    // 浏览器页面重新加载或关闭时自动签出
    window.onbeforeunload = () => {
      this.signOut();
      // 签出时 清空guid
      CookieUtils.set('Guid', '')
    }
    // 创建连接对象
    const servers = { iceServers: [{ urls: 'stun:localhost' }] }
    const pc = new RTCPeerConnection(servers)
    // 错误回调和成功回调
    // eslint-disable-next-line no-new-func
    const errfunc = new Function()
    const successfunc = result => pc.setLocalDescription(result)

    // 创建传输信道
    pc.createDataChannel('')
    // 创建sdp对象
    pc.createOffer(successfunc, errfunc)
    // 在监听中得到ip地址
    pc.onicecandidate = function() {}
  }

  componentWillReceiveProps(nextProps) {
    const { isDail, orderId, callerPhone, isRevisit } = nextProps
    const { dispatchDailPhone } = this.props
    if (isDail) {
      this.setState(
        {
          isWaihu: true,
          orderId,
          dataFalge: 0,
          isWaihuCallPhone: callerPhone,
          isRevisit
        },
        () => {
          dispatchDailPhone({
            isDail: false,
            orderId: '',
            isRevisit
          })
          this.showPhoneBarModal(
            {
              preventDefault: () => {}
            },
            2
          )
        }
      )
    }
  }

  componentWillUnmount() {
    if (this.getIntervalTime) {
      clearInterval(this.getIntervalTime)
    }
    if (this.getIdleTime) {
      clearTimeout(this.getIdleTime)
    }
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
    localStorage.setItem('login-url', null)
  }

  // 将报错信息下载到本地
  downloadErrorFile = (filename, res, errorInfo) => {
    const { url, data } = res.config;
    let urls;
    if (data) {
      const { url: realurl } = JSON.parse(data)
      urls = realurl
    } else {
      urls = url;
    }
    const time = new Date()
    const blob = new Blob(
      [
        `${filename}\n触发时间为：${time}\n转发接口为：${urls}\n实际接口为：${urls}\n错误内容为：${errorInfo}`
      ],
      { type: 'text/plain;charset=utf-8' }
    )
    saveAs(blob, `${filename}.txt`)
  }

  handleResetValue = () => {
    clearInterval(this.updateGuidInterval)
    clearInterval(this.getIntervalTime)
    clearInterval(this.getIdleTime)
    clearTimeout(this.timeoutId)
    this.setState({
      isSignIn: true,
      isCalling: false,
      isWaihu: false, //  是否点击外呼
      orderId: '', // 是否点击外呼工单Id
      isStillWork: false, // 是否正在工作
      idleTime: 240, // 工作时长读秒
      callLastTime: 0, // 通话持续时间
      workSkillType: '', // 技能
      isKeepHangPhone: true, // 是否保持
      isMute: true, // 是否静音
      isShowRest: false, // 是否显示休息
      isWorking: true, // 显示为 工作态还是休息态
      isRest: true, // 显示为 休息还是取消休息
      isShowCircle: false, // 是否显示三方操作
      isShowBusy: false, // 是否显示示忙操作
      isShowSignIn: false, // 是否显示签入modal
      isShowDail: false, // 是否拨打电话(包含内呼和外呼)
      isOnlineSwitching: false, // 是否在线转接
      isOnlineSwitchingSearch: false, // 是否在线转接 查询
      isphoneNumber: '', // 是否选择了转接坐席
      seleCss: '', // 在线转接 列表点击选中
      isShowListenBox: false, // 是否显示监听通话模态框
      isShowKeepCall: false, // 是否显示保持通话模态框
      giiValue: '', // 监听通话 inputValue
      hangUp: '', // 挂断
      inputValue: '', // 二次拨号 提交框value值
      isNumWindeow: false, // 是否展示小键盘
      isCallingBefore: false,
      findInfoInputValue: '',
      tabFalse: false,
      tabTrue: true,
      tabDefault: '1',
      isWorState: false,
      coidID: '', // 录音成功扩展事 callID
      isSayFree: true,
      isCallSuccess: false, // 7-3新加 处理话后 外呼不成功 工作态倒计时重置bug
      isListen: false, // 是否显示监听按钮
      isWebsocket: false, // 建立ws连接 21.4.23新+
      isTime: '', // 时间戳
      isWaihuCallPhone: '', // 回访外呼默认回填号码
      isSanfang: false, // 三方
      isKeep: false, // 保持按钮
      loading: false, // 控制modal是否处于请求中
      callid: '', // 电话呼叫的标识 用来进行保持呼叫
      citizenphone: '', // 市民呼入电话
      callednum: '',
      hotTransferTime: 0, // 热转时间
      callidOut: '', // 热转呼叫id
      callidHelp: '', // 求助callid
      signInto: -1, // 签入的cti是哪个地址
      markType: '', // 三方外转触发
      count: 0 // 记录坐席事件重试次数
    });
  }

  handleLoopEvent = () => {
    const { userInfo } = this.props
    this.updateGuidInterval = setInterval(() => {
      useApi
        .updateGuid({
          agentid: userInfo.userNum
        })
        .then(
          res => {
            if (!res?.data) {
              message.error('更新guid接口异常');
              this.signOut();
              return;
            }
            const { code, data } = res.data
            if (code === 200) {
              const { retcode, result } = JSON.parse(data)
              if (retcode === '0') {
                CookieUtils.set('Guid', result.guid)
                // eslint-disable-next-line react/destructuring-assignment
                if (this.state.count !== 0) {
                  this.handleSubscribe()
                  this.setState({
                    count: 0
                  })
                }
                const { url: logurl } = JSON.parse(res.config.data)
                const substr = logurl.substring(0, logurl.indexOf(8043) + 4)
                localStorage.setItem('login-url', substr)
              } else {
                this.signOut('更新guid服务异常');
                this.handleResetValue()
              }
            } else {
              this.signOut('更新guid服务异常');
              this.handleResetValue()
              const { url: logurl } = JSON.parse(res.config.data)
              const substr = logurl.substring(0, logurl.indexOf(8043) + 4)
              localStorage.setItem('login-url', substr)
            }
          },
          err => {
            message.error(err)
            this.signOut('更新guid服务异常');
            this.handleResetValue()
          }
        )
    }, 60000)
    this.handleSubscribe()
  }

  // 定义一个长轮询 监听当前通话触发事件
  handleSubscribe = () => {
    const { userInfo } = this.props
    const guid = CookieUtils.getJSON('Guid')
    if (guid === '' || guid === undefined || guid === null) {
      this.setState({
        isSignIn: true
      })
      return
    }
    useApi
      .handshakes({
        agentid: userInfo.userNum
      })
      .then(
        res => {
          if (!res?.data) {
            message.error('轮询接口接口异常');
            this.signOut();
            return;
          }
          const { code, data } = res.data
          const { isWorking, markType } = this.state
          if (code === 200) {
            const { event, message: msg } = JSON.parse(data)
            if (msg === 'agent not login') {
              this.setState({
                isSignIn: true
              });
              return
            }
            if (event) {
              const { eventType, content } = event
              // 录音开始事件
              if (eventType === 'AgentMediaEvent_Record') {
                if (content) {
                  const {
                    caller,
                    called,
                    callId,
                    fileName,
                    locationId
                  } = content
                  let calledCopy = called
                  // pCallID 录音呼叫callid caller录音呼叫的主叫号码 called录音呼叫的被叫号码 SelfRecFileName录音文件地址
                  if (called.charAt(0) === '9') {
                    calledCopy = called.substring(1)
                  }
                  this.doOnRecordSuccessEx(
                    callId,
                    caller,
                    calledCopy,
                    fileName,
                    locationId
                  )
                  this.setState({
                    callid: callId,
                    citizenphone: caller,
                    callednum: calledCopy
                  })
                }
              }
              // 座席久不应答
              if (eventType === 'AgentEvent_No_Answer') {
                this.doOnLongNoAnswer()
              }
              // 转接成功
              if (eventType === 'AgentEvent_linkagePhoneTransferOut_Result') {
                const { result } = content
                if (result === 0) {
                  this.doOnTransOutSuccess()
                }
              }
              if (eventType === 'AgentEvent_Talking') {
                this.setState({
                  hotTransferTime: 0,
                  callidOut: ''
                })
                clearInterval(this.hotTransferTask)
              }
              // 外呼失败时
              if (eventType === 'AgentEvent_Call_Out_Fail') {
                const { callidOut } = this.state
                if (callidOut !== '') {
                  this.setState({
                    isKeep: false,
                    hotTransferTime: 0, // 热转计时 超15秒取消当前呼出
                    callidOut: ''
                    // isKeepHangPhone: true,
                    // isCallingBefore: true
                  })
                  const { callid } = this.state
                  if (callid) {
                    useApi.cancelHold({
                      agentid: userInfo.userNum,
                      callid
                    })
                  }
                  clearInterval(this.hotTransferTask)
                } else {
                  this.setState({
                    isCallingBefore: false
                  });
                  message.error('呼叫失败');
                }
              }
              // 外呼失败时 重新热转 座席退出呼叫（AgentEvent_Call_Release）客户退出呼叫（AgentEvent_Customer_Release）
              if (
                eventType === 'AgentEvent_Call_Release' ||
                eventType === 'AgentEvent_Customer_Release'
              ) {
                const { callid } = this.state
                if (callid) {
                  useApi.cancelHold({
                    agentid: userInfo.userNum,
                    callid
                  })
                }
                // 不管是坐席还客户退出呼叫 将解除热转置为false
                this.setState({
                  isKeep: false
                })
                clearInterval(this.hotTransferTask)
              }
              // 坐席物理挂机(拒接) 手动进入空闲态
              if (eventType === 'AgentOther_PhoneRelease' && isWorking) {
                // 进入空闲状态
                // useApi.sayfreeEvent({
                //   agentid: userInfo.userNum
                // });
                this.doOnRequestReleaseEx()
              }
              // 坐席进入工作中时 将坐席置忙  或录音结束
              if (
                eventType === 'AgentState_Work' ||
                eventType === 'AgentMediaEvent_StopRecordDone'
              ) {
                this.doOnRequestReleaseEx()
              }
              // 成功转接时
              if (eventType === 'AgentEvent_SuccTransResult') {
                // this.doOnRequestReleaseEx();
                if (markType) {
                  // this.hangOutPhone('三方外转');
                }
              }
            }
            this.setState({
              isSignIn: false
            })
          }
          this.handleSubscribe()
        },
        err => {
          message.error(err)
          this.handleResetValue()
        }
      )
  }

  // 转残联对列
  handleChangeOver = () => {
    const { userInfo } = this.props
    useApi
      .transferCall({
        agentid: userInfo.userNum,
        devicetype: 1,
        address: 30,
        mode: 0,
        caller: '12345'
      })
      .then(res => {
        const { code, data } = res.data
        if (code === 200) {
          const { retcode } = JSON.parse(data)
          this.handleCloseModalTransfer()
          if (retcode !== '0') {
            message.error('转接残联失败')
          }
        }
      })
  }

  // 在线转接 查询38 99（企业热线、班长）空闲席位
  QueryBySkillQueue = async () => {
    const { userInfo } = this.props
    const workNoORskillId = [99, 11754, 38, 117, 18] // 10812 残联转接直接转对列
    const monitorArray = [] // 班长
    const businessArray = [] // 企业
    const EnglishArray = [] // 英文席
    // const cdpfArr = [] // 残联
    const leaderArr = [] // 领导
    const offArr = [] // 交管局
    for (let i = 0; i < workNoORskillId.length; i += 1) {
      const num = workNoORskillId[i]
      // 如果是38走技能id查询其他走班组查询
      if (num === 38 || num === 99 || num === 117 || num === 18) {
        useApi
          .agentbyskill({
            agentid: userInfo.userNum,
            skillid: num
          })
          .then(res => {
            const { code, data } = res.data
            if (code === 200) {
              const { retcode, result } = JSON.parse(data)
              if (retcode === '0') {
                result.forEach(item => {
                  if (item.status === '4' || item.status === 4) {
                    const obj = {
                      gii: item.workno, // 工号
                      curState: item.status, // 状态
                      name: item.name, // 姓名
                      workGroupId: item.groupid // 组别
                    }
                    if (num === 99) {
                      businessArray.push(obj)
                    } else if (num === 18 && obj.workGroupId === 15) {
                      leaderArr.push(obj)
                    } else if (num === 38) {
                      monitorArray.push(obj)
                    } else if (num === 117) {
                      EnglishArray.push(obj)
                    }
                    this.setState({
                      // lastarrB: businessArray,
                      lastarrM: monitorArray,
                      englishArr: EnglishArray,
                      leaderArr
                    })
                  }
                })
              }
            }
          })
      } else {
        useApi
          .agentbyworkno({
            agentid: userInfo.userNum,
            workNo: num
          })
          .then(res => {
            const { code, data } = res.data
            if (code === 200) {
              const { retcode, result } = JSON.parse(data)
              if (retcode === '0') {
                if (result.status === 4 || result.status === '4') {
                  const obj = {
                    gii: result.workno, // 工号
                    curState: result.status, // 状态
                    name: result.name, // 姓名
                    workGroupId: result.groupid // 组别
                  }
                  if (num === 11754) {
                    offArr.push(obj)
                  }
                  // else if (num === 10812) {
                  //   cdpfArr.push(obj)
                  // }
                  this.setState({
                    // lastArrCdpf: cdpfArr,
                    offArr
                  })
                }
              }
            }
          })
      }
    }
  }

  // 在线转接 选择空闲列表中的坐席
  handClickGroup = gii => {
    this.setState({
      seleCss: gii,
      isphoneNumber: gii
    })
  }

  // 在线转接 请求38、99 打开窗口
  showPhoneBarModalTransfer = () => {
    this.QueryBySkillQueue()
    this.setState({
      isOnlineSwitching: true
    })
  }

  // 在线转接  关闭窗口
  handleCloseModalTransfer = () => {
    this.setState({
      isOnlineSwitching: false,
      findInfoInputValue: ''
    })
  }

  // 在线转接 查询 输入工号
  changeFindInfoValue = e => {
    const changVal = e.target.value
    this.setState({
      findInfoInputValue: changVal
    })
  }

  // 在线转接 点击查询
  searchNum = async () => {
    const { userInfo } = this.props
    const { findInfoInputValue } = this.state
    if (findInfoInputValue === '' || findInfoInputValue.includes(' ')) return
    const res = await useApi.agentbyworkno({
      agentid: userInfo.userNum,
      workNo: findInfoInputValue
    })
    const { code, data } = res.data
    if (code === 200) {
      const { retcode, result } = JSON.parse(data)
      if (retcode === '0') {
        const { status, name, groupid } = result
        const arr = []
        // 如果是空闲 Phone.AgentInfoEx_CurState === 1
        if (status === '4' || status === 4) {
          const obj = {
            gii: findInfoInputValue, // 工号
            curState: status, // 状态
            name, // 姓名
            workGroupId: groupid // 组别
          }
          arr.push(obj)
          this.setState({
            searchArr: arr,
            isOnlineSwitchingSearch: true
          })
        }
      }
    }
  }

  // 监听通话 点击查询
  ListhenGii = () => {
    const { giiValue } = this.state
    const { userInfo } = this.props
    useApi
      .agentbyworkno({
        agentid: userInfo.userNum,
        workNo: giiValue
      })
      .then(res => {
        const { code, data } = res.data
        if (code === 200) {
          const { retcode, result } = JSON.parse(data)
          if (retcode === '0') {
            const { status } = result
            if (status === '7' || status === 7) {
              useApi
                .addsupervise({
                  agentid: userInfo.userNum,
                  workNo: giiValue
                })
                .then(r => {
                  const { data: watchdata } = r.data
                  const { retcode: watchCode } = JSON.parse(watchdata)
                  if (watchCode === 0 || watchCode === '0') {
                    message.success(`监听${giiValue}通话成功！`)
                    // 监听成功后显示挂机按钮 挂机按钮用于退出监听通话
                    this.setState({
                      isCallingBefore: true,
                      isShowListenBox: false,
                      isListen: false
                    })
                  } else {
                    message.error('坐席状态异常，监听失败')
                    // 转接失败后重新打开窗口获取最新坐席状态
                    this.setState({
                      isShowListenBox: false
                    })
                    this.handleListen()
                  }
                })
            } else {
              message.error('坐席状态异常，监听失败')
              // 转接失败后重新打开窗口获取最新坐席状态
              this.setState({
                isShowListenBox: false
              })
              this.handleListen()
            }
          }
        }
      })
  }

  // 当监听通话 输入框的值发生改变时
  giiValueChange = e => {
    const giiValueNew = e.target.value

    this.setState({
      giiValue: giiValueNew
    })
  }

  // 在线转接  关闭窗口
  handleCloseModalTransferSearch = () => {
    this.setState({
      isOnlineSwitchingSearch: false,
      giiValue: ''
    })
  }

  QueryBySkillQueueNew = () => {
    const allListNew = []
    const { userInfo } = this.props
    useApi
      .agentbyskill({
        agentid: userInfo.userNum,
        skillid: 18 // 18 后面需要调整为18  目前测试环境只有队列1
      })
      .then(res => {
        const { code, data } = res.data
        if (code === 200) {
          const { retcode, result, message: msg } = JSON.parse(data)
          if (retcode === '0') {
            result.forEach(item => {
              if (item.status === '7' || item.status === 7) {
                const obj = {
                  gii: item.workno, // 工号
                  curState: item.status, // 状态
                  name: item.name, // 姓名
                  workGroupId: item.groupid // 组别
                }
                allListNew.push(obj)
              }
            })
          } else message.error(msg)
        }
      })
      .catch(err => {
        console.error(err)
      })
    this.setState({
      allList: allListNew
    })
  }

  // 监听通话 请求38、99   打开 监听通话模态框
  handleListen = () => {
    this.QueryBySkillQueueNew()
    this.setState({
      isShowListenBox: true
    })
  }

  // 监听通话  关闭窗口
  handleCloseListenBox = () => {
    this.setState({
      isShowListenBox: false,
      giiValue: ''
    })
  }

  // 二次拨号
  AgentSend = val => {
    const { userInfo } = this.props
    useApi
      .seconddialex({
        agentid: userInfo.userNum,
        number: val
      })
      .then(res => {
        const { data } = res.data
        const { retcode, message: msg } = JSON.parse(data)
        if (retcode !== '0') {
          this.downloadErrorFile('二次拨号出错', res, msg)
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  // 二次拨号 点击键盘
  handleBtnNum = num => {
    const { inputValue } = this.state
    const inputValuels = inputValue
    const addNmu = inputValuels + num
    this.setState({
      inputValue: addNmu
    })
  }

  // 二次拨号 提交键盘
  handleSubmitNum = () => {
    const { inputValue } = this.state
    this.AgentSend(inputValue)
    this.setState({
      inputValue: ''
    })
  }

  // 二次拨号 关闭窗口
  handleCloseModalNum = () => {
    this.setState({
      inputValue: '',
      isNumWindeow: false
    })
  }

  // 二次拨号 打开窗口
  showPhoneBarModalNmu = () => {
    this.setState({
      isNumWindeow: true
    })
  }

  // 二次拨号 手动输入
  changeValue = e => {
    const changVal = e.target.value
    this.setState({
      inputValue: changVal
    })
  }

  // 坐席添加通话标记
  addSigns = () => {
    const { coidID, callLastTime } = this.state
    const parameter = {
      coid: coidID,
      signTime: callLastTime
    }
    useApi.addSign(parameter);
  }

  getSkills = () => {
    useApi.getUserSkill().then(suc => {
      const { data } = suc
      const { code, data: dataArr, msg } = data
      if (code === 200) {
        this.setState({
          skillsArr: dataArr,
          defaultValueText:
            dataArr && dataArr.length > 0 ? dataArr[0].dictKey : '',
          workSkillType: dataArr && dataArr.length > 0 ? dataArr[0].dictKey : ''
        })
      } else {
        console.log(msg)
      }
    })
  }

  // 用户挂机提示
  doOnRequestReleaseEx = () => {
    const { hangUp, isStillWork, isMute, isKeepHangPhone } = this.state;
    store.dispatch({
      type: SET_WAIHU,
      payload: {
        isWaihuMeta: 0
      }
    })
    const { userInfo } = this.props
    // 判断是否是在保持通话期间挂机
    if (isKeepHangPhone) {
      // 新增挂断接口
      useApi.HangUp({ coid: hangUp })
      // 判断保持功能是否异常
      if (!isKeepHangPhone) {
        this.setState({
          isKeepHangPhone: !isKeepHangPhone
        })
      }
      // 判断挂机时是否是静音  静音时取消静音
      if (!isMute) {
        this.setState({
          isMute: !isMute
        })
        useApi.endmute({
          agentid: userInfo.userNum
        })
      }
      this.setState(
        {
          isCallSuccess: false,
          isCalling: false,
          // isCallOutValue: 0,
          isCallingBefore: false,
          isWaihuCallPhone: '' // 挂机时清空外呼得值
        },
        () => {
          if (isStillWork) {
            clearInterval(this.getIdleTime)
            clearTimeout(this.timeoutId)
            this.setState(
              {
                idleTime: 240
              },
              () => {
                this.exitAgentEnterIdle()
              }
            )
          } else {
            this.setState(
              {
                idleTime: 240,
                isStillWork: true,
                isWorState: true,
                isWorking: false
              },
              () => {
                this.exitAgentEnterIdle()
              }
            )
          }
        }
      )
    }
    // 判断是否是在 保持通话期间挂机
    if (!isKeepHangPhone) {
      this.getKeepCall()
    }
  }

  // 接入电话回调函数
  doOnRecordSuccessEx = (
    pCallID,
    caller,
    called,
    selfRecFileName,
    locationId
  ) => {
    const {
      // isCallOutValue,
      isWaihu,
      orderId,
      isShowBusy,
      txtPhoneNumVal,
      isRevisit
    } = this.state
    const { userInfo } = this.props
    const { phoneReduce } = store.getState()
    const { isWaihuMeta } = phoneReduce
    this.setState({
      hangUp: pCallID,
      coidID: pCallID, // 录音成功扩展事 callID
      isCallSuccess: true
    })
    useApi
      .agentstatus({
        agentid: userInfo.userNum
      })
      .then(res => {
        const { code, data } = res.data
        if (code === 200) {
          const { retcode, result } = JSON.parse(data)
          if (retcode === '0') {
            const { agentState, isWorking } = result
            // 0519  判断通话前是否示忙
            if (isShowBusy && agentState === 4) {
              this.setState({
                isShowBusy: !isShowBusy,
                isSayFree: true
              })
            }
            if (isWorking) {
              clearTimeout(this.timeoutId)
            }
          }
        }
      })
    let orderIdVal = orderId

    if (isWaihu || isWaihuMeta === 1) {
      if (isWaihuMeta === 1 && isWaihu === false) {
        orderIdVal = '0'
      }
      // 调取接口
      const parame = {
        calledPhone: called,
        callerPhone: caller,
        coid: pCallID,
        recordPath: selfRecFileName,
        orderId: orderIdVal,
        locationId,
        callTypeDetail: isRevisit === '1' ? '1' : '0' // 回拨1   外呼0
      }
      useApi.Outbound(parame).then(suc => {
        const { data: dataResult } = suc
        const { code, msg } = dataResult
        if (code !== 200) {
          message.info(msg)
        }
        this.setState({
          isWaihu: false
        })
      })
    }

    this.setState(
      {
        isCalling: true,
        callLastTime: 0
      },
      () => {
        /* eslint-disable react/prop-types */
        message.info('您有新的电话接入')
        this.startCountTime()
        // const { isCallOutValue: isCallout } = this.state;
        if (caller !== userInfo.userNum) {
          const { history } = this.props
          useApi
            .newCallWorkOrder({
              calledPhone: called,
              callerPhone: caller,
              coid: pCallID,
              recordPath: selfRecFileName,
              locationId
            })
            .then(suc => {
              const { data: dataResult } = suc
              const { code, data: callOrderInfo, msg } = dataResult
              if (code === 200) {
                const { workOrder: workCallIdState } = callOrderInfo || {}
                const { id, callTime } = workCallIdState
                const uuid = this.guid()
                const params = {
                  msgId: uuid, // 科大定义
                  cmd: 'orderInfo', // 科大定义
                  sessionId: /* '55552002' ||  */ txtPhoneNumVal, // 坐席电话号
                  agentId: /* '55552002' || */ txtPhoneNumVal, // 坐席电话号
                  orderId: id, // 工单id
                  userPhone: caller, // 市民号码
                  callTime // 来电时间
                }
                this.setState(
                  {
                    webSendData: params,
                    isKeep: false
                  },
                  () => {
                    // 来电工单的路由
                    history.push(`/acc_popup_order?orderId=${id}`)
                  }
                )
              } else {
                this.downloadErrorFile('工单新建异常', suc, msg)
              }
            })
        }
      }
    )
  }

  guid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      // eslint-disable-next-line no-bitwise
      const r = (Math.random() * 16) | 0
      // eslint-disable-next-line no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  doOnTransOutSuccess = () => {
    message.success('转接成功')
    this.setState({
      isOnlineSwitching: false,
      isOnlineSwitchingSearch: false
    })
    this.doOnRequestReleaseEx()
  }

  // 自动流程IVR 0402
  transIVR = () => {
    const { userInfo } = this.props
    const { citizenphone, callednum } = this.state
    const myDate = new Date()
    const yearVal = myDate.getFullYear()
    const monthVal = myDate.getMonth() + 1
    const dateVal = myDate.getDate()
    const hourVal = myDate.getHours()
    const minutesVal = myDate.getMinutes()
    const secondVal = myDate.getSeconds()

    const monthValStr = `0${monthVal}`
    const newMonthValSubstr = monthValStr.substr(-2)
    const dateValStr = `0${dateVal}`
    const dateValSubstr = dateValStr.substr(-2)
    const hourValStr = `0${hourVal}`
    const hourValSubstr = hourValStr.substr(-2)
    const minutesValStr = `0${minutesVal}`
    const minutesValSubstr = minutesValStr.substr(-2)
    const secondValStr = `0${secondVal}`
    const secondValSubstr = secondValStr.substr(-2)

    // 时间戳标记
    const timeString = `${yearVal}-${newMonthValSubstr}-${dateValSubstr} ${hourValSubstr}:${minutesValSubstr}:${secondValSubstr}`
    let callDatakey = nanoid(17) // 唯一标识
    let callDataNo = userInfo.userNum // 工号
    let callDataPhone = citizenphone // 来电号码
    const callDataTime = timeString // 来电时间
    if (userInfo.userNum.length === 4) {
      callDataNo = ` ${callDataNo}`
    }
    // 判断如果主叫是12345取被叫
    if (callDataPhone === 12345 || callDataPhone === '12345') {
      callDataPhone = callednum
    }
    // 判断来电号码是否符合存储标准
    if (callDataPhone.length !== 11) {
      if (callDataPhone.length < 11) {
        const a = 11 - callDataPhone.length
        let str = ''
        for (let i = 0; i < a; i += 1) {
          // str += ` `
          str += ' '
        }
        callDataPhone += `${str}`
      } else {
        callDataPhone = callDataPhone.substr(-11)
      }
    }
    if (callDatakey === '' || callDatakey === null) {
      // 如果未正常设置唯一标识使用该数据填充字段
      callDatakey = '0               '
    }
    let callData = `${callDatakey} ${callDataNo}04   ${callDataPhone}   ${callDataTime}`
    if (callDataNo.length === 5) {
      callData = `${callDatakey} ${callDataNo}04  ${callDataPhone}   ${callDataTime}`
    }

    // 呼叫转移接口 transferCall替换 Phone.TransToIVR
    useApi
      .transferCall({
        agentid: userInfo.userNum,
        devicetype: 3,
        address: '112',
        mode: 0,
        callappdata: callData
      })
      .then(
        res => {
          const { code, data, msg: proxymsg } = res.data
          if (code === 200) {
            const { retcode } = JSON.parse(data)
            if (retcode === '0') {
              // 挂断后需要将当前保持的通话 取消掉
              this.doOnRequestReleaseEx()
            }
          } else {
            this.downloadErrorFile('呼叫转移IVP出错', res, proxymsg)
          }
        },
        err => {
          console.log(err, '呼叫转移IVP出错')
        }
      )
  }

  //  示忙后显示按钮，提示示闲操作
  doOnLongNoAnswer = () => {
    this.setState({
      isShowBusy: true,
      isSayFree: false
    })
  }

  // 开始计时操作
  startCountTime = () => {
    // 开始计时
    if (this.getIntervalTime) {
      clearInterval(this.getIntervalTime)
    }
    this.getIntervalTime = setInterval(() => {
      const { callLastTime } = this.state
      this.setState({
        callLastTime: callLastTime + 1
      })
    }, 1000)
  }

  // 话后开始计时
  startCountIdleTime = () => {
    // 开始计时
    if (this.getIdleTime) {
      clearInterval(this.getIdleTime)
    }
    this.getIdleTime = setInterval(() => {
      const { idleTime } = this.state
      this.setState({
        idleTime: idleTime - 1
      })
    }, 1000)
  }

  // 退出话后定时任务
  exitAgentEnterIdle = () => {
    const { userInfo } = this.props
    const that = this;
    useApi.autoenteridle({
      // 座席进入工作状态
      agentid: userInfo.userNum,
      flag: false
    })
    this.startCountIdleTime()
    clearInterval(this.getIntervalTime)
    this.timeoutId = setTimeout(() => {
      clearInterval(this.getIdleTime)
      this.setState(
        {
          isStillWork: false,
          isWorState: false,
          isWorking: true
        },
        async () => {
          const { isCalling, isShowBusy } = that.state
          if (!isCalling) {
            // 判断示忙状态下 禁止解除话后
            if (!isShowBusy) {
              useApi.cancelwork({ agentid: userInfo.userNum });
            } else {
              const result = await useApi.cancelwork({ agentid: userInfo.userNum });
              const { data: d } = result.data
              const { retcode: ret, message: workmsg } = JSON.parse(d);
              if (ret === '0') {
                // 当前状态为示忙态 由于电话进入导致状态变化 故需要主动将座席状态设置为忙态。
                useApi
                .saybusyEvent({
                  agentid: userInfo.userNum
                })
                .then(res => {
                  const { code, data } = res.data
                  if (code === 200) {
                    const { retcode, message: msg } = JSON.parse(data)
                    if (retcode !== '0') {
                      message.error('操作失败');
                      this.setState({
                        isShowBusy: !isShowBusy
                      });
                      this.downloadErrorFile('示忙操作失败', res, msg)
                    }
                  }
                })
                .catch(err => {
                  console.error(`示忙操作失败：${err}`)
                })
              }  else {
                this.downloadErrorFile('示忙操作失败', result, workmsg);
              }
            }
          }
        }
      )
    }, 240000)
  }

  // 挂机
  hangOutPhone = val => {
    const { isCallSuccess, isListen } = this.state
    const { userInfo } = this.props
    const { userNum } = userInfo
    useApi
      .ringoff({
        agentid: userInfo.userNum
      })
      .then(res => {
        const { code, data } = res.data
        if (code === 200) {
          const { retcode } = JSON.parse(data)
          if (retcode === '0') {
            // 挂机成功 Phone.workNo 换为userNum
            if (!isListen && (userNum === 16888 || userNum === '16888')) {
              this.setState({
                isListen: !isListen
              })
            }
            useApi
              .agentstatus({
                agentid: userInfo.userNum
              })
              .then(response => {
                const { code: c, data: d } = response.data
                if (c === 200) {
                  const { result } = JSON.parse(d)
                  const { agentState } = result
                  if (agentState === 4 && isCallSuccess) {
                    this.doOnRequestReleaseEx()
                  } else {
                    this.setState({
                      isCalling: false,
                      // isCallOutValue: 0,
                      isCallingBefore: false
                    })
                    if (val) {
                      this.setState({
                        isCallSuccess: false,
                        isWaihuCallPhone: '',
                        markType: false
                      })
                    }
                  }
                }
              })
          } else {
            // this.downloadErrorFile('挂断通话失败', res, nmsg);
          }
        } else {
          // this.downloadErrorFile('挂断通话失败', res, proxymsg);
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  // 根据呼叫标识挂机
  hangOutIdPhone = () => {
    const { callidHelp } = this.state
    const { userInfo } = this.props
    if (callidHelp !== '') {
      // 拆除指定的呼叫
      useApi
        .dropCallid({
          agentid: userInfo.userNum,
          callid: callidHelp
        })
        .then(res => {
          const { data } = res.data
          const { retcode } = JSON.parse(data)
          if (retcode === '0') {
            this.setState({
              callidHelp: ''
            })
          }
        })
    }
  }

  // 保持通话
  hangOnPhone = () => {
    const { isKeepHangPhone } = this.state
    if (isKeepHangPhone) {
      // 保持通话
      this.keepCall()
    } else {
      this.getKeepCall()
    }
  }

  keepCall = () => {
    const { userInfo } = this.props
    useApi
      .callHold({ agentid: userInfo.userNum })
      .then(res => {
        const { code, data } = res.data
        if (code === 200) {
          const { retcode, message: msg } = JSON.parse(data)
          if (retcode === '0') {
            this.QueryBySkillQueuekeep()
            this.setState({
              isKeepHangPhone: false,
              isCallingBefore: false,
              isShowKeepCall: true
            })
          } else {
            message.error(msg)
          }
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  QueryBySkillQueuekeep = () => {
    const keepCallListNew = []
    const { userInfo } = this.props
    // 根据指定的技能id 获取座席信息
    useApi
      .agentbyskill({
        agentid: userInfo.userNum,
        skillid: 18 // 18 后面需要调整为18  目前测试环境只有队列1
      })
      .then(res => {
        const { data } = res.data
        const { retcode, result, message: msg } = JSON.parse(data)
        if (retcode === '0') {
          result.forEach(item => {
            if (item.status === '4' || item.status === 4) {
              const obj = {
                gii: item.workno, // 工号
                curState: item.status, // 状态
                name: item.name, // 姓名
                workGroupId: item.groupid // 组别
              }
              keepCallListNew.push(obj)
            }
          })
        } else {
          message.error(msg)
        }
      })
      .catch(err => {
        console.error(err)
      })
    this.setState({
      keepCallList: keepCallListNew
    })
  }

  cancelHolding = () => {
    const { callid } = this.state
    const { userInfo } = this.props
    useApi
      .cancelHold({
        agentid: userInfo.userNum,
        callid
      })
      .then(res => {
        const { code, data } = res.data
        if (code === 200) {
          const { retcode, message: msg } = JSON.parse(data)
          if (retcode === '0') {
            this.setState({
              isKeepHangPhone: true,
              isCallingBefore: true
            })
            message.success('解除求助成功')
          } else {
            message.error(msg)
          }
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  getKeepCall = () => {
    const { userInfo } = this.props
    const { callidHelp } = this.state
    // 将保持的呼叫解除静音
    if (callidHelp !== '') {
      // 拆除指定的呼叫
      useApi
        .dropCallid({
          agentid: userInfo.userNum,
          callid: callidHelp
        })
        .then(res => {
          const { data } = res.data
          const { retcode } = JSON.parse(data)
          if (retcode === '0') {
            this.setState({
              callidHelp: ''
            })
          }
          this.cancelHolding()
        })
    } else {
      this.cancelHolding()
    }
  }

  // 关闭弹出层
  handleCloseModal = () => {
    const { loading } = this.state
    if (!loading) {
      this.setState({
        isShowDail: false,
        isShowSignIn: false,
        isShowCircle: false,
        isShowRest: false,
        isSanfang: false
      })
    }
    document.getElementById('root').style.height = 'auto'
  }

  // 确认操作
  handleOKAndCloseModal = async () => {
    // 基础信息表单部分
    const modalFormResult = this.modalFormControl.getItemsValues()
    const {
      isShowDail,
      isShowSignIn,
      isShowCircle,
      isShowRest,
      isSanfang
    } = this.state

    if (modalFormResult !== -1) {
      // 拨打操作确认事件
      if (isShowDail) {
        // 表示弹出层为拨打电话操作
        this.setState(
          {
            isShowDail: false
          },
          () => {
            this.doDailProcess(modalFormResult)
          }
        )
      }

      // 三方操作确认事件
      if (isShowCircle) {
        this.setState(
          {
            isShowCircle: false
          },
          () => {
            this.doShowCircleProcess(modalFormResult)
          }
        )
      }

      // 休息操作确认事件
      if (isShowRest) {
        this.setState(
          {
            loading: true
          },
          () => {
            this.doShowRestProcess(modalFormResult)
          }
        )
      }

      // 签入操作确认事件
      if (isShowSignIn) {
        this.setState(
          {
            loading: true
          },
          () => {
            const result = this.modalFormControl.getItemsValues()
            useApi
              .forcelogin({
                agentid: result.txtLabourNo, // 座席工号
                password: result.password || '12345', // userInfo.password
                phonenum: result.txtPhoneNum, // 软电话码
                autoenteridle: false,
                status: 3 // 签入后默认为示忙态 重置技能后解除示忙
              })
              .then(
                res => {
                  if (!res) {
                    this.setState({
                      loading: false
                    })
                    return
                  }
                  const { code, data, msg: proxymsg } = res.data
                  if (code === 200) {
                    const { retcode, message: msg, guid } = JSON.parse(data)
                    if (retcode === '0') {
                      const { url } = JSON.parse(res.config.data)
                      let type = -1
                      const urlList = [
                        'https://172.24.198.103:8043',
                        'https://172.24.198.104:8043'
                      ]
                      urlList.forEach((item, index) => {
                        if (url.indexOf(item) !== -1) {
                          type = index
                        }
                      })
                      const { workSkillType } = this.state
                      let workSkillTypeNew = workSkillType
                      if (workSkillTypeNew === '1' || workSkillTypeNew === 1) {
                        workSkillTypeNew = '1,5,34'
                      }
                      CookieUtils.set('Guid', guid[0])
                      const {
                        seatType,
                        txtPhoneNum,
                        txtLabourNo,
                        password
                      } = result
                      // 重置技能对列
                      this.ResetSkillExFn(
                        workSkillTypeNew,
                        type,
                        seatType,
                        txtPhoneNum,
                        txtLabourNo,
                        password
                      )
                      // this.autoGraphJoin(
                      //   seatType,
                      //   txtPhoneNum,
                      //   txtLabourNo,
                      //   password
                      // )
                      localStorage.setItem('login-url', null)
                    } else {
                      this.setState({
                        loading: false
                      })
                      const { url: logurl } = JSON.parse(res.config.data)
                      const substr = logurl.substring(
                        0,
                        logurl.indexOf(8043) + 4
                      )
                      localStorage.setItem('login-url', substr)
                      this.downloadErrorFile('签入操作失败', res, msg)
                      message.error(msg)
                    }
                  } else {
                    const { url: logurl } = JSON.parse(res.config.data)
                    const substr = logurl.substring(0, logurl.indexOf(8043) + 4)
                    localStorage.setItem('login-url', substr)
                    message.error(proxymsg)
                    this.downloadErrorFile('签入操作失败', res, proxymsg)
                    this.setState({
                      loading: false
                    })
                  }
                },
                err => {
                  message.error(err)
                  this.setState({
                    loading: false
                  })
                }
              )
          }
        )
      }

      // 热转
      if (isSanfang) {
        this.setState(
          {
            isSanfang: false
          },
          () => {
            this.doShowSanFangProcess(modalFormResult)
          }
        )
        document.getElementById('root').style.height = 'auto'
      }
    }
  }

  skillsReset = (
    workSkillType,
    type,
    seatType,
    txtPhoneNum,
    txtLabourNo,
    password
  ) => {
    const { userInfo } = this.props
    const that = this
    useApi
      .resetskill({
        agentid: userInfo.userNum, // userNum 座席工号
        autoflag: false,
        skillid: `${workSkillType};18`,
        phonelinkage: 1
      })
      .then(
        res => {
          const { data } = res
          const { code, data: result } = data
          if (code === 200) {
            const { retcode, message: msg } = JSON.parse(result)
            if (retcode === '0') {
              useApi.cancelSaybusy({
                agentid: userInfo.userNum
              })
              that.handleLoopEvent() // 唤起座席更新guid
              that.autoGraphJoin(seatType, txtPhoneNum, txtLabourNo, password)
              that.setState({
                isSignIn: false,
                isShowSignIn: false,
                loading: false,
                signInto: type
              })
              store.dispatch({
                type: SET_WAIHU,
                payload: {
                  isWaihuMeta: 0
                }
              })
            } else {
              that.downloadErrorFile('重置技能失败', res, msg)
              that.setState({
                isSignIn: true,
                loading: false
              });
              that.signOut();
            }
          } else {
            that.downloadErrorFile(
              '重置技能失败',
              res,
              '签入技能失败，接口服务异常'
            )
            that.setState({
              isSignIn: true
            });
            that.signOut();
          }
        },
        err => {
          message.err(err)
        }
      )
  }

  ResetSkillExFn = (
    workSkillType,
    type,
    seatType,
    txtPhoneNum,
    txtLabourNo,
    password
  ) => {
    const workSkillTypeX = workSkillType.indexOf('_')
    const exitDouHaoState = workSkillType.indexOf(',')
    let workSkillTypeNew = workSkillType
    if (workSkillTypeX > 0) {
      workSkillTypeNew = workSkillType.replace(/_/g, ',')
    }
    if (exitDouHaoState === -1 && workSkillTypeX === -1) {
      // 不存在逗号 调用之前的初始化方法
      if (workSkillType) {
        this.skillsReset(
          workSkillType,
          type,
          seatType,
          txtPhoneNum,
          txtLabourNo,
          password
        )
      }
    } else {
      const workSkillids = workSkillTypeNew.replace(/,/g, ';')
      this.skillsReset(
        workSkillids,
        type,
        seatType,
        txtPhoneNum,
        txtLabourNo,
        password
      )
    }
  }

  //  签入操作留痕--坐席助手 签入成功后会自动签入扩展坐席TTF
  autoGraphJoin = (seatType, txtPhoneNum, txtLabourNo) => {
    useApi.getUserInteract(txtLabourNo).then(suc => {
      const { userInfo } = this.props
      const { userNum } = userInfo
      const { data: dataResult } = suc
      const {
        code,
        data: { isHelperInteract },
        timestamp
      } = dataResult
      let isDoWsObj = {}
      if (code === 200 && isHelperInteract && isHelperInteract === '1') {
        // 是否可以进行ws连接
        // const timestamp = moment().valueOf()
        isDoWsObj = {
          isTime: timestamp,
          txtPhoneNumVal: txtPhoneNum, // 坐席电话号
          isWebsocket: true
        }
      } else {
        message.error('坐席无转写权限')
        // 不进行ws连接
        isDoWsObj = {
          isWebsocket: false
        }
      }
      this.setState(
        {
          isSignIn: false,
          isShowSignIn: false,
          ...isDoWsObj
        },
        () => {
          try {
            // 签入操作
            useApi.loginLogSignIn()
          } catch (e) {
            console.log(e)
          }
        }
      )
      // Phone.workNo  质检坐席16888  测试202
      if (userNum === 202 || userNum === '202') {
        this.setState({
          isListen: true
        })
      }
    })
  }

  // 实际执行拨打电话的操作 呼叫 示闲时
  doDailProcess = modalFormResult => {
    const { phoneNumber, isInnerOrOuter } = modalFormResult
    const { userInfo } = this.props
    if (isInnerOrOuter === 1) {
      // 表示内呼操作
      useApi
        .callinner({
          agentid: userInfo.userNum,
          called: phoneNumber
        })
        .then(
          res => {
            const { code, data } = res.data
            if (code === 200) {
              const { retcode, message: info } = JSON.parse(data)
              if (retcode !== '0') {
                this.downloadErrorFile('内部呼叫失败', res, info)
              }
            }
          },
          err => {
            console.log(err, '内呼呼叫失败')
          }
        )
    } else {
      // 外呼操作
      useApi
        .callout({
          agentid: userInfo.userNum,
          caller: 12345,
          called: phoneNumber
        })
        .then(
          res => {
            const { code, data } = res.data
            if (code === 200) {
              const { retcode, message: info } = JSON.parse(data)
              if (retcode !== '0') {
                this.downloadErrorFile('外部呼叫失败', res, info)
              } else {
                this.setState({
                  isCallingBefore: true
                })
              }
            }
          },
          err => {
            console.error(`外部呼叫失败，失败原因为---${err}`)
          }
        )
    }
    this.setState({
      // isCallOutValue: 1
    })
    store.dispatch({
      type: SET_WAIHU,
      payload: {
        isWaihuMeta: 1
      }
    })
  }

  doSwitchOverTransfer = async () => {
    const { isphoneNumber } = this.state
    const { userInfo } = this.props
    const phoneNumber = Number(isphoneNumber) // 0429增加转接前是否空闲判断
    if (!phoneNumber) return
    const res = await useApi.agentbyworkno({
      agentid: userInfo.userNum,
      workNo: phoneNumber
    })
    const { data } = res.data
    const { retcode, result } = JSON.parse(data)
    if (retcode === '0') {
      const { status } = result
      if (status === '4' || status === 4) {
        useApi
          .transferCall({
            agentid: userInfo.userNum,
            devicetype: 2,
            address: phoneNumber,
            mode: 2
          })
          .then(ress => {
            setTimeout(() => {
              useApi
                .agentbyworkno({
                  agentid: userInfo.userNum,
                  workNo: phoneNumber
                })
                .then(response => {
                  const { data: d } = response.data
                  const { result: rt, message: msg } = JSON.parse(d)
                  const { status: s } = rt
                  if (s === '3' || s === 3) {
                    message.error('坐席状态异常，转接失败')
                    this.showPhoneBarModalTransfer()
                    this.downloadErrorFile('座席状态异常，转接失败', ress, msg)
                  }
                })
            }, 3000)

            this.setState({
              isOnlineSwitching: false,
              isOnlineSwitchingSearch: false
            })
          })
      } else {
        message.error('坐席状态异常，转接失败')
        // 转接失败后重新打开窗口获取最新坐席状态
        this.setState({
          isOnlineSwitching: false,
          isOnlineSwitchingSearch: false
        })
        this.showPhoneBarModalTransfer()
      }
    }
  }

  // 监听通话  模态框点击onOk的处理事件
  doSwitchOverTransferNew = async () => {
    const { isphoneNumber } = this.state
    const { userInfo } = this.props
    const phoneNumber = Number(isphoneNumber)
    if (!phoneNumber) return
    const agentRes = await useApi.agentbyworkno({
      agentid: userInfo.userNum,
      workNo: phoneNumber
    })
    const { data } = agentRes.data
    const { retcode, result } = JSON.parse(data)
    if (retcode === '0') {
      const { status } = result
      if (status === 7 || status === '7') {
        const response = await useApi.addsupervise({
          agentid: userInfo.userNum,
          workNo: phoneNumber
        })
        const { code, data: watchdata } = response.data
        if (code === 200) {
          const { retcode: watchCode } = JSON.parse(watchdata)
          if (watchCode === '0') {
            message.success(`监听${phoneNumber}通话成功！`)
            // 监听成功后显示挂机按钮 挂机按钮用于退出监听通话
            this.setState({
              isCallingBefore: true,
              isShowListenBox: false,
              isListen: false
            })
          } else {
            message.error('坐席状态异常，监听失败')
            // 转接失败后重新打开窗口获取最新坐席状态
            this.setState({
              isShowListenBox: false
            })
            this.handleListen()
          }
        }
      } else {
        message.error('坐席状态异常，监听失败')
        // 转接失败后重新打开窗口获取最新坐席状态
        this.setState({
          isShowListenBox: false
        })
        this.handleListen()
      }
    }
  }

  // 保持通话  模态框点击onOk的处理事件
  keepCallOK = async () => {
    const { isphoneNumber } = this.state
    const { userInfo } = this.props
    const phoneNumber = Number(isphoneNumber)
    if (!phoneNumber) return
    const agentRes = useApi.agentbyworkno({
      agentid: userInfo.userNum,
      workNo: phoneNumber
    })
    const { data } = agentRes.data
    const { retcode, result } = JSON.parse(data)
    if (retcode === '0') {
      const { status } = result
      if (status === '4' || status === 4) {
        const res = await useApi.callinner({
          agentid: userInfo.userNum,
          called: phoneNumber
        })
        const { data: d } = res.data
        const { retcode: ret, message: msg, result: rt } = JSON.parse(d)
        if (ret === '0') {
          message.success(`通话成功！`) // 监听成功后显示挂机按钮 挂机按钮用于退出监听通话
          this.setState({
            isShowKeepCall: false,
            callidHelp: rt
          })
        } else {
          message.error(msg) // 转接失败后重新打开窗口获取最新坐席状态
          this.QueryBySkillQueuekeep()
          this.setState({
            isShowKeepCall: false
          })
        }
      } else {
        message.error('坐席状态异常，通话失败') // 转接失败后重新打开窗口获取最新坐席状态
        this.QueryBySkillQueuekeep()
        this.setState({
          isShowKeepCall: true
        })
      }
    }
  }

  // 保持通话 模态框 点击onCancel的处理事件
  closeKeepCall = () => {
    this.setState({
      isShowKeepCall: false,
      giiValue: ''
    })
  }

  // 保持通话 输入框后的  点击 呼叫 的处理
  ListhenGiiKeepCall = async () => {
    const { giiValue } = this.state
    const { userInfo } = this.props
    // 表示内呼操作
    const res = await useApi.callinner({
      agentid: userInfo.userNum,
      called: giiValue
    })
    const { data } = res.data
    const { retcode, message: msg, result } = JSON.parse(data)
    if (retcode === '0') {
      message.success(`呼叫${giiValue}成功`)
      this.setState({
        isShowKeepCall: false,
        callidHelp: result
      })
    } else {
      message.error(msg)
      this.QueryBySkillQueuekeep()
      this.setState({
        isShowKeepCall: true
      })
      this.downloadErrorFile('内呼操作失败', res, msg)
    }
  }

  keepcallView = () => {
    this.QueryBySkillQueuekeep()
    this.setState({
      isShowKeepCall: true
    })
  }

  // 实际三方操作
  doShowCircleProcess = async modalFormResult => {
    const { userInfo } = this.props
    const that = this
    useApi.autoenteridle({
      // 座席进入工作状态
      agentid: userInfo.userNum,
      flag: false
    })
    // 三方操作分两块业务逻辑(三方成功后 座席(第一座席)通话不会挂断  三方通话)
    const { phoneNumber, isInnerOrOuter } = modalFormResult
    // const number = phoneNumber.replace(/[^0-9]/ig, '');
    // eslint-disable-next-line no-shadow
    const number =
      phoneNumber.indexOf(' ') === -1
        ? phoneNumber
        : `9${phoneNumber.split(' ')[1]}`
    if (isInnerOrOuter === 2) {
      // 内部三方业务逻辑(拨打另一座席号码(第二座席) 第一座席通话不挂断 三方通话)
      useApi
        .innerHelp({
          agentid: userInfo.userNum,
          dstaddress: number,
          mode: 2
        })
        .then(res => {
          const { code, data, msg: proxymsg } = res.data
          if (code === 200) {
            const { retcode, message: msg } = JSON.parse(data)
            if (retcode === '0') {
              message.success('操作成功')
            } else {
              message.error(`三方内部呼叫失败，失败原因：${msg}`)
              this.downloadErrorFile('三方内部呼叫失败', res, msg)
            }
          } else {
            message.error(`三方内部呼叫失败，失败原因：${proxymsg}`)
            this.downloadErrorFile('三方内部呼叫失败', res, proxymsg)
          }
        })
    } else if (isInnerOrOuter === 1) {
      // 外部三方业务逻辑(拨打另一外部号码(处理纠纷 相关部门) 第一座席通话不挂断 三方通话)
      useApi
        .transferCall({
          agentid: userInfo.userNum,
          devicetype: 5,
          address: number,
          mode: 4,
          holdflag: false // 是否保持呼叫 不保持一方处于忙音状态
        })
        .then(
          res => {
            const { code, data, msg: proxymsg } = res.data
            if (code === 200) {
              const { retcode, message: msg } = JSON.parse(data)
              if (retcode === '0') {
                message.success('三方外部呼叫成功')
              } else {
                // 生成日志
                this.downloadErrorFile('三方外部呼叫出错', res, msg)
              }
            } else {
              this.downloadErrorFile('三方外部呼叫出错', res, proxymsg)
            }
          },
          err => {
            console.log(err, '三方外部呼叫出错')
          }
        )
    } else if (isInnerOrOuter === 3) {
      useApi
        .transferCall({
          agentid: userInfo.userNum,
          devicetype: 5,
          address: number,
          mode: 2,
          holdflag: false // 是否保持呼叫 不保持一方处于忙音状态
        })
        .then(
          res => {
            const { code, data, msg: proxymsg } = res.data
            if (code === 200) {
              const { retcode, message: msg } = JSON.parse(data)
              if (retcode === '0') {
                message.success('三方外部呼叫成功')
                that.setState({
                  markType: true
                })
              } else {
                // 生成日志
                this.downloadErrorFile('三方外部呼叫出错', res, msg)
              }
            } else {
              this.downloadErrorFile('三方外部呼叫出错', res, proxymsg)
            }
          },
          err => {
            console.log(err, '三方外部呼叫出错')
          }
        )
    }
  }

  // 实际休息操作
  doShowRestProcess = modalFormResult => {
    const { restTime } = modalFormResult
    const { userInfo } = this.props
    const resetSeconds = restTime * 60
    // 座席调用此函数请求休息
    useApi
      .requestRest({
        agentid: userInfo.userNum,
        time: resetSeconds,
        restcause: 0
      })
      .then(
        res => {
          const { data } = res.data
          const { retcode, message: msg } = JSON.parse(data)
          if (retcode === '0') {
            message.success(`休息成功，休息时间为：${restTime}分钟`)
            this.setState({
              isRest: false,
              isShowRest: false,
              loading: false
            })
          } else {
            this.setState({
              loading: false
            })
            message.error(msg)
            this.downloadErrorFile('休息操作失败', res, msg)
          }
        },
        () => {
          this.setState({
            loading: false
          })
        }
      )
  }

  // 显示Modal标题
  getModalTitle = () => {
    const {
      isShowSignIn,
      isShowDail,
      isShowCircle, // 三方
      isShowRest,
      isSanfang
    } = this.state
    let result = null
    if (isShowSignIn) {
      result = <span>坐席签入</span>
    }
    if (isShowDail) {
      result = <span>呼叫</span>
    }
    if (isShowCircle) {
      result = <span>三方转接</span>
    }
    if (isShowRest) {
      result = <span>我要休息</span>
    }
    if (isSanfang) {
      result = <span>热转三方</span>
    }
    return result
  }

  // 根据不同类型，弹出不同的Modal组件
  getFormControl = () => {
    const {
      isShowSignIn, // 签入
      isShowDail, // 拨打电话
      isShowCircle, // 三方
      isShowRest, // 休息
      isWaihuCallPhone, // 回访外呼默认回填号码
      dataFalge,
      isSanfang
    } = this.state
    let result = null
    // 签入
    if (isShowSignIn) {
      result = (
        <SignInControl
          wrappedComponentRef={frm => {
            this.modalFormControl = frm
          }}
        />
      )
    }
    // 三方和拨打电话操作
    if (isShowDail) {
      result = (
        <DailPhone
          wrappedComponentRef={frm => {
            this.modalFormControl = frm
          }}
          dataFalge={dataFalge}
          isWaihuCallPhone={isWaihuCallPhone}
        />
      )
    }
    if (isShowCircle) {
      result = (
        <CircleComponent
          wrappedComponentRef={frm => {
            this.modalFormControl = frm
          }}
        />
      )
    }
    // 休息
    if (isShowRest) {
      result = (
        <RestControl
          wrappedComponentRef={frm => {
            this.modalFormControl = frm
          }}
        />
      )
    }

    // 三方
    if (isSanfang) {
      result = (
        <SanFang
          wrappedComponentRef={frm => {
            this.modalFormControl = frm
          }}
        />
      )
    }
    return result
  }

  // flag取值意义: 0表示三方  1表示转接  2表示拨打  4表示外呼操作  5表示休息操作
  // dataFalge:  区分外呼和呼叫
  showPhoneBarModal = (e, flag) => {
    const { userInfo } = this.props
    // e.preventDefault()
    const { isRest } = this.state
    let result = null
    switch (flag) {
      case 0:
        result = {
          isShowCircle: true
        }
        document.getElementById('root').style.height = '100vh'
        break
      case 1:
        result = {
          isShowSwitchOver: true
        }
        break
      case 2:
        result = {
          isShowDail: true
        }
        break
      case 5:
        if (!isRest) {
          // 取消休息
          useApi
            .cancelrest({
              agentid: userInfo.userNum
            })
            .then(res => {
              const { data } = res.data
              const { retcode, message: msg } = JSON.parse(data)
              if (retcode === '0') {
                message.success('成功取消休息')
                this.setState({
                  isRest: true
                })
              } else {
                message.error(msg)
                this.downloadErrorFile('取消休息操作失败', res, msg)
              }
            })
        } else {
          result = {
            isShowRest: true
          }
        }
        break
      default:
        break
    }

    if (result) {
      this.setState({
        ...result
      })
    }
  }

  // 表示签入操作
  singinPhone = () => {
    const { isSignIn } = this.state
    // 待签入操作
    if (isSignIn) {
      this.setState({
        isShowSignIn: true
      })
    } else {
      // 签出操作
      this.signOut()
    }
  }

  signOut = (str) => {
    const guid = CookieUtils.getJSON('Guid')
    const { userInfo } = this.props
    this.setState({
      signInto: -1,
      count: 0
    })
    if (!guid) return
    if (str) {
      message.error(str);
    }
    useApi
      .logout({
        agentid: userInfo.userNum
      })
      .then(
        res => {
          const { code, data } = res.data
          if (code === 200) {
            const { retcode } = JSON.parse(data)
            if (retcode === '0') {
              message.success('签出成功')
              store.dispatch({
                type: SET_WAIHU,
                payload: {
                  isWaihuMeta: 0
                }
              })
              // 签出时 清空guid
              CookieUtils.set('Guid', '')
              clearInterval(this.updateGuidInterval)
              this.setState(
                {
                  isSignIn: true,
                  isStillWork: false, // 是否正在工作
                  idleTime: 240, // 工作时长读秒
                  callLastTime: 0, // 通话持续时间
                  isCalling: false, // 是否正在通话
                  // isCallOutValue: 0, // 是否外呼操作，外呼操作为1， 来电操作为0， 默认来电
                  isKeepHangPhone: true, // 是否保持
                  isMute: true, // 是否静音
                  isShowRest: false, // 是否显示休息
                  isWorking: true, // 显示为 工作态还是休息态
                  isRest: true, // 显示为 休息还是取消休息
                  isShowCircle: false, // 是否显示三方操作
                  isShowBusy: false, // 是否显示示忙操作
                  isShowSignIn: false, // 是否显示签入modal
                  isShowDail: false, // 是否拨打电话(包含内呼和外呼)
                  isWorState: false,
                  isCallingBefore: false,
                  isSayFree: true,
                  isCallSuccess: false,
                  isListen: false,
                  isWebsocket: false // 坐席助手
                },
                () => {
                  //  签出操作
                  useApi.loginLogSignOut()
                }
              )

              if (this.getIntervalTime) {
                clearInterval(this.getIntervalTime)
              }
              if (this.getIdleTime) {
                clearInterval(this.getIdleTime)
              }
              if (this.timeoutId) {
                clearTimeout(this.timeoutId)
              }

              if (localStorage.getItem('setFn')) {
                clearInterval(localStorage.getItem('setFn'))
              }
            } else {
              message.error(`签出失败`)
              CookieUtils.set('Guid', '')
            }
          } else {
            CookieUtils.set('Guid', '')
          }
        },
        err => {
          message.error(`签出失败,失败原因为---${err}`);
          CookieUtils.set('Guid', '')
        }
      )
  }

  // 切换工作态
  toggleWorkingStatus = async () => {
    // 假若示忙和退出工作态同时存在的情况下 仅更改状态不做取消工作态
    const { isShowBusy } = this.state;
    const { userInfo } = this.props;
    if (!isShowBusy) {
      this.toggleStatus();
    } else {
      clearInterval(this.getIdleTime)
      // 关闭6分钟工作态
      clearTimeout(this.timeoutId)
      this.setState({
        idleTime: 240,
        isStillWork: false,
        isWorState: false,
        isWorking: true
      });
      const result = await useApi.cancelwork({ agentid: userInfo.userNum });
      const { data: d } = result.data
      const { retcode: ret, message: workmsg } = JSON.parse(d);
      if (ret === '0') {
        // 当前状态为示忙态 由于电话进入导致状态变化 故需要主动将座席状态设置为忙态。
        useApi
        .saybusyEvent({
          agentid: userInfo.userNum
        })
        .then(res => {
          const { code, data } = res.data
          if (code === 200) {
            const { retcode, message: msg } = JSON.parse(data)
            if (retcode !== '0') {
              message.error('操作失败');
              this.setState({
                isShowBusy: !isShowBusy
              });
              this.downloadErrorFile('示忙操作失败', res, msg)
            }
          }
        })
        .catch(err => {
          console.error(`示忙操作失败：${err}`)
        })
      } else {
        this.downloadErrorFile('示忙操作失败', result, workmsg);
      }
    }
  }

  toggleStatus = () => {
    const { userInfo } = this.props
    useApi
      .cancelwork({
        agentid: userInfo.userNum
      })
      .then(res => {
        const { code, data, msg: proxymsg } = res.data
        if (code === 200) {
          const { retcode, message: msg } = JSON.parse(data)
          if (retcode === '0') {
            clearInterval(this.getIdleTime)
            // 关闭6分钟工作态
            clearTimeout(this.timeoutId)
            this.setState({
              idleTime: 240,
              isStillWork: false,
              isWorState: false,
              isWorking: true
            })
          } else {
            message.error(`操作失败`)
            this.downloadErrorFile('退出工作态失败', res, msg)
          }
        } else {
          message.error(`操作失败`)
          this.downloadErrorFile('退出工作态失败', res, proxymsg)
        }
      })
  }

  // 切换静音状态
  toggleMuteStatus = () => {
    // e.preventDefault();
    const { userInfo } = this.props
    const { isMute } = this.state
    let result = true
    if (isMute) {
      useApi
        .beginmute({
          agentid: userInfo.userNum
        })
        .then(res => {
          const { code, data, msg: proxymsg } = res.data
          if (code === 200) {
            const { retcode, message: msg } = JSON.parse(data)
            if (retcode === '0') {
              message.success('静音成功')
            } else {
              result = false
              message.error('静音失败')
              this.downloadErrorFile('静音操作失败', res, msg)
            }
          } else {
            message.error('静音失败')
            this.downloadErrorFile('静音操作失败', res, proxymsg)
          }
        })
    } else {
      useApi
        .endmute({
          agentid: userInfo.userNum
        })
        .then(res => {
          const { code, data, msg: proxymsg } = res.data
          if (code === 200) {
            const { retcode, message: msg } = JSON.parse(data)
            if (retcode === '0') {
              message.success('取消静音成功')
            } else {
              result = false
              message.error('取消静音失败')
              this.downloadErrorFile('取消静音操作失败', res, msg)
            }
          } else {
            message.error('取消静音失败')
            this.downloadErrorFile('取消静音操作失败', res, proxymsg)
          }
        })
    }
    if (result) {
      this.setState({
        isMute: !isMute
      })
    }
  }

  // 示忙示闲操作
  toggleRestStatus = () => {
    const { isShowBusy, isWorState } = this.state
    const { userInfo } = this.props
    // 是否开始示忙
    if (!isShowBusy) {
      // 话后工作态 点击示忙按钮 直接退出工作态
      if (isWorState) {
        this.toggleStatus();
        // clearInterval(this.getIdleTime)
        // // 关闭6分钟工作态
        // clearTimeout(this.timeoutId)
        // this.setState({
        //   idleTime: 240,
        //   isStillWork: false,
        //   isWorState: false,
        //   isWorking: true
        // })
      }
      // 将座席状态设置为忙态。
      useApi
        .saybusyEvent({
          agentid: userInfo.userNum
        })
        .then(res => {
          const { code, data } = res.data
          if (code === 200) {
            const { retcode, message: msg } = JSON.parse(data)
            if (retcode === '0') {
              this.setState({
                isShowBusy: !isShowBusy
              })
            } else {
              message.error('操作失败')
              this.downloadErrorFile('示忙操作失败', res, msg)
            }
          }
        })
        .catch(err => {
          console.error(`示忙操作失败：${err}`)
        })
    } else {
      // 将座席状态设置为闲态。
      useApi
        .cancelSaybusy({
          agentid: userInfo.userNum
        })
        .then(res => {
          const { code, data, msg: proxymsg } = res.data
          if (code === 200) {
            const { retcode, message: msg } = JSON.parse(data)
            if (retcode === '0') {
              this.setState({
                isShowBusy: !isShowBusy,
                isSayFree: true
              });
              // 取消示忙时  同时退出工作态进入工作中
              this.toggleStatus();
            } else {
              message.error('操作失败')
              this.downloadErrorFile('空闲状态设置失败', res, msg)
            }
          } else {
            message.error('操作失败')
            this.downloadErrorFile('空闲状态设置失败', res, proxymsg)
          }
        })
    }
  }

  doChangeSkill = val => {
    let workSkillTypeNew = val
    if (val === 1 || val === '1') {
      workSkillTypeNew = '1,5,34'
    }
    this.setState(
      {
        defaultValueText: val,
        workSkillType: workSkillTypeNew
      },
      () => {
        const guid = CookieUtils.getJSON('Guid')
        if (guid) {
          this.resetSkillList(workSkillTypeNew)
        }
      }
    )
  }

  handleDefault = workSkillType => {
    const that = this
    const { userInfo } = this.props
    useApi
      .resetskill({
        agentid: userInfo.userNum, // userNum 座席工号
        autoflag: false,
        skillid: `${workSkillType};18`,
        phonelinkage: 1
      })
      .then(
        res => {
          const { data } = res
          const { code, data: result } = data
          if (code === 200) {
            const { retcode, message: msg } = JSON.parse(result)
            if (retcode !== '0') {
              that.downloadErrorFile('技能切换失败', res, msg)
            }
          }
        },
        err => {
          message.err(err)
        }
      )
  }

  resetSkillList = workSkillType => {
    const workSkillTypeX = workSkillType.indexOf('_')
    const exitDouHaoState = workSkillType.indexOf(',')
    let workSkillTypeNew = workSkillType
    const that = this
    if (workSkillTypeX > 0) {
      workSkillTypeNew = workSkillType.replace(/_/g, ',')
    }
    if (exitDouHaoState === -1 && workSkillTypeX === -1) {
      // 不存在逗号 调用之前的初始化方法
      if (workSkillType) {
        that.handleDefault(workSkillType)
      }
    } else {
      const workSkillids = workSkillTypeNew.replace(/,/g, ';')
      that.handleDefault(workSkillids)
    }
  }

  // 一键签入操作
  quickSingin = () => {
    const { userInfo } = this.props
    const localIpList = Object.values(process.localIp)
    // 获取到坐席电脑的ip
    let txtPhoneNumVal = ''
    seatInfoData.map(item => {
      if (localIpList.includes(item.ip)) {
        txtPhoneNumVal = item.openeye
      }
      return item
    })

    const result = {
      seatType: 4, // 坐席类型  PC+PHONE  4
      txtPhoneNum: txtPhoneNumVal, // openeye账号 55551340
      txtLabourNo: userInfo.userNum,
      password: 12345 // 这里密码由华为配置 更改后需要同步下 否则无法签入
    }
    useApi
      .onlineagent({
        agentid: userInfo.userNum, // 座席工号
        agenttype: result.seatType,
        password: '12345', // userInfo.password
        phonenum: result.txtPhoneNum // 软电话码
      })
      .then(
        res => {
          const { code, msg: proxymsg, data } = res.data
          if (code === 200) {
            const { retcode, message: msg, guid } = JSON.parse(data)
            if (retcode === '0') {
              const { url } = JSON.parse(res.config.data)
              let type = -1
              const urlList = [
                'https://172.24.198.103:8043',
                'https://172.24.198.104:8043'
              ]
              urlList.forEach((item, index) => {
                if (url.indexOf(item) !== -1) {
                  type = index
                }
              })
              const { workSkillType } = this.state
              let workSkillTypeNew = workSkillType
              CookieUtils.set('Guid', guid[0])
              if (workSkillTypeNew === '1' || workSkillTypeNew === 1) {
                workSkillTypeNew = '1,5,34'
              }
              if (workSkillTypeNew.indexOf('_') > 0) {
                workSkillTypeNew = workSkillTypeNew.replace(/_/g, ',')
              }
              const exitDouHaoState = workSkillTypeNew.indexOf(',')
              if (exitDouHaoState === -1) {
                useApi.resetskill({
                  agentid: userInfo.userNum, // userNum 座席工号
                  autoflag: false,
                  skillid: workSkillTypeNew,
                  phonelinkage: 1
                })
              } else {
                // 转变坐席技能id字符串为 坐席技能数组
                const workSkillIdArray = workSkillTypeNew.replace(/,/g, ';')
                // 重置技能对列
                useApi.resetskill({
                  agentid: userInfo.userNum, // userNum 座席工号
                  autoflag: false,
                  skillid: workSkillIdArray,
                  phonelinkage: 1
                })
              }
              const { seatType, txtPhoneNum, txtLabourNo, password } = result
              this.handleLoopEvent() // 唤起座席更新guid
              this.autoGraphJoin(seatType, txtPhoneNum, txtLabourNo, password)
              this.setState({
                isSignIn: false,
                isShowSignIn: false,
                loading: false,
                signInto: type
              })
            } else {
              message.error(`一键签入失败，失败原因为：${msg}`)
              // 报错信息下载到本地
              this.downloadErrorFile('一键签入出错', res, msg)
            }
          } else {
            message.error(`一键签入失败，失败原因为：${proxymsg}`)
            // 报错信息下载到本地
            this.downloadErrorFile('一键签入出错', res, proxymsg)
          }
        },
        err => {
          console.error(err)
        }
      )
  }

  // 三方外呼
  sanFangFn = async () => {
    // e.preventDefault()
    const { isKeep, callid } = this.state
    const { userInfo } = this.props
    if (!isKeep) {
      this.setState({
        isSanfang: true
      })
      document.getElementById('root').style.height = '100vh'
    } else {
      // 取消静音 直接连接三方 会取消静音
      const threeConnect = await useApi.threePartyServices({
        agentid: userInfo.userNum,
        callid
      })
      const { data: thdata } = threeConnect.data
      const { retcode: thcode } = JSON.parse(thdata)
      if (thcode === '0') {
        message.success('三方通话成功！')
      } else {
        message.error('三方通话失败！')
      }
      this.setState({
        isKeep: !isKeep
      })
    }
  }

  // 热转确认
  doShowSanFangProcess = modalFormResult => {
    const { isMute } = this.state
    const { userInfo } = this.props
    const { phoneNumber, isInnerOrOuter } = modalFormResult
    const phoneNumberNew =
      phoneNumber.indexOf(' ') === -1
        ? phoneNumber
        : `9${phoneNumber.split(' ')[1]}`
    if (isMute) {
      if (isInnerOrOuter === 2) {
        useApi
          .innerHelp({
            agentid: userInfo.userNum,
            dstaddress: phoneNumber,
            mode: 2
          })
          .then(res => {
            const { code, data, msg: proxymsg } = res.data
            if (code === 200) {
              const { retcode, message: msg } = JSON.parse(data)
              if (retcode === '0') {
                message.success('操作成功')
              } else {
                message.error(`热转内部呼叫失败，失败原因：${msg}`)
                this.downloadErrorFile('热转内部出错', res, msg)
              }
            } else {
              message.error(`热转内部呼叫失败，失败原因：${proxymsg}`)
            }
          })
          .catch(err => {
            console.error(err)
          })
      } else {
        /**
         * 外部热转 外呼第三方接通后 市民侧处于保持呼叫静音状态 座席可以与三方进行通话
         * 保持当前的呼叫-->外呼第三方
         */
        useApi
          .callHold({
            agentid: userInfo.userNum
          })
          .then(res => {
            const { code, data, msg: proxymsg } = res.data
            if (code === 200) {
              const { retcode, message: msg } = JSON.parse(data)
              if (retcode === '0') {
                useApi
                  .callout({
                    agentid: userInfo.userNum,
                    caller: 12345,
                    called: phoneNumberNew
                  })
                  .then(response => {
                    const { data: d } = response.data
                    const {
                      retcode: outcode,
                      message: calloutMsg,
                      result
                    } = JSON.parse(d)
                    if (outcode === '0') {
                      message.success('呼叫成功')
                      this.setState({
                        isKeep: true,
                        hotTransferTime: new Date().getTime(), // 热转计时 超15秒取消当前呼出
                        callidOut: result
                      })
                      this.hotTransferTask = setInterval(() => {
                        const { hotTransferTime, callidOut } = this.state
                        const now = new Date().getTime()
                        // 超过15秒取消当前呼叫
                        if (
                          (now - hotTransferTime) / 1000 > 60 &&
                          callidOut !== ''
                        ) {
                          useApi.dropCallid({
                            agentid: userInfo.userNum,
                            callid: callidOut
                          })
                          clearInterval(this.hotTransferTask)
                        }
                      }, 1000)
                    } else {
                      message.error(calloutMsg)
                      this.downloadErrorFile(
                        '外部热转外呼出错',
                        res,
                        calloutMsg
                      )
                    }
                  })
              } else {
                message.error(msg)
                this.downloadErrorFile('外部热转保持呼叫出错', res, msg)
              }
            } else {
              message.error(proxymsg)
              this.downloadErrorFile('外部热转保持呼叫出错', res, proxymsg)
            }
          })
      }
    } else {
      console.error('请先取消静音！')
    }
  }

  handleChangeTab = val => {
    this.setState({
      tabDefault: val
    })
  }

  render() {
    const {
      isStillWork, // 是否正在工作,工作中则读秒，没有工作不读秒
      idleTime, // 工作计时时间
      callLastTime, // 计时时间
      skillsArr,
      isKeepHangPhone, // 是否呼叫保持
      isMute, // 是否静音
      isWorking, // 显示为工作态还是休息中
      isRest, // 显示为休息还是取消休息
      isSignIn, // 是签入还是签出操作
      isShowDail, // 拨打操作
      isShowSignIn, // 签入操作
      isShowBusy, // 示忙示闲操作
      isShowRest, // 是否显示休息modal
      isShowCircle, // 三方操作
      isCalling, // 是否正在通话
      isOnlineSwitching, // 是否在线转接
      isOnlineSwitchingSearch,
      seleCss, // 在线转接 列表点击选中
      // lastarrB, // 在线转接 企业
      lastarrM, // 在线转接 班长
      englishArr, // 在线转接 英文席
      leaderArr, // 领导
      offArr, // 交管局
      // lastArrCdpf, // 在线转接 残联
      isShowListenBox, // 是否显示监听通话模态框
      allList, // 监听通话 总签入席
      giiValue, // 监听通话 输入框的值
      isNumWindeow, // 是否显示键盘
      inputValue, // 小键盘提交值
      isCallingBefore, // 是否 拨打成功前调用
      defaultValueText,
      findInfoInputValue,
      tabFalse,
      tabTrue,
      tabDefault,
      isWorState,
      searchArr,
      isSayFree,
      isListen,
      isShowKeepCall,
      keepCallList,
      isWebsocket,
      txtPhoneNumVal,
      isTime,
      webSendData,
      isKeep,
      isSanfang,
      loading,
      signInto
    } = this.state
    return (
      <React.Fragment>
        <React.Fragment>
          <div className="jz-nav-bar-wrapper">
            {signInto !== -1 && (
              <span type="">
                {signInto === 0
                  ? 'CTI: 172.24.198.103:8043'
                  : 'CTI: 172.24.198.104:8043'}
              </span>
            )}
            {!isSayFree && (
              <Button
                type="primary"
                icon="redo"
                onClick={debounce(this.toggleRestStatus, 300)}
                style={{
                  backgroundColor: '#1e93ea',
                  borderColor: '#40a9ff',
                  color: '#ffffff'
                }}
              >
                连接异常检查环境后重试
              </Button>
            )}
            {isCalling && <span>{`通话: ${callLastTime}秒`}</span>}
            {isStillWork && !isCalling && <span>{`话后: ${idleTime}秒`}</span>}
            <Select
              style={{ width: 160 }}
              showSearch
              value={defaultValueText}
              placeholder="请选择技能"
              onChange={this.doChangeSkill}
              // disabled={!CookieUtils.getJSON('Guid')}
            >
              {skillsArr.map(item => {
                return (
                  <Option key={item.id} value={item.dictKey}>
                    {item.dictValue}
                  </Option>
                )
              })}
            </Select>

            {/* 签入后, 通话中显示 transIVR */}
            {!isSignIn && isCalling && isKeepHangPhone && (
              <Button type="primary" onClick={this.transIVR}>
                <i className="hang" />
                评价
              </Button>
            )}

            {/* 签入后, 通话中显示 */}
            {!isSignIn && (isCalling || isCallingBefore) && isKeepHangPhone && (
              <Button type="primary" onClick={this.hangOutPhone}>
                <i className="hang" />
                挂机
              </Button>
            )}

            {/* 签入后, 非通话中, 示闲, 工作态, 取保持 显示 */}
            {!isSignIn &&
              (!isCalling || isShowBusy || !isWorking || !isKeepHangPhone) &&
              !isCallingBefore && (
                <Button
                  type="primary"
                  onClick={e => this.showPhoneBarModal(e, 2)}
                >
                  <i className="dial" />
                  呼叫
                </Button>
              )}

            {/* 签入后, 通话中显示 */}
            {!isSignIn && isCalling && (
              <Button type="primary" onClick={this.hangOnPhone}>
                <i className="wait" />
                {isKeepHangPhone ? '求助' : '结束求助'}
              </Button>
            )}

            {!isSignIn && !isKeepHangPhone && (
              <Button type="primary" onClick={this.keepcallView}>
                <i className="wait" />
                求助
              </Button>
            )}

            {/* 签入后, 通话中显示 */}
            {!isSignIn && isCalling && isKeepHangPhone && (
              <Button
                type="primary"
                onClick={e => this.showPhoneBarModal(e, 0)}
              >
                <i className="circle" />
                三方
              </Button>
            )}

            {!isSignIn && isCalling && (
              <Button type="primary" onClick={this.sanFangFn}>
                <i className="circle" />
                {!isKeep ? '热转' : '解除静音'}
              </Button>
            )}
            {/* 签入后, 通话中显示 在线转接 */}
            {!isSignIn && isCalling && isKeepHangPhone && (
              <Button type="primary" onClick={this.showPhoneBarModalTransfer}>
                <i className="circle" />
                转接
              </Button>
            )}

            {/* 监听通话 按钮 */}
            {!isSignIn && isListen && (
              <Button icon="redo" type="primary" onClick={this.handleListen}>
                监听
              </Button>
            )}

            {/* 签入后, 通话中显示 */}
            {!isSignIn && isCalling && isKeepHangPhone && (
              <Button type="primary" onClick={this.toggleMuteStatus}>
                <i className="dial" />
                {isMute ? '静音' : '结束静音'}
              </Button>
            )}

            {/* 签入后, 非通话中显示, 工作态 */}
            {!isSignIn &&
              !isCalling &&
              (!isShowBusy ? (
                <Button
                  type="primary"
                  onClick={debounce(this.toggleRestStatus, 300)}
                >
                  <i className="dial" style={{ color: '#ffffff' }} />
                  示忙
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={debounce(this.toggleRestStatus, 300)}
                  style={{
                    backgroundColor: '#1e93ea',
                    borderColor: '#40a9ff',
                    color: '#ffffff'
                  }}
                >
                  <i className="dial" style={{ color: '#ffffff' }} />
                  示闲
                </Button>
              ))}
            {/* 签入后, 非通话中显示, 示闲的时候显示 */}
            {!isSignIn && (!isCalling || isShowBusy) && isWorState && (
              <Button
                type="primary"
                onClick={this.toggleWorkingStatus}
                style={{
                  backgroundColor: '#1e93ea',
                  borderColor: '#40a9ff',
                  color: '#ffffff'
                }}
              >
                <i className="work-state" style={{ color: '#ffffff' }} />
                {isWorking ? '工作态' : '退出工作态'}
              </Button>
            )}

            {/* 签入后, 非通话中显示, 示闲的时候, 工作态显示 */}
            {!isSignIn &&
              (!isCalling || isShowBusy || !isWorking) &&
              !isWorState && (
                <Button
                  type="primary"
                  onClick={e => this.showPhoneBarModal(e, 5)}
                >
                  <i className="dial" />
                  {isRest ? '休息' : '取消休息'}
                </Button>
              )}

            {/* 签入后, 通话中显示 二次拨号 */}
            {!isSignIn && isCalling && isKeepHangPhone && (
              <Button type="primary" onClick={this.showPhoneBarModalNmu}>
                <i className="transfer" />
                拨号
              </Button>
            )}

            {/* 一键签入 这里无法获取本地电脑ip暂时隐藏 */}
            {/* {!isCalling && isSignIn && (
              <Button type="primary" onClick={debounce(this.quickSingin, 200)}>
                一键签入
              </Button>
            )} */}
            {/* 签入后, 非通话中 签入/签出 显示 */}
            {!isCalling && (
              <Button type="primary" onClick={debounce(this.singinPhone, 200)}>
                <i className="transfer" />
                {isSignIn ? '签入' : '签出'}
              </Button>
            )}

            {/* 签入后, 通话中显示 录音标记 */}
            {!isSignIn && isCalling && (
              <Button type="primary" onClick={this.addSigns}>
                <Icon type="environment" />
              </Button>
            )}
          </div>

          {/* 组合模态框 */}
          <Modal
            title={this.getModalTitle()}
            width={600}
            visible={
              isShowDail ||
              isShowSignIn ||
              isShowRest ||
              isShowCircle ||
              isSanfang // 三方
            }
            destroyOnClose
            onOk={debounce(this.handleOKAndCloseModal, 200)}
            onCancel={this.handleCloseModal}
            wrapClassName='sanfang-modal-wrapper'
          >
            <Spin spinning={loading}>{this.getFormControl()}</Spin>
          </Modal>

          {/* 在线转接 */}
          <Modal
            title="在线转接"
            width={1200}
            visible={isOnlineSwitching}
            onOk={
              tabDefault === '4'
                ? this.handleChangeOver
                : this.doSwitchOverTransfer
            }
            onCancel={this.handleCloseModalTransfer}
          >
            <Form className="jz-modal-flex">
              <Form.Item className="zb-modal">
                {tabDefault !== '4' && (
                  <Row gutter={24}>
                    <Col span={6}>
                      <Input
                        placeholder="请输入"
                        value={findInfoInputValue}
                        onChange={this.changeFindInfoValue}
                      />
                    </Col>
                    <Col span={2}>
                      <Button type="primary" onClick={this.searchNum}>
                        查找
                      </Button>
                    </Col>
                  </Row>
                )}
                <Tabs
                  defaultActiveKey={tabDefault}
                  onChange={this.handleChangeTab}
                >
                  <TabPane
                    tab={<span>班长席</span>}
                    key="1"
                    disabled={lastarrM.length > 0 ? tabFalse : tabTrue}
                  >
                    <Row>
                      {lastarrM.map(item => {
                        return (
                          <Col
                            span={3}
                            className={
                              seleCss === item.gii
                                ? 'selectionBorders'
                                : 'borders'
                            }
                            onClick={() => this.handClickGroup(item.gii)}
                          >
                            <p>
                              <img src={Free} alt="Free" width="35" />
                            </p>
                            <p>{`${item.gii}:${item.name}`}</p>
                            <p>
                              <span className="kx">空闲</span>
                            </p>
                          </Col>
                        )
                      })}
                    </Row>
                  </TabPane>
                  {/* <TabPane
                    tab={<span>企业服务热线</span>}
                    key="2"
                    disabled={lastarrB.length > 0 ? tabFalse : tabTrue}
                  >
                    <Row>
                      {lastarrB.map(item => {
                        return (
                          <Col
                            span={3}
                            className={
                              seleCss === item.gii
                                ? 'selectionBorders'
                                : 'borders'
                            }
                            onClick={() => this.handClickGroup(item.gii)}
                          >
                            <p>
                              <img src={Free} alt="Free" width="35" />
                            </p>
                            <p>{`${item.gii}:${item.name}`}</p>
                            <p>
                              <span className="kx">空闲</span>
                            </p>
                          </Col>
                        )
                      })}
                    </Row>
                  </TabPane> */}
                  <TabPane
                    tab={<span>英文席</span>}
                    key="3"
                    disabled={englishArr.length > 0 ? tabFalse : tabTrue}
                  >
                    <Row>
                      {englishArr.map(item => {
                        return (
                          <Col
                            span={3}
                            className={
                              seleCss === item.gii
                                ? 'selectionBorders'
                                : 'borders'
                            }
                            onClick={() => this.handClickGroup(item.gii)}
                          >
                            <p>
                              <img src={Free} alt="Free" width="35" />
                            </p>
                            <p>{`${item.gii}:${item.name}`}</p>
                            <p>
                              <span className="kx">空闲</span>
                            </p>
                          </Col>
                        )
                      })}
                    </Row>
                  </TabPane>
                  <TabPane
                    tab={<span>残联心理咨询</span>}
                    key="4"
                    // disabled={lastArrCdpf.length > 0 ? tabFalse : tabTrue}
                  >
                    <Row>
                      {/* {lastArrCdpf.map(item => {
                        return (
                          <Col
                            span={3}
                            className={
                              seleCss === item.gii
                                ? 'selectionBorders'
                                : 'borders'
                            }
                            onClick={() => this.handClickGroup(item.gii)}
                          >
                            <p>
                              <img src={Free} alt="Free" width="35" />
                            </p>
                            <p>{`${item.gii}:${item.name}`}</p>
                            <p>
                              <span className="kx">空闲</span>
                            </p>
                          </Col>
                        )
                      })} */}
                      <Button type="primary" onClick={this.handleChangeOver}>
                        请点击转接或直接确认转接
                      </Button>
                    </Row>
                  </TabPane>
                  <TabPane
                    tab={<span>领导接听</span>}
                    key="5"
                    disabled={leaderArr.length > 0 ? tabFalse : tabTrue}
                  >
                    <Row>
                      {leaderArr.map(item => {
                        return (
                          <Col
                            span={3}
                            className={
                              seleCss === item.gii
                                ? 'selectionBorders'
                                : 'borders'
                            }
                            onClick={() => this.handClickGroup(item.gii)}
                          >
                            <p>
                              <img src={Free} alt="Free" width="35" />
                            </p>
                            <p>{`${item.gii}:${item.name}`}</p>
                            <p>
                              <span className="kx">空闲</span>
                            </p>
                          </Col>
                        )
                      })}
                    </Row>
                  </TabPane>
                  <TabPane
                    tab={<span>政法民声热线</span>}
                    key="6"
                    disabled={offArr.length > 0 ? tabFalse : tabTrue}
                  >
                    <Row>
                      {offArr.map(item => {
                        return (
                          <Col
                            span={3}
                            className={
                              seleCss === item.gii
                                ? 'selectionBorders'
                                : 'borders'
                            }
                            onClick={() => this.handClickGroup(item.gii)}
                          >
                            <p>
                              <img src={Free} alt="Free" width="35" />
                            </p>
                            <p>{`${item.gii}:${item.name}`}</p>
                            <p>
                              <span className="kx">空闲</span>
                            </p>
                          </Col>
                        )
                      })}
                    </Row>
                  </TabPane>
                </Tabs>
              </Form.Item>
            </Form>
          </Modal>
          {/* 监听通话 */}
          <Modal
            title="监听通话"
            width={1200}
            visible={isShowListenBox}
            onOk={this.doSwitchOverTransferNew}
            onCancel={this.handleCloseListenBox}
            style={{ overflow: 'hidden' }}
          >
            <Form className="jz-modal-flex" style={{}}>
              <Form.Item className="zb-modal">
                <Row gutter={24}>
                  <Col span={6}>
                    <Input
                      placeholder="请输入"
                      value={giiValue}
                      onChange={this.giiValueChange}
                    />
                  </Col>
                  <Col span={2}>
                    <Button type="primary" onClick={this.ListhenGii}>
                      监听
                    </Button>
                  </Col>
                </Row>
                <Tabs
                  defaultActiveKey={tabDefault}
                  style={{ height: '438px', overflowY: 'scroll' }}
                >
                  <TabPane
                    tab={<span>总签入席</span>}
                    key="1"
                    disabled={lastarrM.length > 0 ? tabFalse : tabTrue}
                  >
                    <Row>
                      {allList.map(item => {
                        return (
                          <Col
                            span={3}
                            className={
                              seleCss === item.gii
                                ? 'selectionBorders'
                                : 'borders'
                            }
                            onClick={() => this.handClickGroup(item.gii)}
                          >
                            <p>
                              <img src={Free} alt="Free" width="35" />
                            </p>
                            <p>{`${item.gii}:${item.name}`}</p>
                            <p>
                              <span className="kx">通话</span>
                            </p>
                          </Col>
                        )
                      })}
                    </Row>
                  </TabPane>
                </Tabs>
              </Form.Item>
            </Form>
          </Modal>
          {/* 保持通话 */}
          <Modal
            title="疑难专席"
            width={1200}
            visible={isShowKeepCall}
            onOk={this.keepCallOK}
            onCancel={this.closeKeepCall}
            style={{ overflow: 'hidden' }}
          >
            <Form className="jz-modal-flex" style={{}}>
              <Form.Item className="zb-modal">
                <Row gutter={24}>
                  <Col span={6}>
                    <Input
                      placeholder="请输入"
                      value={giiValue}
                      onChange={this.giiValueChange}
                    />
                  </Col>
                  <Col span={2}>
                    <Button type="primary" onClick={this.ListhenGiiKeepCall}>
                      呼叫
                    </Button>
                  </Col>
                </Row>
                <Tabs defaultActiveKey={tabDefault}>
                  <TabPane
                    tab={<span>疑难专席</span>}
                    key="1"
                    disabled={lastarrM.length > 0 ? tabFalse : tabTrue}
                  >
                    <Row>
                      {keepCallList.map(item => {
                        return (
                          <Col
                            span={3}
                            className={
                              seleCss === item.gii
                                ? 'selectionBorders'
                                : 'borders'
                            }
                            onClick={() => this.handClickGroup(item.gii)}
                          >
                            <p>
                              <img src={Free} alt="Free" width="35" />
                            </p>
                            <p>{`${item.gii}:${item.name}`}</p>
                            <p>
                              <span className="kx">空闲</span>
                            </p>
                          </Col>
                        )
                      })}
                    </Row>
                  </TabPane>
                </Tabs>
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title="在线转接查询"
            width={1200}
            visible={isOnlineSwitchingSearch}
            onOk={this.doSwitchOverTransfer}
            onCancel={this.handleCloseModalTransferSearch}
          >
            <Form className="jz-modal-flex">
              <Form.Item className="zb-modal">
                <Row>
                  {searchArr.map(item => {
                    return (
                      <Col
                        span={3}
                        className={
                          seleCss === item.gii ? 'selectionBorders' : 'borders'
                        }
                        onClick={() => this.handClickGroup(item.gii)}
                      >
                        <p>
                          <img src={Free} alt="Free" width="35" />
                        </p>
                        <p>{`${item.gii}:${item.name}`}</p>
                        <p>
                          <span className="kx">空闲</span>
                        </p>
                      </Col>
                    )
                  })}
                </Row>
              </Form.Item>
            </Form>
          </Modal>

          {/* 二次拨号 */}
          <Modal
            title="拨号键"
            width={300}
            visible={isNumWindeow}
            onCancel={this.handleCloseModalNum}
            footer={null}
          >
            <Row Row gutter={16}>
              {arrNum.map(item => {
                return (
                  <Col
                    span={8}
                    onClick={() => this.handleBtnNum(item)}
                    key={item}
                  >
                    <p className="numBg">
                      <span>{item}</span>
                    </p>
                  </Col>
                )
              })}
            </Row>
            <Row Row gutter={16}>
              <Col span={16}>
                <Input value={inputValue} onChange={this.changeValue} />
              </Col>
              <Col span={8}>
                <Button
                  type="primary"
                  onClick={this.handleSubmitNum}
                  style={{ marginRight: 12 }}
                >
                  提交
                </Button>
              </Col>
            </Row>
          </Modal>
        </React.Fragment>

        {isWebsocket && (
          <WS
            isTime={isTime}
            txtPhoneNumVal={txtPhoneNumVal}
            isWebsocket={isWebsocket}
            webSendData={webSendData}
            // isSetFn={isSetFn}
          />
        )}
      </React.Fragment>
    )
  }
}

PhoneBar.defaultProps = {
  isDail: false,
  dispatchDailPhone: () => {}
}

PhoneBar.propTypes = {
  isDail: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  isRevisit: PropTypes.string,
  dispatchDailPhone: PropTypes.func // 点击回拨拨出后调用事件，主要用来还原redux - redux
}

export default withRouter(PhoneBar)
