/*
 * @Author: wangyafei
 * @Date: 2018-11-22 14:24:53
 * @Last Modified by: gy
 * @Last Modified time: 2020-07-07 15:41:38
 */
import React from 'react'
import PropTypes from 'prop-types'
// import GlobalFooter from '../../components/GlobalFooter'
import './UserLayout.less'
import logo from '../../images/logo.png'

class UserLayout extends React.PureComponent {
  render() {
    const { children } = this.props
    return (
      <div className="container">
        <div className="content">
          <div className="top">
            <div className="header">
              <img
                alt="logo"
                className="logo"
                src={logo}
                width="100"
                height="64"
              />
              <span className="title">12345市民热线服务</span>
            </div>
          </div>
          {children}
        </div>
        {/* <GlobalFooter className="jz-login-footer" /> */}
      </div>
    )
  }
}

UserLayout.propTypes = {
  children: PropTypes.element.isRequired
}

export default UserLayout
