/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:53:02
 * @Last Modified by: wangyafei
 * @Last Modified time: 2019-11-13 14:13:35
 */
import * as constants from '../constants'

const initialState = {
  workOrderData: {}
}

/**
 * 根据用户输入的手机号获取该手机号对应的历史工单列表
 * @param {*} state
 * @param {*} param1
 */
export default function workOrderAttach(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case constants.GET_HISTORY_BY_PHONE:
      return { ...initialState, workOrderData: payload }
    case constants.USER_LOGGED_OUT:
      return { ...initialState }
    default:
      return state
  }
}
