/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:52:55
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-03-16 17:19:49
 */
import moment from 'moment'
import pako from 'pako';
import { dateFormat } from './constant';

export const regExpFromString = q => {
  let flags = q.replace(/.*\/([gimuy]*)$/, '$1')
  if (flags === q) {
    flags = ''
  }
  const pattern = flags ? q.replace(new RegExp(`^/(.*?)/${flags}$`), '$1') : q
  try {
    return new RegExp(pattern, flags)
  } catch (e) {
    return null
  }
}

export const hasAuthorities = (authorities, authority) => {
  if (Array.isArray(authorities) && authorities.indexOf(authority) !== -1) {
    return true
  }
  return false
}

export const processObjectMomentDate = result => {
  const processResult = result
  const keys = Object.keys(processResult)
  for (let i = 0, len = keys.length; i < len; i += 1) {
    if (
      typeof processResult[keys[i]] === 'object' &&
      processResult[keys[i]] instanceof moment
    ) {
      processResult[keys[i]] = processResult[keys[i]].format(
        'YYYY-MM-DD HH:mm:ss'
      )
    }
  }
  return processResult
}

export const getFormValues = form => {
  let values = {}
  form.validateFields((err, fieldsValue) => {
    if (err) return
    values = {
      ...fieldsValue
    }
    const keys = Object.keys(values)
    for (let i = 0, len = keys.length; i < len; i += 1) {
      const key = keys[i]
      if (Array.isArray(values[key])) {
        const upperKey = key.charAt(0).toUpperCase() + key.slice(1)
        values[`begin${upperKey}`] = values[key][0].format(
          'YYYY-MM-DD HH:mm:ss'
        )
        values[`end${upperKey}`] = values[key][1].format('YYYY-MM-DD HH:mm:ss')
        delete values[key]
      }
      if (typeof values[key] === 'object' && values[key] instanceof moment) {
        values[key] = values[key].format('YYYY-MM-DD HH:mm:ss')
      }
    }
  })
  return values
}

// 将问题分类列表数据转成树形数据
export const buildQuestionTree = (list, flag, meta) => {
  const temp = {}
  const tree = []

  for (let i = 0; i < list.length; i += 1) {
    const item = list[i]
    item.title = item.requestName || item.name
    item.key = item.id
    item.value = meta ? (item.requestName || item.name) : item.id;
    if (flag === 1) {
      // 禁用
      if (item?.revokeFlag) {
        item.disabled = item.revokeFlag === '1'
      }
      // 变色
      if (item?.color) {
        item.color = item.color || '';
      }
    }
    temp[list[i].id] = item
  }

  const tempKeys = Object.keys(temp)
  for (let i = 0; i < tempKeys.length; i += 1) {
    const j = tempKeys[i]
    if (temp[j].parentId !== '0') {
      try {
        if (!temp[temp[j].parentId].children) {
          temp[temp[j].parentId].children = []
        }
        temp[temp[j].parentId].children.push(temp[j])
        // eslint-disable-next-line no-empty
      } catch (err) {}
    } else {
      // tree[temp[j].id] = temp[j]
      tree.push(temp[j])
    }
  }
  return tree
}

// 市中心处理方式列表数据转成树形数据
export const buildQuestionTreeDispatchType = list => {
  const temp = {}
  const tree = []

  for (let i = 0; i < list.length; i += 1) {
    const item = list[i]
    item.title = item.requestName || item.name;
    item.key = item.id;
    item.value = item.value || '';
    temp[list[i].id] = item;
  }

  const tempKeys = Object.keys(temp)
  for (let i = 0; i < tempKeys.length; i += 1) {
    const j = tempKeys[i]
    if (temp[j].parentId !== 0) {
      try {
        if (!temp[temp[j].parentId].children) {
          temp[temp[j].parentId].children = []
        }
        temp[temp[j].parentId].children.push(temp[j])
        // eslint-disable-next-line no-empty
      } catch (err) {}
    } else {
      // tree[temp[j].id] = temp[j]
      tree.push(temp[j])
    }
  }
  return tree
}

// 将承办单位二级表数据转成树形数据
export const buildQuestionTreeUndertake = (list, name) => {
  const temp = {}
  const tree = []

  for (let i = 0; i < list.length; i += 1) {
    const item = list[i]
    item.title = item.requestName || item.name
    item.key = item.id
    item.value = name ? item.name : item.id
    temp[list[i].id] = item
  }

  const tempKeys = Object.keys(temp)
  for (let i = 0; i < tempKeys.length; i += 1) {
    const j = tempKeys[i]
    if (temp[j].parentId !== 'CA6437DB5A18451CAEA74D2FDA63BDA6') {
      try {
        if (!temp[temp[j].parentId]) {
          tree.push(temp[j])
        } else {
          if (!temp[temp[j].parentId].children) {
            temp[temp[j].parentId].children = []
          }
          temp[temp[j].parentId].children.push(temp[j])
        }
        // eslint-disable-next-line no-empty
      } catch (err) {}
    } else {
      // tree[temp[j].id] = temp[j]
      tree.push(temp[j])
    }
  }
  return tree
}

// 时间改变---切换 当日、 本周、 本月、 自定义
export const doChangeDispatchTime = (e, formRef, startField, endField) => {
  const val = e.target.value
  let qBeginDate = null
  let qEndDate = null
  switch (val) {
    case 1: // 当日
      qBeginDate = moment().startOf('day')
      qEndDate = moment().endOf('day')
      break
    case 2: // 本周
      qBeginDate = moment().startOf('week')
      qEndDate = moment().endOf('week')
      break
    case 3: // 本月
      qBeginDate = moment().startOf('month')
      qEndDate = moment().endOf('month')
      break
    default:
      break
  }

  formRef.setFieldsValue({
    [startField]: qBeginDate,
    [endField]: qEndDate
  })
}

// 转换树形
export const buildTree = (list, name) => {
  const temp = {}
  const tree = []

  for (let i = 0; i < list.length; i += 1) {
    const item = list[i]
    temp[list[i].id] = item
    item.title =
      item.roleName || item.menuName || item.officeName || item.areaName || ''
    item.value = name ? item.areaName : item.id
  }
  const tempKeys = Object.keys(temp)
  for (let i = 0; i < tempKeys.length; i += 1) {
    const j = tempKeys[i]
    if (tempKeys.indexOf(temp[j].parentId) !== -1) {
      try {
        if (!temp[temp[j].parentId].children) {
          temp[temp[j].parentId].children = []
        }
        temp[temp[j].parentId].children.push(temp[j])
      } catch (err) {
        console.error(err)
      }
    } else {
      tree.push(temp[j])
    }
  }
  return tree
}

export const exceedsCharacters = (rule, value, callback, num) => {
  if (value && value.length > num) {
    const characters = Math.abs(num - value.length)
    callback(`您超出了${characters}个字符`)
  } else {
    callback()
  }
}

// 时间校验
export const setDateRules = (form, vals, dateFields) => {
  let isRules
  dateFields.forEach(({ beginField, endField, errorMessage }) => {
    if (vals?.[beginField] && !vals?.[endField]) {
      form.setFields({
        [endField]: {
          errors: [new Error(`请选择${errorMessage}结束时间`)]
        }
      })
      isRules = true
    }
    if (!vals?.[beginField] && vals?.[endField]) {
      form.setFields({
        [beginField]: {
          errors: [new Error(`请选择${errorMessage}开始时间`)]
        }
      })
      isRules = true
    }
    if (vals?.[beginField] && vals?.[endField]) {
      form.setFields({
        [endField]: {
          value: moment(vals[endField]),
          errors: null
        },
        [beginField]: {
          value: moment(vals[beginField]),
          errors: null
        }
      })
      isRules = false
    }
  })
  return isRules
}

function arrayBufferToString(buffer) {
  const bufView = new Uint16Array(buffer)
  const { length } = bufView
  let result = ''
  let addition = Math.pow(2, 16) - 1
  for (let i = 0; i < length; i += addition) {
    if (i + addition > length) {
      addition = length - i
    }
    result += String.fromCharCode.apply(null, bufView.subarray(i, i + addition))
  }
  return result
}

// (function checkWindowAtobCompatibility() {
//   if ('atob' in window) {
//     return;
//   }

//   // https://github.com/davidchambers/Base64.js
  // const digits =
  //   'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
//   // eslint-disable-next-line func-names
//   window.atob = function (input) {
//     // eslint-disable-next-line no-param-reassign
//     input = input.replace(/=+$/, '');
//     if (input.length % 4 === 1) {
//       throw new Error('bad atob input');
//     }
//     // eslint-disable-next-line no-cond-assign
//     for (
//       // eslint-disable-next-line vars-on-top, no-var
//       var bc = 0, bs, buffer, idx = 0, output = '';
//       // eslint-disable-next-line no-plusplus
//       buffer = input.charAt(idx++);
//       // eslint-disable-next-line no-bitwise, no-cond-assign
//       ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
//         // eslint-disable-next-line no-bitwise, no-plusplus
//         bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
//     ) {
//       buffer = digits.indexOf(buffer);
//     }
//     // eslint-disable-next-line block-scoped-var
//     return output;
//   };
// })();

// function atob(input) {
//   const digits =
//     'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
//   // eslint-disable-next-line no-param-reassign
//   input = input.replace(/=+$/, '');
//   if (input.length % 4 === 1) {
//     throw new Error('bad atob input');
//   }
//   // eslint-disable-next-line no-cond-assign
//   for (
//     // eslint-disable-next-line vars-on-top, no-var
//     var bc = 0, bs, buffer, idx = 0, output = '';
//     // eslint-disable-next-line no-plusplus
//     buffer = input.charAt(idx++);
//     // eslint-disable-next-line no-bitwise, no-cond-assign
//     ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
//       // eslint-disable-next-line no-bitwise, no-plusplus
//       bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
//   ) {
//     buffer = digits.indexOf(buffer);
//   }
//   // eslint-disable-next-line block-scoped-var
//   return output;
// };

// 解压
export function unzipData(b64Data) {
  let strData = atob(b64Data)
  // 将二进制字符串转换为字符数数组
  const charData = strData.split('').map(function(x) {
    return x.charCodeAt(0)
  })
  // 将数字数组转换为字节数组
  const binData = new Uint8Array(charData)
  // // unzip
  const data = pako.inflate(binData)
  // 将gunzipped byteArray转换回ascii字符串:
  // eslint-disable-next-line no-eval
  strData = eval(`(${arrayBufferToString(new Uint16Array(data))})`)
  return strData[0]
}

export const transformFormat = (val) => {
  if (!val) return '';
  if (typeof val !== 'string') return '';
  let str = val;
  // 将换行符替换为逗号
  str = str.replace(/\n/g, ',');
  // 使用正则表达式去除多余的连续逗号
  str = str.replace(/,+/, ',');
  // 确保字符串两端没有逗号
  str = str.replace(/^,|,$/g, '');
  return str;
}
/*
 * 日期快捷切换-组件
 * 日期范围
*/
export const ranges = {
  '当日': [
    moment(moment().startOf('day').format(dateFormat)),
    moment(moment().endOf('day').format(dateFormat))
  ],
  '当周': [
    moment(moment().startOf('week').format(dateFormat)),
    moment(moment().endOf('week').format(dateFormat))
  ],
  '当月': [
    moment(moment().startOf('month').format(dateFormat)),
    moment(moment().endOf('month').format(dateFormat))
  ],
  '考核月': [
    moment(moment().date() < 19 ?
      moment().startOf('month').add(-1, 'month').format('YYYY-MM-19 00:00:00')
      : moment().startOf('month').format('YYYY-MM-19 00:00:00')),
    moment(moment().date() < 19 ?
      moment().startOf('month').format('YYYY-MM-18 23:59:59')
      : moment().startOf('month').add(1, 'month').format('YYYY-MM-18 23:59:59'))
  ],
  '上一个考核月': [
    moment(moment().date() < 19 ?
      moment().startOf('month').add(-2, 'month').format('YYYY-MM-19 00:00:00')
      : moment().startOf('month').add(-1, 'month').format('YYYY-MM-19 00:00:00')),
    moment(moment().date() < 19 ?
      moment().startOf('month').add(-1, 'month').format('YYYY-MM-18 23:59:59')
      : moment().startOf('month').format('YYYY-MM-18 23:59:59'))
  ]
}

/**
 * 处理日期数据，便于向后台传递数据
 * @param {*} vals 
 * @returns 
 */
export const processMoment = vals => {
  const values = vals
  const keys = Object.keys(values)
  for (let i = 0, len = keys.length; i < len; i += 1) {
    const key = keys[i]
    if (typeof values[key] === 'object' && values[key] instanceof moment) {
      values[key] = values[key].format('YYYY-MM-DD HH:mm:ss')
    }
  }
  return values
}

/**
 * es参数转换
 * @param {*} json 
 * @returns 
 */
export function buildUrlParam(json) {
  return encodeURI(
    Object.keys(json)
      .map((v) => {
        return v + '=' + json[v];
      })
      .join('&'),
  );
}

/**
 * 获取差异天数
 * @param {*} date1 
 * @param {*} date2 
 * @returns 
 */
export const getDaysDifference = (date1, date2) => {
  // Convert both dates to milliseconds
  const time1 = Date.parse(date1);
  const time2 = Date.parse(date2);

  // Calculate the difference in milliseconds
  const diff = Math.abs(time1 - time2);

  // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
  return Math.ceil(diff / (1000 * 60 * 60 * 24));
}
