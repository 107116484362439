//moment使用日期格式
export const dateFormat = "YYYY-MM-DD HH:mm:ss";

/**
 * 请求转换
 */
export const transformRequest = [
    dataTra => {
        let ret = '';
        const keys = Object.keys(dataTra)
        for (let i = 0; i < keys.length; i += 1) {
            ret += `${encodeURIComponent(keys[i])}=${encodeURIComponent(
            dataTra[keys[i]],
            )}&`;
        }
        return ret;
    },
];