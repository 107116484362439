/*
 * @Author: wangyafei
 * @Date: 2019-10-25 10:21:17
 * @Last Modified by: gy
 * @Last Modified time: 2020-04-24 10:55:01
 */
import {
  USER_INFO_REFRESH,
  USER_LOGGED_OUT,
  USER_LOGGED_IN,
  USER_OUT
} from '../constants'

/**
 * 用户信息
 * @param {*} payload
 */
export const dispatchRefreshUserInfo = payload => {
  return dispatch =>
    dispatch({
      type: USER_INFO_REFRESH,
      payload
    })
}

export const dispatchLogout = payload => {
  return dispatch =>
    dispatch({
      type: USER_LOGGED_OUT,
      payload
    })
}

export const dispatchLoginin = payload => {
  return dispatch =>
    dispatch({
      type: USER_LOGGED_IN,
      payload
    })
}

// 全局
export const userLogin = payload => {
  return dispatch =>
    dispatch({
      type: USER_OUT,
      payload
    })
}
