/*
 * @Author: wangyafei
 * @Description: 转接操作
 * @Date: 2018-11-22 16:21:47
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-01-10 18:25:59
 */
import React, { Component } from 'react'
import { Row, Col, Form, Input, Radio } from 'antd'

class Switching extends Component {
  state = {
    selectedValue: 2
  }

  getItemsValues = () => {
    /* eslint-disable */
    const {
      form: { validateFieldsAndScroll }
    } = this.props
    // form表单结果
    let result
    validateFieldsAndScroll(
      {
        scroll: { offsetTop: 100 }
      },
      (err, values) => {
        if (!err) {
          result = {
            ...values
          }
        } else {
          result = -1
        }
      }
    )
    return result
  }

  doChangeVal = e => {
    const val = e.target.value
    this.setState({
      selectedValue: val
    })
    /* eslint-disable-next-line */
    const { form } = this.props
    if (val === 2) {
      form.setFieldsValue({
        phoneNumber: 9
      })
    } else {
      form.setFieldsValue({
        phoneNumber: ''
      })
    }
  }

  render() {
    /* eslint-disable-next-line */
    const { form } = this.props
    const { getFieldDecorator } = form
    const { selectedValue } = this.state
    return (
      <Form className="jz-modal-flex">
        <Row gutter={16}>
          <Col span={2} />
          <Col span={20}>
            <Form.Item
              label={<span className="justify">请选择呼叫类型</span>}
              style={{ display: 'block', marginBottom: 0, display: 'flex' }}
            >
              {getFieldDecorator(`isInnerOrOuter`, {
                initialValue: 2
              })(
                <Radio.Group onChange={this.doChangeVal}>
                  <Radio value={1}>三方内转</Radio>
                  <Radio value={2}>三方外转</Radio>
                  <Radio value={3}>外部转接</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          <Col span={2} />
        </Row>
        <Row gutter={16}>
          <Col span={2} />
          <Col span={20}>
            <Form.Item
              hasFeedback
              label={
                <span className="justify">
                  {selectedValue === 2 ? '请输入电话号码' : '请输入工号'}
                </span>
              }
              style={{ display: 'block', marginBottom: 0 }}
            >
              {getFieldDecorator(`phoneNumber`, {
                rules: [
                  {
                    required: true,
                    message: selectedValue === 2 ? '电话号码必填' : '工号必填'
                  }
                ],
                initialValue: 9
              })(
                <Input
                  placeholder={
                    selectedValue === 2 ? '请输入电话号码' : '请输入工号'
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={2} />
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Switching)
