/*
 * @Author: wangyafei
 * @Date: 2019-07-09 17:31:14
 * @Last Modified by: gy
 * @Last Modified time: yyyy-09-Mo 03:42:04
 */
import store from '../redux/store'
import { getCurMenu } from './ajax'
import * as Constants from '../redux/constants'

const initialMenus = [
  {
    href: '/acc_uncommit_order_detail',
    id: '100000000000000000000001',
    menuName: '未提交工单详情',
    show: false
  },
  {
    href: '/acc_complain_detail',
    id: '100000000000000000000002',
    menuName: '投诉举报工单详情',
    show: false
  },
  {
    href: '/acc_complain_return_detail',
    id: '100000000000000000000003',
    menuName: '投诉举报退回工单详情',
    show: false
  },
  {
    href: '/acc_return_by_appeal_detail',
    id: '100000000000000000000004',
    menuName: '诉求退回工单详情',
    show: false
  },
  {
    href: '/acc_monitor_view_detail',
    id: '100000000000000000000005',
    menuName: '班长查看诉求退回详情',
    show: false
  },
  {
    href: '/appeal_distribute_order_detail',
    id: '100000000000000000000100',
    menuName: '诉求分配详情',
    show: false
  },
  {
    href: '/appeal_return_order_detail',
    id: '100000000000000000000101',
    menuName: '诉求退回详情',
    show: false
  },
  {
    href: '/appeal_postpone_order_detail',
    id: '100000000000000000000102',
    menuName: '诉求延期详情',
    show: false
  },
  {
    href: '/appeal_reply_order_detail',
    id: '100000000000000000000103',
    menuName: '诉求回复详情',
    show: false
  },
  {
    href: '/appeal_reply_order_detail',
    id: '100000000000000000000104',
    menuName: '诉求回复详情',
    show: false
  },
  {
    href: '/a3_sign_detail',
    id: '100000000000000000000201',
    menuName: 'A3待签收详情',
    show: false
  },
  {
    href: '/a3_wait_for_handle_detail',
    id: '100000000000000000000202',
    menuName: 'A3待处理详情',
    show: false
  },
  {
    href: '/a3_work_order_detail',
    id: '100000000000000000000203',
    menuName: 'A3补充回复详情',
    show: false
  },
  {
    href: '/a3_a6_back_detail',
    id: '100000000000000000000204',
    menuName: 'A6退回审核详情',
    show: false
  },
  {
    href: '/a3_a6_postpone_detail',
    id: '100000000000000000000205',
    menuName: 'A6延期审核详情',
    show: false
  },
  {
    href: '/a3_a6_done_detail',
    id: '100000000000000000000206',
    menuName: 'A6完成审核详情',
    show: false
  },
  {
    href: '/a6_sign_detail',
    id: '100000000000000000000301',
    menuName: 'A6待签收详情',
    show: false
  },
  {
    href: '/a6_wait_for_handle_detail',
    id: '100000000000000000000302',
    menuName: 'A6待处理详情',
    show: false
  },
  {
    href: '/acc_order_list_detail',
    id: '100000000000000000000303',
    menuName: '工单详情',
    show: false
  },
  {
    href: '/appeal_work_order_list',
    id: '100000000000000000000304',
    menuName: '诉求查询分派',
    show: false
  },
  {
    href: '/a3_get_detail',
    id: '100000000000000000000305',
    menuName: '工单详情',
    show: false
  },
  {
    href: '/a6_get_detail',
    id: '100000000000000000000306',
    menuName: '工单详情',
    show: false
  },
  {
    href: '/common_detail',
    id: '100000000000000000000306',
    menuName: '工单详情',
    show: false
  },
  {
    href: '/wait_for_revisit_detail',
    id: '100000000000000000000307',
    menuName: '回访工单详情',
    show: false
  },
  {
    href: '/appeal_automatic_dispatch',
    id: '100000000000000000000308',
    menuName: '自动派单',
    show: false
  },
  {
    href: '/appeal_net_order_info',
    id: '100000000000000000000309',
    menuName: '网络诉求分配详情',
    show: false
  },
  {
    href: '/appeal_net_return_info',
    id: '100000000000000000000310',
    menuName: '网络诉求退回详情',
    show: false
  },
  {
    href: '/appeal_net_reply_info',
    id: '100000000000000000000311',
    menuName: '网络诉求回复详情',
    show: false
  },
  {
    href: '/appeal_net_delay_info',
    id: '100000000000000000000312',
    menuName: '网络诉求延期详情',
    show: false
  },
  {
    href: '/notice_details',
    id: '100000000000000000000313',
    menuName: '公告详情',
    show: false
  },
  {
    href: '/wx_work_order_detail',
    id: '100000000000000000000314',
    menuName: '微信网络工单详情',
    show: false
  },
  {
    href: '/new_work_order_detail',
    id: '100000000000000000000314',
    menuName: '新国际工单详情',
    show: false
  },
  {
    href: '/wx_net_woker_order',
    id: '100000000000000000000315',
    menuName: '新建工单',
    show: false
  },
  {
    href: '/wx_reply_detail',
    id: '100000000000000000000316',
    menuName: '网络回复审核详情',
    show: false
  },
  {
    href: '/wx_new_woke_order_copy',
    id: '100000000000000000000317',
    menuName: '拆分工单',
    show: false
  },
  {
    href: '/wx_work_order_detail2',
    id: '100000000000000000000318',
    iconName: 'exclamation-circle',
    menuName: '微信网络工单详情',
    show: false
  },
  {
    href: '/wx_reply_detail2',
    id: '100000000000000000000319',
    iconName: 'exclamation-circle',
    menuName: '网络回复审核详情',
    show: false
  },
  {
    href: '/website_woker_order',
    id: '100000000000000000000320',
    menuName: '首都之窗待受理详情',
    show: false
  },
  {
    href: '/card_out_list_detail',
    id: '100000000000000000000321',
    menuName: '挂账、不纳入申请页面',
    show: false
  },
  {
    href: '/card_out_list_detail_center',
    id: '100000000000000000000322',
    menuName: '挂账、不纳入市中心审核详情',
    show: false
  },
  {
    href: '/card_out_list_detail_sub',
    id: '100000000000000000000323',
    menuName: '挂账、不纳入分中心审核详情',
    show: false
  },
  {
    href: '/hsoa_woker_order',
    id: '100000000000000000000324',
    menuName: '国办电子政务受理详情',
    show: false
  },
  {
    href: '/card_out_list_detailY',
    id: '100000000000000000000325',
    menuName: '挂账、不纳入已申请详情查看',
    show: false
  },
  {
    href: '/currency_work_order',
    id: '100000000000000000000326',
    menuName: '新建工单',
    show: false
  },
  {
    href: '/headline_work_order',
    id: '100000000000000000000327',
    menuName: '今日头条待受理详情',
    show: false
  },
  {
    href: '/net_supervise_woker_order',
    id: '100000000000000000000328',
    menuName: '互联网+监管受理详情',
    show: false
  },
  {
    href: '/random_audit_list',
    id: '100000000000000000000329',
    menuName: '挂账、不纳入随机审核列表',
    show: false
  },
  {
    href: '/review_of_audit_results',
    id: '100000000000000000000330',
    menuName: '挂账、不纳入审核结果查看',
    show: false
  },
  {
    href: '/review_of_audit_results_detail',
    id: '100000000000000000000331',
    menuName: '挂账、不纳入审核结果查看详情',
    show: false
  },
  {
    href: '/view_notice',
    id: '100000000000000000000332',
    menuName: '查看公告',
    show: false
  },
  {
    // 政务平台回复审核
    href: '/net_reply_hsoa_woker_order',
    id: '100000000000000000000333',
    menuName: '网络回复审核详情',
    show: false
  },
  {
    // 互联网+监管回复审核
    href: '/net_reply_supervise_woker_order',
    id: '100000000000000000000334',
    menuName: '网络回复审核详情',
    show: false
  },
  {
    // 首都之窗回复审核
    href: '/net_reply_website_woker_order',
    id: '100000000000000000000335',
    menuName: '网络回复审核详情',
    show: false
  },
  {
    // 上报审核-首都之窗
    href: '/net_report_website_woker_order',
    id: '100000000000000000000336',
    menuName: '上报审核详情',
    show: false
  },
  {
    // 上报审核-政务平台回复审核
    href: '/net_report_hsoa_woker_order',
    id: '100000000000000000000337',
    menuName: '上报审核详情',
    show: false
  },
  {
    // 上报审核-互联网+监管
    href: '/net_report_supervise_woker_order',
    id: '100000000000000000000338',
    menuName: '上报审核详情',
    show: false
  },
  {
    // 市中心承办单位待办明细
    href: '/subcenter_unhandleorder_list',
    id: '100000000000000000000339',
    menuName: '承办单位待办明细',
    show: false
  },
  {
    // 市中心个人待办明细
    href: '/center_un_handle_order_list',
    id: '100000000000000000000340',
    menuName: '市中心个人待办明细',
    show: false
  },
  {
    // 对接工单查询互联网+监管详情
    href: '/net_report_woker_order',
    id: '100000000000000000000341',
    menuName: '互联网+监管工单详情',
    show: false
  },
  {
    href: '/beiJingTong_work_order',
    id: '100000000000000000000342',
    menuName: '北京通APP待受理详情',
    show: false
  },
  {
    href: '/website_woker_order1',
    id: '100000000000000000000343',
    menuName: '首都之窗信件详情',
    show: false
  },
  {
    href: '/weiBo_work_order',
    id: '100000000000000000000344',
    menuName: '北京12345政务微博待受理详情',
    show: false
  },
  {
    href: '/peopleOnline_work_order',
    id: '100000000000000000000345',
    menuName: '人民网待受理详情',
    show: false
  },
  {
    href: '/goodBadRating_work_order',
    id: '100000000000000000000346',
    menuName: '好差评待受理详情',
    show: false
  },
  {
    href: '/classic_details',
    id: '100000000000000000000347',
    menuName: '典型案例详情',
    show: false
  },
  {
    href: '/tc_details',
    id: '100000000000000000000348',
    menuName: '主动剔除详情',
    show: false
  },
  {
    href: '/beiJingTime_work_order',
    id: '100000000000000000000349',
    menuName: '北京时间待受理详情',
    show: false
  },
  {
    href: '/police_worker_detail',
    id: '100000000000000000000350',
    menuName: '退回工单详情',
    show: false
  },
  {
    href: '/police_worker_detail_back',
    id: '100000000000000000000350',
    menuName: '回复工单详情',
    show: false
  },
  {
    href: '/police_order_detail',
    id: '100000000000000000000351',
    menuName: '待受理警单详情',
    show: false
  },
  {
    href: '/jingTong_work_order',
    id: '100000000000000000000352',
    menuName: '京通待受理详情',
    show: false
  },
  {
    href: '/jingTong_work_order1',
    id: '100000000000000000000353',
    menuName: '京通信件详情',
    show: false
  },
  {
    href: '/collaborative_detail',
    id: '100000000000000000000354',
    menuName: '协办工单详情',
    show: false
  },
  {
    href: '/list_library_detail',
    id: '100000000000000000000355',
    menuName: '挂账详情',
    show: false
  },
  {
    href: '/list_library_full_detail',
    id: '100000000000000000000355',
    menuName: '全量挂账详情',
    show: false
  },
  {
    href: '/list_library_examine_detail',
    id: '100000000000000000000355',
    menuName: '销账区级初审详情',
    show: false
  },
  {
    href: '/gjb_work_order',
    id: '100000000000000000000356',
    menuName: '国际版待受理详情',
    show: false
  },
  {
    href: '/jingTong_qy_work_order',
    id: '100000000000000000000357',
    menuName: '京通企业待受理详情',
    show: false
  }
]

// 获取用户菜单
export default async function getUserMenus() {
  // 如果没有获取过用户菜单，则通过AJAX获取用户菜单
  const suc = await getCurMenu()
  const { code, data: menuList } = suc.data
  if (code === 200) {
    store.dispatch({
      type: Constants.USER_MENUS,
      payload: [...menuList, ...initialMenus]
    })
    return [...menuList, ...initialMenus]
  }
  return [...initialMenus]
}
