/* eslint-disable import/prefer-default-export */
/*
 * @Author: wangyafei
 * @Date: 2018-06-06 14:02:54
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2019-10-30 15:40:32
 */
import axios from 'axios'

// footer  用户手册下载
export function downloadsUserFile(params) {
  return axios({
    method: 'GET',
    responseType: 'blob',
    url: `${BASE_URL}/accept/orderFile/downloadsUserFile`,
    params
  })
}
