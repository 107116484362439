/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:53:02
 * @Last Modified by: gy
 * @Last Modified time: 2020-04-21 19:58:42
 */
import * as constants from '../constants'

const initialState = {
  data: null,
  currentUserInfo: {},
  isLoggedOut: false
}

/* const loginState = {
  loginArr: []
} */
export default function userUpdate(state = initialState, { type, payload }) {
  switch (type) {
    case constants.USER_INFO_REFRESH:
      return {
        ...initialState,
        currentUserInfo: payload,
        isLoggedOut: false
      }
    case constants.USER_LOGGED_OUT:
      return { ...initialState, isLoggedOut: true }
    // case constants.USER_LOGIN:
    //   return { ...loginState }
    default:
      return { ...state, isLoggedOut: false }
  }
}
