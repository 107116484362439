/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:53:02
 * @Last Modified by: gy
 * @Last Modified time: 2020-03-20 16:37:41
 * 通知公告redux
 */
import * as constants from '../constants'

const initialState = {
  isNotice: false,
  data: ''
}

export default function noticeReducer(state = initialState, { type, isNotice }) {
  switch (type) {
    case constants.NOTICE_LIST:
      return { ...state, data: isNotice }
    default:
      return state
  }
}
