import * as constants from '../constants'

const initialState = {
  data: null,
  officeSecList: {},
  OfficeSec: false
}

export default function Offices(
  state = initialState,
  { type, payload, OfficeSec }
) {
  switch (type) {
    case constants.UNDERTAKE_OFFICESECOND:
      return {
        ...initialState,
        officeSecList: payload,
        OfficeSec
      }
    default:
      return { ...state, OfficeSec: false }
  }
}
