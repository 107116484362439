/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:53:02
 * @Last Modified by: wangyafei
 * @Last Modified time: 2019-11-13 14:39:08
 */
import * as constants from '../constants'

const initialState = {
  workOrderInfo: null
}

export default function workOrderAttach(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case constants.WORK_ORDER_ATTACH_HISTORY:
      return { ...initialState, workOrderInfo: payload }
    case constants.USER_LOGGED_OUT:
      return { ...initialState }
    default:
      return state
  }
}
