/* eslint-disable no-nested-ternary */
/* eslint-disable vars-on-top */
/* eslint-disable no-use-before-define */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:53:16
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2021-02-04 15:55:20
 */
import React from 'react'
import {
  Layout,
  Menu,
  Icon,
  Dropdown,
  Avatar,
  Modal,
  Tabs,
  Popconfirm,
  Tooltip,
  List,
  message,
  Button,
  Skeleton
} from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ScrollArea from 'react-scrollbar'
import GlobalFooter from '../../components/GlobalFooter'
import * as actions from '@/redux/actions/userAction'
import TabsRouter from './TabsRouter'
import { updatePass, getCurrentUserInfo } from '@/data/ajax'
import CookieUtils from '@/data/common'
import getUserMenus from '@/data/menuInfo'
import './ResponsiveLayout.less'
import { systemLoginLog, revoketoken } from './api'
import WebSocketControl from './WebSocketControl'
import PasswordChange from './PasswordChange'
// import NetworkWarnning from './NetworkWarnning'

const LOGO = require('../../images/logo.png')
const WARNING_LOGO = require('../../images/warning-icon.png')

const { SubMenu } = Menu
const { Header, Sider } = Layout
const { TabPane } = Tabs

const regexp = /((http|https):\/\/([\w-]+\.)+[\w-]+(\/[\w\u4e00-\u9fa5\-./?@%!&=+~:#;,]*)?)/gi

@connect(state => {
  const { loginReducer, tabsReducer, userReducer } = state
  return {
    ...loginReducer,
    ...tabsReducer,
    ...userReducer
  }
}, actions)
class ResponsiveLayout extends React.PureComponent {
  state = {
    collapsed: false,
    selectedMenuKey: '0',
    menuVals: {},
    uuid: 100000,
    isBell: true,
    currentMinHeight: document.body.clientHeight - 152,
    isShowChangePasswordModal: false,
    isForcedPasswordChange: false, // forcedPasswordChange值为1则弹出强制修改密码页面  为2则弹出密码口令含账号信息，请及时修改页面
    // isNet: false, // 网络预警权限
    userInfoData: {}
  }

  componentDidMount() {
    const { isAuthenticated, history } = this.props
    if (!isAuthenticated) {
      history.push('/login')
    } else {
      this.getUserInfo()
    }
  }

  componentWillUpdate(nextProps) {
    const { history } = this.props
    if (!nextProps.isAuthenticated) {
      history.push('/login')
    }
  }

  getUserInfo = flag => {
    const { isAuthenticated, dispatchRefreshUserInfo } = this.props
    if (isAuthenticated) {
      getCurrentUserInfo().then(suc => {
        const { data } = suc
        const { code, data: dataObj } = data
        if (code === 200) {
          this.setState({
            isForcedPasswordChange: dataObj.forcedPasswordChange,
            // isNet: dataObj.authorities.includes('NETWORK_FAULT_WARNING'),
            userInfoData: dataObj
          })
          dispatchRefreshUserInfo(dataObj)
          if (!flag) {
            getUserMenus()
          }
        }
      })
    }
  }

  exitSystem = () => {
    const { dispatchLogout /* , currentUserInfo */ } = this.props
    const authInfo = CookieUtils.getJSON('AuthorizationUserInfo')
    // dispatchLogout()
    // window.isSwitch = false
    // localStorage.removeItem('在线')
    systemLoginLog().then(suc => {
      if (suc && suc.status === 200) {
        this.getRevoketoken(authInfo.access_token)
        // 退出登录
        dispatchLogout()
        window.isSwitch = false
        localStorage.removeItem('在线')

        if (localStorage.getItem('setFn')) {
          clearInterval(localStorage.getItem('setFn'))
          localStorage.removeItem('setFn')
        }
        /* this.setState({
          isNet: false
        }) */

        const self = document.getElementById('watermark')
        if (self) {
          document.body.removeChild(self)
        }
      }
      // const { loginName } = currentUserInfo
    })
  }

  // token失效接口
  getRevoketoken = token => {
    revoketoken(token).then(suc => {
      const { data: dataResult } = suc
      const { msg } = dataResult
      console.log('token失效接口返回值', msg)
    })
  }

  redirectToHome = () => {
    const { history } = this.props
    history.push('/')
  }

  doOpenSetting = () => {
    window.open('/backsys/', '_blank')
  }

  generateUUID = () => {
    const { uuid } = this.state
    this.setState({
      uuid: uuid + 1
    })
  }

  guid = () => {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, c => {
      // eslint-disable-next-line no-bitwise
      const r = (Math.random() * 16) | 0
      // eslint-disable-next-line no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  generateMenu = menuList => {
    const { menuVals } = this.state
    const { history } = this.props
    // 最终生成的菜单
    const menuHtmlTemplate = []
    if (menuList.length > 0) {
      for (let i = 0; i < menuList.length; i += 1) {
        const menuItem = menuList[i]
        if (menuItem.href && menuItem.href.indexOf('/acc_new_order') !== -1) {
          menuItem.href = `/acc_new_order/${this.guid()}`
        }
        const menuChildren = menuItem.children || []
        if (menuItem.show !== false) {
          let pubVal
          let priVal = 0
          if (menuItem.personalFlag && menuVals[menuItem.personalFlag]) {
            priVal = menuVals[menuItem.personalFlag]
          }
          if (menuItem.personalFlag && menuVals[menuItem.publicFlag]) {
            pubVal = menuVals[menuItem.publicFlag]
          }
          // 表扬告知单查询
          if (menuItem.publicFlag === '2-81-001') {
            priVal = ''
            pubVal = menuVals[menuItem.publicFlag]
          }

          if (menuChildren.length <= 0) {
            let flag = false
            if (
              menuItem.href &&
              menuItem.href.indexOf('acc_new_order') !== -1
            ) {
              flag = true
            }
            if (!flag) {
              menuHtmlTemplate.push(
                <Menu.Item
                  key={menuItem.id}
                  onClick={() => this.linkPath(menuItem)}
                >
                  <Link
                    className="jz-menu-link"
                    to={{
                      pathname: `${
                        menuItem.href
                          ? menuItem.href.indexOf('/dashboard') !== -1
                            ? ''
                            : menuItem.href
                          : '/404'
                      }/${menuItem.menuParams ? menuItem.menuParams : ''}`,
                      key: menuItem.id
                    }}
                    onClick={this.generateUUID}
                  >
                    {menuItem.menuIcon && (
                      <span>
                        <i className={`fa fa-${menuItem.menuIcon}`} />
                      </span>
                    )}
                    <span>{menuItem.menuName}</span>
                    {((pubVal !== 0 && typeof pubVal !== 'undefined') ||
                      priVal !== 0) && (
                      <Tooltip
                        placement="right"
                        trigger="hover"
                        title={
                          <div>
                            {priVal !== '' && (
                              <div>{`个人池子数量: ${priVal}`}</div>
                            )}
                            {typeof pubVal !== 'undefined' && (
                              <div>{`公共池子数量: ${pubVal}`}</div>
                            )}
                          </div>
                        }
                      >
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            right: 12,
                            left: 0,
                            textAlign: 'right',
                            zIndex: 9
                          }}
                        >
                          {typeof pubVal !== 'undefined' && `${pubVal}`}
                          {pubVal && priVal && '/'}
                          <i style={{ color: '#FFE44E', fontStyle: 'normal' }}>
                            {priVal}
                          </i>
                        </div>
                      </Tooltip>
                    )}
                  </Link>
                </Menu.Item>
              )
            } else {
              menuHtmlTemplate.push(
                <Menu.Item key={menuItem.id}>
                  <Popconfirm
                    placement="right"
                    title={
                      <span style={{ color: '#f00', fontSize: '24px' }}>
                        是否确认创建新工单?
                      </span>
                    }
                    okText="确定"
                    onConfirm={() => {
                      history.push(
                        `${menuItem.href ? menuItem.href : '/404'}/${
                          menuItem.menuParams ? menuItem.menuParams : ''
                        }`
                      )
                    }}
                    cancelText="取消"
                  >
                    <Link
                      className="jz-menu-link"
                      to={{
                        pathname: `${menuItem.href ? menuItem.href : '/404'}/${
                          menuItem.menuParams ? menuItem.menuParams : ''
                        }`,
                        key: menuItem.id
                      }}
                      onClick={this.generateUUID}
                    >
                      {menuItem.menuIcon && (
                        <span>
                          <i className={`fa fa-${menuItem.menuIcon}`} />
                        </span>
                      )}
                      <span>{menuItem.menuName}</span>
                    </Link>
                  </Popconfirm>
                </Menu.Item>
              )
            }
          } else {
            let hasNumber = false

            menuChildren.forEach(imenu => {
              if (
                menuVals[imenu.personalFlag] > 0 &&
                menuItem.id === '8EA5596830DB40F89CCA53A2D3D756F4'
              ) {
                hasNumber = true
              }
            })
            menuHtmlTemplate.push(
              <SubMenu
                key={menuItem.id}
                title={
                  <span>
                    {menuItem.menuIcon && (
                      <span>
                        <i className={`fa fa-${menuItem.menuIcon}`} />
                      </span>
                    )}
                    <span>{menuItem.menuName}</span>
                    {hasNumber && (
                      <i
                        className="fa fa-exclamation"
                        style={{
                          color: 'red',
                          fontSize: 18,
                          float: 'right',
                          marginRight: '-8px',
                          marginTop: '13px'
                        }}
                      />
                    )}
                  </span>
                }
              >
                {this.generateMenu(menuItem.children)}
              </SubMenu>
            )
          }
        }
      }
    }
    return menuHtmlTemplate
  }

  handleRefreshAction = () => {
    this.scrollAreaWrapper.scrollArea.refresh()
  }

  // 弹出消息内容
  getPopoverContent = () => {
    const dataList = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]

    return (
      <Tabs defaultActiveKey="1" style={{ width: '250px' }}>
        <TabPane tab="新消息" key="2">
          <List
            itemLayout="horizontal"
            dataSource={dataList}
            renderItem={item => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={<Link to="/afiling">2018-09-28收到一条新消息</Link>}
                />
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane tab="历史消息" key="1">
          <List
            itemLayout="horizontal"
            dataSource={dataList}
            renderItem={item => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={<Link to="/afiling">2018-09-28收到一条历史消息</Link>}
                />
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
    )
  }

  // 弹出菜单内容
  getDropDownMenu = () => {
    return (
      <Menu style={{ maxWidth: 200 }}>
        <Menu.Item>
          <Link to="/usercenter">
            <Icon type="user" />
            <span style={{ paddingLeft: '8px' }}>个人中心</span>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <div role="presentation" onClick={this.doChangePassword}>
            <Icon type="lock" />
            <span style={{ paddingLeft: '8px' }}>修改密码</span>
          </div>
        </Menu.Item>
      </Menu>
    )
  }

  doChangePassword = () => {
    this.setState({
      isShowChangePasswordModal: true
    })
  }

  toggle = () => {
    const { collapsed } = this.state
    this.setState({
      collapsed: !collapsed
    })
  }

  onOpenMenu = key => {
    this.setState({
      selectedMenuKey: key
    })
  }

  // 更新菜单显示
  onRefreshMenu = vals => {
    this.setState(
      {
        menuVals: vals
      },
      () => {
        this.forceUpdate()
      }
    )
  }

  // 成功修改密码
  handleSubmitPasswordClose = () => {
    // 反映问题信息表单部分
    const formValues = this.passwordChangeForm.getItemsValues()
    if (formValues !== -1) {
      updatePass(formValues).then(suc => {
        const { data } = suc
        const { code, msg } = data
        if (code === 200) {
          this.setState(
            {
              isShowChangePasswordModal: false
            },
            () => {
              message.success(msg)
              // 重新获取用户信息
              this.getUserInfo(true)
            }
          )
        } else {
          message.error(msg)
        }
      })
    }
  }

  handlePasswordClose = () => {
    this.setState({
      isShowChangePasswordModal: false
    })
  }

  /**
   * 自定义底部按钮
   */
  getCustomFooter = () => {
    const { currentUserInfo = {} } = this.props
    let customFooter = [
      <Button key="back" onClick={() => this.handlePasswordClose()}>
        取消
      </Button>,
      <Button
        key="submit"
        type="primary"
        onClick={() => this.handleSubmitPasswordClose()}
      >
        确定
      </Button>
    ]
    if (currentUserInfo.passEditFlag === '0') {
      customFooter = [
        <Button
          key="submit"
          type="primary"
          onClick={() => this.handleSubmitPasswordClose()}
        >
          确定
        </Button>
      ]
    }
    return customFooter
  }

  doBellOrNot = () => {
    const { isBell } = this.state
    this.setState({
      isBell: !isBell
    })
  }

  userTit = passEditFlag => {
    const { isForcedPasswordChange } = this.state
    let result = null
    if (passEditFlag === '0') {
      result = '首次登录请修改密码'
    } else if (isForcedPasswordChange === '1') {
      result = (
        <div style={{ color: 'red' }}>密码更换周期已超90天，请修改密码</div>
      )
    } else if (isForcedPasswordChange === '2') {
      result = (
        <div style={{ color: 'red' }}>密码口令含账号信息，请及时修改</div>
      )
    } else {
      result = '修改密码'
    }
    return result
  }

  linkPath = paneItem => {
    const { history, activeKey } = this.props
    if (paneItem.href && paneItem.href.search(regexp) >= 0) {
      window.open(paneItem.href)
      history.push(activeKey)
    }
  }

  render() {
    const {
      selectedMenuKey,
      collapsed,
      isBell,
      currentMinHeight,
      isShowChangePasswordModal,
      isForcedPasswordChange,
      // isNet,
      userInfoData
    } = this.state
    const { menuList, currentUserInfo = {} } = this.props
    // 弹出菜单
    const menu = this.getDropDownMenu()
    return (
      <Layout className="jz-layout">
        <Sider
          trigger={null}
          collapsible
          width={200}
          collapsed={collapsed}
          style={{ height: '100vh', overflow: 'hidden', position: 'fixed' }}
        >
          <ScrollArea
            speed={0.8}
            ref={x => {
              this.scrollAreaWrapper = x
            }}
            className="jz-sider-slider"
            contentClassName="jz-sider-content"
            horizontal={false}
          >
            <Link to="/" className="jz-logo">
              <img src={LOGO} alt="logo" width="100" height="64" />
              <p>12345市民热线服务</p>
            </Link>
            <div
              role="button"
              tabIndex="-1"
              onClick={this.handleRefreshAction}
              onKeyDown={this.handleRefreshAction}
            >
              {menuList.length > 0 ? (
                <Menu
                  theme="dark"
                  selectedKeys={[selectedMenuKey]}
                  mode="inline"
                  style={{ fontSize: '16px' }}
                >
                  {this.generateMenu(menuList)}
                </Menu>
              ) : (
                <div style={{ margin: '0 12px' }}>
                  <Skeleton active paragraph={{ rows: 6 }} />
                </div>
              )}
            </div>
          </ScrollArea>
        </Sider>
        <Layout
          style={{ transition: 'all .2s', marginLeft: collapsed ? 80 : 200 }}
        >
          <Header
            style={{
              position: 'fixed',
              padding: 0,
              zIndex: 99,
              transition: 'all .2s',
              width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 200px)'
            }}
          >
            <div className="jz-utility">
              {window['warning-sign'] && (
                <img className="warning-icon" src={WARNING_LOGO} alt="" />
              )}
              <Menu
                theme="dark"
                selectable={false}
                mode="horizontal"
                style={{ lineHeight: '60px' }}
              >
                <Menu.Item key="1" className="jz-userinfo">
                  <Dropdown overlay={menu}>
                    <div>
                      <Avatar
                        size="large"
                        src={
                          currentUserInfo.showUrl
                            ? currentUserInfo.showUrl
                            : currentUserInfo.photo
                        }
                      />
                      <span>
                        {`${currentUserInfo.userName ||
                          ''}(${currentUserInfo.loginName || ''})`}
                        <Icon type="down" />
                      </span>
                    </div>
                  </Dropdown>
                </Menu.Item>
                <Menu.Item key="2">
                  <Tooltip
                    placement="bottom"
                    title={isBell ? '未静音' : '已静音'}
                  >
                    <div
                      className="jz-menu-icon"
                      role="presentation"
                      onClick={this.doBellOrNot}
                    >
                      <i
                        className={
                          isBell ? 'fa fa-bell fa-2x' : 'fa fa-bell-slash fa-2x'
                        }
                      />
                    </div>
                  </Tooltip>
                </Menu.Item>
                <Menu.Item key="4">
                  <Popconfirm
                    title="确定要退出系统吗?"
                    onConfirm={this.exitSystem}
                    okText="确定"
                    cancelText="取消"
                    placement="bottomRight"
                  >
                    <div className="jz-menu-icon" role="presentation">
                      <i className="fa fa-power-off fa-2x" />
                    </div>
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            </div>
          </Header>
          <div className="jz-nav-bar" style={{ minHeight: currentMinHeight }}>
            {menuList.length > 0 && (
              <TabsRouter
                style={{
                  left: collapsed ? '80px' : '200px'
                }}
                {...this.props}
                menuList={menuList}
                onOpenMenu={this.onOpenMenu}
                userInfoData={userInfoData}
              />
            )}
          </div>
          <GlobalFooter className="jz-dashboard-footer" />
        </Layout>
        <WebSocketControl
          id={currentUserInfo.id}
          onRefreshMenu={this.onRefreshMenu}
          isBell={isBell}
        />
        {/* {isNet && <NetworkWarnning />} */}

        <Modal
          title={this.userTit(currentUserInfo.passEditFlag)}
          // title={
          //   currentUserInfo.passEditFlag === '0'
          //     ? '首次登录请修改密码'
          //     : '修改密码'
          // }
          visible={
            isShowChangePasswordModal ||
            currentUserInfo.passEditFlag === '0' ||
            ['1', '2'].includes(isForcedPasswordChange)
          }
          okText="确定"
          keyboard={false}
          cancelText="取消"
          maskClosable={false}
          closable={currentUserInfo.passEditFlag !== '0'}
          width={440}
          destroyOnClose
          footer={this.getCustomFooter()}
          onCancel={this.handlePasswordClose}
        >
          <PasswordChange
            wrappedComponentRef={form => {
              this.passwordChangeForm = form
            }}
          />
        </Modal>
      </Layout>
    )
  }
}

ResponsiveLayout.contextTypes = {
  scrollArea: () => {}
}

ResponsiveLayout.defaultProps = {
  isAuthenticated: false,
  history: {},
  menuList: [],
  tokenInfo: {},
  currentUserInfo: {},
  dispatchLogout: () => {}, // 退出登录 - redux
  dispatchRefreshUserInfo: () => {} // 刷新用户信息 - redux
}

ResponsiveLayout.propTypes = {
  isAuthenticated: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    key: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    length: PropTypes.number
  }),
  children: PropTypes.element.isRequired,
  tokenInfo: PropTypes.shape({
    data: PropTypes.shape({
      busUserType: PropTypes.string
    })
  }),
  currentUserInfo: PropTypes.shape({
    id: PropTypes.string,
    userName: PropTypes.string
  }),
  menuList: PropTypes.arrayOf(
    PropTypes.objectOf({
      id: PropTypes.string
    })
  ), // 菜单列表
  dispatchLogout: PropTypes.func, // 退出登录 - redux
  dispatchRefreshUserInfo: PropTypes.func, // 刷新用户信息 - redux
  activeKey: PropTypes.string.isRequired
}

export default withRouter(ResponsiveLayout)
