/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:53:02
 * @Last Modified by: zss
 * @Last Modified time: 2021-05-16 09:59:38
 */
import * as constants from '../constants'

const initialState = {
  isDail: false,
  isAgent: '',
  fillinFormData: {}
}

export default function dailPhone(state = initialState, { type, payload }) {
  switch (type) {
    case constants.DIAL_PHONE:
      return { ...initialState, ...payload }
    case constants.AGENT_ASSISTANT:
      return { ...initialState, isAgent: payload }
    case constants.FILLIN_FORM:
      return { ...initialState, fillinFormData: payload }
    default:
      return state
  }
}
