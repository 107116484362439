/*
 * @Author: wangyafei
 * @Date: 2019-10-25 10:15:25
 * @Last Modified by: wangyafei
 * @Last Modified time: 2019-11-10 08:57:45
 */
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import logger from 'redux-logger'
import rootReducer from '../reducers'

const middlewares = [thunkMiddleware, logger]

function configStore() {
  const store = createStore(rootReducer, {}, applyMiddleware(...middlewares))
  return store
}

export default configStore()
