/* eslint-disable react/prop-types */
/*
 * @Autor: gy
 * @Date: 2023-04-19 17:26:20
 * @Description: 三方
 * @LastEditors: lvchengjun 13021966637@163.com
 * @LastEditTime: 2023-11-15 14:21:33
 */

import React, { Component } from 'react'
import { Row, Col, Form, Radio, message, AutoComplete, Input } from 'antd'
import { queryAll } from '../api'

const { Option: Opt } = AutoComplete

class SanFang extends Component {
  state = {
    queryAllList: []
  }

  componentDidMount() {
    const { form } = this.props
    form.setFieldsValue({
      phoneNumber: '9'
    })
    this.queryAllFn()
  }

  queryAllFn = () => {
    queryAll({ type: '1' }).then(suc => {
      const { data: dataResult } = suc
      const { code, data, msg } = dataResult
      if (code === 200) {
        for (let i = 0; i < data.length; i += 1) {
          Object.assign(data[i], {
            all: `${data[i].orgName} ${data[i].phoneNum}`
          })
        }
        this.setState({
          queryAllList: data
        })
      } else {
        message.error(msg)
      }
    })
  }

  getItemsValues = () => {
    /* eslint-disable */
    const {
      form: { validateFieldsAndScroll }
    } = this.props
    // form表单结果
    let result
    validateFieldsAndScroll(
      {
        scroll: { offsetTop: 100 }
      },
      (err, values) => {
        if (!err) {
          result = {
            ...values
          }
        } else {
          result = -1
        }
      }
    )
    return result
  }

  doChangeVal = e => {
    const val = e.target.value
    const { form } = this.props
    if (val === 1) {
      form.setFieldsValue({
        phoneNumber: '9'
      })
    } else {
      form.setFieldsValue({
        phoneNumber: ''
      })
    }
  }

  render() {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { queryAllList } = this.state

    const options =
      queryAllList &&
      queryAllList.length > 0 &&
      queryAllList.map(it => {
        return (
          <Opt key={it.phoneNum} value={it.all}>
            {it.all}
          </Opt>
        )
      })

    return (
      <Form className="jz-modal-flex">
        <Row gutter={16}>
          <Col span={2} />
          <Col span={20}>
            <Form.Item
              hasFeedback
              label={<span className="justify">{ form.getFieldValue('isInnerOrOuter') === 1 ? '请输入号码' : '请输入工号' }</span>}
              style={{ display: 'block' }}
            >
              {form.getFieldValue('isInnerOrOuter') === 1 ? (
                <React.Fragment>
                  {getFieldDecorator(`phoneNumber`, {
                    rules: [
                      {
                        required: true,
                        message: '号码/账号必填'
                      }
                    ]
                  })(
                    <AutoComplete
                      dataSource={options}
                      placeholder="请选择号码/账号"
                      /* filterOption={(inputValue, option) =>
                        option.props.children.indexOf(inputValue) !== -1
                      } */
                    />
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getFieldDecorator(`phoneNumber`, {
                    rules: [
                      {
                        required: true,
                        message: '号码/账号必填'
                      }
                    ]
                  })(<Input placeholder="请输入号码/账号" />)}
                </React.Fragment>
              )}

              {/* {getFieldDecorator(`phoneNumber`, {
                rules: [
                  {
                    required: true,
                    message: '号码/账号必填'
                  }
                ]
              })(
                <React.Fragment>
                  {form.getFieldValue('isInnerOrOuter') === 1 ? (
                    <AutoComplete
                      dataSource={options}
                      placeholder="请选择号码/账号"
                      filterOption={(inputValue, option) =>
                    option.props.children.indexOf(inputValue) !== -1
                  }
                    />
                  ) : (
                    <Input placeholder="请输入号码/账号" />
                  )}
                </React.Fragment>
              )} */}
            </Form.Item>
          </Col>
          <Col span={2} />
        </Row>
        <Row gutter={16}>
          <Col span={2} />
          <Col span={20}>
            <Form.Item
              label={<span className="justify">请选择三方类型</span>}
              style={{ marginBottom: 0, display: 'flex' }}
            >
              {getFieldDecorator(`isInnerOrOuter`, {
                initialValue: 1
              })(
                <Radio.Group onChange={this.doChangeVal}>
                  <Radio value={2}>三方内部</Radio>
                  <Radio value={1}>三方外部</Radio> 
                  {/* <Radio value={3}>外部转接</Radio> */}
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          <Col span={2} />
        </Row>
      </Form>
    )
  }
}

export default Form.create()(SanFang)
