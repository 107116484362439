/* eslint-disable no-irregular-whitespace */
/*
 * @Author: wangyafei
 * @Date: 2018-12-04 16:59:14
 * @Last Modified by: gy
 * @Last Modified time: 2020-05-13 13:04:32
 */
import React from 'react'
import Websocket from 'react-websocket'
import PropTypes from 'prop-types'
import { notification, Button } from 'antd'
import ReadMssage from './ReadMssage'

/* const getPos = () => {
  const pathname = BASE_WEBSOCKET_URL
  const url = new URL(pathname)
  const { hostname, protocol } = url || {}
  const urlPathName = `${protocol}//${hostname}`
  return urlPathName // return new URL(url).port
} */

class WebSocketControl extends React.PureComponent {
  state = {
    id: '', // 用户ID
    readMessage: '' // 不稳定因素提醒弹窗
  }

  componentDidMount() {
    this.dataResult = {}
    const { id } = this.props
    if (id) {
      this.setState({
        id
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { id, isBell } = nextProps
    const { id: idState } = this.state
    if (id !== idState) {
      this.setState({
        id
      })
    }
    if (!isBell) {
      const audioControl = document.getElementById('audioDiv')
      audioControl.currentTime = 0
      audioControl.pause()
    }
  }

  componentWillUnmount() {
    this.dataResult = null
  }

  handleWebSocketData = data => {
    const { onRefreshMenu, isBell } = this.props
    if (onRefreshMenu) {
      try {
        let isShowBell = false
        const resultData = JSON.parse(data)
        if (Array.isArray(resultData)) {
          for (let i = 0; i < resultData.length; i += 1) {
            const ic = resultData[i]
            if (
              ic.menuType === '2-31-001' &&
              ic.menuVale !== this.dataResult[ic.menuType] &&
              ic.menuVale > 0
            ) {
              isShowBell = true
            }
            this.dataResult[ic.menuType] = ic.menuVale

            // 不稳定因素
            if (ic.menuType === '3-001') {
              this.setState({
                readMessage: resultData
              })
            }
          }
          onRefreshMenu(this.dataResult)
          if (isBell && isShowBell) {
            const audioControl = document.getElementById('audioDiv')
            audioControl.currentTime = 0
            audioControl.play()
            this.info()
            audioControl.addEventListener(
              'ended',
              () => {
                audioControl.play()
              },
              false
            )
          }
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }

  // 通知提醒框
  info = () => {
    const key = '1'
    const close = () => {
      const audioControl = document.getElementById('audioDiv')
      audioControl.currentTime = 0
      audioControl.pause()
      notification.close(key)
    }
    const btn = (
      <Button type="primary" size="small" onClick={() => close()}>
        知道了
      </Button>
    )
    notification.open({
      message: '您有新的消息',
      duration: '',
      btn,
      key,
      placement: 'topRight',
      onClose: close
    })
  }

  render() {
    const { id, readMessage } = this.state
    return (
      <React.Fragment>
        {id && (
          <Websocket
            url={`${BASE_WEBSOCKET_URL}/websocket/sendWS/${id}`}
            onMessage={this.handleWebSocketData}
          />
        )}
        <ReadMssage readMessage={readMessage} />
      </React.Fragment>
    )
  }
}

WebSocketControl.defaultProps = {
  id: '',
  isBell: true,
  onRefreshMenu: () => {}
}

WebSocketControl.propTypes = {
  id: PropTypes.string,
  onRefreshMenu: PropTypes.func,
  isBell: PropTypes.bool
}

export default WebSocketControl
