/* eslint-disable no-unused-vars */
/*
 * @Author: wangyafei
 * @Date: 2018-06-06 14:02:54
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2021-02-04 15:23:45
 */
import axios from 'axios'
import { Modal, message } from 'antd'
import md5 from 'md5'
import CryptoJS from 'crypto-js'
import store from '../redux/store'
import * as Constants from '../redux/constants'
import CookieUtils from './common'

let isRefreshing = false
let subscribers = []
let hasErrorInfo = false

// AES秘钥
const CRYPTOJSKEY = 'b7ee962457164fb8'
// 加密
export function encrypt(plaintText) {
  const options = {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }
  const key = CryptoJS.enc.Utf8.parse(CRYPTOJSKEY)
  const encryptedData = CryptoJS.AES.encrypt(plaintText, key, options)
  let encryptedBase64Str = encryptedData.toString().replace(/\//g, '_')
  encryptedBase64Str = encryptedBase64Str.replace(/\+/g, '-')
  return encryptedBase64Str
}
// 解密
/* export function decrypt(encryptedBase64Str) {
  const vals = encryptedBase64Str.replace(/\-/g, '+').replace(/_/g, '/')
  const options = {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }
  const key = CryptoJS.enc.Utf8.parse(CRYPTOJSKEY)
  const decryptedData = CryptoJS.AES.decrypt(vals, key, options)
  const decryptedStr = CryptoJS.enc.Utf8.stringify(decryptedData)
  return decryptedStr
} */

function subscribeTokenRefresh(cb) {
  subscribers.push(cb)
}

function onRrefreshed(token) {
  subscribers.map(cb => cb(token))
  isRefreshing = false
}

// 显示message形式的请求错误
function showAjaxMessageError(errMsg) {
  // 拦截错误，只处理一次
  if (!hasErrorInfo) {
    message.error(errMsg, 2, () => {
      hasErrorInfo = false
    })
    hasErrorInfo = true
  }
}

// 显示message形式的请求错误
// eslint-disable-next-line no-unused-vars
function showAjaxModalError(errMsg, status) {
  // 其它错误消息提示即可，注: 同一时刻多个错误请求只提示一次
  if (hasErrorInfo) return;
  hasErrorInfo = true
  Modal.error({
    title: errMsg,
    okText: '确定',
    onOk: () => {
      setTimeout(() => {
        hasErrorInfo = false
      }, 200);
      // 如果检测到请求未授权，则跳转到登录页面
      if (status === 401) {
        try {
          store.dispatch({
            type: Constants.USER_LOGGED_OUT
          })
          // axios({
          //   method: 'POST',
          //   url: `${BASE_URL}/system/loginLog/loginOut`,
          //   success: res => {
          //     if (res && res.status === 200) {
          //       // 退出登录
          //       store.dispatch({
          //         type: Constants.USER_LOGGED_OUT
          //       })
          //     }
          //   }
          // })
        } catch (e) {
          console.log(e)
        }
      }
    }
  })
}

// 刷新token
export function refreshToken() {
  const authInfo = CookieUtils.getJSON('AuthorizationUserInfo')
  const data = {
    grant_type: 'refresh_token',
    scope: 'all',
    token_type: 'bearer',
    expires_in: 0,
    access_token: authInfo.access_token,
    refresh_token: authInfo.refresh_token
  }
  axios({
    method: 'POST',
    url: `${BASE_URL}/oauth/token`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic c3lzdGVtOnN5c3RlbSExMjM0NQ=='
      // Authorization: 'Basic c2FtcGxlOjEyMzQ1Ng=='
    },
    transformRequest: [
      dataTra => {
        let ret = ''
        const keys = Object.keys(dataTra)
        for (let i = 0; i < keys.length; i += 1) {
          ret += `${encodeURIComponent(keys[i])}=${encodeURIComponent(
            dataTra[keys[i]]
          )}&`
        }
        return ret
      }
    ],
    data: {
      ...data
    }
  }).then(suc => {
    const userInfo = CookieUtils.getJSON('AuthorizationUserInfo')
    const { data: tokenInfo } = suc
    const result = {
      ...userInfo,
      ...tokenInfo
    }
    // 记录登录信息
    try {
      axios({
        method: 'POST',
        url: `${BASE_URL}/system/loginLog/loginIn`
      })
    } catch (e) {
      console.log(e)
    }
    if (JSON.stringify(result) !== '{}') {
      CookieUtils.set('AuthorizationUserInfo', result)
      // eslint-disable-next-line no-restricted-globals
      // location.reload()
      onRrefreshed(tokenInfo.access_token)
    }
  })
}

// 为每个请求添加认证信息
axios.interceptors.request.use(
  config => {
    const configConst = config
    const { url } = configConst
    if (url.indexOf('/oauth/token') !== -1) {
      return configConst
    }
    if (url.indexOf('/statistics/CenterSeat/networkConnectivity') !== -1) {
      return configConst
    }
    const userInfo = CookieUtils.getJSON('AuthorizationUserInfo')
    if (userInfo && userInfo.access_token) {
      configConst.headers.Authorization = `Bearer ${userInfo.access_token}`
      // configConst.headers.Authorization = `Bearer 70af1acf-07da-4dca-8054-a38ebcad3924`
    }
    return configConst
  },
  error => Promise.reject(error)
)

function doRefreshTokenGet(origin) {
  const originalRequest = origin
  if (!isRefreshing) {
    isRefreshing = true
    refreshToken()
    subscribers = []
  }
  const requestSubscribers = new Promise(resolve => {
    subscribeTokenRefresh(token => {
      originalRequest.headers.Authorization = `Bearer ${token}`
      resolve(axios(originalRequest))
    })
  })
  return requestSubscribers
}

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。'
}

function handleAjaxErrors(err) {
  // 这里是返回状态码不为200时候的错误处理
  const { response } = err || {}
  if (!response) {
    showAjaxMessageError('您的网络发生异常，无法连接服务器!')
    return Promise.reject(err)
  }
  const statusCode = response.status
  const errorText = codeMessage[statusCode] || response.statusText

  const { data = {}, config: originalRequest } = response
  const { msg: errMsg, code } = data
  const userInfo = CookieUtils.getJSON('AuthorizationUserInfo')
  if (statusCode === 401 || statusCode === 400) {
    if (code === 601) {
      // if (userInfo && userInfo.refresh_token) {
      //   return doRefreshTokenGet(originalRequest)
      // }
      // 跳转到登录页面
      showAjaxModalError(errMsg, 401)
      return Promise.reject(err)
    }
    if (code === 602) {
      // 跳转到登录页面
      showAjaxModalError(errMsg, 401)
      return Promise.reject(err)
    }
    // 跳转到登录页面
    showAjaxModalError(errMsg, 401)
    return Promise.reject(err)
  }
  if (statusCode === 403) {
    Modal.error({
      title: '无权访问，请联系系统管理员！'
    })
  }
  showAjaxMessageError(errorText)
  return Promise.reject(err)
}

// 拦截响应response，并做一些错误处理
axios.interceptors.response.use(
  response => {
    if (response.data && response.data.code === 401) {
      message.error(response.data.msg, 5)
    }
    if (response.status === 504 || response.status === 502) {
      message.error('当前服务超时请求，请重试');
    }
    if (response.config.url.indexOf('/phone/forward') !== -1 && response.data.code !== 200) {
      // 路径为phone/forward下 请求华为cti3.8接口
      window['warning-sign'] = 'existing problem';
      message.error('当前服务有问题，请重新重新签入');
      // CookieUtils.set('Guid', '');
    } else {
      window['warning-sign'] = undefined;
    }
    return response
  },
  err => {
    handleAjaxErrors(err)
  }
)

// 获取当前登录用户信息
export function getCurrentUserInfo() {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/system/user/getCurrentUser`
  })
}

// 获取当前登录用户信息
export function updatePass(data) {
  const { oldPassword, newPassword, id } = data
  return axios({
    method: 'POST',
    url: `${BASE_URL}/system/user/updatePass`,
    data: {
      oldPassword: encrypt(md5(oldPassword)),
      newPassword: encrypt(md5(newPassword)),
      id
    },
    transformRequest: [
      dataTra => {
        let ret = ''
        const keys = Object.keys(dataTra)
        for (let i = 0; i < keys.length; i += 1) {
          ret += `${encodeURIComponent(keys[i])}=${encodeURIComponent(
            dataTra[keys[i]]
          )}&`
        }
        return ret
      }
    ]
  })
}

// 获取当前登录用户信息
export function getCurMenu() {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/system/menu/getCurMenu`
  })
}

// 获取字典信息, 并缓存字典信息
// flag 表示是否强制从接口获取数据，不从本地获取。用于清除本地缓存
export async function getDictByDictType(dictType, flag) {
  const dictResult = sessionStorage.getItem(dictType)
  // 如果已经获取过用户菜单，则直接返回
  // console.log(dictResult, 'dictResult')
  if (dictResult && !flag) {
    return JSON.parse(dictResult)
  }
  // 如果没有获取过用户菜单，则通过AJAX获取用户菜单
  const suc = await axios({
    url: `${BASE_URL}/system/dict/findList`,
    method: 'POST',
    dataType: 'application/json',
    data: {
      dictType
    }
  })
  const { data } = suc
  const { code, data: dataArr } = data
  if (code === 200) {
    sessionStorage.setItem(dictType, JSON.stringify(dataArr))
  }
  return dataArr
}

// 获取字典信息, 并缓存字典信息
export async function findAll(dictType, flag) {
  const dictResult = sessionStorage.getItem(dictType)
  // 如果已经获取过用户菜单，则直接返回
  if (dictResult && !flag) {
    return JSON.parse(dictResult)
  }
  // 如果没有获取过用户菜单，则通过AJAX获取用户菜单
  const suc = await axios({
    url: `${BASE_URL}/system/handleType/findAll`,
    method: 'POST',
    dataType: 'application/json',
    data: {
      dictType
    }
  })
  const { data } = suc
  const { code, data: dataArr } = data
  if (code === 200) {
    sessionStorage.setItem(dictType, JSON.stringify(dataArr))
  }
  return dataArr
}

// 获取字典信息, 并缓存字典信息
// flag 表示是否强制从接口获取数据，不从本地获取。用于清除本地缓存
export async function getDictByDictTypeList(dictType) {
  const dictResult = sessionStorage.getItem(`${dictType}_getlist`)
  // 如果已经获取过用户菜单，则直接返回
  if (dictResult) {
    return JSON.parse(dictResult)
  }
  // 如果没有获取过用户菜单，则通过AJAX获取用户菜单
  const suc = await axios({
    url: `${BASE_URL}/system/dict/getList`,
    method: 'POST',
    dataType: 'application/json',
    data: {
      dictType
    }
  })
  const { data } = suc
  const { code, data: dataArr } = data
  if (code === 200) {
    sessionStorage.setItem(`${dictType}_getlist`, JSON.stringify(dataArr))
  }
  return dataArr
}

/**
 * @description 埋点接口
 * @param {string} orderNum 工单编号
 * @param {string} logInfo 对应的信息
 * @param {string} submitCode 对应标记字符数字   
 */
export function loginMap(params) {
  return axios({
    method: 'POST',
    url: `${BASE_URL}/loginfo/logInfo/logInfoMap`,
    params
  })
}

/**
 * @description 行政区划信息
 */
export function regionInfo() {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/query/gisAddressInfo/regionInfo`
  })
}

// 获取落点落图导航列表
export function gisInfoList() {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/query/gisAddressInfo/gisInfoList`
  })
}

/**
 * @description 根据导航标签获取gis信息列表
 * @param {string} label 标签
 */
export function getByLabel(params) {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/query/gisAddressInfo/getByLabel`,
    params
  })
}