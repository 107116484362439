/* eslint-disable no-dupe-keys */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*
 * @Author: wangyafei
 * @Date: 2018-11-22 16:22:28
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-03-05 10:42:14
 */
import React from 'react'
import { Icon, Modal, Checkbox } from 'antd'
import './index.less'
import { downloadsUserFile } from './api'

// eslint-disable-next-line no-unused-vars
const copyright = (
  <React.Fragment>
    Copyright
    <Icon type="copyright" style={{ margin: '0 4px' }} />
    2019 北京市市民热线服务中心 技术支持电话：010-65261628
    技术支持邮箱：12345fuwu@capinfo.com.cn
  </React.Fragment>
)

class GlobalFooter extends React.Component {
  // const clsString = classNames('globalFooter', className)

  state = {
    isShowUserModal: false,
    fileType: ''
  }

  // 开启Modal
  getSelectUserModal = () => {
    this.setState({
      isShowUserModal: true
    })
  }

  // 关闭Modal
  handleUserModal = () => {
    this.setState({
      isShowUserModal: false
    })
  }

  // 用户手册导出下载
  handleDownLoad = () => {
    const { fileType } = this.state
    let fileName = ''
    switch (fileType) {
      case 1:
        fileName = '12345市民热线-办理反馈子系统_市中心用户手册.pdf'
        break
      case 2:
        fileName = '12345市民热线-办理反馈子系统_分中心用户手册.pdf'
        break
      case 3:
        fileName = '12345市民热线-办理反馈子系统_工作站用户手册.pdf'
        break

      default:
        break
    }

    downloadsUserFile({ fileType }).then(suc => {
      const blob = new Blob([suc.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // for IE
        window.navigator.msSaveOrOpenBlob(blob, '用户手册.pdf')
      } else {
        // for Non-IE (chrome, firefox etc.)
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = fileName // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      }
    })

    this.setState({
      isShowUserModal: false
    })
  }

  render() {
    const { isShowUserModal } = this.state
    return (
      <footer className="globalFooter">
        <div className="links">
          <a
            role="presentation"
            onClick={this.getSelectUserModal}
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            用户手册下载
          </a>
        </div>
        {/*
        关闭原因:需求未定 暂时关闭
         {links && (
          <div className="links">
            {links.map(link => (
              <a
                key={link.key}
                title={link.key}
                target={link.blankTarget ? '_blank' : '_self'}
                href={link.href}
              >
                {link.title}
              </a>
            ))}
          </div>
        )} */}
        {copyright && <div className="copyright">{copyright}</div>}
        <Modal
          title="选择用户手册"
          width={600}
          visible={isShowUserModal}
          onOk={this.handleDownLoad}
          onCancel={this.handleUserModal}
        >
          <Checkbox
            onChange={() => {
              this.setState({
                fileType: 1
              })
            }}
          >
            市中心用户手册下载
          </Checkbox>
          <Checkbox
            onChange={() => {
              this.setState({
                fileType: 2
              })
            }}
          >
            分中心用户手册下载
          </Checkbox>
          <Checkbox
            onChange={() => {
              this.setState({
                fileType: 3
              })
            }}
          >
            工作站用户手册下载
          </Checkbox>
        </Modal>
      </footer>
    )
  }
}

// GlobalFooter.defaultProps = {
//   className: ''
// }

// GlobalFooter.propTypes = {
//   className: PropTypes.string
// }

export default GlobalFooter
