/*
 * @Author: wangyafei
 * @Description: 填写处理意见
 * @Date: 2018-11-22 16:21:47
 * @Last Modified by: wangyafei
 * @Last Modified time: 2019-10-12 17:39:14
 */
import React, { Component } from 'react'
import { Row, Col, Form, InputNumber } from 'antd'

class RestControl extends Component {
  state = {}

  getItemsValues = () => {
    /* eslint-disable */
    const {
      form: { validateFieldsAndScroll }
    } = this.props
    // form表单结果
    let result
    validateFieldsAndScroll(
      {
        scroll: { offsetTop: 100 }
      },
      (err, values) => {
        if (!err) {
          result = {
            ...values
          }
        } else {
          result = -1
        }
      }
    )
    return result
  }

  render() {
    /* eslint-disable-next-line */
    const { form } = this.props
    const { getFieldDecorator } = form
    return (
      <Form className="jz-modal-flex">
        <Row gutter={16}>
          <Col span={2} />
          <Col span={20}>
            <Form.Item
              hasFeedback
              label={
                <span className="justify">请输入休息时间(单位: 分钟)</span>
              }
              style={{ display: 'block', marginBottom: 0 }}
            >
              {getFieldDecorator(`restTime`, {
                rules: [
                  {
                    required: true,
                    message: '休息时间必填'
                  }
                ]
              })(
                <InputNumber
                  placeholder="请输入休息时间"
                  min={1}
                  max={60}
                  style={{ width: '100%' }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={2} />
        </Row>
        <Row gutter={16}>
          <Col span={2} />
          <Col span={20}>备注: 向系统发起休息请求</Col>
          <Col span={2} />
        </Row>
      </Form>
    )
  }
}

export default Form.create()(RestControl)
