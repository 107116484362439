/* eslint-disable no-case-declarations */
/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:53:02
 * @Last Modified by: gy
 * @Last Modified time: 2020-02-06 22:29:46
 * @Last Modified by: gy
 * @Last Modified time: 2020-07-21 15:26:49
 */
import * as constants from '../constants'
import MenuToComponent from '../../data/MenuToComponent'

const initialState = {
  menuList: [],
  refreshKey: '',
  isCloseCurrentPage: false,
  openAccessMenus: [
    {
      key: '/',
      title: '首页',
      pathname: '/',
      closable: true,
      content: MenuToComponent.dashboard
    } // 首页
  ],
  activeKey: '/'
}

export default function menuUpdate(state = initialState, { type, payload }) {
  switch (type) {
    case constants.USER_MENUS:
      return { ...state, menuList: payload, refreshKey: 'refresh_list' }
    case constants.PAGE_CURRENT_CLOSE:
      return { ...state, isCloseCurrentPage: true, ...payload }
    case constants.REFESH_REFRESHKEY:
      return { ...state, ...payload }
    case constants.PAGE_CURRENT_PARENT_REFRESH:
      return { ...state, isCloseCurrentPage: false, ...payload }
    case constants.PAGE_CURRENT_CLOSE_RESET:
      return { ...state, isCloseCurrentPage: false }
    case constants.USER_LOGGED_OUT:
      return {
        ...initialState,
        refreshKey: '',
        openAccessMenus: [
          {
            key: '/',
            title: '首页',
            pathname: '/',
            closable: false,
            content: MenuToComponent.dashboard
          } // 首页
        ],
        activeKey: '/'
      }
    case constants.USER_OUT:
      return {
        ...initialState,
        refreshKey: '',
        openAccessMenus: [
          {
            key: '/',
            title: '首页',
            pathname: '/',
            closable: false,
            content: MenuToComponent.dashboard
          } // 首页
        ],
        activeKey: '/'
      }
    case constants.PAGE_OPEN:
      return {
        ...state,
        ...payload
      }
    case constants.CLEAR_USER_MENUS:
      return state
    default:
      return state
  }
}
