/* eslint-disable react/prop-types */
/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:53:28
 * @Last Modified by: wangyafei
 * @Last Modified time: 2019-08-20 09:49:18
 */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

export default function(ComposedComponent) {
  class Authentication extends Component {
    PropTypes = {
      router: PropTypes.object
    }

    componentWillMount() {
      const { authenticated, history } = this.props
      if (!authenticated) {
        history.push('/login')
      }
    }

    componentWillUpdate(nextProps) {
      const { history } = this.props
      if (!nextProps.authenticated) {
        history.push('/login')
      }
    }

    render() {
      const { authenticated } = this.props
      return authenticated ? <ComposedComponent {...this.props} /> : null
    }
  }
  function mapStateToProps(state) {
    return {
      authenticated: state.loginReducer.isAuthenticated,
      tokenInfo: state.loginReducer.data
    }
  }
  return connect(mapStateToProps)(withRouter(Authentication))
}
