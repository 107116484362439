/*
 * @Author: wangyafei
 * @Date: 2019-10-25 10:21:17
 * @Last Modified by: zss
 * @Last Modified time: 2021-05-16 09:56:12
 */
import { DIAL_PHONE, AGENT_ASSISTANT, FILLIN_FORM, SET_WAIHU } from '../constants'

/**
 * 用户信息
 * @param {*} payload
 */
export const dispatchDailPhone = payload => {
  return dispatch =>
    dispatch({
      type: DIAL_PHONE,
      payload
    })
}

export const dispatchGetList = payload => {
  console.log(payload)
}

export const agentAssistant = payload => {
  return dispatch =>
    dispatch({
      type: AGENT_ASSISTANT,
      payload
    })
}
export const dispatchFillinForm = payload => {
  return dispatch =>
    dispatch({
      type: FILLIN_FORM,
      payload
    })
}

// 设置是否外呼
export const dispatchIsWaihu = payload => {
  return dispatch =>
    dispatch({
      type: SET_WAIHU,
      payload
  });
}
