/*
 * @Author: wangyafei
 * @Date: 2018-06-06 13:53:02
 * @Last Modified by: wangyafei
 * @Last Modified time: 2019-11-11 16:11:17
 */
import * as constants from '../constants'

const initialState = {
  data: null
}
export default function menuUpdate(state = initialState, { type, payload }) {
  switch (type) {
    case constants.SUPPLY_HEATING:
      return { ...state, data: payload }
    default:
      return state
  }
}
