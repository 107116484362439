/*
 * @Author: wangyafei
 * @Description: 新建工单 - 基础信息工单来源修改事件
 * @Date: 2018-06-06 13:53:02
 * @Last Modified by: wangyafei
 * @Last Modified time: 2019-11-08 23:34:24
 */
import * as constants from '../constants'

const initialState = {
  isShowNetwork: false
}

export default function changeSourceType(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case constants.CHANGE_SOURCE_TYPE:
      return { ...initialState, ...payload }
    default:
      return state
  }
}
