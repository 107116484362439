/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/*
 * @Author: wangyafei
 * @Date: 2019-08-19 09:32:31
 * @Last Modified by: zss
 * @Last Modified time: 2021-06-03 11:52:39
 */
import React from 'react'
import { Tabs, Icon, Modal } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import PhoneBar from './PhoneBar'
import PhoneBarcti from './PhoneBarcti5.6'
import MenuToComponent from '../../data/MenuToComponent'
import * as actions from '@/redux/actions/tabsAction'
import store from '@/redux/store'
import * as Constants from '@/redux/constants'
import { hasAuthorities } from '@/utils/utils'
import './tabRouter.less'

const { TabPane } = Tabs

let num = 1

const getSearchParams = url => {
  const theRequest = {}
  if (url.indexOf('?') !== -1) {
    const str = url.substr(1)
    const strs = str.split('&')
    for (let i = 0; i < strs.length; i += 1) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
    }
  }
  return theRequest
}

@connect(
  state => {
    const { tabsReducer, userReducer } = state
    return {
      ...userReducer,
      ...tabsReducer
    }
  },
  {
    ...actions
  }
)
class TabsRouter extends React.PureComponent {
  state = {
    visible: false,
    left: 0,
    top: 0,
    refreshStatus: false,
    customKey: 0,
    clickKey: 0
  }

  componentDidMount() {
    const { location } = this.props
    this.setOpenAccessMenu(location)

    document.addEventListener('click', this.handleClick)
  }

  componentWillReceiveProps(nextProps) {
    // eslint-disable-next-line react/prop-types
    const {
      location,
      isCloseCurrentPage,
      dispatchResetPageCurrent,
      activeKey
    } = nextProps
    // 关闭当前tab页面
    if (isCloseCurrentPage) {
      // 关闭当前页面
      dispatchResetPageCurrent()
      this.remove(activeKey)
    } else {
      const { pathname, search } = location
      if (activeKey !== pathname + search) {
        this.setOpenAccessMenu(location)
      }
    }
  }

  // 设置打开的tab页面
  setOpenAccessMenu = (loc, ma) => {
    const { pathname, search } = loc
    const {
      menuList,
      onOpenMenu,
      openAccessMenus,
      dispatchOpenPage
    } = this.props
    let { activeKey } = this.props
    const searchObj = getSearchParams(search)
    if (pathname !== '/' && pathname !== '/dashboard/') {
      const pathNameAll = pathname + search
      const result = /\/([a-z_A-Z0-9]+)\/??/g.exec(pathname)
      const resPathName = result[1]
      let tabName = resPathName === 'acc_popup_order' ? '来电工单' : ''
      // 定义图标名称
      let tabIconName = ''
      menuList.forEach(im => {
        if (im.href) {
          const imHref = /\/([a-z_A-Z0-9]+)\/??/g.exec(im.href)
          if (imHref && imHref[1] === resPathName) {
            tabName = im.menuName
            tabIconName = im.iconName === undefined ? '' : im.iconName
            if (onOpenMenu) {
              onOpenMenu(im.id)
            }
          }
        }
        if (im.children) {
          im.children.forEach(imf => {
            const imHref = /\/([a-z_A-Z0-9]+)\/??/g.exec(imf.href)
            if (imHref && imHref[1] === resPathName) {
              tabName = imf.menuName
              tabIconName = im.iconName === undefined ? '' : im.iconName
              if (onOpenMenu) {
                onOpenMenu(imf.id)
              }
            }

            if (imf.children) {
              imf.children.forEach(imff => {
                const imfHref = /\/([a-z_A-Z0-9]+)\/??/g.exec(imff.href)
                if (imfHref && imfHref[1] === resPathName) {
                  tabName = imff.menuName
                  tabIconName = im.iconName === undefined ? '' : im.iconName
                  if (onOpenMenu) {
                    onOpenMenu(imff.id)
                  }
                }
              })
            }
          })
        }
      })
      const item = openAccessMenus.filter(im => im.key === pathNameAll)
      if (item.length <= 0) {
        if (MenuToComponent[resPathName]) {
          if (tabName) {
            openAccessMenus.push({
              ...loc,
              content: MenuToComponent[resPathName],
              key: pathNameAll,
              closable: true,
              match: ma,
              title: resPathName === '404' ? '页面未找到' : tabName,
              icons: tabIconName,
              ...searchObj
            })
            activeKey = pathNameAll
          } else {
            Modal.error({
              title: '无权访问菜单地址，请联系系统管理员！',
              onOk: () => {
                this.remove(resPathName)
              }
            })
          }
        } else {
          const notFoundItem = openAccessMenus.filter(im => im.key === '/404/')
          if (notFoundItem.length <= 0) {
            openAccessMenus.push({
              ...loc,
              content: MenuToComponent['404'],
              key: '/404/',
              closable: true,
              match: ma,
              title: '页面未找到',
              icons: tabIconName
            })
          }
          activeKey = '/404/'
        }
      } else {
        activeKey = pathNameAll
      }
      dispatchOpenPage({
        openAccessMenus,
        activeKey
      })

      if (
        resPathName.indexOf('detail') !== -1 &&
        ![
          'statistics_call_dispatch_details',
          'statistics_back_details'
        ].includes(resPathName)
      ) {
        this.waterMark()
      } else {
        const self = document.getElementById('watermark')
        if (self) {
          document.body.removeChild(self)
        }
      }
    } else {
      dispatchOpenPage({
        activeKey: pathname
      })
    }
  }

  // Tab页签改变事件
  onChangeTab = isRefreshActiveKey => {
    const { dispatchOpenPage } = this.props
    const { customKey } = this.state
    dispatchOpenPage({
      activeKey: isRefreshActiveKey
    })
  }

  // Tab点击事件，用来修改URL
  onTabClick = ak => {
    const { history, openAccessMenus, activeKey } = this.props
    const { customKey } = this.state
    this.setState({
      refreshStatus: false,
      clickKey: customKey !== 0 ? customKey : 0
    })
    if (ak === 'dashboard') {
      history.push('/')
      return
    }
    if (ak !== activeKey) {
      const item = openAccessMenus.filter(im => im.key === ak)
      history.push(item[0].key)
    }

    if (
      ak.indexOf('detail') !== -1 &&
      ![
        '/statistics_call_dispatch_details/',
        '/statistics_back_details/'
      ].includes(ak)
    ) {
      this.waterMark()
    } else {
      const self = document.getElementById('watermark')
      if (self) {
        document.body.removeChild(self)
      }
    }
  }

  // Tab页编辑事件，包括编辑和删除
  doOnEdit = (targetKey, action) => {
    if (targetKey.includes('wait_for_revisit_detail')) {
      store.dispatch({
        type: Constants.REVISIT_DETAIL,
        isLonge: false
      })
    }
    this[action](targetKey)
  }

  // 删除Tab页签
  remove = targetKey => {
    const { history, openAccessMenus, dispatchOpenPage } = this.props
    let { activeKey } = this.props
    let lastIndex
    openAccessMenus.forEach((pane, i) => {
      if (pane.key === activeKey) {
        lastIndex = i - 1
      }
    })

    const panes = openAccessMenus.filter(pane => pane.key !== targetKey)
    if (lastIndex >= 0 && activeKey === targetKey) {
      activeKey = panes[lastIndex].key
    }
    const path = openAccessMenus.filter(s => s.key === activeKey)[0].key
    history.push(path)
    dispatchOpenPage({
      openAccessMenus: panes,
      activeKey
    })
    if (
      path.indexOf('detail') !== -1 &&
      ![
        '/statistics_call_dispatch_details/',
        '/statistics_back_details/'
      ].includes(path)
    ) {
      this.waterMark()
    } else {
      const self = document.getElementById('watermark')
      if (self) {
        document.body.removeChild(self)
      }
    }
  }

  // 右键菜单
  handleContextmenu = e => {
    e.preventDefault()
    const x = e.clientX
    const y = e.clientY
    this.setState({
      visible: true,
      left: x,
      top: y
    })
  }

  // 刷新
  refresh = () => {
    this.setState({
      refreshStatus: true,
      customKey: (num += 1)
    })
  }

  // 关闭右键菜单
  handleClick = () => {
    this.setState({
      visible: false
    })
  }

  getWidth = () => {
    if (window.innerWidth) {
      return window.innerWidth
    }
    if (document.compatMode === 'CSS1Compat') {
      return document.documentElement.clientWidth
    }
    return document.body.clientWidth
  }

  // 背景水印效果
  waterMark = (timeCur = moment().format('YYYY-MM-DD hh:mm:ss')) => {
    // localStorage.removeItem('waterMark')
    const self = document.getElementById('watermark')
    if (self) {
      document.body.removeChild(self)
    }
    const { userInfoData } = this.props
    const searchObjState = window.location.href
    const search = getSearchParams(decodeURI(searchObjState))
    const username = userInfoData?.userName
    const orderNum = search?.orderNum

    const canvas = document.createElement('canvas')
    if (!canvas.getContext) {
      // 你的浏览器不支持canvas!
      return
    }
    canvas.width = 500
    canvas.height = 300
    const ctx = canvas.getContext('2d')
    ctx.font = '18px  PingFangSC-Regular'
    ctx.rotate((-10 * Math.PI) / 180)
    ctx.fillStyle = '#404040'
    ctx.globalAlpha = 0.1
    ctx.fillText(timeCur, 10, 70)
    ctx.fillText(username, 10, 100)

    if (typeof orderNum !== 'undefined') {
      ctx.fillText(orderNum, 10, 130)
    }
    /* ctx.fillText(timeCur, 10, 260)
    ctx.fillText(username, 10, 290)
    ctx.fillText(orderNum, 10, 320)

    ctx.fillText(timeCur, 220, 260)
    ctx.fillText(username, 220, 290)
    ctx.fillText(orderNum, 220, 320)

    ctx.fillText(timeCur, 400, 260)
    ctx.fillText(username, 400, 290)
    ctx.fillText(orderNum, 400, 320) */

    const newImg = ctx.canvas.toDataURL()
    const wrapper = document.createElement('div')
    wrapper.id = 'watermark'
    wrapper.style.backgroundImage = `url(${newImg})`
    document.body.appendChild(wrapper)

    // localStorage.setItem('waterMark', newImg)
  }

  render() {
    const { style, currentUserInfo, activeKey, openAccessMenus } = this.props
    const { authorities = [] } = currentUserInfo || {}
    const {
      visible,
      left,
      top,
      refreshStatus,
      customKey,
      clickKey
    } = this.state
    // console.log(clickKey)
    return (
      <div>
        {visible && (
          <div
            id="contextmenu"
            style={{ left: `${left}px`, top: `${top}px` }}
            ref={ref => {
              this.contextmenu = ref
            }}
          >
            <ul>
              <li onClick={this.refresh} role="presentation">
                刷新当前
              </li>
            </ul>
          </div>
        )}
        <Tabs
          hideAdd
          tabBarStyle={{
            position: 'fixed',
            top: '60px',
            right: 0,
            zIndex: 99,
            background: '#ddd',
            left: '240px',
            transition: 'all .2s',
            ...style
          }}
          onChange={this.onChangeTab}
          activeKey={activeKey}
          onEdit={this.doOnEdit}
          type="editable-card"
          className="jz-tabs-bar"
          onTabClick={this.onTabClick}
          tabBarExtraContent={
            hasAuthorities(authorities, 'SEAT_SIGNIN') ? (
               // 判断环境是cti3.8版本还是3.6版本
              <>{Phone.isIeBrowser() === 'IE' || Phone.isIeBrowser() !== 'Google Chrome' ? <PhoneBarcti /> : <PhoneBar />}</>
              ) : null
          }
          // tabBarExtraContent={
          //   hasAuthorities(authorities, 'SEAT_SIGNIN') ? <PhoneBarcti />: null
          // }
        >
          {openAccessMenus.map(pane => {
            const {
              content: Page,
              title,
              closable,
              key,
              icons,
              ...props
            } = pane
            if (icons === 'exclamation-circle') {
              return (
                <TabPane
                  forceRender
                  tab={
                    <span
                      style={{ color: '#f0917f' }}
                      // onContextMenu={this.handleContextmenu}
                      role="presentation"
                    >
                      <Icon
                        type={icons}
                        style={{ color: '#f0917f', fontSize: 16 }}
                      />
                      {pane.title}
                    </span>
                  }
                  closable={pane.title === '首页' ? false : pane.closable}
                  key={pane.key}
                  style={{ top: 40, position: 'relative', paddingBottom: 40 }}
                >
                  {/* <Page
                    key={
                      refreshStatus && pane.key === activeKey ? customKey : ''
                    }
                    {...props}
                  /> */}
                  <Page {...props} />
                </TabPane>
              )
            }
            return (
              <TabPane
                forceRender
                tab={
                  <span
                    // onContextMenu={this.handleContextmenu}
                    role="presentation"
                  >
                    {pane.title}
                  </span>
                }
                closable={pane.title === '首页' ? false : pane.closable}
                key={pane.key}
                style={{ top: 40, position: 'relative', paddingBottom: 40 }}
              >
                {/* <Page
                  key={refreshStatus && pane.key === activeKey ? customKey : ''}
                  {...props}
                /> */}
                <Page {...props} />
              </TabPane>
            )
          })}
        </Tabs>
      </div>
    )
  }
}

TabsRouter.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      menuName: PropTypes.string,
      iconName: PropTypes.string
    })
  ).isRequired,
  onOpenMenu: PropTypes.func.isRequired,
  userInfoData: PropTypes.shape({
    userName: PropTypes.string
  }).isRequired
}

export default withRouter(TabsRouter)
